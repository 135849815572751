import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import PageTitle from '../../Utilites/PageTitle';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../Context/LocalizationContext';
export default function BMICalculator(props) {
    // translation 
    const { t } = useTranslation("HealthHub");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // const [modalShow, setModalShow] = React.useState(false);


    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    // state
    const [weight, setWeight] = useState('')
    const [height, setHeight] = useState('')
    const [bmi, setBmi] = useState('')
    const [message, setMessage] = useState('')

    let calcBmi = (event) => {
        //prevent submitting to the server
        event.preventDefault()

        if (weight === '' || height === '') {
            alert(t('Please enter a valid weight and height'))
        } else {
            let bmi = (weight / ((0.01 * height) * (0.01 * height)))
            setBmi(bmi.toFixed(1))

            // Logic for message

            if (bmi < 18.5) {
                setMessage(t('You are underweight'))
            } else if (bmi >= 18.5 && bmi < 24.9) {
                setMessage(t('You are a Normal weight'))
            } else if (bmi >= 25 && bmi < 29.9) {
                setMessage(t('You are a overweight'))
            } else {
                setMessage(t('obesity'))
            }
        }
    }


    return <>


        {/* <!--page title start--> */}

        {i18n.language === 'en' ? <PageTitle title={t('BMI')} anotherTitle={t('Calculator')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/health-tools/bmi`} currentPage={t('BMI Calculator')} /> : <PageTitle title={t('Calculator')} anotherTitle={t('BMI')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/health-tools/bmi`} currentPage={t('BMI Calculator')} />}


        {/* <!--page title end--> */}


        {/* <!--body content start--> */}

        <div className="page-content mt-5 mb-5 pt-5 overflow-hidden">

            {/* <!--appointment start--> */}

            <section class="theme-bg text-white position-relative pb-0 pb-lg-11 mt-5 pt-5">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-xl-8 col-lg-7 col-md-12 ms-auto ps-lg-11 order-lg-1">
                            <div class="section-title text-center">
                                {/* <h6>Reservation</h6> */}
                                <h2 class="title text-white">{t('BMI Calculator!')}
                                </h2>
                                <p class="mb-0"> {t("Body Mass Index is a simple calculation using a person's height and weight.")}</p>
                            </div>
                            <form class="row" onSubmit={calcBmi} >
                                <div class="messages"></div>
                                <div class="form-group d-flex justify-content-between col-sm-6">
                                    <input value={weight} onChange={(e) => setWeight(e.target.value)} class="form-control" placeholder={t("Your Weight")} required="required" data-error="Valid Your Weight is required." />

                                    <div class="help-block with-errors"></div>
                                    <div className="form-group col-sm-4 ms-5 pt-5">
                                        <span>{t('Kg')}</span>
                                    </div>
                                </div>

                                <div class="form-group d-flex justify-content-between col-sm-6">
                                    <input value={height} onChange={(event) => setHeight(event.target.value)} class="form-control" placeholder={t("Your Height")} required="required" data-error="Valid Your Height is required." />
                                    <div class="help-block with-errors"></div>
                                    <div className="form-group col-sm-4 ms-5 pt-5">
                                        <span>{t('Cm')}</span>
                                    </div>
                                </div>



                                <div class="col-sm-12 my-5 text-center">
                                    <button class="btn btn-white btn-radius" type='submit' onClick={handleShow}><span>{t('Compute BMI')}</span>
                                    </button>


                                    <Modal   {...props}
                                        size="lg"
                                        style={customStyles}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered show={show} onHide={handleClose} className="top-0 ">

                                        <Modal.Header closeButton>
                                            <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                            <Modal.Title style={{ color: "#4ab242" }}>{t('BMI Calculator')}</Modal.Title>
                                        </Modal.Header>

                                        <Modal.Body>
                                            <div className="team-member text-center">
                                                {/* <div className="team-images">
                                                    <img className="img-fluid" src="images/team/04.jpg" alt="" />
                                                </div> */}
                                                <div className="team-description pt-0"> <span>{t('Hello')}</span>
                                                    <h5 className="mb-4"><Link> {t('BMI Result')}</Link></h5>
                                                    {/* <span>Your BMI IS :</span>
                                                    <p>Normal Weight = 18.5 : 24.9 </p> */}
                                                    <div className="social-icons social-colored social-fullwidth">
                                                        <ul style={{ marginBottom: " 2px" }}>
                                                            <li className="bg-black fw-bolder"><Link>{t('Your BMI Is')} :</Link>
                                                            </li>
                                                            <li><Link className='bg-light text-muted'>{bmi}</Link>
                                                            </li>

                                                        </ul>
                                                        <div className="social-icons social-colored social-fullwidth">
                                                            <ul>
                                                                <li className="bg-black fw-bolder"><Link>{t('Your Message Is')}:</Link>
                                                                </li>
                                                                <li><Link className='bg-light text-muted'>{message}</Link>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="social-icons social-colored social-fullwidth">
                                                    <ul style={{ margin: " 2px" }}>
                                                        <li className="social-facebook"><Link>Name :</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>Mr. John Maxwell</Link>
                                                        </li>

                                                    </ul>
                                                    <ul style={{ margin: " 2px" }}>
                                                        <li className="social-twitter"><Link>Phone Number :</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>0120255662</Link>
                                                        </li>

                                                    </ul>
                                                    <ul style={{ margin: " 2px" }}>
                                                        <li className="social-gplus"><Link>Pin Number :</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>45115</Link>
                                                        </li>

                                                    </ul>
                                                    <ul style={{ margin: "2px" }}>
                                                        <li className="social-linkedin"><Link>Email :</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>john-naxwell@gmail.com</Link>
                                                        </li>
                                                    </ul>

                                                </div> */}

                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                {t('Close')}
                                            </Button>

                                        </Modal.Footer>

                                    </Modal>
                                </div>
                            </form>
                        </div>
                        <div class="col-xl-4 col-md-12 ps-lg-0 d-lg-block d-none">
                            <div class="appoinment-img mt-8 mt-lg-0 top-0">
                                <img class="img-fluid w-75" src="/images/about/01.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div >
            </section >

        </div >

        {/* <!--appointment end--> */}
    </>
}
