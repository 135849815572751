
import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationContext } from "./LocalizationContext";
export let GetDoctorsOptionsContext = createContext('');

export default function GetDoctorsOptionsContextProvider(props) {
    // translation 
    const { t } = useTranslation("BookNow");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    let [DoctorsOptions, setDoctorsOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedBranch, setSelectedBranch] = useState({ label: t('Select Branch') });

    async function GetDoctorsSelectOptions() {

        let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/booking/doctors/${selectedBranch.value}/en?department_id=${selectedOption.value}`);
        setDoctorsOptions(data);

        // console.log('DETAILS >>> ' + DoctorsOptions);
        // console.log(data);

    }
    // useEffect(() => { GetDoctorsSelectOptions() }, [])


    return <>
        <GetDoctorsOptionsContext.Provider value={{
            GetDoctorsSelectOptions, DoctorsOptions, setDoctorsOptions, selectedOption, setSelectedOption, selectedBranch, setSelectedBranch

        }}>
            {props.children}
        </GetDoctorsOptionsContext.Provider>

    </>
}
