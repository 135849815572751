import Cookies from 'js-cookie';
import React, { useEffect, useState, useContext } from 'react'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import jsonData from './../../nurses.json';
import Select from 'react-select';
import { LocalizationContext } from '../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';
export default function NursesNomination(props) {

    let options = {}
    options = jsonData.options;
    const { pathname } = useLocation();

    const [nurseName, setNurseName] = useState('');

    const [nurseId, setNurseId] = useState({});
    const [patientPin, setPatientPin] = useState('');
    const [patientName, setPatientName] = useState('');
    const [roomNumber, setRoomNumber] = useState('');
    const [question2, setQuestion2] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [dateOfVisit, setDateOfVisit] = useState('');


    const [floor, setFloor] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    const validateForm = () => {
        const errors = [];
        const phoneNumberRegex = /^[\+]?[0-9]{0,3}\W?[()0-9]{0,3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

        if (!nurseName) {
            errors.push('Nurse Name is required.');
        }

        if (!roomNumber) {
            errors.push('Room Number is required.');
        }
        if (!floor) {
            errors.push('Unit/Floor is required.');
        }
        if (!patientName) {
            errors.push('Your Name is required.');
        }

        if (!dateOfVisit) {
            errors.push('Date Of Visit is required.');
        }

        if (!phone) {
            errors.push('Your phone number is required.');
        }
        else if (!phoneNumberRegex.test(phone)) {
            errors.push('Please enter a valid phone number. Expected format: [+][Country Code][Area Code][Phone Number].');
        }
        if (!question2) {
            errors.push('Patient status is required.');
        }
        if (!message) {
            errors.push('patient situation is required.');
        }

        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append('nurse name', nurseName);
            bodyFormData.append('room number', roomNumber);
            bodyFormData.append('Unit/Floor', floor);
            bodyFormData.append('patient name', patientName);
            bodyFormData.append('Date of Visit', dateOfVisit);
            bodyFormData.append('phone', phone);
            bodyFormData.append('patient status', question2);
            bodyFormData.append('patient situation', message);

            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/nurse-nomination`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg(t('submit msg'));
                        setNurseName('');
                        setRoomNumber('');
                        setFloor('');
                        setPatientName('');
                        setDateOfVisit('');
                        setPhone('');
                        setQuestion2('');
                        setMessage('');
                        // setMessageForm('Thank you for submitting the Nurse Nanomotion form. ');
                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    // const handleSelectedNurse = (nurseName) => {

    //     setNurseName(nurseName.label);
    //     setNurseId(nurseName.value);

    // };
    // const customFilterOption = (option, rawInput) => {
    //     const inputValue = rawInput.toLowerCase();
    //     const label = option.label.toLowerCase();

    //     return label.startsWith(inputValue);
    // };
    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    const navigate = useNavigate();
    // window.alert(`${lang}`);
    let { lang } = useParams();
    const { t } = useTranslation("NurseNomination");

    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    const changeLanguage = (lang, dir) => {
        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
        document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    }
    useEffect(() => {
        if (pathname.includes('/en')) {
            // Cookies.set('i18next', 'en')

            // i18n.language = Cookies.get('i18next');

            // changeLanguage('en', 'ltr')
            document.getElementsByTagName("html")[0].setAttribute("lang", 'en');
            document.getElementsByTagName("html")[0].setAttribute("dir", 'ltr');


        } else {
            // i18n.language = Cookies.get('i18next');

            // Cookies.set('i18next', 'ar')
            // changeLanguage('ar', 'rtl')
            document.getElementsByTagName("html")[0].setAttribute("lang", 'ar');
            document.getElementsByTagName("html")[0].setAttribute("dir", 'rtl');


        }


        // fixed hedader when scroll down




    }, [])

    useEffect(() => {


        if (i18n.language === 'ar') {

            changeLanguage('ar', 'rtl')
            Cookies.set('i18next', 'ar')

        } else {
            changeLanguage('en', 'ltr')
            Cookies.set('i18next', 'en')

        }




    }, [Navigate, navigate]);
    return <>
        <section className="page-title overflow-hidden grey-bg">
            <div className="container">
                <div className="row align-items-center position-relative p-5  justify-content-between nurse-title-container">
                    <div className="col-md-6 position-absolute end-0  text-end nurse-title">
                        <h1 className=" mb-0 ">{t('title-1')}<span> {t('title-2')}</span></h1>

                    </div>
                    <div className="col-md-5 position-absolute start-0 text-start nurse-img">
                        <a className="navbar-brand logo logo-nurse py-1" href={`/#/sgh/${Cookies.get('i18next')}`}>
                            <img id="logo-img" className="img-fluid" src='/images/logos/Health logo of egypt-min.webp' alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </section>

        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">

                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-6 p-5 order-3">
                                            <div className='row justify-content-center'>
                                                <div class="section-title mb-5">
                                                    {/* <h2 class="title mb-3 text-center">complete <span>Form</span></h2> */}

                                                    <h6>{t('thanks')}</h6>

                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label>{t('Nurses Name')}</label>
                                                    <input type='text' value={nurseName} className={`form-control ${errors.includes('Nurse Name is required.') ? 'is-invalid' : ''}`} onChange={(e) => setNurseName(e.target.value)} />

                                                    {errors.includes('Nurse Name is required.') && (
                                                        <div className="invalid-feedback">Nurse Name is required.</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>{t("Date of Visit")}</label>
                                                    <input type="date" name="DATE" className={`form-control ${errors.includes('Date Of Visit is required.') ? 'is-invalid' : ''}`} value={dateOfVisit}
                                                        onChange={(e) => setDateOfVisit(e.target.value)}
                                                    />

                                                    {errors.includes('Date Of Visit is required.') && (
                                                        <div className="invalid-feedback">Date Of Visit is required.</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>{t('Room')}
                                                    </label>
                                                    <input type="text" name="room" className={`form-control ${errors.includes('Room Number is required.') ? 'is-invalid' : ''}`} value={roomNumber} onChange={(e) => setRoomNumber(e.target.value)} data-error="Room Number is required." />
                                                    {errors.includes('Room Number is required.') && (
                                                        <div className="invalid-feedback">Room Number is required.</div>
                                                    )}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>{t('Unit/Floor')}</label>
                                                    <input type="text" name="floor" className={`form-control ${errors.includes('Unit/Floor is required.') ? 'is-invalid' : ''}`} value={floor}
                                                        onChange={(e) => setFloor(e.target.value)}
                                                    />

                                                    {errors.includes('Unit/Floor is required.') && (
                                                        <div className="invalid-feedback">Unit/Floor is required.</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>{t('Name')}</label>
                                                    <input type="text" name="experience" className={`form-control ${errors.includes('Your Name is required.') ? 'is-invalid' : ''}`} value={patientName}
                                                        onChange={(e) => setPatientName(e.target.value)}
                                                    />

                                                    {errors.includes('Your Name is required.') && (
                                                        <div className="invalid-feedback">Your Name is required.</div>
                                                    )}
                                                </div>
                                                {/* <div className="form-group col-md-6">
                                                    <label>{t('Pin Number')}</label>
                                                    <input type="text" maxLength={10} name="experience" className={`form-control ${errors.includes('Your Pin Number is required.') ? 'is-invalid' : ''} ${errors.includes('Please enter a numeric value for Patient PIN.') ? 'is-invalid' : ''}`} value={patientPin}
                                                        onChange={(e) => setPatientPin(e.target.value)}
                                                    />

                                                    {errors.includes('Your Pin Number is required.') && (
                                                        <div className="invalid-feedback">Your Pin Number is required.</div>
                                                    )}
                                                    {errors.includes('Please enter a numeric value for Patient PIN.') && (
                                                        <div className="invalid-feedback">Please enter a numeric value for Patient PIN.</div>
                                                    )}
                                                </div> */}

                                                {/* <div className="form-group col-md-6">
                                                    <label>{t('Email')}</label>
                                                    <input type="email" name="email" className={`form-control ${errors.includes('Please enter a valid email address. Expected format: example@example.com.') ? 'is-invalid' : ''} ${errors.includes('Your Email is required.') ? 'is-invalid' : ''}`} value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                    {errors.includes('Your Email is required.') && (
                                                        <div className="invalid-feedback">Your Email is required.</div>
                                                    )}
                                                    {errors.includes('Please enter a valid email address. Expected format: example@example.com.') && (
                                                        <div className="invalid-feedback">Please enter a valid email address. Expected format: example@example.com.</div>
                                                    )}
                                                </div> */}
                                                <div className="form-group col-md-6">
                                                    <label>{t('Phone')}</label>
                                                    <input type="text" name="phone" className={`form-control ${errors.includes('Your phone number is required.') ? 'is-invalid' : ''} ${errors.includes('Please enter a valid phone number. Expected format: [+][Country Code][Area Code][Phone Number].') ? 'is-invalid' : ''}`} value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                    {errors.includes('Your phone number is required.') && (
                                                        <div className="invalid-feedback">Your phone number is required.</div>
                                                    )}
                                                    {errors.includes('Please enter a valid phone number. Expected format: [+][Country Code][Area Code][Phone Number].') && (
                                                        <div className="invalid-feedback">Please enter a valid phone number. Expected format: [+][Country Code][Area Code][Phone Number].</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className={`${errors.includes('Patient status is required.') ? 'is-invalid' : ''}`}>{t('optins')} </label>
                                                </div>
                                                <div className="form-group col-md-6">

                                                    <input
                                                        type="radio" className='mx-2' id='Patient'
                                                        value="Patient"
                                                        checked={question2 === 'Patient'}
                                                        onChange={(e) => setQuestion2(e.target.value)}
                                                    />
                                                    <label htmlFor='Patient'>  {t('opt-1')}</label>

                                                    <input className='ms-3 mx-2' id='visitor'
                                                        type="radio"
                                                        value="Visitor"
                                                        checked={question2 === 'Visitor'}
                                                        onChange={(e) => setQuestion2(e.target.value)}
                                                    />
                                                    <label htmlFor='visitor'>  {t('opt-2')}</label>
                                                    <input className='ms-3 mx-2' id='staff'
                                                        type="radio"
                                                        value="Staff"
                                                        checked={question2 === 'Staff'}
                                                        onChange={(e) => setQuestion2(e.target.value)}
                                                    />
                                                    <label htmlFor='staff'>  {t('opt-3')}</label>


                                                    {errors.includes('Patient status is required.') && (
                                                        <div className="invalid-feedback">Patient status is required.</div>
                                                    )}
                                                </div>


                                                <div className="form-group col-md-12">
                                                    <label>{t('textarea')}
                                                    </label>
                                                    <textarea id="form_address" type="text" name="message" className={`form-control ${errors.includes('patient situation is required.') ? 'is-invalid' : ''}`} value={message} onChange={(e) => setMessage(e.target.value)} >
                                                    </textarea>
                                                    {errors.includes('patient situation is required.') && (
                                                        <div className="invalid-feedback">patient situation is required.</div>
                                                    )}
                                                </div>


                                                <div className="col-md-12 row justify-content-center">
                                                    <button className="btn btn-border btn-circle col-md-5 mb-3 mb-md-0" type='submit'><span>{t("submit")}</span>
                                                    </button>
                                                    {i18n.language === 'en' && <button className='btn btn-border btn-circle col-md-5 me-2' onClick={() => {
                                                        i18n.changeLanguage('ar');
                                                        changeLanguage('ar', 'rtl');

                                                        navigate(`/${Cookies.get('i18next')}/${pathname.slice('4')}`)

                                                    }}>  <span>
                                                            العربية  </span>

                                                    </button>}
                                                    {i18n.language === 'ar' && <button className='btn btn-border btn-circle col-md-5 me-2' onClick={() => {
                                                        i18n.changeLanguage('en');
                                                        changeLanguage('en', 'ltr');

                                                        navigate(`/${Cookies.get('i18next')}/${pathname.slice('4')}`)


                                                    }}><span>
                                                            English</span>

                                                    </button>}


                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-5" style={{ backgroundImage: `url('/images/forms/nurse-nomination.jpeg')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                        </div> */}
                                        <div className="col-md-6 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/nurse-nomination.jpeg')`, backgroundSize: 'contain', backgroundPosition: 'top', backgroundRepeat: 'no-repeat' }}>
                                            <img src="/images/forms/nurse-nomination.png" alt="excon 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'contain', backgroundColor: '#61882b', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} />
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>{t('Your Message')}</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><a>{submitMsg}</a></h5>
                                                            {/* <p className='lead'>{messageForm}</p> */}
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        {t('Close')}
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
