import React, { useEffect } from 'react'

export default function BatarjiAwards() {

    return <>
        <div class="page-content" style={{ backgroundImage: 'url("/images/pattern/01.png")' }}>

            {/* <!--coming soon start--> */}

            <section class="fullscreen-banner p-0 overflow-hidden text-center" data-bg-img="/images/events/batrgi_awards.webp" style={{ backgroundImage: 'url("/images/events/batrgi_awards.webp")', backgroundSize: 'contain' }}>
                <div class="align-center position-sticky" style={{ paddingTop: '1200px' }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--coming soon end--> */}

        </div>
    </>
}
