import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import Carousel from "react-bootstrap/Carousel";

export default function LaboratoryAndBloodBank() {

    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Laboratory And ' anotherTitle='BloodBank' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services`} prevPage='Our Services' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services/laboratory-and-blood-bank`} currentPage='Laboratory & BloodBank' />
        {/* <!--page title end--> */}

        <div className="page-content">

            {/* <!--service start--> */}

            <section>
                <div class="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <Carousel indicators={false} slide={true}>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/03.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/02.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/01.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/03.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/02.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>

                            </Carousel>
                        </div>
                        <div className="col-lg-7 col-md-12 mt-5 mt-lg-0">
                            <div className="section-title mb-4">
                                <h2 className="title"> Our Services (Allied Health)
                                </h2>
                                <h6 className='h5'><span>Laboratory & blood bank </span></h6>
                                <p className="mb-0">Saudi Gorman Hospital operates a full-service laboratory, 24 hours a day, seven days a week.<br />
                                    The laboratory scope including the following specialties: Clinical Hematology / Clinical Chemistry/ Clinical immunology / Clinical Microbiology / Bone Marrow diagnosis unit / Flow cytometry / Blood bank unit / histopathology unit / Immunohistochemistry unit.<br />
                                    The Division of Clinical Chemistry provides a full range of biochemical analytic services for Saudi German Hospital- Cairo<br />
                                    The division offers clinical immunology support through therapeutic drug monitoring, hormone and tumor marker assays, serology testing, and assays of We nonligand nutrition and lipid analysis.<br />
                                    The Division of Hematology provide full range of hematological Diagnose including cell counting & coagulation.<br />
                                    Advanced units as molecular biology, immunohistochemistry & flowcytometry concerned mainly with diagnosis & prognosis of benign & malignant tumors.<br />
                                    Blood bank unit provide comprehensive scope including blood donation services, cross matching & blood transfusion services.<br />
                                    The laboratory supervised with attendant consultants of clinical pathology 24/7
                                </p>
                            </div>


                        </div>
                    </div>

                    <div className="row justify-content-center ">

                        <div className="section-title my-4">

                            <h6 className='h3'><span>Services : </span></h6>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>HEMATOLOGY & COAGULATION</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>MICROBIOLOGY</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-drug"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>FLOWCYTOMETRY</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-blood-donation"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>TRANSFUSION MEDICINE</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>IMMUNOLOGY & SEROLOGY</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>MOLECULAR BIOLOGY</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>DRUG MONITORING DEPARTMENT</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Ultrasound (2,3&4D) & Color Duplex</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>ANATOMIC PATHOLOGY & IMMUNOHISTOCHEMISTRY</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-drug"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>HORMONES AND TUMOR MARKERS DEPARTMENT</h6>
                                </div>
                                {/* <div class="featured-desc">
                <p>We have experience in different areas of Health Center, We offer our Better solutions your
                    treatment is appropriate.</p>
            </div> */}
                            </div>

                        </div>
                        {/* <div className="col-lg-4 col-md-6 mt-5 mt-md-0 footer-list ps-lg-5">
        <ul className='list-unstyled'>
            <li>HEMATOLOGY & COAGULATION
            </li>

            <li>MICROBIOLOGY
            </li>
            <li>FLOWCYTOMETRY
            </li>
            <li>TRANSFUSION MEDICINE
            </li>
            <li>IMMUNOLOGY & SEROLOGY
            </li>


        </ul>
    </div> */}
                        {/* <div className="col-lg-6 col-md-6 mt-5 mt-md-0 footer-list ps-lg-5">
        <ul className='list-unstyled'>

            <li>MOLECULAR BIOLOGY
            </li>
            <li>DRUG MONITORING DEPARTMENT
            </li>

            <li>Ultrasound (2,3&4D) & Color Duplex
            </li>
            <li>ANATOMIC PATHOLOGY & IMMUNOHISTOCHEMISTRY
            </li>
            <li>HORMONES AND TUMOR MARKERS DEPARTMENT
            </li>
        </ul>
    </div> */}

                    </div>
                </div>

            </section>


        </div>
    </>
}
