import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { GetDepartmentsOptionsContext } from '../../Context/GetDepartmentsOptions';

export default function ORServicesSatisfactionSurvey(props) {

    const { pathname } = useLocation();
    const navigate = useNavigate();


    const [department, setDepartment] = useState('');
    const [question_1, setQuestion_1] = useState('');
    const [question_2, setQuestion_2] = useState('');
    const [question_3, setQuestion_3] = useState('');
    const [question_4, setQuestion_4] = useState('');
    const [question_5, setQuestion_5] = useState('');
    const [question_6, setQuestion_6] = useState('');
    const [question_7, setQuestion_7] = useState('');
    const [question_8, setQuestion_8] = useState('');
    const [question_9, setQuestion_9] = useState('');
    const [question_10, setQuestion_10] = useState('');
    const [question_11, setQuestion_11] = useState('');
    const [question_12, setQuestion_12] = useState('');
    const [question_13, setQuestion_13] = useState('');
    const [question_14, setQuestion_14] = useState('');
    const [question_15, setQuestion_15] = useState('');
    const [question_16, setQuestion_16] = useState('');


    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    // const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    const validateForm = () => {
        const errors = [];

        // if (department.trim() === '') {
        //     errors.push('Please Enter Your Department.');
        // }


        if (!question_1) {
            errors.push('This Field Has been Required.');
        }
        if (!question_2) {
            errors.push('This Field Has been Required.');
        }
        if (!question_3) {
            errors.push('This Field Has been Required.');
        }
        if (!question_4) {
            errors.push('This Field Has been Required.');
        }

        if (!question_5) {
            errors.push('This Field Has been Required.');
        }
        if (!question_6) {
            errors.push('This Field Has been Required.');
        }
        if (!question_7) {
            errors.push('This Field Has been Required.');
        }
        if (!question_8) {
            errors.push('This Field Has been Required.');
        }
        if (!question_9) {
            errors.push('This Field Has been Required.');
        }
        if (!question_10) {
            errors.push('This Field Has been Required.');
        }
        if (!question_11) {
            errors.push('This Field Has been Required.');
        }
        if (!question_12) {
            errors.push('This Field Has been Required.');
        }
        if (!question_13) {
            errors.push('This Field Has been Required.');
        }
        if (!question_14) {
            errors.push('This Field Has been Required.');
        }
        if (!question_15) {
            errors.push('This Field Has been Required.');
        }

        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append('department', department);
            bodyFormData.append('How satisfied are you with the overall care provided to your patients within pre-operative services? ', question_1);
            bodyFormData.append('How satisfied are you with the availability to schedule your patients in a timely manner?', question_2);
            bodyFormData.append('How satisfied are you with the ability to add-on surgical cases?', question_3);
            bodyFormData.append('How satisfied are you with first case start time?', question_4);
            bodyFormData.append('How satisfied are you with case turnover time?', question_5);
            bodyFormData.append('How satisfied are you with the technical skills of operating room staff?', question_6);
            bodyFormData.append('How satisfied are you with the professionalism of operating room staff?', question_7);
            bodyFormData.append('How satisfied are you with the knowledge and help of anesthesiologists?', question_8);
            bodyFormData.append('How satisfied are you with the availability of the surgical instruments and supplies?', question_9);
            bodyFormData.append('How satisfied are you with the availability and suitability of the surgical attire?', question_10);
            bodyFormData.append('How satisfied are you with the safety environment in the operating room?', question_11);
            bodyFormData.append('How satisfied are you with the teamwork management in the operating room?', question_12);
            bodyFormData.append('How satisfied are you with the OR management response to your questions and concerns?', question_13);
            bodyFormData.append('Would you recommend SGH-Cairo operating room to your family and colleagues?', question_14);
            bodyFormData.append('Would you recommend SGH-Cairo Anesthesia team to your family and colleagues?', question_15);
            bodyFormData.append('What would you like to see changed to help make things more efficient? ', question_16);


            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/ORServicesSatisfactionSurvey`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Thank you for Registration!');
                        // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');
                        setQuestion_1('');
                        setQuestion_2('');
                        setQuestion_3('');
                        setQuestion_4('');
                        setQuestion_5('');
                        setQuestion_6('');
                        setQuestion_7('');
                        setQuestion_8('');
                        setQuestion_9('');
                        setQuestion_10('');
                        setQuestion_11('');
                        setQuestion_12('');
                        setQuestion_13('');
                        setQuestion_14('');
                        setQuestion_15('');
                        setQuestion_16('')
                        setDepartment('');


                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])


    // get departments
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    useEffect(() => {
        GetDepartmentsSelectOptions('1');

    }, [])
    return <>
        <PageTitle title='OR Services' anotherTitle='Satisfaction Survey' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/or-Services-Satisfaction-Survey`} currentPage='OR SERVICES SATISFACTION SURVEY' />
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='p-0'>
                <div className="container">
                    <div className="row">

                        <div className="post-comments">
                            <div className='row justify-content-center'>
                                <div className="section-title text-center mb-0">
                                    <h6 className="title my-3 text-center">WELCOME <span>TO THE SURGERY AUDIT QUESTIONNAIRE</span></h6>
                                    <p className='text-center'>
                                        By completing this survey, you can help OR Department focus its efforts and resources effectively while recognizing and prioritizing your needs. This survey is confidential and is for services specifically provided at SGH-Cairo. Thank you, in advance, for your contribution to this survey.
                                        <br />
                                        We look forward to providing you with continued and improved OR services.
                                        <br />  Your support is appreciated.

                                    </p>
                                </div>
                            </div>



                            <form onSubmit={submitForm}>
                                <div className="messages"></div>
                                <div className="row justify-content-center">
                                    <div className="col-md-7 p-5 order-3 box-shadow">

                                        <div className="row">

                                            <div className="form-group col-md-5">
                                                <input className='form-control' placeholder='Please indicate your department' value={department} onChange={(e) => setDepartment(e.target.value)} />


                                            </div>

                                            <div className="row">


                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the overall care provided to your patients within pre-operative services?</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques1-1'
                                                        value="Strongly Agree"
                                                        checked={question_1 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques1-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_1 === 'Agree'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques1-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_1 === 'Neither'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques1-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_1 === 'Disagree'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques1-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_1 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the availability to schedule your patients in a timely manner?</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques2-1'
                                                        value="Strongly Agree"
                                                        checked={question_2 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques2-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_2 === 'Agree'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques2-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_2 === 'Neither'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques2-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_2 === 'Disagree'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques2-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_2 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the ability to add-on surgical cases ?  </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques3-1'
                                                        value="Strongly Agree"
                                                        checked={question_3 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques3-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_3 === 'Agree'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques3-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_3 === 'Neither'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques3-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_3 === 'Disagree'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques3-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_3 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with first case start time ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques4-1'
                                                        value="Strongly Agree"
                                                        checked={question_4 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques4-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_4 === 'Agree'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques4-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_4 === 'Neither'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques4-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_4 === 'Disagree'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques4-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_4 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with case turnover time ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques5-1'
                                                        value="Strongly Agree"
                                                        checked={question_5 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques5-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_5 === 'Agree'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques5-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_5 === 'Neither'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques5-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_5 === 'Disagree'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques5-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_5 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the technical skills of operating room staff ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques6-1'
                                                        value="Strongly Agree"
                                                        checked={question_6 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques6-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_6 === 'Agree'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques6-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_6 === 'Neither'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques6-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_6 === 'Disagree'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques6-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_6 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the professionalism of operating room staff ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques7-1'
                                                        value="Strongly Agree"
                                                        checked={question_7 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques7-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_7 === 'Agree'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques7-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_7 === 'Neither'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques7-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_7 === 'Disagree'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques7-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_7 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the knowledge and help of anesthesiologists ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques8-1'
                                                        value="Strongly Agree"
                                                        checked={question_8 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques8-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_8 === 'Agree'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques8-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_8 === 'Neither'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques8-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_8 === 'Disagree'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques8-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_8 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the availability of the surgical instruments and supplies ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques9-1'
                                                        value="Strongly Agree"
                                                        checked={question_9 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques9-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_9 === 'Agree'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques9-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_9 === 'Neither'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques9-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_9 === 'Disagree'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques9-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_9 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the availability and suitability of the surgical attire ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques10-1'
                                                        value="Strongly Agree"
                                                        checked={question_10 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques10-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_10 === 'Agree'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques10-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_10 === 'Neither'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques10-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_10 === 'Disagree'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques10-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_10 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the safety environment in the operating room ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques11-1'
                                                        value="Strongly Agree"
                                                        checked={question_11 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_11(e.target.value)}
                                                    />
                                                    <label htmlFor='ques11-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques11-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_11 === 'Agree'}
                                                        onChange={(e) => setQuestion_11(e.target.value)}
                                                    />
                                                    <label htmlFor='ques11-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques11-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_11 === 'Neither'}
                                                        onChange={(e) => setQuestion_11(e.target.value)}
                                                    />
                                                    <label htmlFor='ques11-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques11-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_11 === 'Disagree'}
                                                        onChange={(e) => setQuestion_11(e.target.value)}
                                                    />
                                                    <label htmlFor='ques11-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques11-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_11 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_11(e.target.value)}
                                                    />
                                                    <label htmlFor='ques11-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the teamwork management in the operating room ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques12-1'
                                                        value="Strongly Agree"
                                                        checked={question_12 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques12-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_12 === 'Agree'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques12-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_12 === 'Neither'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques12-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_12 === 'Disagree'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques12-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_12 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>How satisfied are you with the OR management response to your questions and concerns ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques13-1'
                                                        value="Strongly Agree"
                                                        checked={question_13 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques13-2'
                                                        type="radio"
                                                        value="Agree"
                                                        checked={question_13 === 'Agree'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-2'>Agree</label>
                                                    <input className='ms-3 mx-2' id='ques13-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_13 === 'Neither'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques13-4'
                                                        type="radio"
                                                        value="Disagree"
                                                        checked={question_13 === 'Disagree'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-4'> Disagree</label>
                                                    <input className='ms-3 mx-2' id='ques13-5'
                                                        type="radio"
                                                        value="Strongly Disagree"
                                                        checked={question_13 === 'Strongly Disagree'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-5'> Strongly Disagree</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Would you recommend SGH-Cairo operating room to your family and colleagues ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques14-1'
                                                        value="Yes"
                                                        checked={question_14 === 'Yes'}
                                                        onChange={(e) => setQuestion_14(e.target.value)}
                                                    />
                                                    <label htmlFor='ques14-1'> Yes</label>

                                                    <input className='ms-3 mx-2' id='ques14-2'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_14 === 'No'}
                                                        onChange={(e) => setQuestion_14(e.target.value)}
                                                    />
                                                    <label htmlFor='ques14-2'>No</label>


                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Would you recommend SGH-Cairo Anesthesia team to your family and colleagues ? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques15-1'
                                                        value="Yes"
                                                        checked={question_15 === 'Yes'}
                                                        onChange={(e) => setQuestion_15(e.target.value)}
                                                    />
                                                    <label htmlFor='ques15-1'> Yes</label>

                                                    <input className='ms-3 mx-2' id='ques15-2'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_15 === 'No'}
                                                        onChange={(e) => setQuestion_15(e.target.value)}
                                                    />
                                                    <label htmlFor='ques15-2'>No</label>


                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div class="form-group col-md-12">
                                                    <label>What would you like to see changed to help make things more efficient ? </label>
                                                    <textarea id="form_address" type="text" name="message" className='form-control' value={question_16} onChange={(e) => setQuestion_16(e.target.value)} >
                                                    </textarea>

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <button className="btn btn-border btn-circle w-100" type='submit'><span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/survey-form-online.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                                <img src="/images/forms/survey-form-online.jpg" alt="patient survey 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'contain', backgroundColor: '#61882b', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} />
                                            </div> */}
                                    <div class="col-sm-12 text-center">
                                        <Modal   {...props}
                                            size="lg"
                                            style={customStyles}
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered show={show} onHide={handleClose} className="top-0 ">

                                            <Modal.Header closeButton>
                                                <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <div className="team-member text-center">

                                                    <div className="team-description pt-0">
                                                        <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                        {/* <p className='lead'>{messageForm}</p> */}
                                                    </div>



                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>

                                            </Modal.Footer>

                                        </Modal>
                                    </div>
                                </div>

                            </form>

                        </div>

                    </div >
                </div >
            </section >
        </div >
    </>
}
