import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { GetDepartmentsOptionsContext } from '../../Context/GetDepartmentsOptions';
import { InputGroup } from 'reactstrap';
import { LocalizationContext } from '../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';
export default function BreastfeedingEvent(props) {
    const { pathname } = useLocation();
    const navigate = useNavigate();


    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [preferredLocation, setPreferredLocation] = useState('');




    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    // const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    const validateForm = () => {
        const errors = [];

        if (name.trim() === '') {
            errors.push(t('Please Enter Your Name.'));
        }
        const phoneNumberRegex = /^[\+]?[0-9]{0,3}\W?[()0-9]{0,3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;


        if (!phoneNumber) {
            errors.push(t('phone Number is Required.'));
        }
        else if (!phoneNumberRegex.test(phoneNumber)) {
            errors.push(t('Please enter a valid phone number.'));
        }
        if (!email) {
            errors.push(t('Please Enter Your Email.'));
        }
        if (!question1) {
            errors.push(t('Please Enter Your Answer.'));
        }
        if (question1 === t('yes')) {
            if (!question2) {
                errors.push(t('Please Enter Number Of Months.'));
            }
        }

        if (!preferredLocation) {
            errors.push(t('Please Enter your preferred Location.'));
        }



        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append('Name', name);
            bodyFormData.append('phone Number', phoneNumber);
            bodyFormData.append('Email', email);
            bodyFormData.append('Are you currently pregnant ?', question1);
            if (question1 === t('yes')) {
                bodyFormData.append('Please Enter Number Of Months', question2);

            } else {
                bodyFormData.append('Please Enter Number Of Months', null);

            }
            bodyFormData.append('Preferred Location', preferredLocation);


            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/BreastfeedingEvent`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg(t('submitMsg'));
                        // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');

                        setName('');
                        setEmail('');
                        setPhoneNumber('');
                        setQuestion1('');
                        setQuestion2('');
                        setPreferredLocation('');

                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])

    let { lang } = useParams();
    const { t } = useTranslation("BreastfeedingEvent");

    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    const changeLanguage = (lang, dir) => {
        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
        document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    }
    useEffect(() => {
        if (pathname.includes('/en')) {
            // Cookies.set('i18next', 'en')

            // i18n.language = Cookies.get('i18next');

            // changeLanguage('en', 'ltr')
            document.getElementsByTagName("html")[0].setAttribute("lang", 'en');
            document.getElementsByTagName("html")[0].setAttribute("dir", 'ltr');


        } else {
            // i18n.language = Cookies.get('i18next');

            // Cookies.set('i18next', 'ar')
            // changeLanguage('ar', 'rtl')
            document.getElementsByTagName("html")[0].setAttribute("lang", 'ar');
            document.getElementsByTagName("html")[0].setAttribute("dir", 'rtl');


        }


        // fixed hedader when scroll down




    }, [])

    useEffect(() => {


        if (i18n.language === 'ar') {

            changeLanguage('ar', 'rtl')
            Cookies.set('i18next', 'ar')

        } else {
            changeLanguage('en', 'ltr')
            Cookies.set('i18next', 'en')

        }




    }, [Navigate, navigate]);
    return <>

        <div class="container d-flex align-items-start position-relative">
            <div class="row g-0 justify-content-center">
                <div class="col-lg-4 offset-lg-1 mx-0 px-0">
                    <div id="title-container">
                        <img class="covid-image rounded-circle w-100" src="../images/forms/post_result.webp" />
                        <h2 className='fs-3'>{t('Breastfeeding Event')}</h2>
                        <h3 className='fs-2 fw-bolder mb-3'>{t('Register Now!')}</h3>
                        <p>{t('title')}</p>
                    </div>
                </div>

                <div class="col-lg-7 mx-0 px-0" >

                    <div id="qbox-container">
                        <form class="needs-validation" id="form-wrapper" name="form-wrapper" onSubmit={submitForm}>
                            {/* <!-- STEPS HERE --> */}


                            <div id="steps-container">

                                <div className='row'>

                                    {/* <div className="col-md-12 mb-5">
                                        <h3 className='border-bottom pb-3'>Please Complete The Form!</h3>
                                    </div> */}
                                    <div className="col-md-10">
                                        <label class="form-label"> {t('Name')}</label>
                                        <input class={`form-registration ${errors.includes(t('Please Enter Your Name.')) ? 'is-invalid' : ''}`} value={name} onChange={(e) => setName(e.target.value)} data-error={t('Please Enter Your Name.')} type="text" />
                                        {errors.includes(t('Please Enter Your Name.')) && <div className="ivaild-error">{t('Please Enter Your Name.')}</div>}
                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> {t('Email')}</label>
                                        <input class={`form-registration ${errors.includes(t('Please Enter Your Email.')) ? 'is-invalid' : ''}`} value={email} onChange={(e) => setEmail(e.target.value)} data-error={t('Please Enter Your Email.')} type="email" />
                                        {errors.includes(t('Please Enter Your Email.')) && <div className="ivaild-error">{t('Please Enter Your Email.')}</div>}

                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> {t('phone Number')}</label>
                                        <input className={`form-registration ${errors.includes(t('phone Number is Required.')) ? 'is-invalid' : ''}${errors.includes(t('Please enter a valid phone number.')) ? 'is-invalid' : ''}`} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} data-error={t("phone Number is Required.")} type="text" />
                                        {errors.includes('phone Number is Required.') && <div className="ivaild-error">{t('phone Number is Required.')}</div>}
                                        {errors.includes(t('Please enter a valid phone number.')) && (
                                            <div className="invalid-feedback">{t("Please enter a valid phone number.")}</div>
                                        )}
                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> {t('Are you currently pregnant?')} </label>
                                        <div class="form-check d-flex p-0 q-box justify-content-md-between">
                                            <div class="q-box__question w-50 me-3">
                                                <input class="form-check-input question__input" id="q_2_yes" type="radio" value="Yes" checked={question1 === 'Yes'} onChange={(e) => setQuestion1(e.target.value)} />
                                                <label class="form-check-label question__label" for="q_2_yes">{t('yes')}</label>
                                            </div>
                                            <div class="q-box__question w-50">
                                                <input class="form-check-input question__input" id="q_2_no" type="radio" value="No" checked={question1 === 'No'} onChange={(e) => setQuestion1(e.target.value)} />
                                                <label class="form-check-label question__label" for="q_2_no">{t('no')}</label>
                                            </div>
                                        </div>

                                        {/* <input class={`form-registration ${errors.includes('Please Enter Your Answer.') ? 'is-invalid' : ''}`} value={question1} onChange={(e) => setQuestion1(e.target.value)} data-error="Please Enter Your Answer." type="text" /> */}
                                        {errors.includes(t('Please Enter Your Answer.')) && <div className="ivaild-error">{t('Please Enter Your Answer.')}</div>}

                                    </div>
                                    {question1 === 'Yes' ? <> <div className="col-md-10">
                                        <label class="form-label mt-4"> {t('If yes, how many months pregnant are you?')} </label>

                                        <input class={`form-registration ${errors.includes('Please Enter Number Of Months.') ? 'is-invalid' : ''}`} value={question2} onChange={(e) => setQuestion2(e.target.value)} data-error="Please Enter Number Of Months." type="number" />
                                        {errors.includes('Please Enter Number Of Months.') && <div className="ivaild-error">Please Enter Number Of Months.</div>}

                                    </div></> : ''}

                                    <div className="col-md-10 mt-4">
                                        <label class="form-label"> {t('preferred Location')}</label>
                                        {/* <input class={`form-registration ${errors.includes(t('Please Enter your preferred Location.')) ? 'ivaild-error' : ''}`} value={preferredLocation} onChange={(e) => setPreferredLocation(e.target.value)} data-error={t("Please Enter your preferred Location.")} type="text" /> */}
                                        <div class="form-check row d-flex p-0 q-box justify-content-md-between">
                                            <div class="q-box__question col-md-6 ">
                                                <input class="form-check-input question__input" id="q_2_Alexandria" type="radio" value="Alexandria 19/8" checked={preferredLocation === 'Alexandria 19/8'} onChange={(e) => setPreferredLocation(e.target.value)} />
                                                <label class="form-check-label question__label  mb-2 mb-md-0" for="q_2_Alexandria">{t('Alexandria 19/8')}</label>
                                            </div>
                                            <div class="q-box__question col-md-6">
                                                <input class="form-check-input question__input" id="q_2_Cairo" type="radio" value="Cairo 20/8" checked={preferredLocation === 'Cairo 20/8'} onChange={(e) => setPreferredLocation(e.target.value)} />
                                                <label class="form-check-label question__label" for="q_2_Cairo">{t('Cairo 20/8')}</label>
                                            </div>
                                        </div>
                                        {errors.includes(t('Please Enter your preferred Location.')) && (
                                            <div className="ivaild-error">{t('Please Enter your preferred Location.')}</div>
                                        )}
                                    </div>
                                </div>




                            </div>
                            <div id="q-box__buttons">

                                <button id="submit-btn" type='submit'>{t('Submit')}</button>

                                {i18n.language === 'en' && <button id="submit-btn" class="mt-3" onClick={() => {
                                    i18n.changeLanguage('ar');
                                    changeLanguage('ar', 'rtl');

                                    navigate(`/${Cookies.get('i18next')}/${pathname.slice('4')}`)

                                }}>  <span>
                                        العربية  </span>

                                </button>}
                                {i18n.language === 'ar' && <button id="submit-btn" class="mt-3" onClick={() => {
                                    i18n.changeLanguage('en');
                                    changeLanguage('en', 'ltr');

                                    navigate(`/${Cookies.get('i18next')}/${pathname.slice('4')}`)


                                }}><span>
                                        English</span>

                                </button>}


                            </div>



                        </form>
                    </div>
                    <div class="col-sm-12 text-center">
                        <Modal   {...props}
                            size="lg"
                            style={customStyles}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={show} onHide={handleClose} className="top-0 ">

                            <Modal.Header closeButton>
                                <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                <Modal.Title style={{ color: "#4ab242" }}>{t('Your Message')}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div className="team-member text-center">

                                    <div className="team-description pt-0">
                                        <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                        {/* <p className='lead'>{messageForm}</p> */}
                                    </div>



                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>

                            </Modal.Footer>

                        </Modal>
                    </div>
                </div>

            </div>
        </div>
    </>
}
