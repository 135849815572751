import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
// import { ReactRadioButtonsGroup, ReactRadioButton } from 'react-radio-buttons-group';
import { evaluate } from 'mathjs';
import PageTitle from '../../Utilites/PageTitle';
import Cookies from 'js-cookie';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../Context/LocalizationContext';

export default function HeartRiskCalculator(props) {
    // translation 
    const { t } = useTranslation("HealthHub");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [cholesterol, setCholesterol] = useState('');
    const [bloodPressure, setBloodPressure] = useState('');
    const [heartRisk, setHeartRisk] = useState(null);
    const { pathname } = useLocation();
    const [message, setMessage] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();

        // Perform heart risk calculation using the input values
        const risk = evaluate(`2 * ${gender === 'male' ? 3 : 2} +
          ${age >= 45 && age <= 54 ? 2 : age >= 55 && age <= 64 ? 3 : age >= 65 ? 4 : 0} +
          ${cholesterol >= 200 && cholesterol <= 239 ? 1 : cholesterol >= 240 ? 2 : 0} +
          ${bloodPressure >= 120 && bloodPressure <= 129 ? 1 : bloodPressure >= 130 && bloodPressure <= 139 ? 2 : bloodPressure >= 140 && bloodPressure <= 159 ? 2 : bloodPressure >= 160 ? 3 : 0}`
        );

        // Set the calculated heart risk
        setHeartRisk(risk);

        if (risk < 5) {
            setMessage(t('Low Risk'));
        } else if (risk >= 5 && risk <= 7.4) {
            // Borderline risk (5% to 7.4%)
            setMessage(t('Borderline Risk'));
        } else if (risk >= 7.5 && risk <= 19.9) {
            // Intermediate risk (7.5% to 19.9%)
            setMessage(t('Intermediate Risk'));
        } else if (risk >= 20) {
            // High risk (≥20%)
            setMessage(t('High risk'));
        } else {
            setMessage(t('invalid input'));

        }

    };


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // const [modalShow, setModalShow] = React.useState(false);


    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    return <>

        {/* <!--page title start--> */}

        {i18n.language === 'en' ? <PageTitle title={t('Heart Risk')} anotherTitle={t('Calculator')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/health-tools/heart-risk-calculator`} currentPage={t('Heart Risk Calculator')} /> : <PageTitle title={t('Calculator')} anotherTitle={t('Heart Risk')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/health-tools/heart-risk-calculator`} currentPage={t('Heart Risk Calculator')} />}


        {/* <!--page title end--> */}
        <div className="page-content mt-5 mb-5 pt-5 overflow-hidden">

            {/* <!--appointment start--> */}

            <section class="theme-bg text-white position-relative pb-0 pb-lg-11 mt-5 pt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-7 col-md-12 ms-auto ps-lg-11 order-lg-1">
                            <div class="section-title text-center">
                                {/* <h6>Reservation</h6> */}
                                <h2 class="title text-white">{t('Heart Risk Calculator!')}
                                </h2>
                                <p class="mb-0"> {t("Body Mass Index is a simple calculation using a person's height and weight.")}</p>
                            </div>
                            <form className="row" onSubmit={handleSubmit}>
                                <div className="form-group col-sm-6">

                                    <select
                                        id="gender"
                                        className="form-select form-control"
                                        value={gender}

                                        onChange={(e) => setGender(e.target.value)}
                                        required
                                    >
                                        <option value="">{t('Select gender')}</option>
                                        <option value="male">{t('Male')}</option>
                                        <option value="female">{t('Female')}</option>
                                    </select>
                                </div>
                                <div className="form-group col-sm-6">

                                    <input
                                        type="number"
                                        id="age"
                                        className="form-control"
                                        value={age}
                                        placeholder={t('Age')}
                                        onChange={(e) => setAge(Number(e.target.value))}
                                        required
                                    />
                                </div>
                                <div className="form-group col-sm-6">

                                    <input
                                        type="number"
                                        id="cholesterol"
                                        className="form-control"
                                        placeholder={t('Total Cholesterol')}
                                        value={cholesterol}
                                        onChange={(e) => setCholesterol(Number(e.target.value))}
                                        required
                                    />
                                </div>
                                <div className="form-group col-sm-6">
                                    <input
                                        type="number"
                                        id="bloodPressure"
                                        className="form-control"
                                        placeholder={t('Systolic Blood Pressure')}
                                        value={bloodPressure}
                                        onChange={(e) => setBloodPressure(Number(e.target.value))}
                                        required
                                    />
                                </div>
                                <div class="col-sm-12 mt-5 text-center">

                                    <button type="submit" className="btn btn-white btn-radius" onClick={handleShow}>{t('Calculate')}</button>
                                </div>
                                {/* Display the heart risk */}
                                {/* {heartRisk !== null && (
                                    <div className="mt-3">
                                        <h4>Heart Risk: {heartRisk}</h4>
                                    </div>
                                )} */}




                                <div class="col-sm-12 mt-5 text-center">



                                    <Modal   {...props}
                                        size="lg"
                                        style={customStyles}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered show={show} onHide={handleClose} className="top-0 ">

                                        <Modal.Header closeButton>
                                            <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                            <Modal.Title style={{ color: "#4ab242" }}>{t('Heart Risk Calculator')}</Modal.Title>
                                        </Modal.Header>

                                        <Modal.Body>
                                            <div className="team-member text-center">

                                                <div className="team-description pt-0"> <span>{t('Hello')}</span>
                                                    <h5 className="mb-4"><Link> {t('Heart Risk Result')}</Link></h5>

                                                    <div className="social-icons social-colored social-fullwidth">
                                                        <ul style={{ marginBottom: " 2px" }}>
                                                            <li className="bg-black fw-bolder"><Link>{t('Your Heart Risk Is')} :</Link>
                                                            </li>
                                                            {heartRisk !== null && (<li><Link className='bg-light text-muted'>{heartRisk}</Link>
                                                            </li>)}

                                                        </ul>
                                                        <div className="social-icons social-colored social-fullwidth">
                                                            <ul>
                                                                <li className="bg-black fw-bolder"><Link>{t('Your Message Is')}:</Link>
                                                                </li>
                                                                <li><Link className='bg-light text-muted'>{message}</Link>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                {t('Close')}
                                            </Button>

                                        </Modal.Footer>

                                    </Modal>
                                </div>
                            </form>
                        </div>
                        <div class="col-xl-4 col-md-12 ps-lg-0 d-lg-block d-none">
                            <div class="appoinment-img mt-8 mt-lg-0 top-0">
                                <img class="img-fluid w-75" src="/images/about/01.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div >
            </section >

        </div >
        {/* <!--body content start--> */}



        {/* <!--appointment end--> */}
        {/* <ReactRadioButtonsGroup group='sex' onChange={(value) => console.log(value)}>
            <ReactRadioButton value='male'>Male 👨</ReactRadioButton>
            <ReactRadioButton value='female'>Female 👩</ReactRadioButton>
        </ReactRadioButtonsGroup> */}
    </>
}
