import React from 'react'

export default function ({ partnerImgSrc, partnerTitle, partnerDescription }) {
    return (
        <section>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <img className="img-fluid rounded box-shadow w-100" height='1000px' src={partnerImgSrc} alt="" />
                    </div>
                    <div className="col-lg-7 col-md-12 mt-5 mt-lg-0">
                        <div className="section-title mb-4">
                            <h2 className="title fs-4">{partnerTitle}</h2>
                            {/* <h6 className='h5'><span>Saudi German Health</span></h6> */}
                            <p className="mb-0">
                                {partnerDescription}
                            </p>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}
