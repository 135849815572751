import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function AfterFooter() {
    // translation 
    const { t } = useTranslation("Footer");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 

    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const { pathname } = useLocation();
    const [showButton, setShowButton] = useState();

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const fullHeight = document.body.offsetHeight;

            // Adjust the scroll threshold as needed
            const scrollThreshold = fullHeight - windowHeight;

            setShowButton(scrollY > 150);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showButton]);

    const handleButtonClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return <>
        {/* <div className="scroll-to-top"><Link className="smoothscroll" to="" onClick={scrollToTop}><i className="flaticon-stethoscope"></i></Link></div> */}

        {/* <div className='d-block d-md-none scroll-to-top book-btn' onClick={scrollToTop}><Link className='btn-open' to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-and-visitors/request-an-appointment`} >Book Now</Link></div> */}
        <div className={`scroll-top ${showButton ? 'show' : ''}`} onClick={scrollToTop}><Link className="smoothscroll" href="#top"><i className="flaticon-stethoscope"></i></Link></div>
        <button
            className={`scroll-to-bottom-button d-block d-md-none ${showButton ? 'show' : ''}`}
            onClick={scrollToTop}
        >
            <Link className="text-white" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-and-visitors/request-an-appointment`} >{t('Book Now')}</Link>

        </button>
    </>
}
