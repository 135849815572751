import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../../../Utilites/PageTitle';

export default function Cardiology() {
    const { pathname } = useLocation();
    const [expandedItems, setExpandedItems] = useState([]);

    const data = [
        {
            id: 1,
            title: 'Electrocardiography',

        },
        {
            id: 2,
            title: 'Treadmill ECG',

        },
        {
            id: 3,
            title: 'Holter 24 and 48-hour monitoring',

        },
        {
            id: 4,
            title: 'Ambulatory blood pressure monitoring',

        },
        {
            id: 5,
            title: 'echocardiography – transthoracic, transesophageal and stress echocardiography and 3d imaging',

        },

    ];
    const toggleList = (itemId) => {
        if (expandedItems.includes(itemId)) {
            setExpandedItems(expandedItems.filter((id) => id !== itemId));
        } else {
            setExpandedItems([...expandedItems, itemId]);
        }
    };
    const renderArrowIcon = (itemId) => {
        if (expandedItems.includes(itemId)) {
            return (


                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                >
                    <path d="M7 14l5-5 5 5z" />
                </svg>
            );
        } else {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                >
                    <path d="M7 10l5 5 5-5z" />
                </svg>
            );
        }
    };

    const renderList = (items) => {
        return items.map((item) => (
            <li key={item.id}>
                <div className="item" onClick={() => toggleList(item.id)}>
                    {renderArrowIcon(item.id)}
                    <span
                        style={{
                            fontWeight: expandedItems.includes(item.id) ? 'bold' : 'normal',
                            color: expandedItems.includes(item.id) ? '4ab242' : 'black',
                        }}
                    >
                        {item.title}
                    </span>
                </div>
                {expandedItems.includes(item.id) && item.children && (
                    <ul className="sub-list">{renderList(item.children)}</ul>
                )}
            </li>
        ));
    };

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Cardiology' anotherTitle='Depertment' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties`} prevPage='specialties' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/cardiology`} currentPage='Cardiology Depertment' />
        {/* <!--page title end--> */}
        <div class="page-content">

            {/* <!--service details start--> */}

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-12">
                            <div class="left-side">
                                <div>

                                    <div class="service-details mt-4">
                                        <h2 class="title">Cardiology <span>Depertment</span></h2>
                                        <p class="lead">To diagnose and treat cardiac conditions efficiently, SGH-Cairo offers the most comprehensive range of state-of-art diagnostic equipment and treatment tools including the catheterization laboratory. </p>
                                        <p class="mb-0">It offers a multi-disciplinary approach for preventing the occurrence or progression of cardiovascular disease covering patients of all ages, making it one of the leading cardiology hospitals in Cairo. Cardiovascular disease (heart disease) is one of the main causes of death and disability globally. Smoking, diabetes, high blood pressure, high cholesterol, obesity, and stress are the main risk factors resulting in heart attack, the majority of which can be controlled with basic precautions.</p>
                                        <div class="tab mt-5">
                                            {/* <!-- Nav tabs --> */}
                                            <div class="service-details mt-4">
                                                <h2 class="h3 title">Scope Of Services:</h2>
                                            </div>

                                            {/* <!-- Tab panes --> */}
                                            <div class="tab-content pb-0" id="nav-tabContent">

                                                {/* <div role="tabpanel" class="tab-pane fade show active" id="tab1-1">

                                                    <ul className="main-list list-unstyled pricing-list clearfix p-0">{renderList(data)}</ul>

                                                </div> */}

                                                <div role="tabpanel" id="tab1-2">
                                                    <ul class="list-unstyled pricing-list clearfix p-0">
                                                        <li><span>Orthotics</span> </li>
                                                        <li><span>Colonoscopy </span> </li>
                                                        <li><span>Paratyroid scan</span> </li>
                                                        <li><span>Full Blood Test</span> </li>
                                                        <li><span>Bronchoscopy</span> </li>
                                                        <li><span>Head</span> </li>
                                                        <li><span>Colonoscopy</span> </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12  mt-5 mt-lg-0">
                            <div class="widget mb-5">
                                <div class="service-images">
                                    <img class="img-fluid w-100" src="/images/service/cardiology.jpg" alt="" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section >

            {/* <!--service details end--> */}





            {/* <!--team start--> */}

            <section>
                <div class="container">
                    <div class="row text-center">
                        <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                            <div class="section-title">
                                <h6>Team</h6>
                                <h2 class="title">Meet Our <span>Doctors</span></h2>
                                <p class="mb-0">Saudi German Hospital Provide Greate Services Exerci tation ullamcorper suscipitorens lobortis nisl ut aliquip ex ea commodo, Exerci tation ullamcorper suscipitorens Ut elit tellus.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
                            <div class="team-member text-center">
                                <div class="team-images">
                                    <img class="img-fluid" src="/images/team/01.jpg" alt="" />
                                    <div class="team-about">
                                        <p>Graduated from Ainshams University in 2009. His subspecialty is Cardiac Catheterization.</p>
                                        <div class="team-social-icon">
                                            <ul>
                                                <li><Link to=""><i class="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="team-description"> <span>Doctor</span>
                                    <h5><Link to="doctor-single.html">Dr. Mostafa Abdelmonaem  </Link></h5>
                                </div>
                                <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/dr-name`} class="btn btn-sm btn-border btn-theme w-100 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>Personal Page</Link>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
                            <div class="team-member text-center">
                                <div class="team-images">
                                    <img class="img-fluid" src="/images/team/02.jpg" alt="" />
                                    <div class="team-about">
                                        <p>graduated from Ain Shams University in 2010. His Subspecialty is in Pediatric cardiology. </p>
                                        <div class="team-social-icon">
                                            <ul>
                                                <li><Link to=""><i class="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="team-description"> <span>Doctor</span>
                                    <h5><Link to="doctor-single.html">Dr. Youssef Mohamed Fahmy </Link></h5>
                                </div>
                                <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/dr-name`} class="btn btn-sm btn-border btn-theme w-100 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>Personal Page</Link>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
                            <div class="team-member text-center">
                                <div class="team-images">
                                    <img class="img-fluid" src="/images/team/03.jpg" alt="" />
                                    <div class="team-about">
                                        <p>Dr. Sohil Abdalla Elfar is a Consultant specializing in Interventional Cardiology, which involves using minimally invasive procedures to diagnose and treat various heart conditions. </p>
                                        <div class="team-social-icon">
                                            <ul>
                                                <li><Link to=""><i class="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="team-description"> <span>Doctor</span>
                                    <h5><Link to="doctor-single.html">Dr. Sohil Abdalla Elfar  </Link></h5>
                                </div>
                                <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/dr-name`} class="btn btn-sm btn-border btn-theme w-100 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>Personal Page</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--team end--> */}

        </div >
    </>
}
