import React, { useEffect, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { GetCategoryDetailsContext } from '../../Context/GetCategoryDetails';
import { LocalizationContext } from '../../Context/LocalizationContext';
import CopyWrite from '../Utilites/MayoClinic/CopyWrite';
import OverViewPageDetails from '../Utilites/MayoClinic/OverViewPageDetails';
import PageDetails from '../Utilites/MayoClinic/PageDetails';
import PageTitle from '../Utilites/PageTitle';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function MayoArticleDetails() {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation  
    let { id } = useParams();
    const { pathname } = useLocation();

    let { articleDetails, setArticleDetails, getCategoryDetails } = useContext(GetCategoryDetailsContext);
    console.log('kkkk');

    //   console.log(`${articleDetails}`);
    useEffect(() => {
        getCategoryDetails(id, setArticleDetails);
        // console.log(getCategoryDetails(setDiseaseDetails));
    }, [i18n.language]);
    return <>
        {/* <!--page title start--> */}
        {articleDetails.map((articleDetail, index) => (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{articleDetail.title}</title>
                <link rel="canonical" href={articleDetail.originalUrl} />
            </Helmet>
            {i18n.language === 'en' ? <PageTitle key={index} title={t('Mayo Clinic')} anotherTitle={t('Health Library')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Article/${articleDetail.docId}`} currentPage={t('Article')} />
                : <PageTitle key={index} title={t('Health Library')} anotherTitle={t('Mayo Clinic')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Article/${articleDetail.docId}`} currentPage={t('Article')} />
            }
        </>))}

        {/* <!--page title end--> */}


        {/* <!--body content start--> */}

        <div class="page-content">

            {/* <!--blog start--> */}

            <section>
                <div class="container">
                    <div class="row">
                        {articleDetails.map((articleDetail, index) => (<>

                            <div key={index} class="col-lg-12 col-md-12 order-lg-12">
                                <PageDetails contentType={articleDetail.contentType} title={articleDetail.title} updateDate={articleDetail.updateDate} />

                                <div className="service-details mt-4">
                                    {/* {articleDetail.sections.map((section, index) => (<>
                                        {section.media ?
                                            section.media.map((med, index) => (<>
                                                {med.file_name ? <div key={index} class="col-lg-3 col-md-12 sidebar mt-7 mt-lg-0 order-sm-1">
                                                    <OverViewPageDetails fileName={med.file_name} />
                                                </div > : ''}

                                            </>))
                                            : ''}

                                    </>))} */}
                                    <h2 className="title"> {t('overview')} </h2>
                                    {/* <p className="lead" dangerouslySetInnerHTML={{ __html: articleDetail.abstract }}></p> */}
                                    <div class="row">

                                        {articleDetail.sections.map((section, index) => (<>
                                            <div key={index} className="col-md-9 order-2">
                                                {section.sectionHead ? <h6 className='text-theme'>{section.sectionHead}</h6> : ''}
                                                <p className='lead' dangerouslySetInnerHTML={{ __html: section.html }}></p>
                                            </div>
                                            <div class="col-md-3 mt-3 mt-md-0 order-1 order-md-3">
                                                {section.media ?
                                                    section.media.map((med, index) => (<>
                                                        {med.file_name ?
                                                            <OverViewPageDetails key={index} fileName={med.file_name} />
                                                            : ''}

                                                    </>))
                                                    : ''}
                                            </div>
                                        </>))}
                                    </div>
                                </div>

                                <CopyWrite />
                            </div>
                        </>)

                        )}
                    </div>
                </div>
            </section>

            {/* <!--blog end--> */}

        </div>

        {/* <!--body content end-->  */}
    </>
}
