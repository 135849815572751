import Cookies from 'js-cookie';
import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import PageTitle from '../../../Utilites/PageTitle';
import Pagination from 'react-js-pagination';

export default function News() {
    const { pathname } = useLocation();
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    return <>
        <div className="page-content">

            {/* <!--blog start--> */}
            {/* <!--page title start--> */}
            <PageTitle title='News' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/news`} currentPage='News' />
            {/* <!--page title end--> */}
            <section className='pt-2'>
                <div className="container">
                    <div class="row text-center">
                        <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                            <div class="section-title">
                                <h6>News</h6>
                                <h2 class="title">Whats <span>News</span></h2>
                                <p class="mb-0">Hectolab Provide Greate Services Exerci tation ullamcorper suscipitorens lobortis nisl ut aliquip ex ea commodo, Exerci tation ullamcorper suscipitorens Ut elit tellus.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-md-4">
                            <div class="item">
                                <div class="post">
                                    <div class="post-image">
                                        <img class="img-fluid w-100 h-100" src="../../../images/blog/01.jpg" alt="" />
                                    </div>
                                    <div class="post-desc">
                                        <div class="post-meta">
                                            <ul class="list-inline">
                                                <li>23 April, 2019</li>
                                                {/*  */}
                                            </ul>
                                        </div>
                                        <div class="post-title">
                                            <h5><Link onClick={scrollToTop} to="">Our Patients’ Healthy Researches is Our Priority</Link></h5>
                                        </div>
                                        <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/news/news-details`}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="item">
                                <div class="post">
                                    <div class="post-image">
                                        <img class="img-fluid w-100 h-100" src="../../../images/blog/02.jpg" alt="" />
                                    </div>
                                    <div class="post-desc">
                                        <div class="post-meta">
                                            <ul class="list-inline">
                                                <li>23 April, 2019</li>

                                            </ul>
                                        </div>
                                        <div class="post-title">
                                            <h5><Link onClick={scrollToTop} to="">Our Patients’ Healthy Researches is Our Priority</Link></h5>
                                        </div>
                                        <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/news/news-details`}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="item">
                                <div class="post">
                                    <div class="post-image">
                                        <img class="img-fluid w-100 h-100" src="../../../images/blog/03.jpg" alt="" />
                                    </div>
                                    <div class="post-desc">
                                        <div class="post-meta">
                                            <ul class="list-inline">
                                                <li>23 April, 2019</li>

                                            </ul>
                                        </div>
                                        <div class="post-title">
                                            <h5><Link onClick={scrollToTop} to="">Our Patients’ Healthy Researches is Our Priority</Link></h5>
                                        </div>
                                        <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/news/news-details`}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <div class="post">
                                    <div class="post-image">
                                        <img class="img-fluid w-100 h-100" src="../../../images/blog/01.jpg" alt="" />
                                    </div>
                                    <div class="post-desc">
                                        <div class="post-meta">
                                            <ul class="list-inline">
                                                <li>23 April, 2019</li>
                                                {/*  */}
                                            </ul>
                                        </div>
                                        <div class="post-title">
                                            <h5><Link onClick={scrollToTop} to="">Our Patients’ Healthy Researches is Our Priority</Link></h5>
                                        </div>
                                        <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/news/news-details`}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="item">
                                <div class="post">
                                    <div class="post-image">
                                        <img class="img-fluid w-100 h-100" src="../../../images/blog/02.jpg" alt="" />
                                    </div>
                                    <div class="post-desc">
                                        <div class="post-meta">
                                            <ul class="list-inline">
                                                <li>23 April, 2019</li>

                                            </ul>
                                        </div>
                                        <div class="post-title">
                                            <h5><Link onClick={scrollToTop} to="">Our Patients’ Healthy Researches is Our Priority</Link></h5>
                                        </div>
                                        <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/news/news-details`}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="item">
                                <div class="post">
                                    <div class="post-image">
                                        <img class="img-fluid w-100 h-100" src="../../../images/blog/03.jpg" alt="" />
                                    </div>
                                    <div class="post-desc">
                                        <div class="post-meta">
                                            <ul class="list-inline">
                                                <li>23 April, 2019</li>

                                            </ul>
                                        </div>
                                        <div class="post-title">
                                            <h5><Link onClick={scrollToTop} to="">Our Patients’ Healthy Researches is Our Priority</Link></h5>
                                        </div>
                                        <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/news/news-details`}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </>
}
