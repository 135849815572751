import React from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../../Utilites/PageTitle';
import OurPartnersDetails from '../../Utilites/OurPartnersDetails';
export default function IHF() {
    const { pathname } = useLocation();

    return <>

        {/* <!--page title start--> */}
        <PageTitle title='IHF' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/`} prevPage='Our Partner' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/international-hospital-federation`} currentPage='IHF' />


        {/* <!--page title end--> */}


        {/* <!--about us start--> */}
        <OurPartnersDetails partnerImgSrc="/images/partners/IHF.png" partnerTitle='International Hospital Federation IHF ' partnerDescription="Championing global healthcare collaboration, Saudi German Health Egypt stands as a proud partner of the International Hospital Federation. This prestigious alliance allows them to exchange knowledge, share best practices, and advance healthcare standards – not just in Egypt, but across the globe. Through this dedicated partnership, Saudi German Health Egypt contributes to shaping a brighter future for healthcare for all." />


        {/* <!--about us end--> */}
    </>
}
