import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


export default function OpenDay(props) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                showPosition(position);
                // alert("Latitude: " + position.coords.latitude + ", Longitude: " + position.coords.longitude);
            }, function (error) {
                if (error.code == error.PERMISSION_DENIED) {
                    alert("User denied geolocation. Please enable location access in your browser settings to use this feature.");
                    // Optionally, provide a way for the user to proceed without location access.
                } else {
                    alert("Error: " + error.message);
                }
            }, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    // Callback function for displaying the position
    function showPosition(position) {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        var locationText = latitude + ", " + longitude;
        // document.getElementById("location").innerHTML = locationText;

        if (30.13233105623922 < latitude && latitude < 30.133672154656303 && 31.38343357389552 < longitude && longitude < 31.384861480767867) {
            // document.getElementById("status").innerHTML = 'IN Hospital ';
            document.getElementById('openDayForm').classList.remove('d-none');
            document.getElementById('openDayForm').classList.add('d-block');
        }
        else
            // document.getElementById("status").innerHTML = 'Outside Hospital';
            // document.getElementById('openDayForm').classList.remove('d-none');

            navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/join-us`)

    }

    useEffect(() => {

        getLocation();
    }, [])

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [degree, setDegree] = useState('');
    const [id, setId] = useState('');
    const [position, setPosition] = useState('');
    const [status, setStatus] = useState('');
    const [question2, setQuestion2] = useState('');
    const [errors, setErrors] = useState([]);
    const [age, setAge] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [gender, setGender] = useState('');
    const [company, setCompany] = useState('');

    //  send uploaded file
    const [fileValue, setFileValue] = useState();
    const [file, setFile] = useState();

    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
        setFileValue(e.target.value);

    }
    async function handleSubmit(e) {
        e.preventDefault();

        const validationErrors = [];

        if (!name) {
            validationErrors.push('Name is required');
        }

        if (!email) {
            validationErrors.push('Email is required');
        }

        if (!position) {
            validationErrors.push('Password is required');
        }
        if (!phone) {
            errors.push('Phone Number is required');
        }
        if (!id) {
            errors.push('National ID Number is required');
        }
        if (!address) {
            errors.push('Address is required');
        }
        if (!degree) {
            errors.push('Educational Degree Certificate is required');
        }
        if (!company) {
            errors.push('Company is required');
        }
        if (!age) {
            errors.push('Age is required');
        }
        if (!gender) {
            errors.push('Gender is required');
        }
        if (!whatsapp) {
            errors.push('whatsapp is required');
        }
        if (!question2) {
            errors.push('Answer for Question 2 is required');
        }
        if (!file) {
            errors.push('cv is required');
        }
        setErrors(validationErrors);

        if (validationErrors.length === 0) {
            // Submit form data
            console.log('Form submitted:', {
                name, email, position, phone,
                id,
                address,
                degree,
                status,
                position,
                gender,
                whatsapp,
                company, age,
                question2, file
            });
        };

        const bodyFormData = new FormData();

        bodyFormData.append('full name', name);
        bodyFormData.append('email', email);
        bodyFormData.append('phone', phone);
        bodyFormData.append('whatsapp', whatsapp);
        bodyFormData.append('age', age);
        bodyFormData.append('gender', gender);
        bodyFormData.append('current company', company);
        bodyFormData.append('Applying for Position?', position);
        bodyFormData.append('Educational Degree Certificate', degree);
        bodyFormData.append('address', address);
        bodyFormData.append('Military Status', status);
        bodyFormData.append('National ID Number', id);
        bodyFormData.append('Are You Ready To Start In Case Of Acceptance', question2);
        bodyFormData.append('cv', file);

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/open-day`, bodyFormData)
            .then(response => {

                if (response.data.Ecode === 200) {
                    console.log(response.data);

                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Form Submitted Successfully!')
                        setMessage('Thank you for reaching out to us. Your message has been received, and we will get back to you as soon as possible.')
                        setAddress('');
                        setAge('');
                        setGender('');
                        setWhatsapp('');
                        setName('')
                        setCompany('');
                        setDegree('');
                        setEmail('');
                        setFile('');
                        setId('');
                        setStatus('');
                        setPosition('');
                        setPhone('');
                        setQuestion2('');
                    } else {
                        setSubmitMsg(response.data.Emsg)

                    }

                    handleShow();

                } else {
                    setSubmitMsg(response.data.Emsg)
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });
    }

    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [message, setMessage] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();

        bodyFormData.append('full name', name);
        bodyFormData.append('email', email);
        bodyFormData.append('phone', phone);
        bodyFormData.append('Applying for Position?', position);
        bodyFormData.append('Educational Degree Certificate', degree);
        bodyFormData.append('address', address);
        bodyFormData.append('Military Status', status);
        bodyFormData.append('National ID Number', id);
        bodyFormData.append('Are You Ready To Start In Case Of Acceptance', question2);

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/open-day`, bodyFormData)
            .then(response => {

                if (response.data.Ecode === 200) {
                    console.log(response.data);

                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Form Submitted Successfully!')
                        setMessage('Thank you for reaching out to us. Your message has been received, and we will get back to you as soon as possible.')

                    } else {
                        setSubmitMsg('Error !! please Check Your Data ')

                    }

                    handleShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>
        {/* <div>
            <h1>User Location:</h1>
            <p id="location"></p>
            <p id="status"></p> */}

        <PageTitle title='open' anotherTitle='Day' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/open-day`} currentPage='Open Day' />
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">
                        <div className="box-shadow">
                            <div className="post-comments">

                                <form id='openDayForm' className='d-none' onSubmit={handleSubmit}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-7 p-5">
                                            <div className='row'>
                                                <div className="form-group col-md-6">
                                                    <input id="form_name" type="text" name="name" className="form-control" placeholder="Full Name" required="required" value={name} onChange={(e) => setName(e.target.value)} data-error="Full Name is required." />
                                                    {errors.includes('Name is required') && <p>Name is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required="required" data-error="Valid email is required." />
                                                    {errors.includes('Email is required') && <p>Email is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="number" name="experience" className="form-control" placeholder="Phone Number" value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        required="required" />
                                                    {errors.includes('Phone Number is required') && <p>Phone Number is required</p>}


                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="number" name="experience" className="form-control" placeholder="WhatsApp Number" value={whatsapp}
                                                        onChange={(e) => setWhatsapp(e.target.value)}
                                                        required="required" />
                                                    {errors.includes('WhatsApp Number is required') && <p>WhatsApp Number is required</p>}


                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="number" name="age" className="form-control" placeholder="Age" value={age}
                                                        onChange={(e) => setAge(e.target.value)}
                                                        required="required" />
                                                    {errors.includes('Age is required') && <p>Age is required</p>}


                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="number" name="id" className="form-control" minLength={14} maxLength={14} placeholder="National ID Number" value={id}
                                                        onChange={(e) => setId(e.target.value)}
                                                        required="required" />
                                                    {errors.includes('National ID Number is required') && <p>National ID Number is required</p>}


                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input id="form_address" type="text" name="address" className="form-control" placeholder="Home Address" required="required" value={address} onChange={(e) => setAddress(e.target.value)} data-error="Address is required." />
                                                    {errors.includes('Address is required') && <p>Home Address is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    {/* <input id="form_address" type="text" name="degree" className="form-control" placeholder="Educational Degree Certificate" required="required" value={degree} onChange={(e) => setDegree(e.target.value)} data-error="Address is required." /> */}
                                                    <select class="form-select form-control" value={degree} onChange={(e) => setDegree(e.target.value)} required data-error="Educational Degree Certificate is required.">
                                                        <option value="" disabled>Select Educational Degree Certificate</option>

                                                        <option value='Bachelor Degree'>Bachelor Degree </option>
                                                        <option value='Institute'>Institute </option>
                                                        <option value='High School'>High School </option>
                                                        <option value='Others'>Others</option>



                                                    </select>
                                                    {errors.includes('Educational Degree Certificate is required') && <p>Educational Degree Certificate is required</p>}

                                                </div>

                                                <div className="form-group col-md-6">
                                                    {/* <input id="form_position" type="text" name="Military Status" className="form-control" value={status} onChange={(e) => setStatus(e.target.value)} placeholder="Military Status" data-error="Military Status is required." /> */}
                                                    <select class="form-select form-control" value={status} onChange={(e) => setStatus(e.target.value)} required data-error="Military Status is required.">
                                                        <option value="" disabled>Select Military Status</option>

                                                        <option value='Completed'>Completed </option>
                                                        <option value='Exempted'>Exempted </option>
                                                        <option value='Postponed'>Postponed </option>
                                                        <option value='Not Applicable'>Not Applicable </option>

                                                    </select>
                                                    {errors.includes('Military Status is required') && <p>Military Status is required</p>}


                                                </div>
                                                <div className="form-group col-md-6">
                                                    {/* <input id="form_position" type="text" name="Military Status" className="form-control" value={status} onChange={(e) => setStatus(e.target.value)} placeholder="Military Status" data-error="Military Status is required." /> */}
                                                    <select class="form-select form-control" value={gender} onChange={(e) => setGender(e.target.value)} required data-error="Gender is required.">
                                                        <option value="" disabled>Select Gender</option>

                                                        <option value='Male'>Male </option>
                                                        <option value='Female'>Female </option>


                                                    </select>
                                                    {errors.includes('Gender is required') && <p>Gender is required</p>}


                                                </div>

                                                <div className="form-group col-md-6">
                                                    <input id="form_Company" type="text" name="position" className="form-control" value={position} onChange={(e) => setPosition(e.target.value)} placeholder="Applying for Position?" data-error="Applying for Position is required." />
                                                    {errors.includes('Applying for Position is required') && <p>Applying for Position is required</p>}


                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="text" name="company" className="form-control" placeholder="Current Company" required="required" value={company} onChange={(e) => setCompany(e.target.value)} data-error="Current Company is required." />
                                                    {errors.includes('Current Company is required') && <p>Current Company is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <p>Are You Ready To Start In Case Of Acceptance?</p>
                                                    <label>
                                                        <input
                                                            type="radio" className='me-2'
                                                            value="Yes"
                                                            checked={question2 === 'Yes'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Yes
                                                    </label>
                                                    <label>
                                                        <input className='ms-3 me-2'
                                                            type="radio"
                                                            value="No"
                                                            checked={question2 === 'No'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        No
                                                    </label>
                                                </div>
                                                <div className="form-group col-md-6">

                                                    <input type='file' id="form_message" name="file" value={fileValue} onChange={handleFileUpload} class="form-control" placeholder="Upload your CV or Resume" rows="4" required="required" data-error="Please,Upload Your CV." />
                                                    {errors.includes('Please,Upload Your CV is required') && <p>Please,Upload Your CV is required</p>}

                                                </div>
                                                <div className="col-md-4">
                                                    <button className="btn btn-border btn-circle w-100" type='submit' disabled={errors.length > 0}><span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5" style={{ backgroundImage: `url('/images/bg/04.webp')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            {/* <img src="images/bg/04.webp" alt="open day" /> */}
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>Open Day</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            <p className='lead'>{message}</p>
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        {/* </div> */}

    </>
}
