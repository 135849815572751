import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


export default function ScientificDay(props) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [organization, setOrganization] = useState('');
    const [position, setPosition] = useState('');
    const [question2, setQuestion2] = useState('');
    const [errors, setErrors] = useState([]);


    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [message, setMessage] = useState('');
    const [agenda, setAgenda] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();

        bodyFormData.append('full name', name);
        bodyFormData.append('email', email);
        bodyFormData.append('phone', phone);
        bodyFormData.append('Position', position);
        bodyFormData.append('organization', organization);
        bodyFormData.append('specialty', specialty);
        bodyFormData.append('Do you confirm attending the conference', question2);

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/scientific-day`, bodyFormData)
            .then(response => {

                if (response.data.Ecode === 200) {
                    console.log(response.data);




                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Registration is Successful!')
                        setMessage("Thank you for registering for the ER Scientific Day at Saudi German Hospital Cairo! We're thrilled to have you join us on April 27th, 2024, at the Auditorium Hall on the third floor.")
                        setAgenda('" Stay tuned for the agenda, which will be provided closer to the event date through our social media platforms. In the meantime, if you have any questions or need further assistance, feel free to reach out.See you soon at the ER Scientific Day! "')

                    } else {
                        setSubmitMsg('Error !! please Check Your Data ')

                    }

                    handleShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>


        <PageTitle title='ER Scientific' anotherTitle='Day' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/scientific-day`} currentPage='ER Scientific Day' />
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">
                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-7 p-5">
                                            <div className='row'>
                                                <div className="form-group col-md-6">
                                                    <input id="form_name" type="text" name="name" className="form-control" placeholder="Full Name" required="required" value={name} onChange={(e) => setName(e.target.value)} data-error="Full Name is required." />
                                                    {errors.includes('Name is required') && <p>Name is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required="required" data-error="Valid email is required." />
                                                    {errors.includes('Email is required') && <p>Email is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="number" name="experience" className="form-control" placeholder="Phone Number" value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        required="required" />
                                                    {errors.includes('Phone Number is required') && <p>Phone Number is required</p>}


                                                </div>


                                                <div className="form-group col-md-6">
                                                    <input id="form_address" type="text" name="organization" className="form-control" placeholder="Organization" required="required" value={organization} onChange={(e) => setOrganization(e.target.value)} data-error="organization is required." />
                                                    {errors.includes('Organization is required') && <p> Organization is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input id="form_address" type="text" name="speciality" className="form-control" placeholder="Speciality" required="required" value={specialty} onChange={(e) => setSpecialty(e.target.value)} data-error="speciality is required." />
                                                    {errors.includes('speciality is required') && <p>speciality is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input id="form_Company" type="text" name="position" className="form-control" value={position} onChange={(e) => setPosition(e.target.value)} placeholder="Position" data-error="Position is required." required="required" />
                                                    {errors.includes('Position is required') && <p>Position is required</p>}


                                                </div>

                                                <div className="form-group col-md-6">
                                                    <p>Do you confirm attending the conference?</p>
                                                    <label>
                                                        <input
                                                            type="radio" className='me-2'
                                                            value="Yes" required
                                                            checked={question2 === 'Yes'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Yes
                                                    </label>
                                                    <label>
                                                        <input className='ms-3 me-2'
                                                            type="radio"
                                                            value="No" required
                                                            checked={question2 === 'No'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        No
                                                    </label>
                                                </div>

                                                <div className="col-md-4">
                                                    <button className="btn btn-border btn-circle w-100" type='submit' disabled={errors.length > 0}><span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5" style={{ backgroundImage: `url('/images/forms/scientific-day.webp')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            {/* <img src="images/bg/04.webp" alt="open day" /> */}
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>Scientific Day</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            <p className='lead'>{message}</p>
                                                            <p className='text-muted pt-2'>{agenda}</p>
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </>
}
