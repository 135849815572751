import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import Cookies from 'js-cookie';

// cairo branch
import CairoHomeEN from "./localization/CairoHome-en.json";
import CairoHomeAR from "./localization/CairoHome-ar.json";
import CairoSpecialistsEN from "./localization/CairoSpecialities-en.json";
import CairoSpecialistsAR from "./localization/CairoSpecialities-ar.json";
import CairoSpecialistsDetailsEN from "./localization/CairoSpecialitiesDetails-en.json";
import CairoSpecialistsDetailsAR from "./localization/CairoSpecialitiesDetails-ar.json";
import CairoServicesEN from "./localization/CairoServices-en.json";
import CairoServicesAR from "./localization/CairoServices-ar.json";
import CairoServicesDetailsEN from "./localization/CairoServicesDetails-en.json";
import CairoServicesDetailsAR from "./localization/CairoServicesDetails-ar.json";
import CairoDoctorEN from "./localization/CairoDoctors-en.json";
import CairoDoctorAR from "./localization/CairoDoctors-ar.json";
import CairoDoctorDetailsEN from "./localization/CairoDoctorDetails-en.json";
import CairoDoctorDetailsAR from "./localization/CairoDoctorDetails-ar.json";
import CairoIVPEN from "./localization/CairoIvp-en.json";
import CairoIVPAR from "./localization/CairoIvp-ar.json";
import CairoIVPDetailsEN from "./localization/CairoIVPDetails-en.json";
import CairoIVPDetailsAR from "./localization/CairoIVPDetails-ar.json";
import CairoEventEN from "./localization/CairoEvents-en.json";
import CairoEventAR from "./localization/CairoEvents-ar.json";
import CairoEventDetailsEN from "./localization/CairoEventsDetails-en.json";
import CairoEventDetailsAR from "./localization/CairoEventsDetails-ar.json";
// alex branch
import AlexHomeEN from "./localization/AlexHome-en.json";
import AlexHomeAR from "./localization/AlexHome-ar.json";
import AlexSpecialistsEN from "./localization/AlexSpecialities-en.json";
import AlexSpecialistsAR from "./localization/AlexSpecialities-ar.json";
import AlexSpecialistsDetailsEN from "./localization/AlexSpecialitiesDetails-en.json";
import AlexSpecialistsDetailsAR from "./localization/AlexSpecialitiesDetails-ar.json";
import AlexServicesEN from "./localization/AlexServices-en.json";
import AlexServicesAR from "./localization/AlexServices-ar.json";
import AlexServicesDetailsEN from "./localization/AlexServicesDetails-en.json";
import AlexServicesDetailsAR from "./localization/AlexServicesDetails-ar.json";
import AlexDoctorEN from "./localization/AlexDoctors-en.json";
import AlexDoctorAR from "./localization/AlexDoctors-ar.json";
import AlexDoctorDetailsEN from "./localization/AlexDoctorDetails-en.json";
import AlexDoctorDetailsAR from "./localization/AlexDoctorDetails-ar.json";
import AlexIVPEN from "./localization/AlexIvp-en.json";
import AlexIVPAR from "./localization/AlexIvp-ar.json";
import AlexIVPDetailsEN from "./localization/AlexIVPDetails-en.json";
import AlexIVPDetailsAR from "./localization/AlexIVPDetails-ar.json";
import AlexEventEN from "./localization/AlexEvents-en.json";
import AlexEventAR from "./localization/AlexEvents-ar.json";
import AlexEventDetailsEN from "./localization/AlexEventsDetails-en.json";
import AlexEventDetailsAR from "./localization/AlexEventsDetails-ar.json";

// health
import translationEN from './localization/en.json';
import translationAR from './localization/ar.json';
import faqAR from './localization/faq-ar.json';
import faqEN from './localization/faq-en.json';
import contactEN from './localization/contact-en.json';
import contactAr from './localization/contact-ar.json';
import PatientRightsEN from './localization/PatientRights-en.json';
import PatientRightsAR from './localization/PatientRights-ar.json';
import NurseNominationAR from './localization/NurseNomination-ar.json';
import NurseNominationEN from './localization/NurseNomination-en.json';
import PatientOutcomeMeasuresSurveyAR from './localization/PatientOutcomeMeasuresSurvey-ar.json';
import PatientOutcomeMeasuresSurveyEN from './localization/PatientOutcomeMeasuresSurvey-en.json';
import HeaderEN from './localization/Header-en.json';
import HeaderAR from './localization/Header-ar.json';
import InsuranceCompaniesEN from "./localization/InsuranceCompanies-en.json";
import InsuranceCompaniesAR from "./localization/InsuranceCompanies-ar.json";
import BreastfeedingEventEN from "./localization/BreastfeedingEvent-en.json";
import BreastfeedingEventAR from "./localization/BreastfeedingEvent-ar.json";
import PatientPortalEN from "./localization/PatientPortal-en.json";
import PatientPortalAR from "./localization/PatientPortal-ar.json";
import FooterEN from './localization/Footer-en.json';
import FooterAR from './localization/Footer-ar.json';
import PatientsStoriesEN from './localization/PatientsStories-en.json';
import PatientsStoriesAR from './localization/PatientsStories-ar.json';
import MediaCenterEN from './localization/MediaCenter-en.json';
import MediaCenterAR from './localization/MediaCenter-ar.json';
import JoinUsEN from './localization/JoinUs-en.json';
import JoinUsAR from './localization/JoinUs-ar.json';
import HealthHubEN from './localization/HealthHub-en.json';
import HealthHubAR from './localization/HealthHub-ar.json';
import FluVCaccineQuestionnairEN from './localization/FluVCaccineQuestionnair-en.json';
import FluVCaccineQuestionnairAR from './localization/FluVCaccineQuestionnair-ar.json';
import AboutEN from './localization/About-en.json';
import AboutAR from './localization/About-ar.json';
import BookNowEN from './localization/BookNow-en.json';
import BookNowAR from './localization/BookNow-ar.json';
import NewsEN from './localization/News-en.json';
import NewsAR from './localization/News-ar.json';
import HomeEN from './localization/Home-en.json';
import HomeAR from './localization/Home-ar.json';
import BeOurPartnerEN from './localization/BeOurPartner-en.json';
import BeOurPartnerAR from './localization/BeOurPartner-ar.json';
import PolicyEN from './localization/Policy-en.json';
import PolicyAR from './localization/Policy-ar.json';
import TermsAndConditionsEN from './localization/TermsAndConditions-en.json';
import TermsAndConditionsAR from './localization/TermsAndConditions-ar.json';
import MayoClinicEN from './localization/MayoClinic-en.json';
import MayoClinicAR from './localization/MayoClinic-ar.json';
const resources = {
    en: {
        translation: translationEN,
        faq: faqEN,
        contact: contactEN,
        PatientRights: PatientRightsEN,
        NurseNomination: NurseNominationEN,
        PatientOutcomeMeasuresSurvey: PatientOutcomeMeasuresSurveyEN,
        InsuranceCompanies: InsuranceCompaniesEN,
        BreastfeedingEvent: BreastfeedingEventEN,
        CairoHome: CairoHomeEN,
        CairoSpecialists: CairoSpecialistsEN,
        CairoSpecialistsDetails: CairoSpecialistsDetailsEN,
        CairoServices: CairoServicesEN,
        CairoServicesDetails: CairoServicesDetailsEN,
        CairoDoctor: CairoDoctorEN,
        CairoDoctorDetails: CairoDoctorDetailsEN,
        CairoIVP: CairoIVPEN,
        CairoIVPDetails: CairoIVPDetailsEN,
        CairoEvent: CairoEventEN,
        CairoEventDetails: CairoEventDetailsEN,
        AlexHome: AlexHomeEN,
        AlexSpecialists: AlexSpecialistsEN,
        AlexSpecialistsDetails: AlexSpecialistsDetailsEN,
        AlexServices: AlexServicesEN,
        AlexServicesDetails: AlexServicesDetailsEN,
        AlexDoctor: AlexDoctorEN,
        AlexDoctorDetails: AlexDoctorDetailsEN,
        AlexIVP: AlexIVPEN,
        AlexIVPDetails: AlexIVPDetailsEN,
        AlexEvent: AlexEventEN,
        AlexEventDetails: AlexEventDetailsEN,
        Header: HeaderEN,
        PatientPortal: PatientPortalEN,
        Footer: FooterEN,
        PatientsStories: PatientsStoriesEN,
        MediaCenter: MediaCenterEN,
        JoinUs: JoinUsEN,
        HealthHub: HealthHubEN,
        FluVCaccineQuestionnair: FluVCaccineQuestionnairEN,
        About: AboutEN,
        BookNow: BookNowEN,
        News: NewsEN,
        Home: HomeEN,
        BeOurPartner: BeOurPartnerEN,
        Policy: PolicyEN,
        TermsAndConditions: TermsAndConditionsEN,
        MayoClinic: MayoClinicEN




    },
    ar: {
        translation: translationAR,
        faq: faqAR,
        contact: contactAr,
        PatientRights: PatientRightsAR,
        NurseNomination: NurseNominationAR,
        PatientOutcomeMeasuresSurvey: PatientOutcomeMeasuresSurveyAR,
        InsuranceCompanies: InsuranceCompaniesAR,
        BreastfeedingEvent: BreastfeedingEventAR,
        CairoHome: CairoHomeAR,
        CairoSpecialists: CairoSpecialistsAR,
        CairoSpecialistsDetails: CairoSpecialistsDetailsAR,
        CairoServices: CairoServicesAR,
        CairoServicesDetails: CairoServicesDetailsAR,
        CairoDoctor: CairoDoctorAR,
        CairoDoctorDetails: CairoDoctorDetailsAR,
        CairoIVP: CairoIVPAR,
        CairoIVPDetails: CairoIVPDetailsAR,
        CairoEvent: CairoEventAR,
        CairoEventDetails: CairoEventDetailsAR,
        AlexHome: AlexHomeAR,
        AlexSpecialists: AlexSpecialistsAR,
        AlexSpecialistsDetails: AlexSpecialistsDetailsAR,
        AlexServices: AlexServicesAR,
        AlexServicesDetails: AlexServicesDetailsAR,
        AlexDoctor: AlexDoctorAR,
        AlexDoctorDetails: AlexDoctorDetailsAR,
        AlexIVP: AlexIVPAR,
        AlexIVPDetails: AlexIVPDetailsAR,
        AlexEvent: AlexEventAR,
        AlexEventDetails: AlexEventDetailsAR,
        Header: HeaderAR,
        PatientPortal: PatientPortalAR,
        Footer: FooterAR,
        PatientsStories: PatientsStoriesAR,
        MediaCenter: MediaCenterAR,
        JoinUs: JoinUsAR,
        HealthHub: HealthHubAR,
        FluVCaccineQuestionnair: FluVCaccineQuestionnairAR,
        About: AboutAR,
        BookNow: BookNowAR,
        News: NewsAR,
        Home: HomeAR,
        BeOurPartner: BeOurPartnerAR,
        Policy: PolicyAR,
        TermsAndConditions: TermsAndConditionsAR,
        MayoClinic: MayoClinicAR

    }

};
// const path = require("path");
i18n
    .use(initReactI18next)
    .use(languageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['en', 'ar'],
        resources,
        lng: Cookies.get('i18next'),
        detection: {
            order: ['cookie', 'htmlTag', 'path', 'localStorage', 'subdomain'],
            caches: ['cookie']
        },
        backend: {
            loadPath: '/localization/{{lng}}.json',
        },

        interpolation: {
            escapeValue: false
        },

        react: {
            useSuspense: false
        }
    });

// i18n.on('languageChanged', (lng) => {
//     Cookies.set('i18next', lng, { expires: 365 });
// });

export default i18n;