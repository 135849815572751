import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import { Helmet } from "react-helmet";

import App from '../../App';
import { MetaTagContext } from '../../Context/MetaTag/MetaTag';
import metaData from '../MetaTag/MetaTag';
import { metaDataAR } from '../MetaTag/MetaTagAR';
import AboutTest from '../Tags/EN/About'
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function About() {
    // translation 
    const { t } = useTranslation("About");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 

    const { pathname } = useLocation();
    let { tags } = useContext(MetaTagContext);

    const options = {
        margin: 30,
        responsiveClass: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },

            1000: {
                items: 3,

            }
        },
    }
    const client = {
        autoplay: true,
        loop: true,
        margin: 30,
        responsiveClass: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 3,
            },
            600: {
                items: 4,
            },

            1000: {
                items: 5,

            }
        },
    }
    return <>


        <AboutTest />


        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('About')} anotherTitle={t('Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} currentPage={t('About Us')} /> : <PageTitle title={t('Us')} anotherTitle={t('About')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} currentPage={t('About Us')} />}


        {/* <!--page title end--> */}


        {/* <!--about us start--> */}

        <section className='p-0 pt-2'>
            {/* <div className="w-100 header-about position-relative" style={{ backgroundImage: `url('/images/about/page-header.jpg')` }}>
                <div className="row align-items-start text-justify">
                    <div className="col-md-12 text-center">
                        <div className="section-title mb-4">
                            <h2 className="title">About Us</h2>
                            <h4>Saudi German Health</h4>
                            <p className="mb-0">Saudi German Health is a prominent healthcare organization dedicated to providing high-quality medical services in the Kingdom of <Link to="https://saudigermanhealth.com/" className='text-decoration-underline'> Saudi Arabia</Link> and beyond. With a strong commitment to patient-centered care, innovation, and excellence, Saudi German Health has established itself as a leading healthcare provider in the Middle East and North Africa (MENA). </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container">
                <div className="row align-items-start text-justify">
                    <div className="col-md-12 text-center">
                        {/* <div className="section-title mt-3 mb-4">

                            <h4>Saudi German Health</h4>
                            <p className="mb-0">Saudi German Health is a prominent healthcare organization dedicated to providing high-quality medical services in the Kingdom of <Link to="https://saudigermanhealth.com/" className='text-decoration-underline'> Saudi Arabia</Link> and beyond. With a strong commitment to patient-centered care, innovation, and excellence, Saudi German Health has established itself as a leading healthcare provider in the Middle East and North Africa (MENA). </p>
                        </div> */}

                        <div class="about-section position-relative overflow-hidden">
                            <header class="section-title mb-3 align-top">
                                <div class="row">
                                    <div class="col-12 col-md-6 offset-md-0">
                                        <div class="text-white"></div>
                                        <h2 class="text-start text-white">{t('Saudi German Health')}</h2>
                                    </div>
                                </div>
                            </header>
                            <div class="row position-relative" style={{ zIndex: 3 }}>
                                <div class="col-12 col-md-6 offset-md-0">
                                    <div class="section-title text-white text-start lead text-justify"><Link to="https://saudigermanhealth.com/" className='text-white fw-bolder text-decoration-underline'>{t('Saudi German Health')}</Link> {t('saudi german health paragraph')}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div class="image col-md-12" style={{ backgroundImage: `url('/images/about/Saudi german Health image -min.webp')` }}>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="row justify-content-between my-3 pe-0 text-justify">

                        <div className="col-lg-8 col-md-12  mt-lg-0 order-2" >
                            {/* <div className="section-title mb-4">
                            <h2 className="title">About Us</h2>
                            <h6 className='h5'><span>Saudi German Health</span></h6>
                            <p className="mb-0">Saudi German Health is a prominent healthcare organization dedicated to providing high-quality medical services in the Kingdom of <Link to="https://saudigermanhealth.com/" className='text-decoration-underline'> Saudi Arabia</Link> and beyond. With a strong commitment to patient-centered care, innovation, and excellence, Saudi German Health has established itself as a leading healthcare provider in the Middle East and North Africa (MENA). </p>
                        </div> */}
                            <div className="section-title mb-4">
                                <h6 className="h5 title">{t('Saudi German Hospital')} - <span className='fw-bold'>{t('Cairo')}</span> </h6>
                                <p className="mb-0">{t('Saudi German Hospital Cairo')}</p>
                            </div>


                        </div>

                        <div className="col-lg-4 col-md-12 pe-0  mb-4 order-1 order-sm-2">
                            <img className="img-fluid rounded box-shadow w-100" src="/images/about/Cairo-min_result.webp" alt="" />

                        </div>

                        <div className="col-lg-8 col-md-12 pe-0 order-4 mt-3 mt-sm-0">

                            <div className="section-title mb-4">

                                <h6 className="h5 title">{t('Saudi German Hospital')} - <span className='fw-bold'>{t('Alexandria')}</span></h6>
                                <p className="mb-0">{t('Saudi German Hospital Alexandria')}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 pe-0 order-3 order-sm-4">
                            <img className="img-fluid rounded box-shadow w-100" src="/images/about/Alex-min_result.webp" alt="" />

                        </div>
                    </div>

                </div>
                {/* <div className="row align-items-start">
                    <div className="col-md-12">
                       s
                    </div>
                </div> */}
            </div>
        </section>

        {/* <!--about us end--> */}

    </>
}
