import React, { useEffect, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { GetCategoryDetailsContext } from '../../Context/GetCategoryDetails';
import { LocalizationContext } from '../../Context/LocalizationContext';
import PageDetails from '../Utilites/MayoClinic/PageDetails';
import CopyWrite from '../Utilites/MayoClinic/CopyWrite';
import PageTitle from '../Utilites/PageTitle';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function MayoVideosDetails() {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    let { id } = useParams();
    const { pathname } = useLocation();
    let { videosDetails, setVideosDetails, getCategoryDetails } = useContext(GetCategoryDetailsContext);
    console.log('kkkk');

    //   console.log(`${diseaseDetails}`);
    useEffect(() => {
        getCategoryDetails(id, setVideosDetails);
        // console.log(getCategoryDetails(setDiseaseDetails));
    }, [i18n.language]);
    return <>

        {/* <!--page title start--> */}
        {videosDetails.map((vidDetail, index) => (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{vidDetail.title}</title>
                <link rel="canonical" href={vidDetail.originalUrl} />
            </Helmet>
            {i18n.language === 'en' ? <PageTitle key={index} title={t('Mayo Clinic')} anotherTitle={t('Health Library')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Video/${vidDetail.docId}`} currentPage={t('Video')} />
                : <PageTitle key={index} title={t('Health Library')} anotherTitle={t('Mayo Clinic')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Video/${vidDetail.docId}`} currentPage={t('Video')} />}

            {/* <!--page title end--> */}


            {/* <!--body content start--> */}

            <div className="page-content">

                {/* <!--blog start--> */}

                <section>
                    <div className="container">
                        <div className="row">
                            <div key={index} className="col-lg-9 col-md-12 order-lg-12">
                                <PageDetails contentType={vidDetail.contentType} title={vidDetail.title} updateDate={vidDetail.updateDate} />


                                <div className="">
                                    <div className="post">
                                        {vidDetail.video.file_name !== null ? <>
                                            <div className="video-post">
                                                <iframe src={`https://my-health.sghcairo.net/mayo/public/mayo/vid/${vidDetail.video.file_name}?color=e2fb2e&amp;title=0&amp;byline=0&amp;portrait=0`}></iframe>
                                            </div> </> : <img src='/images/mayo-clinic/this-video-is-not-available-min.webp' className='w-100' alt='unavailable video' />}
                                        <div className="post-desc">
                                            <div className="post-meta">
                                                <ul className="list-inline">
                                                    <li>{vidDetail.updateDate}</li>
                                                </ul>
                                            </div>
                                            <div className="post-title">
                                                <h5><a href="/" dangerouslySetInnerHTML={{ __html: vidDetail.abstract }}></a></h5>

                                            </div>
                                            <p dangerouslySetInnerHTML={{ __html: vidDetail.vidscript['html'] }}></p>

                                        </div>
                                    </div>
                                </div>
                                <CopyWrite />

                            </div>

                        </div>
                    </div>
                </section>

                {/* <!--blog end--> */}

            </div>
        </>)

        )}
        {/* <!--body content end-->  */}
    </>
}
