import React from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../../Utilites/PageTitle';
import OurPartnersDetails from '../../Utilites/OurPartnersDetails';
export default function AUC() {
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='AUC' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/`} prevPage='Our Partner' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/american-university-in-cairo`} currentPage='ACHS' />


        {/* <!--page title end--> */}


        {/* <!--about us start--> */}
        <OurPartnersDetails partnerImgSrc="/images/partners/AUC.png" partnerTitle='American University in Cairo AUC' partnerDescription='Saudi German Health Egypt joins hands with the esteemed American University in Cairo (AUC). This dynamic partnership fosters academic excellence with Practical Training in the Executive Management Diplomas. ' />


        {/* <!--about us end--> */}
    </>
}
