import React from 'react'
import logo from '../../images/sgh-logo_new.png';

export default function CommingSoon() {
    return <>
        <div class="page-content">

            {/* <!--coming soon start--> */}

            <section class="fullscreen-banner p-0 overflow-hidden text-center" data-bg-img="/images/pattern/01.png" style={{ backgroundImage: 'url("/images/pattern/01.png")' }}>
                <div class="align-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="box-shadow coming-soon py-7 px-md-7 px-2" data-bg-color="rgba(255,255,255,0.5)">
                                    {/* <a class="logo" href="index.html">
                                        <img id="logo-img" class="img-fluid" src={logo} alt="" />
                                    </a> */}
                                    <h1 class="text-uppercase mt-4 mb-2 text-black">Coming <span>Soon</span></h1>
                                    <p>We Are Creatinig Something Awesome And Exciting For You</p>
                                    <ul class="countdown list-inline text-black" data-countdown="2023/9/1"></ul>
                                    <div>
                                        <h5 class="title z-index-1 mb-3">Subscribe to get <span> notified!</span></h5>
                                        <div class="row">
                                            <div class="col-md-6 mx-auto">
                                                <div class="subscribe-form style-2 mt-4">
                                                    <form id="mc-form" class="group">
                                                        <input type="email" value="" name="EMAIL" class="email box-shadow" id="mc-email" placeholder="Email Address" required="" />
                                                        <input class="btn btn-theme btn-circle" type="submit" name="subscribe" value="Send" />
                                                        <label for="mc-email" class="subscribe-message"></label>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--coming soon end--> */}

        </div>
    </>
}
