import React, { useContext } from 'react'
import PageTitle from '../Utilites/PageTitle';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import CountUp from 'react-countup';
import { LocalizationContext } from '../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';

export default function Facts() {
    // translation 
    const { t } = useTranslation("About");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    const endValue = 2500000;
    const formatNumber = (value) => {
        const stringValue = parseFloat(value).toPrecision();
        const formattedValue = stringValue.replace(/0/g, '').replace(/(\d)(?=(\d{1})+(?!\d))/g, '$1.');
        return formattedValue;
    };
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Facts & Figure')} anotherTitle={t('Saudi German Health')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/facts`} currentPage={t('Facts & Figure')} /> : <PageTitle title={t('Facts & Figure')} anotherTitle={t('Saudi German Health')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/facts`} currentPage={t('Facts & Figure')} />}

        {/* <!--page title end--> */}
        <div className="page-content">
            {/* <section> */}
            {/* <div className="container mt-5 price-table style-2 active" data-bg-img="/images/about/bg/01.jpg" style={{ backgroundImage: 'url("/images/about/bg/01.jpg")' }}>
                    <div className="price-inner pt-0">
                        <div className="row" >

                            <div className="col-md-12 " >


                                <div className="tabel">

                                    <Table striped bordered hover className='text-center  '>
                                        <thead>
                                            <tr className='green'>

                                                <th colSpan={8} className='fs-3 fw-semibold bg-new text-white' >Patient</th>

                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}></th>
                                                <th>From Mar-2016</th>
                                                <th>2017</th>
                                                <th>2018</th>
                                                <th>2019</th>
                                                <th>2020</th>
                                                <th>2021</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th colSpan={2}>Average length of stay</th>
                                                <td>3.2</td>
                                                <td>3.0</td>
                                                <td>2.8</td>
                                                <td>3.5</td>
                                                <td>3.5</td>
                                                <td>3.1</td>
                                            </tr>
                                            <tr>
                                                <th colSpan={2}>ER Census</th>
                                                <td>9147</td>
                                                <td>19789</td>
                                                <td>25635</td>
                                                <td>26176</td>
                                                <td>26660</td>
                                                <td>36128</td>
                                            </tr>
                                            <tr>
                                                <th colSpan={2}>OPD Census</th>
                                                <td>75128</td>
                                                <td>175840</td>
                                                <td>237849</td>
                                                <td>297429</td>
                                                <td>248959</td>
                                                <td>289407</td>
                                            </tr>
                                            <tr>
                                                <th colSpan={2}>Inpatient Census</th>
                                                <td >4252</td>
                                                <td>6425</td>
                                                <td>8532</td>
                                                <td>10131</td>
                                                <td>9144</td>
                                                <td>10645</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>

                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <div className="tabel ">

                                    <Table striped bordered hover className='text-center '>
                                        <thead>
                                            <tr>

                                                <th colSpan={8} className='fs-3 fw-semibold  bg-new text-white' >Beds Facts</th>

                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th colSpan={1}></th>
                                                <th>From Mar-2016</th>
                                                <th>2017</th>
                                                <th>2018</th>
                                                <th>2019</th>
                                                <th>2020</th>
                                                <th>2021</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th colSpan={1}>No. Of Operational beds at beginning of year</th>
                                                <td>93</td>
                                                <td>95</td>
                                                <td>113</td>
                                                <td>168</td>
                                                <td>144</td>
                                                <td>155</td>
                                            </tr>
                                            <tr>
                                                <th colSpan={1}>No. Of Operational beds at end of year</th>
                                                <td>93</td>
                                                <td>95</td>
                                                <td>113</td>
                                                <td>144</td>
                                                <td>144</td>
                                                <td>155</td>
                                            </tr>


                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-8">
                                <div className="tabel ">

                                    <Table striped bordered hover size="sm" className='text-center '>

                                        <thead>
                                            <tr>
                                                <th colSpan={3} className='fs-3 fw-semibold  bg-new text-white'>Employees Facts </th>
                                                <th>Total</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th colSpan={2}>No. Of Female Employees</th>
                                                <td>688</td>

                                                <th rowSpan={2} className='text-center pt-4 fs-2'>1729</th>

                                            </tr>
                                            <tr>
                                                <th colSpan={2}>No. Of Male Employees</th>
                                                <td>1041</td>

                                            </tr>


                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <div className="tabel ">

                                    <Table striped bordered hover size="sm" className='text-center '>

                                        <thead>
                                            <tr>
                                                <th >Egyption</th>
                                                <th>Philippion</th>
                                                <th>Pakistani</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1683</td>
                                                <td>26</td>
                                                <td>18</td>



                                            </tr>
                                            <tr>
                                                <th colSpan={3} className='fs-2'>1729</th>


                                            </tr>


                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                    </div>

                </div > */}
            {/* <div className="container">
                    <div className="row mt-5 text-end">
                        <div class="team-description section-title  mb-0">
                            <h6> <span className='fw-bolder'>Last Updated : </span> 10 / 10 / 2023</h6>
                        </div>
                    </div>
                </div> */}

            {/* </section> */}

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="text-center">
                                <div class="featured-icon">
                                    <img src="/images/about/Facts and Figures/Number Of Branches-min.webp" alt="Number of Branches" />
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h2 className='text-theme mt-3 border-top'> <CountUp end={18} /></h2>
                                    <h5>{t('Medical facilities across the Middle East & North Africa')} </h5>
                                </div>
                                {/* <div class="featured-desc">
                                    <p>We have experience in different areas of Health Center, We offer our Better solutions your
                                        treatment is appropriate.</p>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-5 mt-md-0">
                            <div class="text-center">
                                <div class="featured-icon">
                                    <img src="/images/about/Facts and Figures/Bed Capacity-min.webp" alt="Bed Capacity" />

                                </div>
                                <div class="featured-title text-uppercase">
                                    <h2 className='text-theme mt-3 border-top'> <CountUp end={3000} /> <sup className='fw-bolder'>+</sup></h2>

                                    <h5>{t('Beds')} </h5>
                                </div>
                                {/* <div class="featured-desc">
                                    <p>We have experience in different areas of Health Center, We offer our Better solutions your
                                        treatment is appropriate.</p>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
                            <div class="text-center">
                                <div class="featured-icon">
                                    <img src="/images/about/Facts and Figures/Specialties-And-Services-min.webp" alt="Specialties and Services" />

                                </div>
                                <div class="featured-title text-uppercase">
                                    <h2 className='text-theme mt-3 border-top'> <CountUp end={44} /></h2>

                                    <h5>{t('Medical specialties & subspecialties')} </h5>
                                </div>
                                {/* <div class="featured-desc">
                                    <p>We have experience in different areas of Health Center, We offer our Better solutions your
                                        treatment is appropriate.</p>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-5">
                            <div class="text-center">
                                <div class="featured-icon">
                                    <img src="/images/about/Facts and Figures/employees-min.webp" alt="Employees" />

                                </div>
                                <div class="featured-title text-uppercase">
                                    <h2 className='text-theme mt-3 border-top'> <CountUp end={9000} /><sup className='fw-bolder'>+</sup></h2>

                                    <h5>{t('Employees')} </h5>
                                </div>
                                {/* <div class="featured-desc">
                                    <p>We have experience in different areas of Health Center, We offer our Better solutions your
                                        treatment is appropriate.</p>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-5">
                            <div class="text-center">
                                <div class="featured-icon">
                                    <img src="/images/about/Facts and Figures/patient-a-year-min.webp" alt="Patients a Year" />

                                </div>
                                <div class="featured-title text-uppercase">
                                    <h2 className='text-theme mt-3 border-top'> <CountUp start={endValue}
                                        separator=""
                                        decimal=""
                                        decimalPlaces={0}
                                        duration={2.5}
                                        formattingFn={formatNumber} />M<sup className='fw-bolder'>+</sup></h2>
                                    <h5>{t('Patients a Year')} </h5>
                                </div>
                                {/* <div class="featured-desc">
                                    <p>We have experience in different areas of Health Center, We offer our Better solutions your
                                        treatment is appropriate.</p>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-5">
                            <div class="text-center">
                                <div class="featured-icon">
                                    <img src="/images/about/Facts and Figures/satisification--min.webp" alt="Patient Satisfaction" />

                                </div>
                                <div class="featured-title text-uppercase">
                                    <h2 className='text-theme mt-3 border-top'> <CountUp end={35} /><sup className='fw-bolder'>+</sup></h2>
                                    <h5>{t('Years of excellence')}</h5>
                                </div>
                                {/* <div class="featured-desc">
                                    <p>We have experience in different areas of Health Center, We offer our Better solutions your
                                        treatment is appropriate.</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}
