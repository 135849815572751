import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function About() {
    const location = useLocation();
    console.log(`path` + location.pathname);

    const getMetaTags = () => {
        console.log(`path` + location.pathname);
        switch (location.pathname) {
            case '/en/about':
                return <>
                    <meta name="description" content="About AR page description" />
                    <meta name="keywords" content="about, us, company" />
                </>
            case '/ar/about':
                return <>
                    <meta name="description" content="About AR page description" />
                    <meta name="keywords" content="about, us, company" />
                </>
            default:
                return <>
                    <title>about page</title>,<meta name="description" content="About AR page description" />
                    <meta name="keywords" content="about, us, company" />
                </>
        }
    }
    return <>
        <div>
            <Helmet>
                {/* <title>My App</title> */}
                {getMetaTags}
            </Helmet>
            {/* ...the rest of your app */}
        </div>
    </>
}
