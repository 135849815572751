import React from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../../Utilites/PageTitle';
import OurPartnersDetails from '../../Utilites/OurPartnersDetails';
export default function Guc() {
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='GUC' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/`} prevPage='Our Partner' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/guc`} currentPage='GUC' />


        {/* <!--page title end--> */}


        {/* <!--about us start--> */}
        <OurPartnersDetails partnerImgSrc="/images/partners/German_University_in_Cairo_Logo.jpg" partnerTitle='German University in Cairo GUC' partnerDescription="Saudi German Health Egypt and the German University in Cairo's Faculty of Pharmacy forge a powerful partnership. Undergraduate pharmacists gain invaluable hands-on training within the hospital pharmacy, immersing themselves in the inner workings of inpatient, outpatient pharmacy, IV Admixture, medication safety, and clinical Pharmacy. This dynamic collaboration nurtures the next generation of pharmacy professionals, equipping them with the skills and knowledge to excel in tomorrow's healthcare landscape." />


        {/* <!--about us end--> */}
    </>
}
