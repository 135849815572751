import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';
export default function BeOurPartner(props) {
    // translation 
    const { t } = useTranslation("BeOurPartner");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation

    const { pathname } = useLocation();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    //  all values

    const [businessphoneValue, setBusinessPhoneValue] = useState();
    const [companyValue, setCompanyValue] = useState();
    const [supplierPhoneValue, setSupplierPhoneValue] = useState();
    const [emailValue, setEmailValue] = useState();
    const [businessValue, setBusinessValue] = useState();
    const [organizationValue, setOrganizationValue] = useState();
    const [CommunicationValue, setCommunicationValue] = useState();
    const [WebsiteValue, setWebsiteValue] = useState();
    const [PartnershipValue, setPartnershipValue] = useState();


    const [submitMsg, setSubmitMsg] = useState('');
    const [message, setMessage] = useState('');

    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();
        // Append data to the FormData object

        // bodyFormData.append('wpforms[id]', '12801');
        // bodyFormData.append('wpforms[author]', '1');
        // bodyFormData.append('wpforms[post_id]', '12837');
        bodyFormData.append('businessphone', businessphoneValue);
        bodyFormData.append('company', companyValue);
        bodyFormData.append('supplierPhone', supplierPhoneValue);
        bodyFormData.append('email', emailValue);
        bodyFormData.append('business', businessValue);
        bodyFormData.append('organization', organizationValue);
        bodyFormData.append('Communication', CommunicationValue);
        bodyFormData.append('Website', WebsiteValue);
        bodyFormData.append('Partnership', PartnershipValue);



        // const headers = {
        //     'fingerprint': localStorage.getItem('fingerPrint')
        // }

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/be-our-partner`, bodyFormData)
            .then(response => {
                // console.log(branchValue);
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    console.log(response.data);
                    // handleShow();
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg(t('Form Submitted Successfully!'))
                        setMessage(t('Thank you for reaching out to us. Your message has been received, and we will get back to you as soon as possible.'))
                    } else {
                        setSubmitMsg(t('Error!! please Check Your Data'))

                    }
                    // console.log(response.data.targetPath);
                    // file_name:
                    // "wpforms-15001-Omra-Trip-2023-ID-15001-2023-11-08-08-54-33.xlsx"
                    // targetPath:
                    // "https://my-health.sghcairo.net/hr/request45/upload_mena/20231111_104209_886779.xlsx"
                    handleShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    return <>
        {/* <!--page title start--> */}
        <PageTitle title={t('Be Our')} anotherTitle={t('Partner')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/be-our-partner`} currentPage={t('Be Our Partner')} />
        {/* <!--page title end--> */}

        {/* <!--appointment start--> */}

        <section class="dark-bg position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 h-100 image-column image-column-h bg-pos-l" data-bg-img="../../images/bg/04.webp" style={{ backgroundImage: 'url("../../images/bg/04.webp")' }}></div>
                    <div class="col-lg-6 col-md-12 ms-auto ps-lg-5 mt-5 mt-lg-0">
                        <div class="section-title mb-4">
                            {i18n.language === 'en' ? <h2 className='text-light'>{t('Business')}
                                <span> {t('Partner !')} !</span>
                            </h2> : <h2 className='text-light'>{t('Partner !')}
                                <span> {t('Business')} !</span>
                            </h2>}
                            {/* <p class="line-h-3 text-white">We have a secure online referral service to help you refer patients to Saudi German Hospital
                            </p> */}

                        </div>

                        <form class="row" onSubmit={submitForm}>
                            <div class="messages"></div>
                            <div class="form-group col-sm-6">
                                <input type="text" name="name" class="form-control" value={organizationValue} onChange={(e) => setOrganizationValue(e.target.value)} placeholder={t("Name of Organization")}
                                    required="required" data-error="Name of Organization is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input type="text" name="business" class="form-control" value={businessValue} onChange={(e) => setBusinessValue(e.target.value)} placeholder={t("Business Scope")}
                                    required="required" data-error="Valid Business Scope is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input type="text" name="Organization" class="form-control" value={WebsiteValue} onChange={(e) => setWebsiteValue(e.target.value)} placeholder={t("Organization Website")}
                                    required="required" data-error="Valid Organization Website is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input type="text" name="Partnership" class="form-control" value={PartnershipValue} onChange={(e) => setPartnershipValue(e.target.value)} placeholder={t("Partnership Expected Goals")}
                                    required="required" data-error="Valid Partnership Expected Goals is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input type="text" name="Assign" class="form-control" value={CommunicationValue} onChange={(e) => setCommunicationValue(e.target.value)} placeholder={t("Assign for Communication Name")}
                                    required="required" data-error="Valid Assign for Communication Name is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input type="email" name="email" class="form-control" value={emailValue} onChange={(e) => setEmailValue(e.target.value)} placeholder={t("E-mail")}
                                    required="required" data-error="Valid E-mail is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input type="text" name="name" class="form-control" value={businessphoneValue} onChange={(e) => setBusinessPhoneValue(e.target.value)} placeholder={t("Phone Number")}
                                    required="required" />
                            </div>
                            <div class="section-title mb-4">
                                <h2 className='text-light'>{t('Supplier')}

                                </h2>
                                {/* <p class="line-h-3 text-white">We have a secure online referral service to help you refer patients to Saudi German Hospital
                            </p> */}

                            </div>

                            <div class="form-group col-sm-6">
                                <input id="form_number" type="text" name=" Company Name" value={companyValue} onChange={(e) => setCompanyValue(e.target.value)} class="form-control" placeholder={t("Company Name")}
                                    required="required" />
                            </div>
                            <div class="form-group col-sm-6">
                                <input id="form_number" type="text" name="name" class="form-control" value={supplierPhoneValue} onChange={(e) => setSupplierPhoneValue(e.target.value)} placeholder={t("Phone Number")}
                                    required="required" />
                            </div>

                            <div class="col-sm-12 mt-5">
                                <button class="btn btn-theme btn-radius" type='submit'><span>{t('Submit')}</span>
                                </button>
                            </div>
                        </form>

                        <div class="col-sm-12 my-5 text-center">



                            <Modal   {...props}
                                size="lg"
                                style={customStyles}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered show={show} onHide={handleClose} className="top-0 ">

                                <Modal.Header closeButton>
                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                    <Modal.Title style={{ color: "#4ab242" }}>{t('Be Our Partner')}</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <div className="team-member text-center">
                                        <div className="team-description pt-0">
                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                            <p className='lead'>{message}</p>

                                        </div>



                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                        {t('Close')}
                                    </Button>

                                </Modal.Footer>

                            </Modal>
                        </div>
                    </div>
                </div>
            </div >
        </section >

        {/* <!--appointment end--> */}
    </>
}
