import React, { useContext } from 'react'
import PageTitle from '../Utilites/PageTitle'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'
import { LocalizationContext } from '../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';

export default function Vision() {
    const { pathname } = useLocation();
    // translation 
    const { t } = useTranslation("About");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Our')} anotherTitle={t('Vision')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/vision`} currentPage={t('Our Vision')} /> : <PageTitle title={t('Our Vision')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/vision`} currentPage={t('Our Vision')} />}

        {/* <!--page title end--> */}

        {/* <!--about us start--> */}

        <section>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <img className="img-fluid rounded box-shadow w-100" src="/images/about/mission-min.webp" alt="" />
                    </div>
                    <div className="col-lg-7 col-md-12 mt-5 mt-lg-0">
                        <div className="section-title mb-4">
                            <h2 className="title">{t('Vision, Our Purpose & Values')} </h2>
                            <h6 className='h5'><span>{t('Vision')} :</span></h6>
                            <p className="mb-0">{t('We aim to be the most accessible and agile healthcare group in the region, providing innovative, comprehensive, and patient-centric care to all patients, anywhere, anytime.')}</p>
                        </div>
                        <div className="section-title mb-4">
                            <h6 className="h5 title">{t('Our Purpose')} :</h6>
                            <p className="mb-0">{t("At Saudi German Health, our purpose is to positively impact people's health and relieve their suffering.")} </p>
                        </div>
                        <div className="section-title mb-4">

                            <h6 className="h5 title">{t('Values')} : </h6>
                            <p className="mb-0">
                                <ul className='list-unstyled ps-2'>
                                    <li>- {t('Integrity')} . </li>
                                    <li>- {t('Passion & Dedication')} .</li>
                                    <li>- {t('Family and People Centric Culture')} .</li>
                                    <li>- {t('Respect')} .</li>
                                    <li>- {t('Responsibility')} .</li>
                                    <li>- {t('Trust')} .</li>
                                    <li>- {t('Education & Innovation')} .</li>

                                </ul>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        {/* <!--about us end--> */}
    </>
}
