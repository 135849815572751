import axios from 'axios';
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import { useEffect } from 'react';

export default function HomeCareServices() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [category, setCategory] = useState([]);
    async function getAllCategories(e) {
        e.preventDefault();
        const bodyFormData = new FormData();

        const response = await axios.post(`https://sgheg.com/Sadm25/api/homecare/getcategory?lang=${Cookies.get('i18next')}`)
            .then(response => {

                if (response.data.Ecode === 200) {

                    setCategory(response.data.Edata);
                    console.log(response.data.Edata);
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    async function getAllCategories() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),

        }

        const response = await axios.get(`https://sgheg.com/Sadm25/api/homecare/getcategory?lang=${Cookies.get('i18next')}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {

                    setCategory(response.data.Edata);
                    console.log(response.data.Edata);
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getAllCategories();
    }, [])
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    return <>
        <section className="grey-bg position-relative" style={{ backgroundImage: 'url(../images/home-care/bg-7.jpg)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
            <div className="container py-5">
                <div className="row align-items-center justify-content-center text-center text-md-start justify-content-md-between">
                    <div className="col-lg-6 col-md-6 ps-2 ms-2 ps-md-0 ms-md-0">
                        <h3 className="title mb-0 text-white d-none d-md-block">Home Care</h3>

                    </div>
                    <div className="col-lg-6 col-md-5 ps-2 ms-2 ps-md-0 ms-md-0 text-lg-end mt-2 mt-md-0">
                        <nav aria-label="breadcrumb" className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/${Cookies.get('i18next')}`}><i className="fas fa-home"></i></Link>
                                </li>

                                <li className="breadcrumb-item">
                                    <Link to={`/${Cookies.get('i18next')}/home-care/`}>Home Care </Link>
                                </li>


                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section >

        <section class="services-eight" style={{ backgroundImage: 'url(/images/home-care/service-bg.png)' }}>
            <div class="container">
                <div class="section-title-six text-left">
                    <span class="section-title-six__tagline"><span class="section-title-six__tagline__icon"><span aria-hidden="true" class="insur-four-icon-check"><i class="fa-brands fa-servicestack"></i></span></span>Services</span>
                    <h2 class="section-title-six__title">We’re Covering All the<br /> Home Care Services</h2>
                </div>

                <div className="row mt-3 justify-content-center">
                    {category?.map((categories, index) => <>
                        <div class="item col-md-4 mt-5 d-block" key={index}>
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/home-care/${categories.name}/${categories.id}`} onClick={scrollToTop}>

                                <div class="services-eight__single">
                                    <div class="services-eight__single__image">
                                        <img decoding="async" src="/images/home-care/service-8-4.jpg" alt="alt" />
                                    </div>
                                    <div class="services-eight__single__middle">
                                        <div class="services-eight__single__icon"><span aria-hidden="true" class=" insur-four-icon-banking"><i class="fa fa-user-md" aria-hidden="true"></i></span></div>
                                        <h3 class="services-eight__single__title">
                                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/home-care/${categories.name}/${categories.id}`} onClick={scrollToTop}>{categories.name}</Link>
                                        </h3>
                                    </div>
                                    <p class="services-eight__single__text">
                                        {categories.description}
                                    </p>
                                </div>

                            </Link>


                        </div>
                    </>)}

                </div>
            </div>

        </section >
    </>
}
