import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
export default function StaffEvaluationQuestionnaire(props) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [errors, setErrors] = useState([]);

    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    const validateForm = () => {
        const errors = [];


        if (question1.trim() === '') {
            errors.push('Please provide a response for Question 1.');
        }
        if (question2.trim() === '') {
            errors.push('Please provide a response for Question 2.');
        }

        if (question3.trim() === '') {
            errors.push('Please provide a response for Question 3.');
        }

        if (question4.trim() === '') {
            errors.push('Please provide a response for Question 4.');
        }

        if (question5.trim() === '') {
            errors.push('Please provide a response for Question 5.');
        }



        setErrors(errors);
        return errors;
    };
    async function submitForm(e) {
        e.preventDefault();
        const formErrors = validateForm();

        if (formErrors.length === 0) {
            const bodyFormData = new FormData();

            bodyFormData.append('What is the method you think is effective for you for infection control training ?', question1);
            bodyFormData.append('What is the most critical part that needs improvement in IPC program ?', question2);
            bodyFormData.append(' Leadership support for IPC program and actions ?', question3);
            bodyFormData.append('Compliance to IPC policies and procedure level ?', question4);
            bodyFormData.append('IPC department communication and feedback with other department ?', question5);


            const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/StaffEvaluationQuestionnaire`, bodyFormData)
                .then(response => {

                    if (response.data.Ecode === 200) {
                        console.log(response.data);




                        if (response.data.Edata.request_id > 0) {
                            setSubmitMsg('Thank you for completing the survey!');

                        } else {
                            setSubmitMsg('Error !! please Check Your Data')

                        }

                        handleShow();

                    }

                })
                .catch(error => {
                    // Handle any errors
                    console.error(error);
                });

        }
    }
    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>

        <PageTitle title='Staff Evaluation' anotherTitle='Questionnaire' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/staff-evaluation-questionnaire`} currentPage='Staff Evaluation Questionnaire' />
        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">
                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-7 p-5">
                                            <div className='row'>
                                                <div className="section-title col-md-12 text-center">
                                                    <h4 className='title'> <span>IPC program 2024</span></h4>
                                                    <h5 className='fw-bolder'>Staff evaluation questionnaire</h5>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('Please provide a response for Question 1.') ? 'is-invalid' : ''}`}>1.	What is the method you think is effective for you for infection control training ?</label>
                                                </div>
                                                <div class="form-group col-md-6">

                                                    <select className={`form-control ${errors.includes('Please provide a response for Question 1.') ? 'is-invalid' : ''}`} value={question1}
                                                        onChange={(e) => setQuestion1(e.target.value)}>
                                                        <option value=''>Select Answer</option>
                                                        <option value='Lectures'>Lectures</option>
                                                        <option value='On-job training while caring for patients'>On-job training while caring for patients</option>
                                                        <option value='Written work instructions'>Written work instructions </option>
                                                        <option value='Whatsapp groups and posts'>Whatsapp groups and posts</option>
                                                    </select>
                                                    {errors.includes('Please provide a response for Question 1.') && <p className="ivaild-error">Please provide a response for Question 1.</p>}

                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('Please provide a response for Question 2.') ? 'is-invalid' : ''}`}>2.	What is the most critical part need improvement in IPC program ?</label>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select className={`form-control ${errors.includes('Please provide a response for Question 2.') ? 'is-invalid' : ''}`} value={question2}
                                                        onChange={(e) => setQuestion2(e.target.value)}>
                                                        <option value=''>Select Answer</option>
                                                        <option value='Training methodologies'>Training methodologies</option>
                                                        <option value='IPC bundles'>IPC bundles</option>
                                                        <option value='Vaccinations schedules'>Vaccinations schedules</option>
                                                        <option value='Waste segregation'>Waste segregation</option>
                                                    </select>
                                                    {errors.includes('Please provide a response for Question 2.') && <p className="ivaild-error">Please provide a response for Question 2.</p>}

                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('Please provide a response for Question 3.') ? 'is-invalid' : ''}`}>3.	Leadership support for IPC program and actions ?</label>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select className={`form-control ${errors.includes('Please provide a response for Question 3.') ? 'is-invalid' : ''}`} value={question3}
                                                        onChange={(e) => setQuestion3(e.target.value)}>
                                                        <option value=''>Select Answer</option>
                                                        <option value='Good'>Good</option>
                                                        <option value='Fair'>Fair</option>
                                                        <option value='Not good'>Not good</option>
                                                        <option value='Don’t know'>Don’t know</option>
                                                    </select>
                                                    {errors.includes('Please provide a response for Question 3.') && <p className="ivaild-error">Please provide a response for Question 3.</p>}


                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('Please provide a response for Question 4.') ? 'is-invalid' : ''}`}>4.	Compliance to IPC policies and procedure level ?</label>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select className={`form-control ${errors.includes('Please provide a response for Question 4.') ? 'is-invalid' : ''}`} value={question4}
                                                        onChange={(e) => setQuestion4(e.target.value)}>
                                                        <option value=''>Select Answer</option>
                                                        <option value='Very good'>Very good</option>
                                                        <option value='Fair'>Fair</option>
                                                        <option value='Neutral'>Neutral</option>
                                                        <option value='Not good'>Not good</option>
                                                    </select>
                                                    {errors.includes('Please provide a response for Question 4.') && <p className="ivaild-error">Please provide a response for Question 4.</p>}

                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('Please provide a response for Question 5.') ? 'is-invalid' : ''}`}>5.	IPC department communication and feedback with other department ?</label>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select className={`form-control ${errors.includes('Please provide a response for Question 5.') ? 'is-invalid' : ''}`} value={question5}
                                                        onChange={(e) => setQuestion5(e.target.value)}>
                                                        <option value=''>Select Answer</option>
                                                        <option value='Very good'>Very good</option>
                                                        <option value='Fair'>Fair</option>
                                                        <option value='Neutral'>Neutral</option>
                                                        <option value='Not good'>Not good</option>
                                                    </select>
                                                    {errors.includes('Please provide a response for Question 5.') && <p className="ivaild-error"> Please provide a response for Question 5.</p>}

                                                </div>


                                                <div className="col-md-12">
                                                    <button className="btn btn-border btn-circle w-50" type='submit' ><span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5" style={{ backgroundImage: `url('/images/forms/new-hire-paperwork_result.webp')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            {/* <img src="images/forms/new-hire-paperwork_result.webp" alt="open day" /> */}
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>Staff Evaluation Questionnaire</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>

                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}
