import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
// import { AuthContext } from '../../Context/Authentication/AuthContext';
import { useContext } from 'react';
// import { PatientPortalPinInpContext } from '../../Context/Authentication/PatientPortalPinInpContext';
import Cookies from 'js-cookie';
import { AuthContext } from '../../Context/Authentication/AuthContext';
import AfterFooter from '../AfterFooter/AfterFooter';

export default function Layout() {
    // let { userData, setUserData } = useContext(AuthContext);
    // const { pinInp, setPinInp } = useContext(PatientPortalPinInpContext);
    const { pathname } = useLocation();
    let { userData, setUserData } = useContext(AuthContext);

    let navigate = useNavigate();
    function logout() {
        localStorage.removeItem("patientPortalToken");
        setUserData(null);
        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`);
    }
    return <>
        <div className="page-wrapper">
            <Header logout={logout} />
            <Outlet />
            <Footer />
        </div>
        <AfterFooter />
    </>
}
