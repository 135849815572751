const metaData = {
    title: 'My about Title',
    // meta: '<meta name="description" content="About EN page description" />',
    // Add any other metadata properties you need
    // description: 'This is a description of my page',
    meta: [
        { name: 'keywords', content: 'react, javascript, meta tags' },
        { name: 'description', content: 'react, javascript, meta tags' },
        { property: 'description', content: 'react, javascript, meta tags' },

    ]
};
export default metaData;