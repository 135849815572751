import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { GetDepartmentsOptionsContext } from '../../Context/GetDepartmentsOptions';

export default function ICUServiceSatisfactionSurvey(props) {
    const { pathname } = useLocation();
    const navigate = useNavigate();


    const [department, setDepartment] = useState('');
    const [question_1, setQuestion_1] = useState('');
    const [question_2, setQuestion_2] = useState('');
    const [question_3, setQuestion_3] = useState('');
    const [question_4, setQuestion_4] = useState('');
    const [question_5, setQuestion_5] = useState('');
    const [question_6, setQuestion_6] = useState('');
    const [question_7, setQuestion_7] = useState('');
    const [question_8, setQuestion_8] = useState('');
    const [question_9, setQuestion_9] = useState('');
    const [question_10, setQuestion_10] = useState('');



    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    // const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    const validateForm = () => {
        const errors = [];

        // if (department.trim() === '') {
        //     errors.push('Please Enter Your Department.');
        // }


        if (!question_1) {
            errors.push('This Field Has been Required.');
        }
        if (!question_2) {
            errors.push('This Field Has been Required.');
        }
        if (!question_3) {
            errors.push('This Field Has been Required.');
        }
        if (!question_4) {
            errors.push('This Field Has been Required.');
        }

        if (!question_5) {
            errors.push('This Field Has been Required.');
        }
        if (!question_6) {
            errors.push('This Field Has been Required.');
        }
        if (!question_7) {
            errors.push('This Field Has been Required.');
        }
        if (!question_8) {
            errors.push('This Field Has been Required.');
        }
        if (!question_9) {
            errors.push('This Field Has been Required.');
        }
        if (!question_10) {
            errors.push('This Field Has been Required.');
        }


        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append('department', department);
            bodyFormData.append('The process of admission and discharge from ICU is efficient.', question_1);
            bodyFormData.append('The process of consultation to ICU team for patients in the ward is effective.', question_2);
            bodyFormData.append('ICU nurses are competent and respond promptly to critical situations.', question_3);
            bodyFormData.append('The ICU equipment is adequate and ready for critical cases.', question_4);
            bodyFormData.append('ICU team responds effectively through the RRT and code blue.', question_5);
            bodyFormData.append('The handover process of cases transferred from ICU to the ward is smooth', question_6);
            bodyFormData.append('Infection control policies are well applied inside the ICU.', question_7);
            bodyFormData.append('ICU senior staff and managers are responsive in solving patient-related problems', question_8);
            bodyFormData.append('Communication and collaboration of ICU physicians during daily clinical rounds are effective.', question_9);
            bodyFormData.append('ICU beds are vacant when needed (MICU, SICU).', question_10);



            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/ICUServiceSatisfactionSurvey`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Thank you for Registration!');
                        // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');
                        setQuestion_1('');
                        setQuestion_2('');
                        setQuestion_3('');
                        setQuestion_4('');
                        setQuestion_5('');
                        setQuestion_6('');
                        setQuestion_7('');
                        setQuestion_8('');
                        setQuestion_9('');
                        setQuestion_10('');

                        setDepartment('');


                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])


    // get departments
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    useEffect(() => {
        GetDepartmentsSelectOptions('1');

    }, [])
    return <>
        <PageTitle title='ICU Service' anotherTitle='Satisfaction Survey' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/icu-service-satisfaction-survey`} currentPage='ICU Service Satisfaction Survey' />
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='p-0'>
                <div className="container">
                    <div className="row">

                        <div className="post-comments">
                            <div className='row justify-content-center'>
                                <div className="section-title text-center mb-0">
                                    <h6 className="title my-3 text-center">WELCOME <span> TO THE ICU QUESTIONNAIRE</span></h6>
                                    <p className='text-center'>
                                        By completing this survey, you can help Intensive Care Units Department focus its efforts and resources effectively while recognizing and prioritizing your needs. This survey is confidential and is for services specifically provided at SGH-Cairo. Thank you, in advance, for your contribution to this survey
                                        <br />
                                        We look forward to providing you with continued and improved OR services.
                                        <br />  Your support is appreciated.

                                    </p>
                                </div>
                            </div>



                            <form onSubmit={submitForm}>
                                <div className="messages"></div>
                                <div className="row justify-content-center">
                                    <div className="col-md-7 p-5 order-3 box-shadow">

                                        <div className="row">

                                            <div className="form-group col-md-5">
                                                <input className='form-control' placeholder='Please indicate your department' value={department} onChange={(e) => setDepartment(e.target.value)} />


                                            </div>

                                            <div className="row">


                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>The process of admission and discharge from ICU is efficient.</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques1-1'
                                                        value="Strongly Agree"
                                                        checked={question_1 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques1-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_1 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques1-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_1 === 'Neither'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques1-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_1 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques1-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_1 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>The process of consultation to ICU team for patients in the ward is effective.</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques2-1'
                                                        value="Strongly Agree"
                                                        checked={question_2 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques2-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_2 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques2-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_2 === 'Neither'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques2-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_2 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques2-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_2 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />
                                                    <label htmlFor='ques2-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>ICU nurses are competent and respond promptly to critical situations.  </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques3-1'
                                                        value="Strongly Agree"
                                                        checked={question_3 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques3-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_3 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques3-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_3 === 'Neither'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques3-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_3 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques3-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_3 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>The ICU equipment is adequate and ready for critical cases</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques4-1'
                                                        value="Strongly Agree"
                                                        checked={question_4 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques4-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_4 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques4-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_4 === 'Neither'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques4-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_4 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques4-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_4 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>ICU team responds effectively through the RRT and code blue </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques5-1'
                                                        value="Strongly Agree"
                                                        checked={question_5 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques5-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_5 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques5-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_5 === 'Neither'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques5-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_5 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques5-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_5 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />
                                                    <label htmlFor='ques5-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>The handover process of cases transferred from ICU to the ward is smooth. </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques6-1'
                                                        value="Strongly Agree"
                                                        checked={question_6 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques6-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_6 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques6-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_6 === 'Neither'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques6-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_6 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques6-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_6 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Infection control policies are well applied inside the ICU. </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques7-1'
                                                        value="Strongly Agree"
                                                        checked={question_7 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques7-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_7 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques7-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_7 === 'Neither'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques7-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_7 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques7-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_7 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>ICU senior staff and managers are responsive in solving patient-related problems </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques8-1'
                                                        value="Strongly Agree"
                                                        checked={question_8 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques8-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_8 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques8-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_8 === 'Neither'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques8-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_8 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques8-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_8 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Communication and collaboration of ICU physicians during daily clinical rounds are effective. </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques9-1'
                                                        value="Strongly Agree"
                                                        checked={question_9 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques9-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_9 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques9-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_9 === 'Neither'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques9-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_9 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques9-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_9 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />
                                                    <label htmlFor='ques9-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>ICU beds are vacant when needed (MICU, SICU). </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques10-1'
                                                        value="Strongly Agree"
                                                        checked={question_10 === 'Strongly Agree'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-1'> Strongly Agree</label>

                                                    <input className='ms-3 mx-2' id='ques10-2'
                                                        type="radio"
                                                        value="Satisfied"
                                                        checked={question_10 === 'Satisfied'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-2'>Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques10-3'
                                                        type="radio"
                                                        value="Neither"
                                                        checked={question_10 === 'Neither'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-3'> Neither</label>
                                                    <input className='ms-3 mx-2' id='ques10-4'
                                                        type="radio"
                                                        value="Not Satisfied"
                                                        checked={question_10 === 'Not Satisfied'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-4'> Not Satisfied</label>
                                                    <input className='ms-3 mx-2' id='ques10-5'
                                                        type="radio"
                                                        value="Strongly Not Satisfied"
                                                        checked={question_10 === 'Strongly Not Satisfied'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-5'> Strongly Not Satisfied</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>


                                            </div>

                                            <div className="col-md-4">
                                                <button className="btn btn-border btn-circle w-100" type='submit'><span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/survey-form-online.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                                <img src="/images/forms/survey-form-online.jpg" alt="patient survey 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'contain', backgroundColor: '#61882b', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} />
                                            </div> */}
                                    <div class="col-sm-12 text-center">
                                        <Modal   {...props}
                                            size="lg"
                                            style={customStyles}
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered show={show} onHide={handleClose} className="top-0 ">

                                            <Modal.Header closeButton>
                                                <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <div className="team-member text-center">

                                                    <div className="team-description pt-0">
                                                        <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                        {/* <p className='lead'>{messageForm}</p> */}
                                                    </div>



                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>

                                            </Modal.Footer>

                                        </Modal>
                                    </div>
                                </div>

                            </form>

                        </div>

                    </div >
                </div >
            </section >
        </div >
    </>
}
