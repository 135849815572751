import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PageTitle from '../../Utilites/PageTitle';

export default function SessionRegistration(props) {
    const { pathname } = useLocation();
    const [prefix, setPrefix] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [organization, setOrganization] = useState('');
    const [email, setEmail] = useState('');
    const [whatsAppNumber, setWhatsAppNumber] = useState('');
    const [sessionName, setSessionName] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    const validateForm = () => {
        const errors = [];
        const phoneNumberRegex = /^[\+]?[0-9]{0,3}\W?[()0-9]{0,3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

        if (prefix.trim() === '') {
            errors.push('prefix is required.');
        }
        if (name.trim() === '') {
            errors.push('Name is required.');
        }
        if (title.trim() === '') {
            errors.push('Title is required.');
        }

        if (organization.trim() === '') {
            errors.push('Organization is required.');
        }


        if (!email) {
            errors.push('Your Email is required.');
        }
        else if (!email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
            errors.push('Please enter a valid email address. Expected format: example@example.com.');
        }
        if (!whatsAppNumber) {
            errors.push('Your Whatsapp number is required.');
        }
        else if (!phoneNumberRegex.test(whatsAppNumber)) {
            errors.push('Please enter a valid Whatsapp number. Expected format: [+][Country Code][Area Code][Whatsapp Number].');
        }
        if (sessionName.trim() === '') {
            errors.push('Session Name is required.');
        }



        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append('prefix', prefix);
            bodyFormData.append('name', name);
            bodyFormData.append('email', email);
            bodyFormData.append('whatsapp', whatsAppNumber);
            bodyFormData.append('title', title);
            bodyFormData.append('organization', organization);
            bodyFormData.append('session name', sessionName);

            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/excon2024-session-registration`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Thank you for Your Registartion!');
                        setPrefix('');
                        setName('');
                        setEmail('');
                        setWhatsAppNumber('');
                        setTitle('');
                        setOrganization('');
                        setSessionName('');
                        // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');
                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>
        <PageTitle title='Africa Health' anotherTitle='ExCon 2024' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/excon2024-session-registration`} currentPage='Africa Health ExCon 2024' />
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">

                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-6 p-5 order-2">
                                            <div className='row justify-content-center'>
                                                <div class="section-title mb-5">
                                                    <h4 class="title mb-3 text-center">Healthcare Leadership and <span>Management Conference</span></h4>

                                                    {/* <h6>"Transforming Healthcare in Africa: Strategies for Building Resilient, Sustainable and Inclusive Health Systems"</h6> */}

                                                </div>
                                                <div className="form-group col-md-12">
                                                    <select id="form_address" type="text" name="prefix" className={`form-control ${errors.includes('prefix is required.') ? 'is-invalid' : ''}`} value={prefix} onChange={(e) => setPrefix(e.target.value)} >
                                                        <option value="">Select Prefix</option>
                                                        <option value="Dr">Dr</option>
                                                        <option value="Mr">Mr</option>
                                                        <option value="Ms">Ms</option>
                                                        <option value="Eng">Eng</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    {errors.includes('prefix is required.') && (
                                                        <div className="invalid-feedback">prefix is required.</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input id="form_name" type="text" name="name" className={`form-control ${errors.includes('Name is required.') ? 'is-invalid' : ''}`} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} data-error="Name is required." />

                                                    {errors.includes('Name is required.') && (
                                                        <div className="invalid-feedback">Name is required.</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input id="form_name" type="text" name="title" className={`form-control ${errors.includes('Title is required.') ? 'is-invalid' : ''}`} placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} data-error="Title is required." />

                                                    {errors.includes('Title is required.') && (
                                                        <div className="invalid-feedback">Title is required.</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input id="form_name" type="text" name="organization" className={`form-control ${errors.includes('Organization is required.') ? 'is-invalid' : ''}`} placeholder="Organization" value={organization} onChange={(e) => setOrganization(e.target.value)} data-error="Organization is required." />

                                                    {errors.includes('Organization is required.') && (
                                                        <div className="invalid-feedback">Organization is required.</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input type="email" name="email" className={`form-control ${errors.includes('Your Email is required.') ? 'is-invalid' : ''} ${errors.includes('Please enter a valid email address. Expected format: example@example.com') ? 'is-invalid' : ''}`} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} data-error="Please enter a valid email address. Expected format: example@example.com" />
                                                    {errors.includes('Your Email is required.') && (
                                                        <div className="invalid-feedback">Your Email is required.</div>
                                                    )}
                                                    {errors.includes('Please enter a valid email address. Expected format: example@example.com') && (
                                                        <div className="invalid-feedback">Please enter a valid email address. Expected format: example@example.com</div>
                                                    )}

                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input type="text" name="Whatsapp" className={`form-control ${errors.includes('Your Whatsapp number is required.') ? 'is-invalid' : ''} ${errors.includes('Please enter a valid Whatsapp number. Expected format: [+][Country Code][Area Code][Whatsapp Number].') ? 'is-invalid' : ''}`} placeholder="WhatsApp Number" value={whatsAppNumber}
                                                        onChange={(e) => setWhatsAppNumber(e.target.value)}
                                                    />
                                                    {errors.includes('Your Whatsapp number is required.') && (
                                                        <div className="invalid-feedback">Your Whatsapp number is required.</div>
                                                    )}
                                                    {errors.includes('Please enter a valid Whatsapp number. Expected format: [+][Country Code][Area Code][Whatsapp Number].') && (
                                                        <div className="invalid-feedback">Please enter a valid Whatsapp number. Expected format: [+][Country Code][Area Code][Whatsapp Number].</div>
                                                    )}
                                                </div>


                                                <div className="form-group col-md-12">
                                                    <select id="form_address" type="text" name="rounnd-table" className={`form-control ${errors.includes('Session Name is required.') ? 'is-invalid' : ''}`} value={sessionName} onChange={(e) => setSessionName(e.target.value)} >
                                                        <option value="">Select Session</option>
                                                        <option value="Healthy Governance, Healthy Policy: A Recipe for Healthcare Success">Healthy Governance, Healthy Policy: A Recipe for Healthcare Success</option>
                                                        <option value="Aligning Value and Revenue for Better Healthcare Eco-System">Aligning Value and Revenue for Better Healthcare Eco-System</option>
                                                        <option value="The Culture Cure: Transforming Healthcare from the Inside Out">The Culture Cure: Transforming Healthcare from the Inside Out</option>
                                                        <option value="Digital Health Innovations: Building Sustainable and Resilient Health Systems">Digital Health Innovations: Building Sustainable and Resilient Health Systems</option>
                                                        <option value="Maximizing Success: Empowering a Value-Driven Workforce for Sustainable Growth">Maximizing Success: Empowering a Value-Driven Workforce for Sustainable Growth</option>
                                                        <option value="Greening Healthcare: Building Sustainable Hospitals for the Future">Greening Healthcare: Building Sustainable Hospitals for the Future</option>
                                                        <option value="Metrics that Matter: Mastering System Measurement for Optimal Outcomes">Metrics that Matter: Mastering System Measurement for Optimal Outcomes</option>
                                                        <option value="Enhancing Healthcare Supply Chains: Tactics for Improving Efficiency and Lowering Costs">Enhancing Healthcare Supply Chains: Tactics for Improving Efficiency and Lowering Costs</option>

                                                    </select>
                                                    {errors.includes('Session Name is required.') && (
                                                        <div className="invalid-feedback">Session Name is required.</div>
                                                    )}
                                                </div>


                                                <div className="col-md-6">
                                                    <button className="btn btn-border btn-circle w-100" type='submit'><span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/registration.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            <img src="/images/forms/AF1QipNpl8Ix5zPg5lnLqSdLAv1AYSPHUstgGFBibSth=w1600-h400.jpg" alt="excon 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} />
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            {/* <p className='lead'>{messageForm}</p> */}
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
