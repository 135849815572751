import React, { useContext, useEffect, useState } from 'react'
import PageTitle from '../Utilites/PageTitle'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';
export default function PatientsStories() {
    // translation 
    const { t } = useTranslation("PatientsStories");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();

    let [shownStroke, setShownStroke] = useState(t("Join Salim on his courageous journey through various medical specialties at Saudi German Hospital Cairo."))
    let [shownCases, setShownCases] = useState(t("Precision Microscopic Surgery and Collaborating with integrated specialties to treat a hand injury for Engineer Hossam Al-Din Mohamed."))
    let [shownOperation, setShownOperation] = useState(t("Diagnosis of a liver hematoma at the Saudi German Hospital - Cairo Heartbeat of Hope: A Life Saved."))
    let [shownFingerOperation, setShownFingerOperation] = useState(t("Success Story of 'Farida' at the Saudi German Hospital - Cairo after tumor removal in the brain."))
    let [shownMario, setShownMario] = useState(t("Severe fatigue during his trip to Dubai led Mario Patchenelo to an emergency landing at Cairo Airport, where he sought urgent medical care at the Saudi German Hospital."))
    let [shownPatient, setShownPatient] = useState(t("Various success stories achieved by Professor Dr. Mohammed Al-Sayyad at the Saudi German Hospital - Cairo."))
    let [shownSurvivor, setShownSurvivor] = useState(t("After suddenly suffering a stroke at work, Mohamed Salem was rushed to the Saudi German Hospital - Cairo,"))
    let [shownJinan, setShownJinan] = useState(t("Recovery Story of our hero 'Jinan' at the Saudi German Hospital - Cairo in the Bone Marrow Transplant Unit."))
    let [shownRehab, setShownRehab] = useState(t("Recovery Journey of 'Rehab' from Yemen documented by the Bone Marrow Transplant Unit at the Saudi German Hospital - Cairo. Follow Rehab's courageous journey of recovery as they undergo a transformative bone marrow transplant at the Saudi German Hospital - Cairo."))


    // const videoUrl = '../../images/success-stories/Cerebral-Stroke-Patient.mp4/video?autoplay=0';
    // useEffect(() => {
    //     const iframe = document.querySelector('.videos');
    //     const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    //     const videoElement = iframeDocument.querySelector('video');

    //     iframe.innerHTML = `<video controls="" name="media"><source src="http://localhost:3000/images/success-stories/Cerebral-Stroke-Patient.mp4" type="video/mp4"></video>`;

    // }, [])
    function seeMoreStroke() {
        setShownStroke(t("Join Salim on his courageous journey through various medical specialties at Saudi German Hospital Cairo. After a prolonged diagnostic process, his life was ultimately saved, highlighting the hospital's commitment to comprehensive care and expertise. You can know more by watching the video."))
        document.querySelector('.post-btn').classList.add('d-none');
        document.querySelector('.less-btn').classList.remove('d-none');
    }
    function seeLessStroke() {
        setShownStroke(t("Join Salim on his courageous journey through various medical specialties at Saudi German Hospital Cairo."))
        document.querySelector('.post-btn').classList.remove('d-none');
        document.querySelector('.less-btn').classList.add('d-none');
    }
    function seeMoreCases() {
        setShownCases(t("Precision Microscopic Surgery and Collaborating with integrated specialties to treat a hand injury for Engineer Hossam Al-Din Mohamed. Experience the precision and expertise of the surgical team as they restore functionality to Hossam's hand, enabling him to resume his daily activities with confidence. You can know more by watching the video."))
        document.querySelector('.post-cases-btn').classList.add('d-none');
        document.querySelector('.less-cases-btn').classList.remove('d-none');
    }
    function seeLessCases() {
        setShownCases(t("Precision Microscopic Surgery and Collaborating with integrated specialties to treat a hand injury for Engineer Hossam Al-Din Mohamed."))
        document.querySelector('.post-operation-btn').classList.remove('d-none');
        document.querySelector('.less-operation-btn').classList.add('d-none');
    }
    function seeMoreOperation() {
        setShownOperation(t("Diagnosis of a liver hematoma at the Saudi German Hospital - Cairo Heartbeat of Hope: A Life Saved. Follow the inspiring recovery journey of a patient from Libya as they overcome significant challenges with resilience and determination. Witness the compassionate care and advanced medical interventions that lead to their successful recovery. You can know more by watching the video."))
        document.querySelector('.post-operation-btn').classList.add('d-none');
        document.querySelector('.less-operation-btn').classList.remove('d-none');
    }
    function seeLessOperation() {
        setShownOperation(t("Diagnosis of a liver hematoma at the Saudi German Hospital - Cairo Heartbeat of Hope: A Life Saved."))
        document.querySelector('.post-operation-btn').classList.remove('d-none');
        document.querySelector('.less-operation-btn').classList.add('d-none');
    }
    function seeMoreFingerOperation() {
        setShownFingerOperation(t("Success Story of 'Farida' at the Saudi German Hospital - Cairo after tumor removal in the brain. Explore Farida's journey of courage and resilience as she faces and conquers a challenging brain tumor diagnosis. Learn how advanced medical techniques and compassionate support lead to her successful recovery and renewed hope for the future. You can know more by watching the video."))
        document.querySelector('.post-finger-btn').classList.add('d-none');
        document.querySelector('.less-finger-btn').classList.remove('d-none');
    }
    function seeLessFingerOperation() {
        setShownFingerOperation(t("Success Story of 'Farida' at the Saudi German Hospital - Cairo after tumor removal in the brain."))
        document.querySelector('.post-finger-btn').classList.remove('d-none');
        document.querySelector('.less-finger-btn').classList.add('d-none');
    }

    function seeMoreMario() {
        setShownMario(t("Severe fatigue during his trip to Dubai led Mario Patchenelo to an emergency landing at Cairo Airport, where he sought urgent medical care at the Saudi German Hospital. Discover the unexpected journey of this traveler whose mid-flight emergency unveils the hospital's swift response and compassionate support, ensuring Mario's well-being. You can know more by watching the video."))
        document.querySelector('.post-mario-btn').classList.add('d-none');
        document.querySelector('.less-mario-btn').classList.remove('d-none');
    }
    function seeLessMario() {
        setShownMario(t("Severe fatigue during his trip to Dubai led Mario Patchenelo to an emergency landing at Cairo Airport, where he sought urgent medical care at the Saudi German Hospital."))
        document.querySelector('.post-mario-btn').classList.remove('d-none');
        document.querySelector('.less-mario-btn').classList.add('d-none');
    }

    function seeMorePatient() {
        setShownPatient(t("Various success stories achieved by Professor Dr. Mohammed Al-Sayyad at the Saudi German Hospital - Cairo. Explore the diverse journey of recovery for patients suffering from shoulder and spinal pain under the expert care of Professor Dr. Mohammed Al-Sayyad. Witness the positive impact of his expertise and dedication on patient outcomes, ranging from complex surgeries to innovative treatments. You can know more by watching the video."))
        document.querySelector('.post-patient-btn').classList.add('d-none');
        document.querySelector('.less-patient-btn').classList.remove('d-none');
    }
    function seeLessPatient() {
        setShownPatient(t("Various success stories achieved by Professor Dr. Mohammed Al-Sayyad at the Saudi German Hospital - Cairo."))
        document.querySelector('.post-patient-btn').classList.remove('d-none');
        document.querySelector('.less-patient-btn').classList.add('d-none');
    }

    function seeMoreSurvivor() {
        setShownSurvivor(t("After suddenly suffering a stroke at work, Mohamed Salem was rushed to the Saudi German Hospital - Cairo, where his life was saved. Follow the gripping journey of this stroke survivor as he navigates through the challenges of recovery with determination and resilience. Learn how timely medical intervention and comprehensive rehabilitation lead to his successful recovery and renewed hope for the future. You can know more by watching the video."))
        document.querySelector('.post-Survivor-btn').classList.add('d-none');
        document.querySelector('.less-Survivor-btn').classList.remove('d-none');
    }
    function seeLessSurvivor() {
        setShownSurvivor(t("After suddenly suffering a stroke at work, Mohamed Salem was rushed to the Saudi German Hospital - Cairo,"))
        document.querySelector('.post-Survivor-btn').classList.remove('d-none');
        document.querySelector('.less-Survivor-btn').classList.add('d-none');
    }
    function seeMoreJinan() {
        setShownJinan(t("Recovery Story of our hero 'Jinan' at the Saudi German Hospital - Cairo in the Bone Marrow Transplant Unit. Join Jinan on her remarkable journey of recovery as she undergoes a life-saving bone marrow transplant at the Saudi German Hospital - Cairo. Witness the hospital's specialized care and unwavering support in ensuring Jinan's successful recovery and renewed hope for the future. You can know more by watching the video."))
        document.querySelector('.post-Jinan-btn').classList.add('d-none');
        document.querySelector('.less-Jinan-btn').classList.remove('d-none');
    }
    function seeLessJinan() {
        setShownJinan(t("Recovery Story of our hero 'Jinan' at the Saudi German Hospital - Cairo in the Bone Marrow Transplant Unit."))
        document.querySelector('.post-Jinan-btn').classList.remove('d-none');
        document.querySelector('.less-Jinan-btn').classList.add('d-none');
    }
    function seeMoreRehab() {
        setShownRehab(t("Recovery Journey of 'Rehab' from Yemen documented by the Bone Marrow Transplant Unit at the Saudi German Hospital - Cairo. Follow Rehab's courageous journey of recovery as they undergo a transformative bone marrow transplant at the Saudi German Hospital - Cairo. Discover the hospital's compassionate care and advanced medical interventions that lead to Rehab's successful recovery and renewed hope for the future. You can know more by watching the video."))
        document.querySelector('.post-Rehab-btn').classList.add('d-none');
        document.querySelector('.less-Rehab-btn').classList.remove('d-none');
    }
    function seeLessRehab() {
        setShownRehab(t("Recovery Journey of 'Rehab' from Yemen documented by the Bone Marrow Transplant Unit at the Saudi German Hospital - Cairo. Follow Rehab's courageous journey of recovery as they undergo a transformative bone marrow transplant at the Saudi German Hospital - Cairo."))
        document.querySelector('.post-Rehab-btn').classList.remove('d-none');
        document.querySelector('.less-Rehab-btn').classList.add('d-none');
    }
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Patient')} anotherTitle={t('Testimonials')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-and-visitors`} prevPage={t('patients and Visitor')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-and-visitors/patients-stories`} currentPage={t('Patient testimonials')} /> : <PageTitle title={t('Patient testimonials')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-and-visitors`} prevPage={t('patients and Visitor')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-and-visitors/patients-stories`} currentPage={t('Patient testimonials')} />}
        {/* <!--page title end--> */}

        {/* <!--body content start--> */}

        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">

                    <div className="masonry row">
                        <div className="masonry-brick position-static col-md-6">
                            <div className="post">
                                <div className="video-post">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/v7FEJpzvl_k?si=Exi4nLIlZBkgpOlA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="post-desc">
                                    {/* <div className="post-meta">
                                                <ul className="list-inline">


                                                    <li>23 April, 2019</li>
                                                    <li></li>

                                                </ul>
                                            </div> */}
                                    <div className="post-title">
                                        <h5><Link to="">{t("Dedicated Diagnosis with Salim's Journey")}</Link></h5>
                                    </div>
                                    <p>{shownStroke}

                                    </p>
                                    <a className='post-btn cursor-pointer' onClick={seeMoreStroke}>{t('See More')}</a>
                                    <a className='less-btn d-none cursor-pointer' onClick={seeLessStroke}>{t('See less')}</a>



                                </div>
                            </div>
                        </div>
                        <div className="masonry-brick position-static col-md-6">
                            <div className="post">
                                <div className="video-post">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/SyA0Wqz2BpE?si=QZ_P9Rs96tTbtiDJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="post-desc">
                                    {/* <div className="post-meta">
                                                <ul className="list-inline">


                                                    <li>23 April, 2019</li>
                                                    <li></li>

                                                </ul>
                                            </div> */}
                                    <div className="post-title">
                                        <h5><Link to="">{t('Fine-Tuned Hand Restoration')}</Link></h5>
                                    </div>
                                    <p>{shownCases}</p>
                                    <a className='post-cases-btn cursor-pointer' onClick={seeMoreCases}>{t('See More')}</a>
                                    <a className='less-cases-btn d-none cursor-pointer' onClick={seeLessCases}>{t('See less')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="masonry-brick position-static col-md-6">
                            <div className="post">
                                <div className="video-post">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rLZbAtrp7zo?si=lAFUWbPzQL7cK9nK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="post-desc">
                                    {/* <div className="post-meta">
                                                <ul className="list-inline">


                                                    <li>23 April, 2019</li>
                                                    <li></li>

                                                </ul>
                                            </div> */}
                                    <div className="post-title">
                                        <h5><Link to="">{t('New Recovery Journey from Libya')}</Link></h5>
                                    </div>
                                    <p>{shownOperation} </p>
                                    <a className='post-operation-btn cursor-pointer' onClick={seeMoreOperation}>{t('See More')}</a>
                                    <a className='less-operation-btn d-none cursor-pointer' onClick={seeLessOperation}>{t('See less')}</a>


                                </div>
                            </div>
                        </div>
                        <div className="masonry-brick position-static col-md-6">
                            <div className="post">
                                <div className="video-post">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/GdHzPgHJE_Q?si=p6cOCpZzOmsWC7pD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                        </div>

                                <div className="post-desc">
                                    {/* <div className="post-meta">
                                                <ul className="list-inline">

                                                    <li>23 April, 2019</li>
                                                    <li></li>

                                                </ul>
                                            </div> */}
                                    <div className="post-title">
                                        <h5><Link to="">{t("Farida's Courage: Conquering Tumor")}</Link></h5>
                                    </div>
                                    <p>{shownFingerOperation}</p>
                                    <a className='post-finger-btn cursor-pointer' onClick={seeMoreFingerOperation}>{t('See More')}</a>
                                    <a className='less-finger-btn d-none cursor-pointer' onClick={seeLessFingerOperation}>{t('See less')}</a>




                                </div>
                            </div>
                        </div>
                        <div className="masonry-brick position-static col-md-6">
                            <div className="post">
                                <div className="video-post">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ktj0FDJbM30?si=JD5Q0U_B7lkHW1xn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                        </div>
                                <div className="post-desc">
                                    {/* <div className="post-meta">
                                                <ul className="list-inline">


                                                    <li>23 April, 2019</li>
                                                    <li></li>

                                                </ul>
                                            </div> */}
                                    <div className="post-title">
                                        <h5><Link to="">{t('Emergency Landing: Flight to Care')}</Link></h5>
                                    </div>
                                    <p>{shownMario}</p>
                                    <a className='post-mario-btn cursor-pointer' onClick={seeMoreMario}>{t('See More')}</a>
                                    <a className='less-mario-btn d-none cursor-pointer' onClick={seeLessMario}>{t('See less')}</a>



                                </div>
                            </div>
                        </div>
                        <div className="masonry-brick position-static col-md-6">
                            <div className="post">
                                <div className="video-post">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/DYMwsHg2ymc?si=v-ipL3oGS0zp3OG5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="post-desc">

                                    <div className="post-title">
                                        <h5><Link to="">{t('Professor Mohammed Al-Sayyad Success Stories')}</Link></h5>
                                    </div>
                                    <p>{shownPatient}</p>
                                    <a className='post-patient-btn cursor-pointer' onClick={seeMorePatient}>{t('See More')}</a>
                                    <a className='less-patient-btn d-none cursor-pointer' onClick={seeLessPatient}>{t('See less')}</a>

                                </div>
                            </div>
                        </div>

                        <div className="masonry-brick position-static col-md-6">
                            <div className="post">
                                <div className="video-post">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/n50TF8t0KXg?si=8BXJKuGTDoLwWLQ6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="post-desc">

                                    <div className="post-title">
                                        <h5><Link to="">{t("Stroke Survivor's Story")}</Link></h5>
                                    </div>
                                    <p>{shownSurvivor}</p>

                                    <a className='post-Survivor-btn cursor-pointer' onClick={seeMoreSurvivor}>{t('See More')}</a>
                                    <a className='less-Survivor-btn d-none cursor-pointer' onClick={seeLessSurvivor}>{t('See less')}</a>


                                </div>
                            </div>
                        </div>

                        <div className="masonry-brick position-static col-md-6">
                            <div className="post">
                                <div className="video-post">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/vfuSRda-09M?si=V_1ZWx-3kz3UyuDE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                                                                  </div>
                                <div className="post-desc">

                                    <div className="post-title">
                                        <h5><Link to="">{t("Jinan's Success Story")}</Link></h5>
                                    </div>
                                    <p>{shownJinan}</p>
                                    <a className='post-Jinan-btn cursor-pointer' onClick={seeMoreJinan}>{t('See More')}</a>
                                    <a className='less-Jinan-btn d-none cursor-pointer' onClick={seeLessJinan}>{t('See less')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="masonry-brick position-static col-md-6">
                            <div className="post">
                                <div className="video-post">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/wxixJff-V9s?si=CPYadrVVa9AC068D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="post-desc">

                                    <div className="post-title">
                                        <h5><Link to="">{t("Rehab's Recovery Journey")}</Link></h5>
                                    </div>
                                    <p>{shownRehab}</p>
                                    <a className='post-Rehab-btn cursor-pointer' onClick={seeMoreRehab}>{t('See More')}</a>
                                    <a className='less-Rehab-btn d-none cursor-pointer' onClick={seeLessRehab}>{t('See less')}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section >

            {/* <!--blog end--> */}

        </div >

        {/* <!--body content end--> */}
    </>
}
