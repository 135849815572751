import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Modal, Button } from 'react-bootstrap';

export default function ServicesDetails() {

    const [categoryDetails, setCategoryDetails] = useState([]);

    const { category_name, category_id } = useParams();
    const [categoryName, setCategoryName] = useState();
    console.log(category_id);
    async function getCategoryDetails(categoryId) {

        const response = await axios.get(`https://sgheg.com/Sadm25/api/homecare/getservice?category_id=${categoryId}&lang=${Cookies.get('i18next')}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {

                    setCategoryDetails(response.data.Edata);
                    console.log(response.data.Edata);
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getCategoryDetails(category_id);
        console.log(category_id);
        setCategoryName(category_name)
        // console.log('ddddddddddddddddddddddddddddddd ' + doctorDetails);
    }, [])
    const [selectedCategory, setSelectedCategory] = useState(null);
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        getCategoryDetails(category_id)
        // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/home-care/${categories.name}${categories.id}`)
    };
    const [selectedService, setSelectedService] = useState(null);

    const handleServiceClick = (service) => {
        setSelectedService(service);
    };
    const [showAlert, setShowAlert] = useState(false);
    // const [alertMessage, setAlertMessage] = useState('');
    const closeAlert = () => {
        setShowAlert(false);
    };
    const handleOrderClick = (service) => {
        const existingOrders = JSON.parse(localStorage.getItem('orderedServices')) || [];

        const isAlreadyOrdered = existingOrders.some((order) => order.id === service.id);

        if (!isAlreadyOrdered) {
            const updatedOrders = [...existingOrders, service];
            console.log(updatedOrders);
            localStorage.setItem('orderedServices', JSON.stringify(updatedOrders));
            console.log(updatedOrders);
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        } else {
            alert('This service has already been ordered.');

            setShowAlert(false);
        }
    };

    const handleRemoveService = (serviceId) => {
        const updatedOrders = orderedServices.filter((service) => service.id !== serviceId);
        setOrderedServices(updatedOrders);
        localStorage.setItem('orderedServices', JSON.stringify(updatedOrders));
    };

    const handleViewOrders = () => {
        const orders = JSON.parse(localStorage.getItem('orderedServices')) || [];
        setOrderedServices(orders);
        setShowModal(true);
    };

    const [showModal, setShowModal] = useState(false);
    const [orderedServices, setOrderedServices] = useState([]);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    function registration() {
        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/home-care/registration`);
    }

    // get all categories

    const [category, setCategory] = useState([]);
    async function getAllCategories(e) {
        e.preventDefault();
        const bodyFormData = new FormData();



        const response = await axios.post(`https://sgheg.com/Sadm25/api/homecare/getcategory?lang=${Cookies.get('i18next')}`)
            .then(response => {

                if (response.data.Ecode === 200) {

                    setCategory(response.data.Edata);
                    console.log(response.data.Edata);
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    async function getAllCategories() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),

        }

        const response = await axios.get(`https://sgheg.com/Sadm25/api/homecare/getcategory?lang=${Cookies.get('i18next')}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {

                    setCategory(response.data.Edata);
                    console.log(response.data.Edata);
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getAllCategories();
    }, [])

    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    return <>

        <section className="grey-bg position-relative" style={{ backgroundImage: 'url(/images/home-care/bg-7.jpg)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
            <div className="container py-5">
                <div className="row align-items-center justify-content-center text-center text-md-start justify-content-md-between">
                    <div className="col-lg-6 col-md-6 ps-2 ms-2 ps-md-0 ms-md-0">
                        <h3 className="title mb-0 text-white d-none d-md-block fw-bold">Home Care Categories</h3>

                    </div>
                    <div className="col-lg-10 col-md-10 ps-2 ms-2 ps-md-0 ms-md-0 text-lg-end mt-5 position-absolute " style={{ top: '50%', zIndex: '9' }}>
                        <div class="vc_tta-tabs-container">
                            <ul class="vc_tta-tabs-list">
                                {/* <li key={{ index }}
                                    id={`menu-item-${categoryDetail}`}
                                    className={`menu-item menu-item-type-post_type menu-item-object-page ${selectedService === categoryDetail ? 'current' : ''}`}
                                >
                                    <Link onClick={() => handleServiceClick(categoryDetail)} aria-current="page">
                                        {categoryDetail.service}
                                        <span className="icon-right-arrow1 ms-3">
                                            <i className="fa-solid fa-arrow-right-long"></i>
                                        </span>
                                    </Link>
                                </li> */}

                                {category?.map((categories, index) => <>
                                    <li class="vc_tta-tab" id={`menu-item-${categories}`}
                                        className={`menu-item menu-item-type-post_type menu-item-object-page ${selectedCategory === categories ? 'current' : ''}`}
                                        key={index}>
                                        <Link onClick={() => handleCategoryClick(categories)} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/home-care/${categories.name}/${categories.id}`} data-vc-tabs="" data-vc-container=".vc_tta">
                                            <span class="vc_tta-title-text">{categories.name}</span>
                                        </Link>
                                    </li >
                                </>)}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section >


        <section >
            <div class="container mt-3">

                <div className="row">
                    <div className="col-md-12 text-center">
                        <button className=' mb-3 btn-hover color-1' onClick={handleViewOrders}>View ORders</button>

                    </div>
                    <div className="col-md-4">
                        <div class="tracking__btn-box me-5">
                            <div class="elementor-element elementor-element-99fddf2 elementor-widget elementor-widget-insur-sidebar" data-id="99fddf2" data-element_type="widget" data-widget_type="insur-sidebar.default">
                                <div class="elementor-widget-container">
                                    <div class="make-a-claim__claim-box">
                                        <div class="menu-insur-details-sidebar-container justify-content-center text-center ">
                                            {/* <h3></h3> */}
                                            <h6>{categoryName}</h6>
                                            <ul id="menu-insur-details-sidebar" class="overflow-auto services-list make-a-claim__claim-list list-unstyled ml-0">
                                                {selectedCategory ? <>
                                                    {categoryDetails.map((categoryDetail, index) => <>
                                                        <li key={{ index }}
                                                            id={`menu-item-${categoryDetail}`}
                                                            className={`text-start menu-item menu-item-type-post_type menu-item-object-page ${selectedService === categoryDetail ? 'current' : ''}`}
                                                        >
                                                            <Link onClick={() => handleServiceClick(categoryDetail)} aria-current="page">
                                                                {categoryDetail.service}
                                                                <span className="icon-right-arrow1 ms-3">
                                                                    <i className="fa-solid fa-arrow-right-long"></i>
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    </>)}

                                                </> : <> {categoryDetails.map((categoryDetail, index) => <>
                                                    <li key={{ index }}
                                                        id={`menu-item-${categoryDetail}`}
                                                        className={`text-start menu-item menu-item-type-post_type menu-item-object-page ${selectedService === categoryDetail ? 'current' : ''}`}
                                                    >
                                                        <Link onClick={() => handleServiceClick(categoryDetail)} aria-current="page">
                                                            {categoryDetail.service}
                                                            <span className="icon-right-arrow1 ms-3">
                                                                <i className="fa-solid fa-arrow-right-long"></i>
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </>)}</>}

                                            </ul></div>    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 img-service-care">

                        {selectedService ? <>
                            <div class="alert alert-info title-service" role="alert">
                                <span className='fw-bold'>  Service Name : </span> {selectedService.service} <div></div>
                            </div>

                            {showAlert && <div class="alert alert-info alert-service" role="alert">
                                {selectedService.service}

                                <i class="fa fa-times cursor-pointer" onClick={closeAlert} aria-hidden="true"></i>
                            </div>

                            }
                            <img src="/images/home-care/insurance-details-img-1.jpg" alt="" srcSet="" />
                            <div class="insurance-details__age-box">
                                <div class="insurance-details__age-title-box">
                                    <h3 class="insurance-details__age-title">
                                        Care Level 1 :</h3>
                                </div>
                                <ul class="insurance-details__age-list list-unstyled">
                                    <li>
                                        <p>Price: <span>{selectedService.price} LE</span></p>
                                    </li>
                                    <li>
                                        <p>Add To Order:

                                            <button onClick={() => handleOrderClick(selectedService)} class="order-btn ms-2">Add Order</button>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p className="insurance-details__text-2">
                                    {selectedService.service}
                                </p>
                                <p>{selectedService.details}</p>
                            </div>
                        </> : <>
                            {showAlert && <div class="alert alert-info alert-service" role="alert">
                                {categoryDetails[0]?.service}
                                <i class="fa fa-times" onClick={closeAlert} aria-hidden="true"></i>
                            </div>

                            }
                            <img src="/images/home-care/insurance-details-img-1.jpg" alt="" srcSet="" />
                            <div class="insurance-details__age-box">
                                <div class="insurance-details__age-title-box">
                                    <h3 class="insurance-details__age-title">
                                        Care Level 1 :</h3>
                                </div>
                                <ul class="insurance-details__age-list list-unstyled">
                                    <li>
                                        <p>Price: <span> {categoryDetails[0]?.price}LE</span></p>
                                    </li>
                                    <li>
                                        <p>Add To Order:

                                            <button onClick={() => handleOrderClick(categoryDetails)} class="order-btn ms-2">Add Order</button>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p className="insurance-details__text-2">
                                    {categoryDetails[0]?.service}
                                </p>
                                <p>{categoryDetails[0]?.details}</p>
                            </div></>}




                        <Modal show={showModal} size='lg' onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />
                                <Modal.Title>Ordered Services</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='px-4 order-modal '>
                                <div className='row justify-content-between my-2 border-bottom'>
                                    <span className='col-md-10 mb-2 fw-bold'>Service Name</span>
                                    <span className='col-md-2 mb-2 fw-bold '>Remove
                                    </span>
                                </div>
                                <div className='row overflow-auto' style={{ height: '250px' }}>
                                    {orderedServices.map((service, index) => (
                                        <div key={index} className='row justify-content-between my-2 border-bottom '>
                                            <span className='col-md-10 mb-2 '>{service.service}</span>
                                            <button className='btn-trash col-md-2 mb-2 ' onClick={() => handleRemoveService(service.id)}><i class="fa fa-trash" aria-hidden="true"></i>
                                                <span class="etalon-tooltip">Delete<span class="trialngle-tooltip"></span></span>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='close-order' variant="secondary" onClick={() => setShowModal(false)}>
                                    Close
                                </Button>
                                <Button className='close-order' variant="secondary" onClick={registration}>
                                    Make Order
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </section >
    </>
}
