import React, { useContext } from 'react'
import { LocalizationContext } from '../../../Context/LocalizationContext'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function CopyWrite() {
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    return <>
        <p>{t('copyWrite-mayoClinic')}  <Link class="post-btn" to="https://www.mayoclinic.org/about-this-site/terms-use-licensed-content">{t('Terms of Use')}
            {i18n.language === 'en' ? <i class="mx-2 fas fa-long-arrow-alt-right"></i> : <i class="mx-2 fas fa-long-arrow-alt-left"></i>}
        </Link></p>
    </>
}
