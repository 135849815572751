import React from 'react'

export default function OverViewPageDetails({ fileName }) {
    return <>

        <div class="widget">

            <img src={`https://my-health.sghcairo.net/mayo/public/mayo/img/${fileName}`} class="img-fluid w-100" alt='' />

        </div>

    </>
}
