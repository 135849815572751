// import logo from './logo.svg';
// import { BrowserRouter, createBrowserRouter, Navigate, Route, RouterProvider, Routes } from 'react-router-dom';
import {
  createBrowserRouter, RouterProvider, BrowserRouter as Router, Route, Redirect, Switch,
  Routes,
  Navigate,
  useParams,
  useLocation,
  useNavigate,
  HashRouter,

} from 'react-router-dom';

import './App.css';
import './index.css';

import Doctors from './Components/Doctors/Doctors';
import Layout from './Components/Layout/Layout';
import OurServices from './Components/OurServices/OurServices';
import { LocalizationContext } from './Context/LocalizationContext';
import { useContext, useState } from 'react';

import Home from './Components/Home/Home';
import About from './Components/About/About';
import PatientPortalVisits from './Components/PatientPortal/PatientPortalVisits';
import PatientPortalHome from './Components/PatientPortal/PatientPortalHome';
import PatientPortalLab from './Components/PatientPortal/PatientPortalLab';
import NewAppointments from './Components/PatientPortal/NewAppointments';
import MyAppointments from './Components/PatientPortal/MyAppointments';
import FindDr from './Components/PatientPortal/FindDr';
import PatientPortalRadiology from './Components/PatientPortal/PatientPortalRadiology';
import Medication from './Components/PatientPortal/Medication';
import AlexEventDetails from './Components/Branches/Alexandria/FacilitiesEvents/FacilitiesEventsDetails'
import CairoEventDetails from './Components/Branches/Cairo/FacilitiesEvents/FacilitiesEvenrtsDetails'
import CairoNewsDetails from './Components/Branches/Cairo/News/NewsDetails'
import AlexNewsDetails from './Components/Branches/Alexandria/News/NewsDetails'
import News from './Components/News/News'
import NewsDetails from './Components/News/NewsDetails'
import { useEffect } from 'react';
// import AppointmentBooking from './Components/PatientPortal/AppointmentCalendar';
// import AppointmentCalendar from './Components/PatientPortal/AppointmentCalendar';
// import RegistrationForm from './Components/PatientPortal/RegestrationForm';
import PatientPortalLogin from './Components/PatientPortal/PatientPortalLogin';
// import MayoClinic from './Components/MayoClinic/MayoClinic';
// import MayoSearch from './Components/MayoClinic/MayoSearch';
import MayoSlideshow from './Components/MayoClinic/MayoSlideshow';
import MayoMain from './Components/MayoClinic/MayoMain';
import MayoVideos from './Components/MayoClinic/MayoVideos';
import BMICalculator from './Components/HelathHub/HealthTools/BMICalculator';
import HeartRiskCalculator from './Components/HelathHub/HealthTools/HeartRiskCalculator';
import StrokeRiskCalculator from './Components/HelathHub/HealthTools/StrokeRiskCalculator';
import MayoArticle from './Components/MayoClinic/MayoArticle';
import MayoSymptom from './Components/MayoClinic/MayoSymptom';
import MayoRecipe from './Components/MayoClinic/MayoRecipe';
import MayoTestProcedure from './Components/MayoClinic/MayoTestProcedure';
import MayoFirstAid from './Components/MayoClinic/MayoFirstAid'
import MayoDisease from './Components/MayoClinic/MayoDisease'
import MayoDiseaseDetails from './Components/MayoClinic/MayoDiseaseDetails';
import MayoVideosDetails from './Components/MayoClinic/MayoVideosDetails';
import MayoArticleDetails from './Components/MayoClinic/MayoArticleDetails';
import MayoSymptomDetails from './Components/MayoClinic/MayoSymptomDetails';
import FirstAidDetails from './Components/MayoClinic/FirstAidDetails';
import TestProcedureDetails from './Components/MayoClinic/TestProcedureDetails';
import SlideShowDetails from './Components/MayoClinic/SlideShowDetails';
import RecipeDetails from './Components/MayoClinic/RecipeDetails';
import AnsowerDetails from './Components/MayoClinic/AnsowerDetails';
import Vision from './Components/About/Vision';
import Facts from './Components/About/Facts';
import Reports from './Components/About/Reports';
import Awards from './Components/About/Awards ';
import Contact from './Components/Contact/Contact';
import BookingResult from './Components/BookingResult/BookingResult';
import FAQ from './Components/FAQ/FAQ';
import PatientRights from './Components/PatientRights/PatientRights';
import Policy from './Components/Policy/Policy';
import TermsAndConditions from './Components/TermsAndConditions/TermsAndConditions';
import BeOurPartner from './Components/BeOurPartner/BeOurPartner';
import ReferAPatient from './Components/ReferAPatient/ReferAPatient';
import JoinUs from './Components/JoinUs/JoinUs';
import OurPartners from './Components/About/OurPartners/OurPartners';
import SuccessStories from './Components/About/SuccessStories';
import Cairo from './Components/Branches/Cairo/Main/Main';
import Alex from './Components/Branches/Alexandria/Main/Main';
import CairoServices from './Components/Branches/Cairo/OurServices/OurServices';
import CairoServicesDetails from './Components/Branches/Cairo/OurServices/ServicesDetails';
import CairoPhysiotherapy from './Components/Branches/Cairo/OurServices/PhysiotherapyDepartment';
import CairoRadiology from './Components/Branches/Cairo/OurServices/Radiology';
import CairoDoctor from './Components/Branches/Cairo/Doctors/Doctors';
import CairoIVP from './Components/Branches/Cairo/IVP/IVP';
import CairoIVPDetails from './Components/Branches/Cairo/IVP/IvpDetails';
import AlexIVPDetails from './Components/Branches/Alexandria/IVP/IvpDetails';
import AlexDoctor from './Components/Branches/Alexandria/Doctors/Doctors';
import AlexIVP from './Components/Branches/Alexandria/IVP/IVP';
import AlexServices from './Components/Branches/Alexandria/OurServices/OurServices';
import AlexServicesDetails from './Components/Branches/Alexandria/OurServices/ServicesDetails';
import AlexPhysiotherapy from './Components/Branches/Alexandria/OurServices/PhysiotherapyDepartment';
import AlexRadiology from './Components/Branches/Alexandria/OurServices/Radiology';
import MediaCenter from './Components/MediaCenter/MediaCenter';
import CommingSoon from './Components/CommingSoon/CommingSoon';
import MetaTag from './Components/MetaTag/MetaTag';
import CairoSpecialists from './Components/Branches/Cairo/Specialitsts/Specialists';
import AlexSpecialists from './Components/Branches/Alexandria/Specialitsts/Specialists';
import CairoSpecialityDetails from './Components/Branches/Cairo/Specialitsts/SpecialityDetails';
import CairoCardiology from './Components/Branches/Cairo/Specialitsts/Cardiology';
import CairoChest from './Components/Branches/Cairo/Specialitsts/Chest';
import AlexSpecialityDetails from './Components/Branches/Alexandria/Specialitsts/SpecialityDetails';
import AlexCardiology from './Components/Branches/Alexandria/Specialitsts/Cardiology';
import AlexChest from './Components/Branches/Alexandria/Specialitsts/Chest';

import LaboratoryAndBloodBank from './Components/OurServices/LaboratoryAndBloodBank';
import PhysiotherapyDepartment from './Components/OurServices/PhysiotherapyDepartment';
import Radiology from './Components/OurServices/Radiology';
import GSC from './Components/About/OurPartners/GSC';
// import Temos from './Components/About/OurPartners/Temos';
import AAST from './Components/About/OurPartners/AAST';
import ACHS from './Components/About/OurPartners/ACHS';
import IHF from './Components/About/OurPartners/IHF';
import MayoClinic from './Components/About/OurPartners/MayoClinic';
import Guc from './Components/About/OurPartners/Guc';
import AUC from './Components/About/OurPartners/AUC';
import ISO from './Components/About/OurPartners/ISO';
import CairoDoctorDetails from './Components/Branches/Cairo/Doctors/DoctorDetails';
import CairoNews from './Components/Branches/Cairo/News/News';
import AlexNews from './Components/Branches/Alexandria/News/News';
import AlexDoctorDetails from './Components/Branches/Alexandria/Doctors/DoctorDetails';
import RequestAppointment from './Components/PatientsAndVisitors/RequestAppointment';
import ScrollToTopContextProvider, { ScrollToTopContext } from './Context/ScrollToTop/ScrollToTop';
import DoctorList from './Components/DoctorList/DoctorList';
import Cookies from 'js-cookie';
// import { AuthenticationContext } from './Context/Authentication/PatientPortalPinInp';
import jwtDecode from "jwt-decode";

import PatientsStories from './Components/PatientsAndVisitors/PatientsStories';
import FacilitiesEvents from './Components/Branches/Cairo/FacilitiesEvents/FacilitiesEvents';
import AlexFacilitiesEvents from './Components/Branches/Alexandria/FacilitiesEvents/FacilitiesEvents'
import MediaCenterPhotos from './Components/MediaCenter/MediaCenterPhotos';
import MediaCenterVideos from './Components/MediaCenter/MediaCenterVideos';
import axios from 'axios';
import Loader from './Components/Loader/Loader';
import Portal from './Components/PatientPortal/Portal';
import OpenDay from './Components/Forms/OpenDay';
import MedicalEducation from './Components/HelathHub/MedicalEducation/MedicalEducation';
import MedicalEducationServices from './Components/HelathHub/MedicalEducation/MedicalEducationServices';
import MedicalEducationFeatures from './Components/HelathHub/MedicalEducation/MedicalEducationFeatures';
import ScientificDay from './Components/Forms/ScientificDay';
import AHA from './Components/Forms/AHA';
import BatarjiAwards from './Components/BatarjiAwards/BatarjiAwards';
import SatisfactionSurvey from './Components/Forms/SatisfactionSurvey';
import ShuttleBus from './Components/Branches/Alexandria/ShuttleBus/ShuttleBus';
import AlexComplain from './Components/Forms/AlexComplain';
import CairoComplain from './Components/Forms/CairoComplain';
import NursesNomination from './Components/Forms/NursesNomination';
import RoundTableRegistration from './Components/Forms/AfricaHealthExCon2024/RoundTableRegistration';
import SessionRegistration from './Components/Forms/AfricaHealthExCon2024/SessionRegistration';
import PatientoutcomeMeasuresSurvey from './Components/Forms/PatientoutcomeMeasuresSurvey';
import SummerTrainingDentistryDepartment from './Components/Forms/SummerTrainingDentistryDepartment';
import ORServicesSatisfactionSurvey from './Components/Forms/ORServicesSatisfactionSurvey';
import EmergencydepartmentSatisfactionSurvey from './Components/Forms/EmergencydepartmentSatisfactionSurvey';
import ICUServiceSatisfactionSurvey from './Components/Forms/ICUServiceSatisfactionSurvey';
import LaboratoryServiceSurvey from './Components/Forms/LaboratoryServiceSurvey';
import OPDQuestionnaire from './Components/Forms/OPDQuestionnaire';
import MedicationManagementServiceSurvey from './Components/Forms/MedicationManagementServiceSurvey';
import RadiologyServicesSatisfactionSurvey from './Components/Forms/RadiologyServicesSatisfactionSurvey';
import CardioDiabetesDay from './Components/Forms/CardioDiabetesDay';
import InsuranceCompanies from './Components/InsuranceCompanies/InsuranceCompanies';
import HomeCareServices from './Components/HomeCare/HomeCareServices';
import ServicesDetails from './Components/HomeCare/ServicesDetails';
import Registration from './Components/HomeCare/Registration';
import BreastfeedingEvent from './Components/Forms/BreastfeedingEvent';
import SeasonalFluVaccineQuestionnaire from './Components/Forms/SeasonalFluVaccineQuestionnaire ';
import PulmoNephroDay from './Components/Forms/PulmoNephroDay';
import StaffEvaluationQuestionnaire from './Components/Forms/StaffEvaluationQuestionnaire';

// import { useTranslation } from 'react-i18next';
// import RedirectToDefaultLanguage from './Components/RedirectToDefaultLanguage/RedirectToDefaultLanguage';

// import { LocalizationContext } from '../../Context/LocalizationContext';

function App() {
  let lang = useParams();
  let { i18n } = useContext(LocalizationContext);
  const bootstrap = document.getElementById('bootstrap-direction');
  const base = document.getElementById('base-direction');
  const animate = document.getElementById('animate-direction');
  const fontawesome = document.getElementById('fontawesome-direction');
  const themify = document.getElementById('themify-direction');
  const magnific = document.getElementById('magnific-direction');
  const carousel = document.getElementById('carousel-direction');
  const picker = document.getElementById('picker-direction');
  const spacing = document.getElementById('spacing-direction');
  const shortcodes = document.getElementById('shortcodes-direction');
  const style = document.getElementById('style-direction');
  const responsive = document.getElementById('responsive-direction');
  const customize = document.getElementById('customize-direction');
  const pathname = window.location.pathname;

  useEffect(() => {

    if (i18n.language === 'en' || `${lang}` === 'en' || Cookies.get('i18next') === 'en' || pathname.includes('/en/')) {

      bootstrap.href = '/sgh/css/bootstrap.min.css';
      animate.href = '/sgh/css/animate.css';
      fontawesome.href = '/sgh/css/fontawesome-all.css';
      themify.href = '/sgh/css/themify-icons.css';
      magnific.href = '/sgh/css/magnific-popup/magnific-popup.css';
      carousel.href = '/sgh/css/owl-carousel/owl.carousel.css';
      picker.href = '/sgh/css/date-picker/date-picker.css';
      spacing.href = '/sgh/css/spacing.css';
      base.href = '/sgh/css/base.css';
      shortcodes.href = '/sgh/css/shortcodes.css';
      style.href = '/sgh/css/style.css';
      responsive.href = '/sgh/css/responsive.css';
      customize.href = '/sgh/css/color-customize/color-customizer.css';

    } else {

      bootstrap.href = '/sgh/rtl/css/bootstrap.min.css';
      animate.href = '/sgh/rtl/css/animate.css';
      fontawesome.href = '/sgh/rtl/css/fontawesome-all.css';
      themify.href = '/sgh/rtl/css/themify-icons.css';
      magnific.href = '/sgh/rtl/css/magnific-popup/magnific-popup.css';
      carousel.href = '/sgh/rtl/css/owl-carousel/owl.carousel.css';
      picker.href = '/sgh/rtl/css/date-picker/date-picker.css';
      spacing.href = '/sgh/rtl/css/spacing.css';
      base.href = '/sgh/rtl/css/base.css';
      shortcodes.href = '/sgh/rtl/css/shortcodes.css';
      style.href = '/sgh/rtl/css/style.css';
      responsive.href = '/sgh/rtl/css/responsive.css';
      // customize.href = '/rtl/css/color-customize/color-customizer.css';

    }

    // 👇️ scroll to top on page load
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  // loading screen when apis failed or till api come
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        setLoading(true);
        document.getElementsByTagName('html')[0].style.overflow = 'auto';
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        return config;

      },
      (error) => {
        setLoading(false);
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        document.getElementsByTagName('html')[0].style.overflow = 'auto';
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setLoading(false);
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        document.getElementsByTagName('html')[0].style.overflow = 'auto';
        return response;
      },
      (error) => {
        setLoading(false);
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        document.getElementsByTagName('html')[0].style.overflow = 'auto';
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, []);

  // call apis when failer 3 times
  // const axiosRetry = () => {
  //   let retryCount = 0;

  //   const interceptor = axios.interceptors.response.use(
  //     (response) => response,
  //     (error) => {
  //       if (error.config && retryCount < 3 && error.response) {
  //         retryCount++;
  //         console.log(`Retrying request... (${retryCount}/3)`);
  //         return axios.request(error.config);
  //       }
  //       return Promise.reject(error);
  //     }
  //   );

  //   return {
  //     eject: () => {
  //       axios.interceptors.response.eject(interceptor);
  //     },
  //   };
  // };

  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const interceptor = axiosRetry();

  //   const requestInterceptor = axios.interceptors.request.use(
  //     (config) => {
  //       setLoading(true);
  //       document.getElementsByTagName('html')[0].style.overflow = 'auto';
  //       document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  //       return config;
  //     },
  //     (error) => {
  //       setLoading(false);
  //       document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  //       document.getElementsByTagName('html')[0].style.overflow = 'auto';

  //       return Promise.reject(error);
  //     }
  //   );

  //   const responseInterceptor = axios.interceptors.response.use(
  //     (response) => {
  //       setLoading(false);
  //       document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  //       document.getElementsByTagName('html')[0].style.overflow = 'auto';

  //       return response;
  //     },
  //     (error) => {
  //       setLoading(false);
  //       document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  //       document.getElementsByTagName('html')[0].style.overflow = 'auto';

  //       return Promise.reject(error);
  //     }
  //   );

  //   // Clean up the interceptors when the component unmounts
  //   return () => {
  //     axios.interceptors.request.eject(requestInterceptor);
  //     axios.interceptors.response.eject(responseInterceptor);
  //     interceptor.eject();
  //   };


  // }, []);


  // overflow hidden when modal open
  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      const modalBackdrop = document.querySelector('.modal-backdrop.show');
      const htmlElement = document.querySelector('html');

      if (modalBackdrop) {
        htmlElement.style.overflow = 'hidden';
      } else {
        htmlElement.style.overflow = 'auto';
      }
    });

    observer.observe(document.body, { attributes: true, childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);
  return <>
    {/* <RouterProvider router={router} >

    </RouterProvider> */}
    {loading && <Loader />}

    <HashRouter basename="/sgh">
      <Routes>
        <Route
          path="/:lang/"
          element={<Layout />}>

          <Route index element={<Home />} />
          <Route path={`services`} element={<OurServices />} />
          <Route path={`services/laboratory-and-blood-bank`} element={<LaboratoryAndBloodBank />} />
          <Route path={`services/physiotherapy-department`} element={<PhysiotherapyDepartment />} />
          <Route path={`services/radiology`} element={<Radiology />} />
          <Route path={`about`} element={<About />} />
          <Route path={`about/vision`} element={<Vision />} />
          <Route path={`about/facts`} element={<Facts />} />
          <Route path={`about/board-of-directiion`} element={<CommingSoon />} />
          <Route path={`about/leadership-team`} element={<CommingSoon />} />
          <Route path={`about/reports`} element={<Reports />} />
          <Route path={`about/awards`} element={<Awards />} />
          <Route path={`about/success-stories`} element={<SuccessStories />} />
          <Route path={`contact`} element={<Contact />} />
          <Route path={`faq`} element={<FAQ />} />
          <Route path={`doctors`} element={<Doctors />} />
          <Route path={`patient-and-visitors/health-innsurance-partners`} element={<CommingSoon />} />
          <Route path={`patient-and-visitors/request-an-appointment`} element={<RequestAppointment />} />
          <Route path={`patient-and-visitors/request-medical-record`} element={<CommingSoon />} />
          <Route path={`patient-and-visitors/patients-satisfaction-survey`} element={<CommingSoon />} />
          <Route path={`patient-and-visitors/policies/patient-rights`} element={<PatientRights />} />
          <Route path={`patient-and-visitors/policies/non-smoking-policy`} element={<CommingSoon />} />
          <Route path={`patient-and-visitors/patient-and-visitors/patients-journey`} element={<CommingSoon />} />
          <Route path={`patient-and-visitors/patients-journey/out-patient-journey`} element={<CommingSoon />} />
          <Route path={`patient-and-visitors/patients-journey/in-patient-journey`} element={<CommingSoon />} />
          <Route path={`patient-and-visitors/patients-journey/Patients-receiving-services`} element={<CommingSoon />} />
          <Route path={`patient-and-visitors/patients-stories`} element={<PatientsStories />} />
          <Route path={`loyalty-program`} element={<CommingSoon />} />
          <Route path={`international-patients`} element={<CommingSoon />} />
          <Route path={`policy`} element={<Policy />} />
          <Route path={`terms-conditions`} element={<TermsAndConditions />} />
          <Route path={`be-our-partner`} element={<BeOurPartner />} />
          <Route path={`refer-patient`} element={<ReferAPatient />} />
          <Route path={`join-us`} element={<JoinUs />} />
          <Route path={`about/our-partners`} element={<OurPartners />} />
          <Route path={`about/our-partners/gsc`} element={<GSC />} />
          <Route path={`about/our-partners/aast`} element={<AAST />} />
          <Route path={`about/our-partners/australian-council-on-health-care-standards`} element={<ACHS />} />
          <Route path={`about/our-partners/international-hospital-federation`} element={<IHF />} />
          <Route path={`about/our-partners/american-university-in-cairo`} element={<AUC />} />
          <Route path={`about/our-partners/guc`} element={<Guc />} />
          <Route path={`about/our-partners/mayo-clinic`} element={<MayoClinic />} />
          <Route path={`about/our-partners/iso`} element={<ISO />} />
          <Route path={`media-center`} element={<MediaCenter />} />
          <Route path={`media-center/gallery/:media_name/:media_id`} element={<MediaCenterPhotos />} />
          <Route path={`media-center/videos/:media_name/:media_id`} element={<MediaCenterVideos />} />
          <Route path={`news`} element={<News />} />
          <Route path={`news/:news_name/:news_id`} element={<NewsDetails />} />

          <Route path={`cairo`} element={<Cairo />} />
          <Route path={`cairo/ivp`} element={<CairoIVP />} />
          <Route path={`cairo/ivp/:doc_id`} element={<CairoIVPDetails />} />
          <Route path={`cairo/services`} element={<CairoServices />} />
          <Route path={`cairo/services/:service_name/:service_id`} element={<CairoServicesDetails />} />
          <Route path={`cairo/services/physiotherapy-department`} element={<CairoPhysiotherapy />} />
          <Route path={`cairo/services/radiology`} element={<CairoRadiology />} />
          <Route path={`cairo/doctors`} element={<CairoDoctor />} />
          <Route path={`cairo/doctors/:doc_id`} element={<CairoDoctorDetails />} />
          <Route path={`cairo/news`} element={<CairoNews />} />
          <Route path={`cairo/news/news-details`} element={<CairoNewsDetails />} />
          <Route path={`cairo/facilities-events`} element={<FacilitiesEvents />} />
          <Route path={`cairo/event-details/:event_name/:event_id`} element={<CairoEventDetails />} />
          <Route path={`cairo/specialties`} element={<CairoSpecialists />} />
          <Route path={`cairo/specialties/:speciality_name/:speciality_id`} element={<CairoSpecialityDetails />} />
          <Route path={`cairo/specialties/cardiology`} element={<CairoCardiology />} />
          <Route path={`cairo/specialties/chest-and-pulmonology`} element={<CairoChest />} />
          <Route path={`cairo/complain`} element={<CairoComplain />} />

          <Route path={`alex/specialties/:speciality_name/:speciality_id`} element={<AlexSpecialityDetails />} />
          <Route path={`alex/specialties/cardiology`} element={<AlexCardiology />} />
          <Route path={`alex/specialties/chest-and-pulmonology`} element={<AlexChest />} />
          <Route path={`alex`} element={<Alex />} />
          <Route path={`alex/ivp`} element={<AlexIVP />} />
          <Route path={`alex/ivp/:doc_id`} element={<AlexIVPDetails />} />
          <Route path={`alex/services`} element={<AlexServices />} />
          <Route path={`alex/services/:service_name/:service_id`} element={<AlexServicesDetails />} />
          <Route path={`alex/services/physiotherapy-department`} element={<AlexPhysiotherapy />} />
          <Route path={`alex/services/radiology`} element={<AlexRadiology />} />
          <Route path={`alex/doctors`} element={<AlexDoctor />} />
          <Route path={`alex/doctors/:doc_id`} element={<AlexDoctorDetails />} />
          <Route path={`alex/facilities-events`} element={<AlexFacilitiesEvents />} />
          <Route path={`alex/news`} element={<AlexNews />} />
          <Route path={`alex/news/news-details`} element={<AlexNewsDetails />} />
          <Route path={`alex/specialties`} element={<AlexSpecialists />} />
          <Route path={`alex/event-details/:event_name/:event_id`} element={<AlexEventDetails />} />
          <Route path={`alex/shuttle-bus`} element={<ShuttleBus />} />
          <Route path={`alex/complain`} element={<AlexComplain />} />
          <Route path={`booking/doctors`} element={<BookingResult />} />
          <Route path={`patient-portal/patient_portal_login`} element={<CommingSoon />} />
          <Route path={`portal`} element={<Portal />} />
          <Route path={`patient-portal`} element={<PatientPortalVisits />} />
          <Route path={`patient-portal/patient_portal_home`} element={<CommingSoon />} />
          <Route path={`patient-portal/patient_portal_lab`} element={<CommingSoon />} />
          <Route path={`patient-portal/patient_portal_radiology`} element={<CommingSoon />} />
          <Route path={`patient-portal/new_appointments`} element={<CommingSoon />} />
          <Route path={`patient-portal/my_appointments`} element={<CommingSoon />} />
          <Route path={`patient-portal/find_dr`} element={<CommingSoon />} />
          <Route path={`patient-portal/medication`} element={<CommingSoon />} />
          <Route path={`MCLibrary`} element={<MayoMain />} />
          <Route path={`MCLibrary/Slideshow`} element={<MayoSlideshow />} />
          <Route path={`MCLibrary/Video`} element={<MayoVideos />} />
          <Route path={`MCLibrary/Symptom`} element={<MayoSymptom />} />
          <Route path={`MCLibrary/Disease`} element={<MayoDisease />} />
          <Route path={`MCLibrary/Article`} element={<MayoArticle />} />
          <Route path={`MCLibrary/FirstAid`} element={<MayoFirstAid />} />
          <Route path={`MCLibrary/Recipe`} element={<MayoRecipe />} />
          <Route path={`MCLibrary/TestProcedure`} element={<MayoTestProcedure />} />
          <Route path={`MCLibrary/:contentType/:id`} element={<MayoVideosDetails />} />
          <Route path={`MCLibrary/Disease/:id`} element={<MayoDiseaseDetails />} />
          <Route path={`MCLibrary/Article/:id`} element={<MayoArticleDetails />} />
          <Route path={`MCLibrary/Symptom/:id`} element={<MayoSymptomDetails />} />
          <Route path={`MCLibrary/FirstAid/:id`} element={<FirstAidDetails />} />
          <Route path={`MCLibrary/TestProcedure/:id`} element={<TestProcedureDetails />} />
          <Route path={`MCLibrary/Slideshow/:id`} element={<SlideShowDetails />} />
          <Route path={`MCLibrary/Recipe/:id`} element={<RecipeDetails />} />
          <Route path={`MCLibrary/Answer/:id`} element={<AnsowerDetails />} />
          <Route path={`health-hub/health-tools/bmi`} element={<BMICalculator />} />
          <Route path={`health-hub/health-tools/heart-risk-calculator`} element={<HeartRiskCalculator />} />
          <Route path={`health-hub/health-tools/stroke-risk-calculator`} element={<StrokeRiskCalculator />} />
          <Route path={`health-hub/medical-education-unit`} element={<MedicalEducation />} />
          <Route path={`health-hub/medical-education-unit/american-heart-association-course`} element={<AHA />} />
          <Route path={`health-hub/medical-education-unit/services`} element={<MedicalEducationFeatures />} />
          <Route path={`sgh-awards`} element={<BatarjiAwards />} />
          <Route path={`doctor-list`} element={<DoctorList />} />
          <Route path={`open-day`} element={<OpenDay />} />
          <Route path={`scientific-day`} element={<ScientificDay />} />
          <Route path={`satisfaction-survey`} element={<SatisfactionSurvey />} />
          <Route path={`excon2024-round-table`} element={<RoundTableRegistration />} />
          <Route path={`excon2024-session-registration`} element={<SessionRegistration />} />
          {/* stop link for dr ghada */}
          {/* <Route path={`dentistry-department-training`} element={<SummerTrainingDentistryDepartment />} /> */}
          <Route path={`or-Services-Satisfaction-Survey`} element={<ORServicesSatisfactionSurvey />} />
          <Route path={`emergency-department-satisfaction-survey`} element={<EmergencydepartmentSatisfactionSurvey />} />
          <Route path={`iCU-service-satisfaction-survey`} element={<ICUServiceSatisfactionSurvey />} />
          <Route path={`laboratory-service-survey`} element={<LaboratoryServiceSurvey />} />
          <Route path={`opd-questionnaire`} element={<OPDQuestionnaire />} />
          <Route path={`medication-management-service-survey`} element={<MedicationManagementServiceSurvey />} />
          <Route path={`radiology-services-satisfaction-survey`} element={<RadiologyServicesSatisfactionSurvey />} />
          {/* stop link for dr ghada */}
          {/* <Route path={`cardio-diabetes-day`} element={<CardioDiabetesDay />} /> */}
          <Route path={`seasonal-fluvaccine-questionnaire`} element={<SeasonalFluVaccineQuestionnaire />} />
          <Route path={`pulmoNephro-day`} element={<PulmoNephroDay />} />
          <Route path={`staff-evaluation-questionnaire`} element={<StaffEvaluationQuestionnaire />} />

          <Route path={`insurance-companies`} element={<InsuranceCompanies />} />
          <Route path={`breastfeeding-event`} element={<BreastfeedingEvent />} />
          <Route path={`home-care`} element={<HomeCareServices />} />
          <Route path={`home-care/:category_name/:category_id`} element={<ServicesDetails />} />
          <Route path={`home-care/registration`} element={<Registration />} />



        </Route>
        <Route path={`/:lang/nurse-nomination`} element={<NursesNomination />} />
        <Route path={`/:lang/patient-survey`} element={<PatientoutcomeMeasuresSurvey />} />

        <Route path={`/:lang/health-hub/medical-education-unit/features`} element={<MedicalEducationServices />} />

      </Routes>

    </HashRouter>

    {/* <Router>

      <Routes>
        <Route exact path={`/:lang`} element={<Layout />} >
          <Route exact index element={<Home />} />
          <Route exact path={`/:lang/about`} element={<About />} />
      
        </Route>
      </Routes>
    </Router> */}
    {/* {i18n.language === 'en' ? <RouterProvider router={router_en} /> : <RouterProvider router={router_ar} />} */}

    {/* <BrowserRouter >
      <Routes>
        <Route path={i18n.language === 'en' ? '/' : '/:lang'} element={<Layout />} >
          <Route index element={<Home />} />
          <Route path="/:lang/about" element={<About />} />
          <Route path={i18n.language === 'en' ? "doctors" : "/ar/doctors"} element={<Doctors />} />
          <Route path={i18n.language === 'en' ? "services" : "ar/services"} element={<OurServices />} />
        </Route>
      </Routes>
    </BrowserRouter> */}

  </>

}

export default App;
