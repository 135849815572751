import React, { useContext } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { LocalizationContext } from '../../Context/LocalizationContext';
// import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
const PatientPortalChangePasswordModale = ({ show, onHide, changePasswordError, setChangePasswordError, handleChangePasswordSubmit, oldPasswordInp, setOldPasswordInp, newPasswordInp, setNewPasswordInp, confirmationPasswordInp, setConfirmationPasswordInp, togglePasswordVisibility, showPassword, showConfimedPassword, showNewPassword, showOldPassword, toggleConfirmedPasswordVisibility, toggleNewPasswordVisibility, toggleOldPasswordVisibility }) => {
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    const { t } = useTranslation("Header");

    return (
        <Modal show={show} onHide={onHide} id="patientportalChangePasswordModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />
                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {changePasswordError && <div className="portal-error text-white my-2">{t('ERROR!')} {changePasswordError}</div>}
                <Form className='row justify-content-center align-items-center flex-column' onSubmit={handleChangePasswordSubmit}>
                    <Form.Group className="col-md-8 position-relative" controlId="ControlText">
                        <Form.Control
                            type={showOldPassword ? 'text' : 'password'}
                            minLength={8}
                            maxLength={16}
                            name="code"
                            value={oldPasswordInp}
                            onChange={(e) => setOldPasswordInp(e.target.value)}
                            placeholder={t('Old Password')}
                            style={{ paddingRight: '2.5rem' }} // Adjust the padding to accommodate the eye icon
                        />
                        <div
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '20px',
                                cursor: 'pointer',
                            }}
                            onClick={toggleOldPasswordVisibility}
                        >
                            {showOldPassword ? '👁️' : '🔒'} {/* Eye and lock emoji icons */}
                        </div>
                    </Form.Group>
                    <Form.Group className="col-md-8 position-relative" controlId="ControlText">
                        <Form.Control type={showNewPassword ? 'text' : 'password'} minLength={8} maxLength={16} name="code" value={newPasswordInp} onChange={(e) => setNewPasswordInp(e.target.value)} placeholder={t('New Password')} style={{ paddingRight: '2.5rem' }} />
                        <div
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '20px',
                                cursor: 'pointer',
                            }}
                            onClick={toggleNewPasswordVisibility}
                        >
                            {showNewPassword ? '👁️' : '🔒'} {/* Eye and lock emoji icons */}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-5 col-md-8 position-relative " controlId="ControlText">
                        <Form.Control type={showConfimedPassword ? 'text' : 'password'} minLength={8} maxLength={16} name="code" value={confirmationPasswordInp} onChange={(e) => setConfirmationPasswordInp(e.target.value)} placeholder={t('Confirm Password')} style={{ paddingRight: '2.5rem' }} />
                        <div
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '20px',
                                cursor: 'pointer',
                            }}
                            onClick={toggleConfirmedPasswordVisibility}
                        >
                            {showConfimedPassword ? '👁️' : '🔒'} {/* Eye and lock emoji icons */}
                        </div>
                    </Form.Group>
                    <div className='me-3 d-flex justify-content-center'>
                        <Button className='col-md-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3' type="submit">
                            {t('Confirm')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <small className='text-theme' >
                    {t('The password must be at least 8 characters long.,At least one digit (0-9).,At least one uppercase letter (A-Z) and At least one special character of the following [$, !, #, %, *, (, ), _, -, or @].')}</small>
            </Modal.Footer>
        </Modal>
    );
};

export default PatientPortalChangePasswordModale;