import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
export default function AlexComplain(props) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);


    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    const validateForm = () => {
        const errors = [];
        const phoneNumberRegex = /^[\+]?[0-9]{0,3}\W?[()0-9]{0,3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

        if (name.trim() === '') {
            errors.push('Full Name is required.');
        }

        if (!email) {
            errors.push('Your Email is required.');
        }
        else if (!email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
            errors.push('Please enter a valid email address. Expected format: example@example.com.');
        }
        if (!phone) {
            errors.push('Your phone number is required.');
        }
        else if (!phoneNumberRegex.test(phone)) {
            errors.push('Please enter a valid phone number. Expected format: [+][Country Code][Area Code][Phone Number].');
        }

        if (message.trim() === '') {
            errors.push('Message is required.');
        }

        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append('full name', name);
            bodyFormData.append('email', email);
            bodyFormData.append('phone', phone);
            bodyFormData.append('message', message);

            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/alex-Complain`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Thank you for bringing your concerns to our attention!');
                        setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');
                        setName('');
                        setEmail('');
                        setPhone('');
                        setMessage('');
                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>
        <PageTitle title='Patient' anotherTitle='complaint' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/complain`} currentPage='Patient complaint' />
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">

                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-7 p-5">
                                            <div className='row justify-content-center'>
                                                <div class="section-title mb-5">
                                                    <h2 class="title mb-3 text-center">complete <span>Form</span></h2>

                                                    <h6>Your feedback is invaluable to us please complete our patient complaint form.</h6>

                                                </div>

                                                <div className="form-group col-md-12">
                                                    <input id="form_name" type="text" name="name" className={`form-control ${errors.includes('Full Name is required.') ? 'is-invalid' : ''}`} placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} data-error="Full Name is required." />

                                                    {errors.includes('Full Name is required.') && (
                                                        <div className="invalid-feedback">Full Name is required.</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input type="email" name="email" className={`form-control ${errors.includes('Your Email is required.') ? 'is-invalid' : ''} ${errors.includes('Please enter a valid email address. Expected format: example@example.com') ? 'is-invalid' : ''}`} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} data-error="Please enter a valid email address. Expected format: example@example.com" />
                                                    {errors.includes('Your Email is required.') && (
                                                        <div className="invalid-feedback">Your Email is required.</div>
                                                    )}
                                                    {errors.includes('Please enter a valid email address. Expected format: example@example.com') && (
                                                        <div className="invalid-feedback">Please enter a valid email address. Expected format: example@example.com</div>
                                                    )}

                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input type="text" name="experience" className={`form-control ${errors.includes('Your phone number is required.') ? 'is-invalid' : ''} ${errors.includes('Please enter a valid phone number. Expected format: [+][Country Code][Area Code][Phone Number].') ? 'is-invalid' : ''}`} placeholder="Phone Number" value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                    {errors.includes('Your phone number is required.') && (
                                                        <div className="invalid-feedback">Your phone number is required.</div>
                                                    )}
                                                    {errors.includes('Please enter a valid phone number. Expected format: [+][Country Code][Area Code][Phone Number].') && (
                                                        <div className="invalid-feedback">Please enter a valid phone number. Expected format: [+][Country Code][Area Code][Phone Number].</div>
                                                    )}
                                                </div>


                                                <div className="form-group col-md-12">
                                                    <textarea id="form_address" type="text" name="message" className={`form-control ${errors.includes('Message is required.') ? 'is-invalid' : ''}`} placeholder="Your Message" value={message} onChange={(e) => setMessage(e.target.value)} >
                                                    </textarea>
                                                    {errors.includes('Message is required.') && (
                                                        <div className="invalid-feedback">Message is required.</div>
                                                    )}
                                                </div>


                                                <div className="col-md-6">
                                                    <button className="btn btn-border btn-circle w-100" type='submit'><span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5" style={{ backgroundImage: `url('/images/forms/complain_result.webp')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            {/* <img src="images/bg/04.webp" alt="open day" /> */}
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            <p className='lead'>{messageForm}</p>
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
