import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function AHA(props) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [courseName, setCourseName] = useState('');
    const [position, setPosition] = useState('');
    const [errors, setErrors] = useState([]);


    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [message, setMessage] = useState('');
    const [agenda, setAgenda] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();

        bodyFormData.append('full name', name);
        bodyFormData.append('email', email);
        bodyFormData.append('phone', phone);
        bodyFormData.append('position', position);
        bodyFormData.append('course Name', courseName);

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/american-heart-association-course`, bodyFormData)
            .then(response => {

                if (response.data.Ecode === 200) {
                    console.log(response.data);

                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Registration is Successful!')
                        setMessage("Thank you for registration we will contact you soon")

                    } else {
                        setSubmitMsg('Error !! please Check Your Data ')

                    }

                    handleShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>


        <PageTitle title='American heart association' anotherTitle='courses' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/medical-education-unit/american-heart-association-course`} currentPage='AHA COURSE' />
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">

                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-7 p-5">
                                            <div className='row'>
                                                <div class="section-title mb-5">
                                                    <h2 class="title mb-3 text-center">Registration <span>Form</span></h2>

                                                    <h6>American heart association life support courses
                                                        SGH International training centre</h6>

                                                </div>

                                                <div className="form-group col-md-6">
                                                    <input id="form_name" type="text" name="name" className="form-control" placeholder="Full Name" required="required" value={name} onChange={(e) => setName(e.target.value)} data-error="Full Name is required." />
                                                    {errors.includes('Name is required') && <p>Name is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required="required" data-error="Valid email is required." />
                                                    {errors.includes('Email is required') && <p>Email is required</p>}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input type="number" name="experience" className="form-control" placeholder="Phone Number" value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        required="required" />
                                                    {errors.includes('Phone Number is required') && <p>Phone Number is required</p>}


                                                </div>


                                                <div className="form-group col-md-6">
                                                    <input id="form_Company" type="text" name="position" className="form-control" value={position} onChange={(e) => setPosition(e.target.value)} placeholder="Position" data-error="Position is required." required="required" />
                                                    {errors.includes('Position is required') && <p>Position is required</p>}


                                                </div>

                                                <div className="form-group col-md-6">
                                                    <input id="form_address" type="text" name="courseName" className="form-control" placeholder="Course Name" required="required" value={courseName} onChange={(e) => setCourseName(e.target.value)} data-error="courseName is required." />
                                                    {errors.includes('Course Name is required') && <p> Course Name is required</p>}

                                                </div>


                                                <div className="col-md-4">
                                                    <button className="btn btn-border btn-circle w-100" type='submit' disabled={errors.length > 0}><span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5" style={{ backgroundImage: `url('/images/forms/american-heart-association-image.webp')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            {/* <img src="images/bg/04.webp" alt="open day" /> */}
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            <p className='lead'>{message}</p>
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
