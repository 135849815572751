import Cookies from 'js-cookie'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom'
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function MayoSearch(props) {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    return <>

        {/* <!--body content start--> */}

        {/* <!--blog start--> */}

        <div className="container">

            <div className="row">
                {props.searchData.map((dataSearch, index) => (<>
                    <div className="col-lg-9 col-md-12 order-lg-1 blog-items" key={index}>
                        {/* {dataSearch.filter((dataFilter)=>dataFilter.contentType)} */}

                        {/* <h3>{dataSearch.contentType}</h3> */}

                        <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/${dataSearch.contentType}/${dataSearch.docId}/`} onClick={scrollToTop}>

                            <div className="" style={{ marginBottom: "20px", paddingBottom: "20px" }}>
                                <div className="post shadow">
                                    <div className="post-desc" style={{ padding: " 15px 30px" }}>
                                        <div className="post-title row justify-content-between featured-step p-0 shadow-none">
                                            <h5 className='col-md-10'>{dataSearch.title}</h5>
                                            <span className='position-static fs-5 d-none d-md-block col-md-2'>{dataSearch.contentType}</span>

                                        </div>
                                        <p className='text-black' dangerouslySetInnerHTML={{ __html: dataSearch.abstract }}></p>
                                        <Link className="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/${dataSearch.contentType}/${dataSearch.docId}/`} onClick={scrollToTop}>{t('Read More')}
                                            {i18n.language === 'en' ? <i className="ms-2 fas fa-long-arrow-alt-right"></i> : <i className="ms-2 fas fa-long-arrow-alt-left"></i>}

                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>
                </>))}
            </div>
        </div>

        {/* <!--blog end--> */}





        {/* <!--body content end--> */}

    </>
}
