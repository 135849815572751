import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom'
import PageTitle from '../Utilites/PageTitle';
import Select from 'react-select';
import { GetDepartmentsOptionsContext } from '../../Context/GetDepartmentsOptions';
import axios from 'axios';
import { GetDoctorsOptionsContext } from '../../Context/GetDoctorsOptions';


export default function DoctorList() {
    const { pathname } = useLocation();
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    let { GetDoctorsSelectOptions, DoctorsOptions, selectedOption, setSelectedOption } = useContext(GetDoctorsOptionsContext);
    const [doctorSelectedOption, setDoctorSelectedOption] = useState('');
    const brancheOptions = [
        { value: '1', label: 'Cairo' },
        { value: '2', label: 'Alexandria' },
    ]

    const defaultValue = { value: 'Cairo', label: 'Cairo' };
    const [loading, setLoading] = useState(true);
    const [searchData, setSearchData] = useState([]);
    const [searchInp, setSearchInp] = useState('');
    const [ageInp, setAgeInp] = useState('');
    const [specialistTitleInp, setSpecialistTitleInp] = useState(false);
    const [registrarTitleInp, setRegistrarTitleInp] = useState(false);
    const [consultantTitleInp, setConsultantTitleInp] = useState(false);
    const [maleGenderInp, setMaleGenderInp] = useState(false);
    const [femaleGenderInp, setFemaleGenderInp] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showAvailableSlotsModal, setShowAvailableSlotsModal] = useState(false);
    const [showNextModal, setShowNextModal] = useState(false);
    const [showConfirmedModal, setShowConfirmedModal] = useState(false);
    const [showForgetPinModal, setShowForgetPinModal] = useState(false);
    const [showFirstTimeModal, setShowFirstTimeModal] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);


    const handleOpenAvailableSlotsModal = () => {
        setShowAvailableSlotsModal(true);
    };
    const handleOpenModal = () => {
        setShowModal(true);
        setShowAvailableSlotsModal(false)
    };
    const handleOpenNextModal = () => {
        setShowNextModal(true);
        setShowModal(false);

    };
    const handleOpenConfirmedModal = () => {
        setShowConfirmedModal(true);
        setShowNextModal(false);
        setShowForgetPinModal(false);
        setShowFirstTimeModal(false);
        setShowOTPModal(false);

    };
    const handleOpenForgetPinModal = () => {
        setShowForgetPinModal(true);
        setShowNextModal(false);
        // setShowConfirmedModal(false);

    };
    const handleOpenFirstTimeModal = () => {
        setShowFirstTimeModal(true);
        setShowNextModal(false);
        // setShowConfirmedModal(false);

    };
    const handleOpenOTPModal = () => {
        setShowOTPModal(true);
        // setShowNextModal(false);
        setShowFirstTimeModal(false);

    };

    const handleCloseAvailableSlotsModal = () => {
        setShowAvailableSlotsModal(false);
        document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseModal = () => {
        setShowModal(false);
        document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseNextModal = () => {
        setShowNextModal(false);
        document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseConfirmedModal = () => {
        setShowConfirmedModal(false);
        document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseForgetPinModal = () => {
        setShowForgetPinModal(false);
        document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseFirstTimeModal = () => {
        setShowFirstTimeModal(false);
        document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseOTPModal = () => {
        setShowOTPModal(false);
        document.querySelector('.modale').classList.add('d-none');

    };
    const handleSelectChange = (selectedOption, doctorSelectedOption) => {

        setSelectedOption(selectedOption);
        setDoctorSelectedOption(doctorSelectedOption);



    };
    const handleDoctorSelectChange = doctorSelectedOption => {


        setDoctorSelectedOption(doctorSelectedOption);


    };
    function preventDefault(e) {
        e.preventDefault();
    }

    useEffect(() => {
        GetDepartmentsSelectOptions()
    }, [])
    async function doctorSearch(e) {
        // e.preventDefault();
        if (searchInp || selectedOption || specialistTitleInp || registrarTitleInp || consultantTitleInp || maleGenderInp || femaleGenderInp || ageInp || doctorSelectedOption) {
            let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/en?search_hospital=1&search_name=${searchInp}&search_title[]=${specialistTitleInp ? 'Specialist' : ''}&search_title[]=${registrarTitleInp ? 'Registrar' : ''}&search_title[]=${consultantTitleInp ? 'Consultant' : ''}&gender[]=${maleGenderInp ? '2' : ''}&gender[]=${femaleGenderInp ? '1' : ''}&search_department=${selectedOption.value}&doc_id=${doctorSelectedOption.value}`);
            setSearchData(data.Edata);
            setLoading(false);

        }

    }
    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Doctors' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/booking/doctors`} currentPage='Doctors' />
        {/* <!--page title end--> */}

        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='pt-4'>

                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ms-auto">
                            <div className="pb-3 ">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10 p-0 col-md-12 teams-container">

                                        <div className="d-sm-flex mb-3 teams justify-content-center">
                                            <div className="col-lg-5 col-md-12 sidebar mt-7 mt-lg-0" >



                                                <div className="widget" >

                                                    <div className="widget-searchbox" >
                                                        <Select placeholder="Select Branch" options={brancheOptions} className="w-100 px-2" styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                                outline: state.isFocused ? ' none' : 'none',

                                                            }),
                                                            option: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                                                backgroundColor: state.isFocused ? '#409639' : ' ',
                                                                color: state.isFocused ? '#fff' : '',

                                                            }),

                                                        }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                                        </Select>

                                                    </div >

                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 sidebar mt-7 mt-lg-0" >

                                                <div className="widget" >

                                                    <div className="widget-searchbox" >
                                                        <Select placeholder="Select Department" options={DepartmentsOptions} className="w-100 px-2" styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                                outline: state.isFocused ? ' none' : 'none',
                                                            }),
                                                            option: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                                                backgroundColor: state.isFocused ? '#409639' : ' ',
                                                                color: state.isFocused ? '#fff' : '',

                                                            }),
                                                        }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                                        </Select>

                                                    </div >

                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-12 sidebar mt-7 mt-lg-0" >

                                                <div className="widget" >

                                                    <div className="widget-searchbox" >
                                                        <button className="btn btn-theme bt-sm  py-1 px-2  rounded-bottom rounded-top" onClick={doctorSearch}><span><i className='ti-search'></i></span>
                                                        </button>

                                                    </div >

                                                </div>

                                            </div >
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end m-0">
                                    <div className="col-xl-2 pe-0">

                                        <select className='w-100'>
                                            <option>Name</option>
                                            <option>Speciality</option>
                                            <option>Latest</option>
                                            <option>Age</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-2 ">

                                        <select className='w-100'>
                                            <option>Ascending</option>
                                            <option>Descending</option>
                                        </select>

                                    </div>




                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="row">
                        <div className="col-lg-12 col-md-12 order-lg-1">
                            {/* <!--team start--> */}

                            <section className="grey-bg p-0">
                                <div className="container">

                                    <div className="row">
                                        {/* <div className="section-title pb-0 mb-0">
                                <h6 className='mb-4'><span>Cardiology</span></h6>
                            </div> */}
                                        <div className="col-lg-12 p-0 col-md-12 teams-container">

                                            <div className="d-sm-flex mb-3 teams">
                                                <div className="flex-shrink-0  me-5 pe-4">
                                                    <img className="h-100" alt="image" src="/images/blog/blog-thumb/01.jpg" />
                                                </div>
                                                <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                                    <div className="comment-date mb-2 "> <span className="date me-2">Cardiology</span>
                                                        <a className='fav-icon'>  <i className='far fa-solid fa-heart border-left'></i></a>

                                                    </div>

                                                    <h5 className="text-black font-w-4 m-0">John Glemean</h5>
                                                    <p className='text-muted m-0 small'>Consultant</p>
                                                    <p className=' m-0'>  <i className="fas fa-map-marker-alt text-muted small"></i> Cairo - <a href='https://goo.gl/maps/hAbQWB8g7SAhJPNJ7' className=''>Get Directions</a></p>
                                                  
                                                    <p className='text-muted fw-bolder small m-0'>Languages : <span className="date">Arabic , Hendi , English</span></p>


                                                    <div className=" profile me-3"> <a href="#" className=" me-2 btn btn-sm btn-border ">View Full Profile</a> </div>
                                                    <div className="replay me-3"> <a href="#" className="me-2 btn btn-sm btn-theme btn-border btn-radius">Make Appointment</a> </div>

                                                </div>
                                            </div>


                                        </div>


                                    </div >
                                </div >
                            </section >

                            {/* <!--team end--> */}
                        </div >

                    </div>
                    <div class="row justify-content-center text-center">
                        <div class="col-sm-12">
                            <nav aria-label="Page navigation">
                                <ul class="pagination">
                                    <li class="page-item"><a class="page-link" href="#"><i class="fas fa-arrow-left"></i></a>
                                    </li>
                                    <li class="page-item active"><a class="page-link" href="#">1</a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#">2</a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#">3</a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#"><i class="fas fa-arrow-right"></i></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div >
            </section >

            {/* <!--blog end--> */}

        </div >


        {/* <!--body content end-->  */}
        {/* available slots */}

        {/* slots Modal */}
        <Modal className='modale' centered size="lg" scrollable show={showAvailableSlotsModal} onHide={handleCloseAvailableSlotsModal}>
            <Modal.Header closeButton>
                <Modal.Title>Available Slots</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="row calender-box text-center g-3">
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal}>
                                <div className="date px-1"><h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div>
                                <div className="appointment-box theme-bg"><h6>Book Now</h6></div>
                            </div >
                        </div >

                    </div >


                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }
                            } onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                            </div >
                        </div >
                    </div >

                </div >


            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseAvailableSlotsModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >

        {/* slots Modal */}
        < Modal className='modale' centered show={showModal} onHide={handleCloseModal} >
            <Modal.Header closeButton>
                <Modal.Title>Available Time</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="row calender-box text-center g-3">
                    <div className="col-md-4">

                        <div className="inner-box" style={{ border: '1px solid #409639' }} onClick={handleOpenNextModal}>
                            <div className="date px-1"><h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div>
                            <div className="appointment-box theme-bg"><h6>Book Now</h6></div>
                        </div >

                    </div >

                    <div className="col-md-4">
                        <div className="inner-box" style={{ border: '1px solid #409639' }} onClick={handleOpenNextModal}>
                            <div className="date px-1"><h4> <span>8:15 am </span>to <span>8:30 am</span></h4></div>
                            <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                        </div >
                    </div >
                    <div className="col-md-4">
                        <div className="inner-box" style={{ border: '1px solid #409639' }} onClick={handleOpenNextModal}>
                            <div className="date px-1"><h4> <span>8:30 am </span>to <span>8:45 am</span></h4></div>
                            <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                        </div >
                    </div >
                    <div className="col-md-4">
                        <div className="inner-box" style={{ border: '1px solid #409639' }} onClick={handleOpenNextModal}>
                            <div className="date px-1"><h4> <span>8:45 am </span>to <span>9:00 am</span></h4></div>
                            <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                        </div >
                    </div >
                    <div className="col-md-4">
                        <div className="inner-box" style={{ border: '1px solid #409639' }} onClick={handleOpenNextModal}>
                            <div className="date px-1"><h4> <span>9:00 am </span>to <span>9:15 am</span></h4></div>
                            <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                        </div >
                    </div >
                    <div className="col-md-4">
                        <div className="inner-box" style={{ border: '1px solid #409639' }} onClick={handleOpenNextModal}>
                            <div className="date px-1"><h4> <span>9:15 am </span>to <span>9:30 am</span></h4></div>
                            <div className="appointment-box theme-bg" > <h6>Book Now</h6></div >
                        </div >
                    </div >


                </div >


            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
        {/* < !--body content end-- >  */}
        {/* Nex Modal*/}
        <Modal className='modale' centered show={showNextModal} onHide={handleCloseNextModal}>
            <Modal.Header closeButton>
                <Modal.Title>                    Please Enter Your Medical Pin Number
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row">

                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={preventDefault} method="get" action="">
                        <div className="messages"></div>

                        <div className="form-group col-sm-6">
                            <input id="form_id1" type="text" name="id" className="form-control" placeholder="Patient ID"
                                required="required" data-error="Valid Patient ID is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group col-sm-6 mt-xs-3">
                            <input id="form_id2" type="text" name="id" className="form-control" placeholder="Phone Number"
                                required="required" data-error="Valid Phone Number is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="col-sm-12 mt-1  mb-3">
                            <span className='small'><Link onClick={handleOpenForgetPinModal}>Forget My Pin Number </Link></span>
                        </div>
                        <div className="col-sm-4 mt-xs-3">
                            <button className="btn btn-theme btn-radius" onClick={handleOpenConfirmedModal}><span>Confirmed</span>
                            </button>
                        </div>

                        <div className="col-sm-8 mt-xs-3">
                            <button className="btn btn-theme btn-radius w-100" onClick={handleOpenFirstTimeModal}><span>This Is My First Time</span>
                            </button>
                        </div>
                    </form>

                </div>

            </Modal.Body>
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseNextModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >

        {/* confirmed Modal */}
        < Modal className='modale' centered show={showConfirmedModal} onHide={handleCloseConfirmedModal} >
            <Modal.Header closeButton>
                <Modal.Title>                    Your Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    <div className="team-description text-center">
                        <Link to='' className='h3'>Your booking has been confirmed successfully</Link>
                        <p className='mt-5 '> If you need to change or cancel your booking, please call 16259.</p>

                    </div>

                </div>

            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseConfirmedModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >

        {/* forget Pin MODAL*/}
        < Modal className='modale' centered show={showForgetPinModal} onHide={handleCloseForgetPinModal} >
            <Modal.Header closeButton>
                <Modal.Title>                   Forget Your Pin
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">

                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={preventDefault} method="get" action="">
                        <div className="messages"></div>

                        <div className="form-group col-sm-6">
                            <input id="form_id1" type="text" name="id" className="form-control" placeholder="Phone Number"
                                required="required" data-error="Valid Phone Number is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group col-sm-6">
                            <input id="form_id2" type="text" name="id" className="form-control" placeholder="National ID"
                                required="required" data-error="Valid National ID is required." />
                            <div className="help-block with-errors"></div>
                        </div>

                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" onClick={handleOpenConfirmedModal} ><span>Confirmed</span>
                            </button>
                        </div>


                    </form>
                </div>

            </Modal.Body >
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseForgetPinModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >

        {/* my first time MODAL*/}
        < Modal className='modale' centered show={showFirstTimeModal} onHide={handleCloseFirstTimeModal} >
            <Modal.Header closeButton>
                <Modal.Title>   Enter Your Data
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">

                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={preventDefault} method="get" action="">
                        <div className="messages"></div>
                        <div className="form-group col-sm-6">
                            <input id="form_id1" type="text" name="name" className="form-control" placeholder="Full Name"
                                required="required" data-error="Valid Full Name is required." />
                            <div className="help-block with-errors"></div>

                        </div>
                        <div className="form-group col-sm-6">
                            <select className="form-select form-control" required="required" data-error="Valid Gender is required.">
                                <option value="- Select Gender">- Select Gender</option>
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                            </select>

                            <div className="help-block with-errors"></div>

                        </div>
                        <div className="form-group col-sm-6">
                            <input id="form_number1" type="text" name="id" className="form-control" placeholder="Phone Number"
                                required="required" data-error="Valid Phone Number is required." />
                            <div className="help-block with-errors"></div>
                        </div>

                        <div className="form-group col-sm-6">
                            <input type="date" className="form-control" placeholder="Date" id="datepicker2" />
                        </div>
                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" onClick={handleOpenOTPModal} ><span>Register</span>
                            </button>
                        </div>


                    </form>
                </div >

            </Modal.Body >
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseFirstTimeModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >
        {/* OTP MODAL */}

        < Modal className='modale' centered show={showOTPModal} onHide={handleCloseOTPModal} >
            <Modal.Header closeButton>
                <Modal.Title>   Enter Your OTP
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">

                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={preventDefault} method="get" action="">
                        <div className="messages"></div>


                        <div className="form-group col-sm-6">
                            <input id="form_number1" type="text" name="id" className="form-control" placeholder="Code"
                                required="required" data-error="Valid Code is required." />
                            <div className="help-block with-errors"></div>
                        </div>


                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" onClick={handleOpenConfirmedModal} ><span>Confirmed</span>
                            </button>
                        </div>


                    </form>
                </div>

            </Modal.Body >
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseOTPModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >
    </>
}
