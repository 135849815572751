import Cookies from 'js-cookie';
import React, { useEffect, useState, useContext } from 'react'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { LocalizationContext } from '../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';

export default function PatientoutcomeMeasuresSurvey(props) {
    const { pathname } = useLocation();

    const [ques1, setQues1] = useState('');
    const [ques2, setQues2] = useState('');
    const [ques3, setQues3] = useState('');
    const [ques4, setQues4] = useState('');
    const [ques5, setQues5] = useState('');
    const [ques6, setQues6] = useState('');
    const [ques7, setQues7] = useState('');
    const [ques8, setQues8] = useState('');
    const [ques9, setQues9] = useState('');
    const [ques10, setQues10] = useState('');
    const [ques11, setQues11] = useState('');
    const [ques12, setQues12] = useState('');
    const [ques13, setQues13] = useState('');
    const [ques14, setQues14] = useState('');
    const [ques15, setQues15] = useState('');
    const [ques16, setQues16] = useState('');
    const [ques17, setQues17] = useState('');
    const [ques18, setQues18] = useState('');
    const [ques19, setQues19] = useState('');
    const [ques20, setQues20] = useState('');
    const [ques21, setQues21] = useState('');
    const [ques22, setQues22] = useState('');
    const [ques23, setQues23] = useState('');
    const [ques24, setQues24] = useState('');
    const [ques25, setQues25] = useState('');
    const [ques26, setQues26] = useState('');
    const [ques27, setQues27] = useState('');
    const [ques28, setQues28] = useState('');
    const [ques29, setQues29] = useState('');
    const [ques30, setQues30] = useState('');
    const [ques31, setQues31] = useState('');
    const [ques32, setQues32] = useState('');
    const [ques33, setQues33] = useState('');
    const [ques34, setQues34] = useState('');
    const [ques35, setQues35] = useState('');
    const [ques36, setQues36] = useState('');

    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    let currentStepQuestions = [];
    const validateForm = () => {
        const errors = []

        if (!ques1) {
            errors.push('This Field has been required.');
        }

        if (!ques2) {
            errors.push('This Field has been required.');
        }
        if (!ques3) {
            errors.push('This Field has been required.');
        }
        if (!ques4) {
            errors.push('This Field has been required.');
        }

        if (!ques5) {
            errors.push('This Field has been required.');
        }

        if (!ques6) {
            errors.push('This Field has been required.');
        }

        if (!ques7) {
            errors.push('This Field has been required.');
        }
        if (!ques8) {
            errors.push('This Field has been required.');
        }
        if (!ques9) {
            errors.push('This Field has been required.');
        }
        if (!ques10) {
            errors.push('This Field has been required.');
        }
        if (!ques11) {
            errors.push('This Field has been required.');
        }
        if (!ques12) {
            errors.push('This Field has been required.');
        }
        if (!ques13) {
            errors.push('This Field has been required.');
        }
        if (!ques14) {
            errors.push('This Field has been required.');
        }
        if (!ques15) {
            errors.push('This Field has been required.');
        }
        if (!ques16) {
            errors.push('This Field has been required.');
        }
        if (!ques17) {
            errors.push('This Field has been required.');
        }
        if (!ques18) {
            errors.push('This Field has been required.');
        }
        if (!ques19) {
            errors.push('This Field has been required.');
        }
        if (!ques20) {
            errors.push('This Field has been required.');
        }
        if (!ques21) {
            errors.push('This Field has been required.');
        } if (!ques21) {
            errors.push('This Field has been required.');
        } if (!ques22) {
            errors.push('This Field has been required.');
        } if (!ques23) {
            errors.push('This Field has been required.');
        } if (!ques24) {
            errors.push('This Field has been required.');
        } if (!ques25) {
            errors.push('This Field has been required.');
        } if (!ques26) {
            errors.push('This Field has been required.');
        } if (!ques20) {
            errors.push('This Field has been required.');
        } if (!ques27) {
            errors.push('This Field has been required.');
        } if (!ques28) {
            errors.push('This Field has been required.');
        }
        if (!ques29) {
            errors.push('This Field has been required.');
        }
        if (!ques30) {
            errors.push('This Field has been required.');
        }
        if (!ques31) {
            errors.push('This Field has been required.');
        }
        if (!ques32) {
            errors.push('This Field has been required.');
        } if (!ques33) {
            errors.push('This Field has been required.');
        } if (!ques34) {
            errors.push('This Field has been required.');
        } if (!ques35) {
            errors.push('This Field has been required.');
        } if (!ques36) {
            errors.push('This Field has been required.');
        }
        setErrors(errors);
        return errors;
    }


    async function submitForm(e) {
        e.preventDefault();

        // const formErrors = handleNext();

        if (errors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append(t('ques-1'), ques1);
            bodyFormData.append(t('ques-2'), ques2);
            bodyFormData.append(t('ques-3'), ques3);
            bodyFormData.append(t('ques-4'), ques4);
            bodyFormData.append(t('ques-5'), ques5);
            bodyFormData.append(t('ques-6'), ques6);
            bodyFormData.append(t('ques-7'), ques7);
            bodyFormData.append(t('ques-8'), ques8);
            bodyFormData.append(t('ques-9'), ques9);
            bodyFormData.append(t('ques-10'), ques10);
            bodyFormData.append(t('ques-11'), ques11);
            bodyFormData.append(t('ques-12'), ques12);
            bodyFormData.append(t('ques-13'), ques13);
            bodyFormData.append(t('ques-14'), ques14);
            bodyFormData.append(t('ques-15'), ques15);
            bodyFormData.append(t('ques-16'), ques16);
            bodyFormData.append(t('ques-17'), ques17);
            bodyFormData.append(t('ques-18'), ques18);
            bodyFormData.append(t('ques-19'), ques19);
            bodyFormData.append(t('ques-20'), ques20);
            bodyFormData.append(t('ques-21'), ques21);
            bodyFormData.append(t('ques-22'), ques22);
            bodyFormData.append(t('ques-23'), ques23);
            bodyFormData.append(t('ques-24'), ques24);
            bodyFormData.append(t('ques-25'), ques25);
            bodyFormData.append(t('ques-26'), ques26);
            bodyFormData.append(t('ques-27'), ques27);
            bodyFormData.append(t('ques-28'), ques28);
            bodyFormData.append(t('ques-29'), ques29);
            bodyFormData.append(t('ques-30'), ques30);
            bodyFormData.append(t('ques-31'), ques31);
            bodyFormData.append(t('ques-32'), ques32);
            bodyFormData.append(t('ques-33'), ques33);
            bodyFormData.append(t('ques-34'), ques34);
            bodyFormData.append(t('ques-35'), ques35);
            bodyFormData.append(t('ques-36'), ques36);


            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/patient-outcomes-measures-survey`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg(t('submit msg'));
                        setErrors('')
                        // setMessageForm('Thank you for submitting the Nurse Nanomotion form. ');
                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };


    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();
        }

    }, [submitMsg])
    const navigate = useNavigate();
    const { t } = useTranslation("PatientOutcomeMeasuresSurvey");

    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    const changeLanguage = (lang, dir) => {
        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
        document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    }

    // form steps 

    const [step, setStep] = useState(0);

    const handleNext = () => {
        // // const formErrors = validateForm();
        const errors = []

        // if (!ques1) {
        //     errors.push('This Field has been required.');
        // }

        // if (!ques2) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques3) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques4) {
        //     errors.push('This Field has been required.');
        // }

        // if (!ques5) {
        //     errors.push('This Field has been required.');
        // }

        // if (!ques6) {
        //     errors.push('This Field has been required.');
        // }

        // if (!ques7) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques8) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques9) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques10) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques11) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques12) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques13) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques14) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques15) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques16) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques17) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques18) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques19) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques20) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques21) {
        //     errors.push('This Field has been required.');
        // } if (!ques21) {
        //     errors.push('This Field has been required.');
        // } if (!ques22) {
        //     errors.push('This Field has been required.');
        // } if (!ques23) {
        //     errors.push('This Field has been required.');
        // } if (!ques24) {
        //     errors.push('This Field has been required.');
        // } if (!ques25) {
        //     errors.push('This Field has been required.');
        // } if (!ques26) {
        //     errors.push('This Field has been required.');
        // } if (!ques20) {
        //     errors.push('This Field has been required.');
        // } if (!ques27) {
        //     errors.push('This Field has been required.');
        // } if (!ques28) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques29) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques30) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques31) {
        //     errors.push('This Field has been required.');
        // }
        // if (!ques32) {
        //     errors.push('This Field has been required.');
        // } if (!ques33) {
        //     errors.push('This Field has been required.');
        // } if (!ques34) {
        //     errors.push('This Field has been required.');
        // } if (!ques35) {
        //     errors.push('This Field has been required.');
        // } if (!ques36) {
        //     errors.push('This Field has been required.');
        // }
        // setErrors(errors);
        // if (errors.length === 0) {
        //     setErrors('');
        //     setStep((prevStep) => prevStep + 1);
        // }

        // return errors

        // Check if any question in the current step is unanswered
        const isAnyQuestionUnanswered = currentStepQuestions.some(question => !question);

        if (isAnyQuestionUnanswered) {
            // Display an error message or perform any necessary actions
            errors.push('This Field has been required.');
            console.log('Please answer all questions before proceeding.');
            setErrors(errors);
            return errors;
        }

        // Proceed to the next step if all questions are answered
        setErrors('');
        setStep(prevStep => prevStep + 1);
    };

    const handleBack = () => {
        setStep((prevStep) => prevStep - 1);
    };


    const renderStep = () => {
        switch (step) {
            case 0:
                currentStepQuestions = [ques1, ques2, ques3, ques4, ques5];
                return (<>
                    <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                        <div className="col-md-12">

                            <label> {t('ques-1')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>  <input required
                                type="radio" className='ms-3 mx-2' id={t('ans-1-1')}
                                value={t('ans-1-1')}
                                checked={ques1 === t('ans-1-1')}
                                onChange={(e) => setQues1(e.target.value)}
                            />
                                {t('ans-1-1')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>    <input required className='ms-3 mx-2' id={t('ans-1-2')}
                                type="radio"
                                value={t('ans-1-2')}
                                checked={ques1 === t('ans-1-2')}
                                onChange={(e) => setQues1(e.target.value)}
                            />
                                {t('ans-1-2')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>  <input required className='ms-3 mx-2' id={t('ans-1-3')}
                                type="radio"
                                value={t('ans-1-3')}
                                checked={ques1 === t('ans-1-3')}
                                onChange={(e) => setQues1(e.target.value)}
                            />
                                {t('ans-1-3')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>   <input required className='ms-3 mx-2' id={t('ans-1-4')}
                                type="radio"
                                value={t('ans-1-4')}
                                checked={ques1 === t('ans-1-4')}
                                onChange={(e) => setQues1(e.target.value)}
                            />
                                {t('ans-1-4')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>  <input required className='ms-3 mx-2' id={t('ans-1-5')}
                                type="radio"
                                value={t('ans-1-5')}
                                checked={ques1 === t('ans-1-5')}
                                onChange={(e) => setQues1(e.target.value)}
                            />
                                {t('ans-1-5')}</label>
                        </div>
                        {errors.includes('This Field has been required.') && (
                            <div className="ivaild-error">This Field has been required.</div>
                        )}
                    </div>

                    <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                        <div className="col-md-12">
                            <label > {t('ques-2')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>   <input required
                                type="radio" className='ms-3 mx-2' id={t('ans-2-1')}
                                value={t('ans-2-1')}
                                checked={ques2 === t('ans-2-1')}
                                onChange={(e) => setQues2(e.target.value)}
                            />
                                {t('ans-2-1')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>  <input required className='ms-3 mx-2' id={t('ans-2-2')}
                                type="radio"
                                value={t('ans-2-2')}
                                checked={ques2 === t('ans-2-2')}
                                onChange={(e) => setQues2(e.target.value)}
                            />
                                {t('ans-2-2')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>   <input required className='ms-3 mx-2' id={t('ans-2-3')}
                                type="radio"
                                value={t('ans-2-3')}
                                checked={ques2 === t('ans-2-3')}
                                onChange={(e) => setQues2(e.target.value)}
                            />
                                {t('ans-2-3')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>  <input required className='ms-3 mx-2' id={t('ans-2-4')}
                                type="radio"
                                value={t('ans-2-4')}
                                checked={ques2 === t('ans-2-4')}
                                onChange={(e) => setQues2(e.target.value)}
                            />
                                {t('ans-2-4')}</label>
                        </div>
                        <div className="col-md-12">

                            <label> <input required className='ms-3 mx-2' id={t('ans-2-5')}
                                type="radio"
                                value={t('ans-2-5')}
                                checked={ques2 === t('ans-2-5')}
                                onChange={(e) => setQues2(e.target.value)}
                            />
                                {t('ans-2-5')}</label>
                        </div>
                        {errors.includes('This Field has been required.') && (
                            <div className="ivaild-error">This Field has been required.</div>
                        )}
                    </div>
                    <div className="form-group col-md-12">

                        <h6 className='text-theme'><span>{t('ques-3-title')}</span></h6>
                    </div>
                    <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                        <div className="col-md-12">
                            <label> {t('ques-3')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>   <input required
                                type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                value={t('ans-3-1')}
                                checked={ques3 === t('ans-3-1')}
                                onChange={(e) => setQues3(e.target.value)}
                            />
                                {t('ans-3-1')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>   <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                type="radio"
                                value={t('ans-3-2')}
                                checked={ques3 === t('ans-3-2')}
                                onChange={(e) => setQues3(e.target.value)}
                            />
                                {t('ans-3-2')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>  <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                type="radio"
                                value={t('ans-3-3')}
                                checked={ques3 === t('ans-3-3')}
                                onChange={(e) => setQues3(e.target.value)}
                            />
                                {t('ans-3-3')}</label>
                        </div>


                        {errors.includes('This Field has been required.') && (
                            <div className="ivaild-error">This Field has been required.</div>
                        )}
                    </div>

                    <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                        <div className="col-md-12">
                            <label> {t('ques-4')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>  <input required
                                type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                value={t('ans-3-1')}
                                checked={ques4 === t('ans-3-1')}
                                onChange={(e) => setQues4(e.target.value)}
                            />
                                {t('ans-3-1')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>   <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                type="radio"
                                value={t('ans-3-2')}
                                checked={ques4 === t('ans-3-2')}
                                onChange={(e) => setQues4(e.target.value)}
                            />
                                {t('ans-3-2')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>    <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                type="radio"
                                value={t('ans-3-3')}
                                checked={ques4 === t('ans-3-3')}
                                onChange={(e) => setQues4(e.target.value)}
                            />
                                {t('ans-3-3')}</label>
                        </div>


                        {errors.includes('This Field has been required.') && (
                            <div className="ivaild-error">This Field has been required.</div>
                        )}
                    </div>
                    <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                        <div className="col-md-12">
                            <label> {t('ques-5')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>  <input required
                                type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                value={t('ans-3-1')}
                                checked={ques5 === t('ans-3-1')}
                                onChange={(e) => setQues5(e.target.value)}
                            />
                                {t('ans-3-1')}</label>
                        </div>
                        <div className="col-md-12">

                            <label>   <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                type="radio"
                                value={t('ans-3-2')}
                                checked={ques5 === t('ans-3-2')}
                                onChange={(e) => setQues5(e.target.value)}
                            />
                                {t('ans-3-2')}</label>
                        </div>
                        <div className="col-md-12">

                            <label> <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                type="radio"
                                value={t('ans-3-3')}
                                checked={ques5 === t('ans-3-3')}
                                onChange={(e) => setQues5(e.target.value)}
                            />
                                {t('ans-3-3')}</label>
                        </div>


                        {errors.includes('This Field has been required.') && (
                            <div className="ivaild-error">This Field has been required.</div>
                        )}
                    </div>
                </>);
            case 1:
                currentStepQuestions = [ques6, ques7, ques8, ques9, ques10, ques11, ques12];

                return (
                    <>
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-6')}</label>
                            </div>
                            <div className="col-md-12">

                                <label> <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                    value={t('ans-3-1')}
                                    checked={ques6 === t('ans-3-1')}
                                    onChange={(e) => setQues6(e.target.value)}
                                />
                                    {t('ans-3-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                    type="radio"
                                    value={t('ans-3-2')}
                                    checked={ques6 === t('ans-3-2')}
                                    onChange={(e) => setQues6(e.target.value)}
                                />
                                    {t('ans-3-2')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                    type="radio"
                                    value={t('ans-3-3')}
                                    checked={ques6 === t('ans-3-3')}
                                    onChange={(e) => setQues6(e.target.value)}
                                />
                                    {t('ans-3-3')}</label>
                            </div>


                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-7')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required
                                        type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                        value={t('ans-3-1')}
                                        checked={ques7 === t('ans-3-1')}
                                        onChange={(e) => setQues7(e.target.value)}
                                    />
                                    {t('ans-3-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                        type="radio"
                                        value={t('ans-3-2')}
                                        checked={ques7 === t('ans-3-2')}
                                        onChange={(e) => setQues7(e.target.value)}
                                    />
                                    {t('ans-3-2')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>    <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                    type="radio"
                                    value={t('ans-3-3')}
                                    checked={ques7 === t('ans-3-3')}
                                    onChange={(e) => setQues7(e.target.value)}
                                />
                                    {t('ans-3-3')}</label>
                            </div>


                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-8')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                    value={t('ans-3-1')}
                                    checked={ques8 === t('ans-3-1')}
                                    onChange={(e) => setQues8(e.target.value)}
                                />
                                    {t('ans-3-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>    <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                    type="radio"
                                    value={t('ans-3-2')}
                                    checked={ques8 === t('ans-3-2')}
                                    onChange={(e) => setQues8(e.target.value)}
                                />
                                    {t('ans-3-2')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                    type="radio"
                                    value={t('ans-3-3')}
                                    checked={ques8 === t('ans-3-3')}
                                    onChange={(e) => setQues8(e.target.value)}
                                />
                                    {t('ans-3-3')}</label>
                            </div>


                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-9')}</label>
                            </div>
                            <div className="col-md-12">

                                <label><input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                    value={t('ans-3-1')}
                                    checked={ques9 === t('ans-3-1')}
                                    onChange={(e) => setQues9(e.target.value)}
                                />
                                    {t('ans-3-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                    type="radio"
                                    value={t('ans-3-2')}
                                    checked={ques9 === t('ans-3-2')}
                                    onChange={(e) => setQues9(e.target.value)}
                                />
                                    {t('ans-3-2')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                    type="radio"
                                    value={t('ans-3-3')}
                                    checked={ques9 === t('ans-3-3')}
                                    onChange={(e) => setQues9(e.target.value)}
                                />
                                    {t('ans-3-3')}</label>
                            </div>


                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-10')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                    value={t('ans-3-1')}
                                    checked={ques10 === t('ans-3-1')}
                                    onChange={(e) => setQues10(e.target.value)}
                                />
                                    {t('ans-3-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                    type="radio"
                                    value={t('ans-3-2')}
                                    checked={ques10 === t('ans-3-2')}
                                    onChange={(e) => setQues10(e.target.value)}
                                />
                                    {t('ans-3-2')}</label>
                            </div>
                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                    type="radio"
                                    value={t('ans-3-3')}
                                    checked={ques10 === t('ans-3-3')}
                                    onChange={(e) => setQues10(e.target.value)}
                                />
                                    {t('ans-3-3')}</label>
                            </div>


                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-11')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                    value={t('ans-3-1')}
                                    checked={ques11 === t('ans-3-1')}
                                    onChange={(e) => setQues11(e.target.value)}
                                />
                                    {t('ans-3-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                    type="radio"
                                    value={t('ans-3-2')}
                                    checked={ques11 === t('ans-3-2')}
                                    onChange={(e) => setQues11(e.target.value)}
                                />
                                    {t('ans-3-2')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                    type="radio"
                                    value={t('ans-3-3')}
                                    checked={ques11 === t('ans-3-3')}
                                    onChange={(e) => setQues11(e.target.value)}
                                />
                                    {t('ans-3-3')}</label>
                            </div>


                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-12')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-3-1')}
                                    value={t('ans-3-1')}
                                    checked={ques12 === t('ans-3-1')}
                                    onChange={(e) => setQues12(e.target.value)}
                                />
                                    {t('ans-3-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-3-2')}
                                    type="radio"
                                    value={t('ans-3-2')}
                                    checked={ques12 === t('ans-3-2')}
                                    onChange={(e) => setQues12(e.target.value)}
                                />
                                    {t('ans-3-2')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-3-3')}
                                    type="radio"
                                    value={t('ans-3-3')}
                                    checked={ques12 === t('ans-3-3')}
                                    onChange={(e) => setQues12(e.target.value)}
                                />
                                    {t('ans-3-3')}</label>
                            </div>


                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>


                    </>
                );

            case 2:
                currentStepQuestions = [ques13, ques14, ques15, ques16, ques17, ques18, ques19];
                return (
                    <>
                        <div className="form-group col-md-12">

                            <h6 className='text-theme'><span>{t('ques-4-title')}</span></h6>
                        </div>
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-13')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-4-1')}
                                    value={t('ans-4-1')}
                                    checked={ques13 === t('ans-4-1')}
                                    onChange={(e) => setQues13(e.target.value)}
                                />
                                    {t('ans-4-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-4-2')}
                                    type="radio"
                                    value={t('ans-4-2')}
                                    checked={ques13 === t('ans-4-2')}
                                    onChange={(e) => setQues13(e.target.value)}
                                />
                                    {t('ans-4-2')}</label>
                            </div>


                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-14')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-4-1')}
                                    value={t('ans-4-1')}
                                    checked={ques14 === t('ans-4-1')}
                                    onChange={(e) => setQues14(e.target.value)}
                                />
                                    {t('ans-4-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-4-2')}
                                    type="radio"
                                    value={t('ans-4-2')}
                                    checked={ques14 === t('ans-4-2')}
                                    onChange={(e) => setQues14(e.target.value)}
                                />
                                    {t('ans-4-2')}</label>
                            </div>

                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-15')}</label>
                            </div>
                            <div className="col-md-12">

                                <label> <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-4-1')}
                                    value={t('ans-4-1')}
                                    checked={ques15 === t('ans-4-1')}
                                    onChange={(e) => setQues15(e.target.value)}
                                />
                                    {t('ans-4-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-4-2')}
                                    type="radio"
                                    value={t('ans-4-2')}
                                    checked={ques15 === t('ans-4-2')}
                                    onChange={(e) => setQues15(e.target.value)}
                                />
                                    {t('ans-4-2')}</label>
                            </div>

                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-16')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-4-1')}
                                    value={t('ans-4-1')}
                                    checked={ques16 === t('ans-4-1')}
                                    onChange={(e) => setQues16(e.target.value)}
                                />
                                    {t('ans-4-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-4-2')}
                                    type="radio"
                                    value={t('ans-4-2')}
                                    checked={ques16 === t('ans-4-2')}
                                    onChange={(e) => setQues16(e.target.value)}
                                />
                                    {t('ans-4-2')}</label>
                            </div>

                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>


                        <div className="form-group col-md-12">

                            <h6 className='text-theme'><span>{t('ques-5-title')}</span></h6>
                        </div>
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-17')}</label>
                            </div>
                            <div className="col-md-12">

                                <label> <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-4-1')}
                                    value={t('ans-4-1')}
                                    checked={ques17 === t('ans-4-1')}
                                    onChange={(e) => setQues17(e.target.value)}
                                />
                                    {t('ans-4-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-4-2')}
                                        type="radio"
                                        value={t('ans-4-2')}
                                        checked={ques17 === t('ans-4-2')}
                                        onChange={(e) => setQues17(e.target.value)}
                                    />
                                    {t('ans-4-2')}</label>
                            </div>

                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-18')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-4-1')}
                                    value={t('ans-4-1')}
                                    checked={ques18 === t('ans-4-1')}
                                    onChange={(e) => setQues18(e.target.value)}
                                />
                                    {t('ans-4-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-4-2')}
                                    type="radio"
                                    value={t('ans-4-2')}
                                    checked={ques18 === t('ans-4-2')}
                                    onChange={(e) => setQues18(e.target.value)}
                                />
                                    {t('ans-4-2')}</label>
                            </div>

                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-19')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>    <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-4-1')}
                                    value={t('ans-4-1')}
                                    checked={ques19 === t('ans-4-1')}
                                    onChange={(e) => setQues19(e.target.value)}
                                />
                                    {t('ans-4-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-4-2')}
                                    type="radio"
                                    value={t('ans-4-2')}
                                    checked={ques19 === t('ans-4-2')}
                                    onChange={(e) => setQues19(e.target.value)}
                                />
                                    {t('ans-4-2')}</label>
                            </div>

                            {errors.includes('This Field has been required.') && (
                                <div className="ivaild-error">This Field has been required.</div>
                            )}
                        </div>


                    </>
                );

            case 3:
                currentStepQuestions = [ques20, ques21, ques22];

                return (
                    <>

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-20')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-20-1')}
                                    value={t('ans-20-1')}
                                    checked={ques20 === t('ans-20-1')}
                                    onChange={(e) => setQues20(e.target.value)}
                                />
                                    {t('ans-20-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-20-2')}
                                    type="radio"
                                    value={t('ans-20-2')}
                                    checked={ques20 === t('ans-20-2')}
                                    onChange={(e) => setQues20(e.target.value)}
                                />
                                    {t('ans-20-2')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-20-3')}
                                        type="radio"
                                        value={t('ans-20-3')}
                                        checked={ques20 === t('ans-20-3')}
                                        onChange={(e) => setQues20(e.target.value)}
                                    />
                                    {t('ans-20-3')}</label>
                            </div >
                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-20-4')}
                                    type="radio"
                                    value={t('ans-20-4')}
                                    checked={ques20 === t('ans-20-4')}
                                    onChange={(e) => setQues20(e.target.value)}
                                />
                                    {t('ans-20-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-20-5')}
                                    type="radio"
                                    value={t('ans-20-5')}
                                    checked={ques20 === t('ans-20-5')}
                                    onChange={(e) => setQues20(e.target.value)}
                                />
                                    {t('ans-20-5')}</label>
                            </div >


                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-21')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-21-1')}
                                    value={t('ans-21-1')}
                                    checked={ques21 === t('ans-21-1')}
                                    onChange={(e) => setQues21(e.target.value)}
                                />
                                    {t('ans-21-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-21-2')}
                                        type="radio"
                                        value={t('ans-21-2')}
                                        checked={ques21 === t('ans-21-2')}
                                        onChange={(e) => setQues21(e.target.value)}
                                    />
                                    {t('ans-21-2')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-21-3')}
                                    type="radio"
                                    value={t('ans-21-3')}
                                    checked={ques21 === t('ans-21-3')}
                                    onChange={(e) => setQues21(e.target.value)}
                                />
                                    {t('ans-21-3')}</label>
                            </div >
                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-21-4')}
                                    type="radio"
                                    value={t('ans-21-4')}
                                    checked={ques21 === t('ans-21-4')}
                                    onChange={(e) => setQues21(e.target.value)}
                                />
                                    {t('ans-21-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-21-5')}
                                    type="radio"
                                    value={t('ans-21-5')}
                                    checked={ques21 === t('ans-21-5')}
                                    onChange={(e) => setQues21(e.target.value)}
                                />
                                    {t('ans-21-5')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-21-6')}
                                        type="radio"
                                        value={t('ans-21-6')}
                                        checked={ques21 === t('ans-21-6')}
                                        onChange={(e) => setQues21(e.target.value)}
                                    />
                                    {t('ans-21-6')}</label>
                            </div >
                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-22')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-20-1')}
                                    value={t('ans-20-1')}
                                    checked={ques22 === t('ans-20-1')}
                                    onChange={(e) => setQues22(e.target.value)}
                                />
                                    {t('ans-20-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-22-1')}
                                        type="radio"
                                        value={t('ans-22-1')}
                                        checked={ques22 === t('ans-22-1')}
                                        onChange={(e) => setQues22(e.target.value)}
                                    />
                                    {t('ans-22-1')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>    <input required className='ms-3 mx-2' id={t('ans-20-3')}
                                    type="radio"
                                    value={t('ans-20-3')}
                                    checked={ques22 === t('ans-20-3')}
                                    onChange={(e) => setQues22(e.target.value)}
                                />
                                    {t('ans-20-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-20-4')}
                                        type="radio"
                                        value={t('ans-20-4')}
                                        checked={ques22 === t('ans-20-4')}
                                        onChange={(e) => setQues22(e.target.value)}
                                    />
                                    {t('ans-20-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-20-5')}
                                    type="radio"
                                    value={t('ans-20-5')}
                                    checked={ques22 === t('ans-20-5')}
                                    onChange={(e) => setQues22(e.target.value)}
                                />
                                    {t('ans-20-5')}</label>
                            </div >
                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >


                    </>
                );

            case 4:
                currentStepQuestions = [ques23, ques24, ques25, ques26];

                return (
                    <>
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="form-group col-md-12 mb-0">

                                <h6 className='text-theme'><span>{t('ques-6-title')}</span></h6>
                                <p><span>{t('ques-6-another-title')}</span></p>
                            </div>
                            <div className="col-md-12">
                                <label > {t('ques-23')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                    value={t('ans-23-1')}
                                    checked={ques23 === t('ans-23-1')}
                                    onChange={(e) => setQues23(e.target.value)}
                                />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                    type="radio"
                                    value={t('ans-23-2')}
                                    checked={ques23 === t('ans-23-2')}
                                    onChange={(e) => setQues23(e.target.value)}
                                />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-3')}
                                    type="radio"
                                    value={t('ans-23-3')}
                                    checked={ques23 === t('ans-23-3')}
                                    onChange={(e) => setQues23(e.target.value)}
                                />
                                    {t('ans-23-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                        type="radio"
                                        value={t('ans-23-4')}
                                        checked={ques23 === t('ans-23-4')}
                                        onChange={(e) => setQues23(e.target.value)}
                                    />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                        type="radio"
                                        value={t('ans-23-5')}
                                        checked={ques23 === t('ans-23-5')}
                                        onChange={(e) => setQues23(e.target.value)}
                                    />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                    type="radio"
                                    value={t('ans-23-6')}
                                    checked={ques23 === t('ans-23-6')}
                                    onChange={(e) => setQues23(e.target.value)}
                                />
                                    {t('ans-23-6')}</label>
                            </div >
                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-24')}</label>
                            </div>

                            <div className="col-md-12">

                                <label>    <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                    value={t('ans-23-1')}
                                    checked={ques24 === t('ans-23-1')}
                                    onChange={(e) => setQues24(e.target.value)}
                                />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                    type="radio"
                                    value={t('ans-23-2')}
                                    checked={ques24 === t('ans-23-2')}
                                    onChange={(e) => setQues24(e.target.value)}
                                />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-3')}
                                        type="radio"
                                        value={t('ans-23-3')}
                                        checked={ques24 === t('ans-23-3')}
                                        onChange={(e) => setQues24(e.target.value)}
                                    />
                                    {t('ans-23-3')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>    <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                    type="radio"
                                    value={t('ans-23-4')}
                                    checked={ques24 === t('ans-23-4')}
                                    onChangez={(e) => setQues24(e.target.value)}
                                />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                    type="radio"
                                    value={t('ans-23-5')}
                                    checked={ques24 === t('ans-23-5')}
                                    onChange={(e) => setQues24(e.target.value)}
                                />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                        type="radio"
                                        value={t('ans-23-6')}
                                        checked={ques24 === t('ans-23-6')}
                                        onChange={(e) => setQues24(e.target.value)}
                                    />
                                    {t('ans-23-6')}</label>
                            </div >


                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-25')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                    value={t('ans-23-1')}
                                    checked={ques25 === t('ans-23-1')}
                                    onChange={(e) => setQues25(e.target.value)}
                                />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                        type="radio"
                                        value={t('ans-23-2')}
                                        checked={ques25 === t('ans-23-2')}
                                        onChange={(e) => setQues25(e.target.value)}
                                    />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-23-3')}
                                    type="radio"
                                    value={t('ans-23-3')}
                                    checked={ques25 === t('ans-23-3')}
                                    onChange={(e) => setQues25(e.target.value)}
                                />
                                    {t('ans-23-3')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                    type="radio"
                                    value={t('ans-23-4')}
                                    checked={ques25 === t('ans-23-4')}
                                    onChange={(e) => setQues25(e.target.value)}
                                />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                        type="radio"
                                        value={t('ans-23-5')}
                                        checked={ques25 === t('ans-23-5')}
                                        onChange={(e) => setQues25(e.target.value)}
                                    />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>    <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                    type="radio"
                                    value={t('ans-23-6')}
                                    checked={ques25 === t('ans-23-6')}
                                    onChange={(e) => setQues25(e.target.value)}
                                />
                                    {t('ans-23-6')}</label>
                            </div >
                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-26')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required
                                        type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                        value={t('ans-23-1')}
                                        checked={ques26 === t('ans-23-1')}
                                        onChange={(e) => setQues26(e.target.value)}
                                    />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                        type="radio"
                                        value={t('ans-23-2')}
                                        checked={ques26 === t('ans-23-2')}
                                        onChange={(e) => setQues26(e.target.value)}
                                    />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-3')}
                                    type="radio"
                                    value={t('ans-23-3')}
                                    checked={ques26 === t('ans-23-3')}
                                    onChange={(e) => setQues26(e.target.value)}
                                />
                                    {t('ans-23-3')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                    type="radio"
                                    value={t('ans-23-4')}
                                    checked={ques26 === t('ans-23-4')}
                                    onChange={(e) => setQues26(e.target.value)}
                                />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                    type="radio"
                                    value={t('ans-23-5')}
                                    checked={ques26 === t('ans-23-5')}
                                    onChange={(e) => setQues26(e.target.value)}
                                />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                    type="radio"
                                    value={t('ans-23-6')}
                                    checked={ques26 === t('ans-23-6')}
                                    onChange={(e) => setQues26(e.target.value)}
                                />
                                    {t('ans-23-6')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >




                    </>
                );
            case 5:
                currentStepQuestions = [ques27, ques28, ques29, ques30, ques31];

                return (
                    <>

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-27')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                    value={t('ans-23-1')}
                                    checked={ques27 === t('ans-23-1')}
                                    onChange={(e) => setQues27(e.target.value)}
                                />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                    type="radio"
                                    value={t('ans-23-2')}
                                    checked={ques27 === t('ans-23-2')}
                                    onChange={(e) => setQues27(e.target.value)}
                                />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-3')}
                                        type="radio"
                                        value={t('ans-23-3')}
                                        checked={ques27 === t('ans-23-3')}
                                        onChange={(e) => setQues27(e.target.value)}
                                    />
                                    {t('ans-23-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                        type="radio"
                                        value={t('ans-23-4')}
                                        checked={ques27 === t('ans-23-4')}
                                        onChange={(e) => setQues27(e.target.value)}
                                    />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                    type="radio"
                                    value={t('ans-23-5')}
                                    checked={ques27 === t('ans-23-5')}
                                    onChange={(e) => setQues27(e.target.value)}
                                />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                        type="radio"
                                        value={t('ans-23-6')}
                                        checked={ques27 === t('ans-23-6')}
                                        onChange={(e) => setQues27(e.target.value)}
                                    />
                                    {t('ans-23-6')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-28')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required
                                        type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                        value={t('ans-23-1')}
                                        checked={ques28 === t('ans-23-1')}
                                        onChange={(e) => setQues28(e.target.value)}
                                    />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                        type="radio"
                                        value={t('ans-23-2')}
                                        checked={ques28 === t('ans-23-2')}
                                        onChange={(e) => setQues28(e.target.value)}
                                    />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-3')}
                                        type="radio"
                                        value={t('ans-23-3')}
                                        checked={ques28 === t('ans-23-3')}
                                        onChange={(e) => setQues28(e.target.value)}
                                    />
                                    {t('ans-23-3')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                    type="radio"
                                    value={t('ans-23-4')}
                                    checked={ques28 === t('ans-23-4')}
                                    onChange={(e) => setQues28(e.target.value)}
                                />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                    type="radio"
                                    value={t('ans-23-5')}
                                    checked={ques28 === t('ans-23-5')}
                                    onChange={(e) => setQues28(e.target.value)}
                                />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                    type="radio"
                                    value={t('ans-23-6')}
                                    checked={ques28 === t('ans-23-6')}
                                    onChange={(e) => setQues28(e.target.value)}
                                />
                                    {t('ans-23-6')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-29')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                    value={t('ans-23-1')}
                                    checked={ques29 === t('ans-23-1')}
                                    onChange={(e) => setQues29(e.target.value)}
                                />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                        type="radio"
                                        value={t('ans-23-2')}
                                        checked={ques29 === t('ans-23-2')}
                                        onChange={(e) => setQues29(e.target.value)}
                                    />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-3')}
                                        type="radio"
                                        value={t('ans-23-3')}
                                        checked={ques29 === t('ans-23-3')}
                                        onChange={(e) => setQues29(e.target.value)}
                                    />
                                    {t('ans-23-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                        type="radio"
                                        value={t('ans-23-4')}
                                        checked={ques29 === t('ans-23-4')}
                                        onChange={(e) => setQues29(e.target.value)}
                                    />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                    type="radio"
                                    value={t('ans-23-5')}
                                    checked={ques29 === t('ans-23-5')}
                                    onChange={(e) => setQues29(e.target.value)}
                                />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                    type="radio"
                                    value={t('ans-23-6')}
                                    checked={ques29 === t('ans-23-6')}
                                    onChange={(e) => setQues29(e.target.value)}
                                />
                                    {t('ans-23-6')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-30')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                    value={t('ans-23-1')}
                                    checked={ques30 === t('ans-23-1')}
                                    onChange={(e) => setQues30(e.target.value)}
                                />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                        type="radio"
                                        value={t('ans-23-2')}
                                        checked={ques30 === t('ans-23-2')}
                                        onChange={(e) => setQues30(e.target.value)}
                                    />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-3')}
                                        type="radio"
                                        value={t('ans-23-3')}
                                        checked={ques30 === t('ans-23-3')}
                                        onChange={(e) => setQues30(e.target.value)}
                                    />
                                    {t('ans-23-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                        type="radio"
                                        value={t('ans-23-4')}
                                        checked={ques30 === t('ans-23-4')}
                                        onChange={(e) => setQues30(e.target.value)}
                                    />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                        type="radio"
                                        value={t('ans-23-5')}
                                        checked={ques30 === t('ans-23-5')}
                                        onChange={(e) => setQues30(e.target.value)}
                                    />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                    type="radio"
                                    value={t('ans-23-6')}
                                    checked={ques30 === t('ans-23-6')}
                                    onChange={(e) => setQues30(e.target.value)}
                                />
                                    {t('ans-23-6')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-31')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required
                                        type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                        value={t('ans-23-1')}
                                        checked={ques31 === t('ans-23-1')}
                                        onChange={(e) => setQues31(e.target.value)}
                                    />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                        type="radio"
                                        value={t('ans-23-2')}
                                        checked={ques31 === t('ans-23-2')}
                                        onChange={(e) => setQues31(e.target.value)}
                                    />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-23-3')}
                                    type="radio"
                                    value={t('ans-23-3')}
                                    checked={ques31 === t('ans-23-3')}
                                    onChange={(e) => setQues31(e.target.value)}
                                />
                                    {t('ans-23-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                        type="radio"
                                        value={t('ans-23-4')}
                                        checked={ques31 === t('ans-23-4')}
                                        onChange={(e) => setQues31(e.target.value)}
                                    />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                    type="radio"
                                    value={t('ans-23-5')}
                                    checked={ques31 === t('ans-23-5')}
                                    onChange={(e) => setQues31(e.target.value)}
                                />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                        type="radio"
                                        value={t('ans-23-6')}
                                        checked={ques31 === t('ans-23-6')}
                                        onChange={(e) => setQues31(e.target.value)}
                                    />
                                    {t('ans-23-6')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                    </>
                );
            case 6:
                currentStepQuestions = [ques32, ques33, ques34, ques35, ques36];

                return (
                    <>
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="col-md-12">
                                <label > {t('ques-32')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required
                                    type="radio" className='ms-3 mx-2' id={t('ans-23-1')}
                                    value={t('ans-23-1')}
                                    checked={ques32 === t('ans-23-1')}
                                    onChange={(e) => setQues32(e.target.value)}
                                />
                                    {t('ans-23-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-2')}
                                        type="radio"
                                        value={t('ans-23-2')}
                                        checked={ques32 === t('ans-23-2')}
                                        onChange={(e) => setQues32(e.target.value)}
                                    />
                                    {t('ans-23-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-4')}
                                        type="radio"
                                        value={t('ans-23-4')}
                                        checked={ques32 === t('ans-23-4')}
                                        onChange={(e) => setQues32(e.target.value)}
                                    />
                                    {t('ans-23-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-23-5')}
                                    type="radio"
                                    value={t('ans-23-5')}
                                    checked={ques32 === t('ans-23-5')}
                                    onChange={(e) => setQues32(e.target.value)}
                                />
                                    {t('ans-23-5')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-23-6')}
                                        type="radio"
                                        value={t('ans-23-6')}
                                        checked={ques32 === t('ans-23-6')}
                                        onChange={(e) => setQues32(e.target.value)}
                                    />
                                    {t('ans-23-6')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >
                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>
                            <div className="form-group col-md-12">

                                <h6 className='text-theme'><span>{t('ques-7-title')}</span></h6>

                            </div>
                            <div className="col-md-12">
                                <label > {t('ques-33')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required
                                        type="radio" className='ms-3 mx-2' id={t('ans-33-1')}
                                        value={t('ans-33-1')}
                                        checked={ques33 === t('ans-33-1')}
                                        onChange={(e) => setQues33(e.target.value)}
                                    />
                                    {t('ans-33-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>  <input required className='ms-3 mx-2' id={t('ans-33-2')}
                                    type="radio"
                                    value={t('ans-33-2')}
                                    checked={ques33 === t('ans-33-2')}
                                    onChange={(e) => setQues33(e.target.value)}
                                />
                                    {t('ans-33-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-3')}
                                        type="radio"
                                        value={t('ans-33-3')}
                                        checked={ques33 === t('ans-33-3')}
                                        onChange={(e) => setQues33(e.target.value)}
                                    />
                                    {t('ans-33-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-4')}
                                        type="radio"
                                        value={t('ans-33-4')}
                                        checked={ques33 === t('ans-33-4')}
                                        onChange={(e) => setQues33(e.target.value)}
                                    />
                                    {t('ans-33-4')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-5')}
                                        type="radio"
                                        value={t('ans-33-5')}
                                        checked={ques33 === t('ans-33-5')}
                                        onChange={(e) => setQues33(e.target.value)}
                                    />
                                    {t('ans-33-5')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>

                            <div className="col-md-12">
                                <label > {t('ques-34')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required
                                        type="radio" className='ms-3 mx-2' id={t('ans-33-1')}
                                        value={t('ans-33-1')}
                                        checked={ques34 === t('ans-33-1')}
                                        onChange={(e) => setQues34(e.target.value)}
                                    />
                                    {t('ans-33-1')}</label>
                            </div>
                            <div className="col-md-12">

                                <label>   <input required className='ms-3 mx-2' id={t('ans-33-2')}
                                    type="radio"
                                    value={t('ans-33-2')}
                                    checked={ques34 === t('ans-33-2')}
                                    onChange={(e) => setQues34(e.target.value)}
                                />
                                    {t('ans-33-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-3')}
                                        type="radio"
                                        value={t('ans-33-3')}
                                        checked={ques34 === t('ans-33-3')}
                                        onChange={(e) => setQues34(e.target.value)}
                                    />
                                    {t('ans-33-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-4')}
                                        type="radio"
                                        value={t('ans-33-4')}
                                        checked={ques34 === t('ans-33-4')}
                                        onChange={(e) => setQues34(e.target.value)}
                                    />
                                    {t('ans-33-4')}</label>
                            </div >
                            <div className="col-md-12">

                                <label> <input required className='ms-3 mx-2' id={t('ans-33-5')}
                                    type="radio"
                                    value={t('ans-33-5')}
                                    checked={ques34 === t('ans-33-5')}
                                    onChange={(e) => setQues34(e.target.value)}
                                />
                                    {t('ans-33-5')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>

                            <div className="col-md-12">
                                <label > {t('ques-35')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required
                                        type="radio" className='ms-3 mx-2' id={t('ans-33-1')}
                                        value={t('ans-33-1')}
                                        checked={ques35 === t('ans-33-1')}
                                        onChange={(e) => setQues35(e.target.value)}
                                    />
                                    {t('ans-33-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-2')}
                                        type="radio"
                                        value={t('ans-33-2')}
                                        checked={ques35 === t('ans-33-2')}
                                        onChange={(e) => setQues35(e.target.value)}
                                    />
                                    {t('ans-33-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-3')}
                                        type="radio"
                                        value={t('ans-33-3')}
                                        checked={ques35 === t('ans-33-3')}
                                        onChange={(e) => setQues35(e.target.value)}
                                    />
                                    {t('ans-33-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-4')}
                                        type="radio"
                                        value={t('ans-33-4')}
                                        checked={ques35 === t('ans-33-4')}
                                        onChange={(e) => setQues35(e.target.value)}
                                    />
                                    {t('ans-33-4')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-5')}
                                        type="radio"
                                        value={t('ans-33-5')}
                                        checked={ques35 === t('ans-33-5')}
                                        onChange={(e) => setQues35(e.target.value)}
                                    />
                                    {t('ans-33-5')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >

                        <div className={`form-group col-md-12 row ${errors.includes('This Field has been required.') ? 'is-invalid' : ''}`}>

                            <div className="col-md-12">
                                <label> {t('ques-36')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required
                                        type="radio" className='ms-3 mx-2' id={t('ans-33-1')}
                                        value={t('ans-33-1')}
                                        checked={ques36 === t('ans-33-1')}
                                        onChange={(e) => setQues36(e.target.value)}
                                    />
                                    {t('ans-33-1')}</label>
                            </div>
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-2')}
                                        type="radio"
                                        value={t('ans-33-2')}
                                        checked={ques36 === t('ans-33-2')}
                                        onChange={(e) => setQues36(e.target.value)}
                                    />
                                    {t('ans-33-2')}</label>
                            </div >

                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-3')}
                                        type="radio"
                                        value={t('ans-33-3')}
                                        checked={ques36 === t('ans-33-3')}
                                        onChange={(e) => setQues36(e.target.value)}
                                    />
                                    {t('ans-33-3')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-4')}
                                        type="radio"
                                        value={t('ans-33-4')}
                                        checked={ques36 === t('ans-33-4')}
                                        onChange={(e) => setQues36(e.target.value)}
                                    />
                                    {t('ans-33-4')}</label>
                            </div >
                            <div className="col-md-12">
                                <label>
                                    <input required className='ms-3 mx-2' id={t('ans-33-5')}
                                        type="radio"
                                        value={t('ans-33-5')}
                                        checked={ques36 === t('ans-33-5')}
                                        onChange={(e) => setQues36(e.target.value)}
                                    />
                                    {t('ans-33-5')}</label>
                            </div >

                            {
                                errors.includes('This Field has been required.') && (
                                    <div className="ivaild-error">This Field has been required.</div>
                                )
                            }
                        </div >
                    </>);


            // Add more cases for additional steps
            default:
                return null;
        }
    };

    return <>

        <section className="page-title overflow-hidden grey-bg">
            <div className="container">
                <div className="row align-items-center position-relative p-5  justify-content-between nurse-title-container">
                    <div className="col-md-8 position-absolute end-0  text-end nurse-title">
                        <h1 className=" mb-0 ">{t('title')}<span> </span></h1>

                    </div>
                    <div className="col-md-4 position-absolute start-0 text-start nurse-img">
                        <a className="navbar-brand logo logo-nurse py-1" href={`/#/sgh/${Cookies.get('i18next')}`}>
                            <img id="logo-img" className="img-fluid" src='/images/logos/Health logo of egypt-min.webp' alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">

                        <div className="box-shadow">
                            <div className="post-comments">

                                {/* <form onSubmit={submitForm}> */}
                                <div className="messages"></div>
                                <div className="row justify-content-center">
                                    <div className="col-md-6 px-5 pt-3 order-3">
                                        <div className='row justify-content-center'>
                                            <div class="section-title mb-2 row justify-content-center">
                                                {/* <h2 class="title mb-3 text-center">complete <span>Form</span></h2> */}

                                                {i18n.language === 'en' && <button className='btn btn-border btn-circle col-md-5 mb-3' onClick={() => {
                                                    i18n.changeLanguage('ar');
                                                    changeLanguage('ar', 'rtl');

                                                    navigate(`/${Cookies.get('i18next')}/${pathname.slice('4')}`)

                                                }}>  <span>
                                                        العربية  </span>

                                                </button>}
                                                {i18n.language === 'ar' && <button className='btn btn-border btn-circle col-md-5 mb-3' onClick={() => {
                                                    i18n.changeLanguage('en');
                                                    changeLanguage('en', 'ltr');

                                                    navigate(`/${Cookies.get('i18next')}/${pathname.slice('4')}`)


                                                }}><span>
                                                        English</span>

                                                </button>}
                                                <h6 className='text-theme'>{t('formTitle')}</h6>

                                            </div>


                                            <form onSubmit={submitForm}>
                                                {renderStep()}

                                                <div>
                                                    {step > 0 && (
                                                        <button type="button" className='btn btn-theme btn-sm appoint-btn mt-md-0 mt-3 rounded-top rounded-bottom mb-0 mb-md-3 mx-2' onClick={handleBack}>
                                                            {t('back')}
                                                        </button>
                                                    )}
                                                    {step < 7 - 1 ? (
                                                        <button type="button" className='btn btn-theme btn-sm appoint-btn mt-md-0 mt-3 rounded-top rounded-bottom mb-0 mb-md-3 mx-2' onClick={handleNext}>
                                                            {t('next')}
                                                        </button>
                                                    ) : (
                                                        <button type="submit" className='btn btn-theme btn-sm appoint-btn mt-md-0 mt-3 rounded-top rounded-bottom mb-0 mb-md-3 mx-2'>
                                                            {t('submit')}
                                                        </button>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-5" style={{ backgroundImage: `url('/images/forms/nurse-nomination.jpeg')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                            </div> */}
                                    <div className="col-md-6 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/l9p.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                        <img src="/images/forms/General-Patient.png" alt="patient survey 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'contain', backgroundColor: '#61882b', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} />
                                    </div>
                                    <div class="col-sm-12 text-center">
                                        <Modal   {...props}
                                            size="lg"
                                            style={customStyles}
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered show={show} onHide={handleClose} className="top-0 ">

                                            <Modal.Header closeButton>
                                                <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                <Modal.Title style={{ color: "#4ab242" }}>{t('Your Message')}</Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <div className="team-member text-center">

                                                    <div className="team-description pt-0">
                                                        <h5 className="my-5"><a>{submitMsg}</a></h5>
                                                        {/* <p className='lead'>{messageForm}</p> */}
                                                    </div>



                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                    {t('Close')}
                                                </Button>

                                            </Modal.Footer>

                                        </Modal>
                                    </div>
                                </div>

                                {/* </form> */}
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >



    </>
}
