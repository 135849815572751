

import React, { createContext, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import metaData from "../../Components/MetaTag/MetaTag";
import { metaDataAR } from "../../Components/MetaTag/MetaTagAR";

export let MetaTagContext = createContext('');

export default function MetaTagContextProvider(props) {
  // let [tag, setTag] = useState({});
  const location = useLocation();

  // console.log(`path ${pathname}`);
  let tags = () => {

    switch (location.pathname) {
      case `/en/about`:
        return (
          {
            meta: [
              { name: 'keywords', content: 'ماذا نحن' },
              { property: 'description', content: 'ماذا نحن' },

            ]
          }

        );
      case `/ ar / about`:
        return (
          {
            meta: [
              { name: 'keywords', content: 'ماذا نحن' },
              { property: 'description', content: 'ماذا نحن' },

            ]
          }

        );
      default:
        return (
          <>
            <meta name="description" content="Default page description" />
            <meta name="keywords" content="default, page" />
          </>
        );
    }


  }

  return <>
    <MetaTagContext.Provider value={{
      tags
    }}>
      {props.children}
    </MetaTagContext.Provider>


  </>
}
