import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageTitle from '../Utilites/PageTitle';
import { Container, Row, Col, Card, CardImg, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import axios from 'axios';
import Pagination from 'react-js-pagination';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
export default function MediaCenter() {
    // translation 
    const { t } = useTranslation("MediaCenter");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 

    const { pathname } = useLocation();
    const mediaItems = [
        { id: 1, title: 'Image 1', src: '../../images/gallery/01.jpg' },
        { id: 2, title: 'Image 2', src: '../../images/gallery/02.jpg' },
        { id: 3, title: 'Image 3', src: '../../images/gallery/03.jpg' },
        // Add more media items as needed
    ];

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openSlider = (index) => {
        setSelectedImageIndex(index);
        setIsModalOpen(true);
    };

    const closeSlider = () => {
        setSelectedImageIndex(0);
        setIsModalOpen(false);
    };

    const images = mediaItems.map((item) => ({
        original: item.src,
        thumbnail: item.src,
        description: item.title,
    }));
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    // pagination
    let [lastPageOfSearch, setLastPageOfSearch] = useState()

    let [perPage, setPerPage] = useState();
    let [total, setTotal] = useState();
    let [currentPage, setCurrentPage] = useState(1)
    const handlePageChange = (pageNumber) => {
        // const pageNumber = e;
        setCurrentPage(pageNumber);
        scrollToTop();
    };
    // get media center items 


    const [allMedia, setAllMedia] = useState([]);
    // const [allMediaMsg, setAllMediaMsg] = useState('');

    async function getMedia(hospital_id) {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }

        const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/media/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}&page=${currentPage}&in_home=1`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    setAllMedia(response.data.Edata.data)
                    setPerPage(response.data.Edata.per_page);
                    setTotal(response.data.Edata.total);
                    setLastPageOfSearch(response.data.Edata.last_page)

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    useEffect(() => {
        getMedia('1')

    }, [currentPage])
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Media')} anotherTitle={t('Center')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/media-center`} currentPage={t('Media Center')} />
            : <PageTitle title={t('Center')} anotherTitle={t('Media')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/media-center`} currentPage={t('Media Center')} />}
        {/* <!--page title end--> */}

        <div className="page-content">

            {/* <!--gallery start--> */}

            <section className="overflow-hidden">

                <div className="container">
                    {/* <div className="row">
                        <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                            <div class="section-title">
                                <h2 class="title text-center">Latest <span>Events</span></h2>
                            </div>
                        </div>
                    </div> */}


                    <div className="row gy-3">
                        {allMedia.map((media, index) => <>
                            <div className="col-md-4" key={index}>
                                <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/media-center/gallery/${media.famous_name}/${media.id}`}>
                                    <div className="team-member text-center" role="button">
                                        <div className="team-images">
                                            {media.image ? <img className="img-fluid" src={media.image} alt={media.famous_name} /> : <img className="img-fluid" src="/images/mayo-clinic/disease-min.webp" alt='' />}
                                            <div className="team-about">
                                                <div className="team-social-icon">
                                                    <ul>
                                                        <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/media-center/gallery/${media.famous_name}/${media.id}`} onClick={scrollToTop} ><li className='bg-white p-2 rounded-3'><i class="fa-regular fa-images" title='photos'></i>
                                                        </li></Link>
                                                        <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/media-center/videos/${media.famous_name}/${media.id}`} onClick={scrollToTop} ><li className='bg-white p-2 rounded-3'><i class="fa-regular fa-file-video" title='videos'></i>
                                                        </li></Link>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="team-description">
                                            <h5>
                                                <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/media-center/gallery/${media.famous_name}/${media.id}`}>
                                                    {media.famous_name}</Link>
                                            </h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </>)}


                    </div>

                    {lastPageOfSearch > 1 ? <>
                        < div className="row justify-content-center text-center mt-3" id='pagination'>
                            < Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                hideLastPages={true}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div></> : ''}
                </div>
            </section>


            {/* <!--gallery end--> */}
        </div>
    </>
}
