import React, { useEffect, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { GetCategoryDetailsContext } from '../../Context/GetCategoryDetails';
import { LocalizationContext } from '../../Context/LocalizationContext';
import PageDetails from '../Utilites/MayoClinic/PageDetails';
import OverViewPageDetails from '../Utilites/MayoClinic/OverViewPageDetails';
import CopyWrite from '../Utilites/MayoClinic/CopyWrite';
import PageTitle from '../Utilites/PageTitle';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function TestProcedureDetails() {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    let { id } = useParams();
    const { pathname } = useLocation();


    let { testProcedureDetails, setTestProcedureDetails, getCategoryDetails } = useContext(GetCategoryDetailsContext);

    useEffect(() => {
        getCategoryDetails(id, setTestProcedureDetails);
    }, [i18n.language]);

    return <>
        {/* <!--page title start--> */}
        {testProcedureDetails.map((testProcedureDetail, index) => (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{testProcedureDetail.title}</title>
                <link rel="canonical" href={testProcedureDetail.originalUrl} />
            </Helmet>
            {i18n.language === 'en' ? <PageTitle key={index} title={t('Mayo Clinic')} anotherTitle={t('Health Library')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/TestProcedure/${testProcedureDetail.docId}`} currentPage={t('Test Procedure')} />
                : <PageTitle key={index} title={t('Health Library')} anotherTitle={t('Mayo Clinic')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/TestProcedure/${testProcedureDetail.docId}`} currentPage={t('Test Procedure')} />}
        </>))}


        {/* <!--page title end--> */}


        {/* <!--body content start--> */}

        <div class="page-content">

            {/* <!--blog start--> */}

            <section>
                <div class="container">
                    <div class="row">

                        {testProcedureDetails.map((testProcedureDetail, index) => (<>

                            <div key={index} class="col-lg-12 col-md-12 order-sm-12">
                                <PageDetails contentType={testProcedureDetail.contentType} title={testProcedureDetail.title} updateDate={testProcedureDetail.updateDate} />

                                <div className="service-details mt-4">

                                    <h2 className="title"> {t('overview')} </h2>

                                    <div class="row">

                                        {testProcedureDetail.sections.map((section, index) => (<>
                                            <div key={index} className="col-md-9 order-2">
                                                {section.sectionHead ? <h6 className='text-theme'>{section.sectionHead}</h6> : ''}
                                                <p className='lead' dangerouslySetInnerHTML={{ __html: section.html }}></p>
                                            </div>
                                            <div class="col-md-3 mt-3 mt-md-0 order-1 order-md-3">
                                                {section.media ?
                                                    section.media.map((med, index) => (<>
                                                        {med.file_name ?
                                                            <OverViewPageDetails key={index} fileName={med.file_name} />
                                                            : ''}

                                                    </>))
                                                    : ''}
                                            </div>
                                        </>))}
                                    </div>

                                </div>

                                <CopyWrite />


                            </div>
                        </>)

                        )}
                    </div>
                </div>
            </section>

            {/* <!--blog end--> */}

        </div>

        {/* <!--body content end-->  */}
    </>
}
