import { createContext } from "react";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
export let LocalizationContext = createContext(0);

function LocalizationContextProvider(props) {
    let [t, i18n] = useTranslation(0);

    const handleLanguageChange = (newLanguage) => {
        i18n.changeLanguage(newLanguage);
        Cookies.set('i18next', newLanguage, { expires: 365 });
        // const currentPath = window.location.pathname;
        // const hash = window.location.hash;

        // // Update the URL hash fragment to include the new language code
        // const newPath = currentPath + hash.replace(/\/sgh\/(en|ar)/, `/sgh/${newLanguage}`);
        // window.location.href = newPath;

        // window.history.pushState(null, '', newLanguage);


        const url = window.location.href;

        // Define the language codes to be removed
        const langCodes = ['en', 'ar'];

        let newUrl = url;

        langCodes.forEach(lang => {
            const langIndex = newUrl.indexOf(`/${lang}`);
            if (langIndex !== -1) {
                newUrl = newUrl.slice(0, langIndex) + `/${newLanguage}` + newUrl.slice(langIndex + lang.length + 1);
                console.log(newUrl);

            }
        });

        // Check if the URL ends with '#/' and add the path after that
        if (newUrl.endsWith('#/')) {
            const path = window.location.hash.substring(2);

            newUrl += path;
        }
        window.history.pushState(null, '', newLanguage);

        window.location.href = newUrl;

    };
    return <LocalizationContext.Provider value={{ t, i18n, handleLanguageChange }}>
        {props.children}
    </LocalizationContext.Provider>

}
export default LocalizationContextProvider;