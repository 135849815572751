import React from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../../Utilites/PageTitle';
import OurPartnersDetails from '../../Utilites/OurPartnersDetails';

export default function GSC() {
    const { pathname } = useLocation();

    return <>


        {/* <!--page title start--> */}
        <PageTitle title='GSC' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/`} prevPage='Our Partner' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/gsc`} currentPage='GSC' />


        {/* <!--page title end--> */}


        {/* <!--about us start--> */}
        <OurPartnersDetails partnerImgSrc="/images/partners/Logo-GSC.png" partnerTitle='Geneva Sustainability Center GSC' partnerDescription='Leading the way in sustainable healthcare, Saudi German Health Egypt proudly collaborates with the Geneva Sustainability Center. This dynamic partnership fosters green initiatives, reduces their environmental footprint, and ensures a healthier future for patients and the planet. From energy-efficient practices to waste reduction programs, Saudi German Health sets a new standard for environmentally responsible healthcare in Egypt.' />


        {/* <!--about us end--> */}
    </>
}
