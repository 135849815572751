import React from 'react'
import PageTitle from '../Utilites/PageTitle'
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
export default function PatientRights() {
    const { pathname } = useLocation();
    const { t } = useTranslation("PatientRights");

    return <>
        {/* <!--page title start--> */}
        <PageTitle title={t('PatientRights')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/policies/patient-rights`} currentPage={t('PatientRights')} />
        {/* <!--page title end--> */}
        {/* <!--body content start--> */}

        <div className="page-content">

            {/* <!--privacy start--> */}

            <section>
                <div className="container">


                    <div className="row mt-3">

                        <div className="col-lg-7 col-md-12 order-2 mt-3 mt-md-0">

                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <h2 className="title mb-3"><span className='fw-bold'>{t('Overview')}</span></h2>
                                </div>
                            </div>
                            <p>{t('BillRightsP')}</p>
                            <h5 className="mt-3 text-theme text-capitalize">{t('RightsOfMedicalCare')}</h5>
                            <ul className="list-unstyled list-icon">
                                <li className='lead'> <i className="fa fa-angle-right"></i>{t('MedicalCareList1')} </li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('MedicalCareList2')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i>{t('MedicalCareList3')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i>{t('MedicalCareList4')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('MedicalCareList5')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('MedicalCareList6')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('MedicalCareList7')}</li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 order-1 order-md-3">
                            <img src="/images/patient-and-visitor/patient-rights-min.webp" className='img-fluid w-100' alt="" srcset="" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">

                            <h5 className="text-theme text-capitalize">{t('ReceiveInformation')}</h5>
                            <ul className="list-unstyled list-icon">
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('ReceiveInformationList1')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i>{t('ReceiveInformationList2')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('ReceiveInformationList3')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('ReceiveInformationList4')}</li>
                                {/* <li className='lead'> <i className="fa fa-angle-right"></i> {t('ReceiveInformationList5')}</li>
                                <p>{t('ReceiveInformationList6')}</p> */}
                            </ul>
                            <h5 className="mt-3 text-theme text-capitalize">{t('ConfidentialityPrivacy')}</h5>
                            <ul className="list-unstyled list-icon">
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('ConfidentialityPrivacyList1')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i>{t('ConfidentialityPrivacyList2')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('ConfidentialityPrivacyList3')}</li>

                            </ul>
                            <h5 className="mt-3 text-theme text-capitalize">{t('SafetySecurity')}</h5>
                            <ul className="list-unstyled list-icon">
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('SafetySecurityList1')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i>{t('SafetySecurityList2')}</li>

                            </ul>
                            <h5 className="mt-3 text-theme text-capitalize">{t('PatientsFamiliesResponsibilities')}</h5>
                            <ul className="list-unstyled list-icon">
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('PatientsFamiliesResponsibilitiesList1')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i>{t('PatientsFamiliesResponsibilitiesList2')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('PatientsFamiliesResponsibilitiesList3')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('PatientsFamiliesResponsibilitiesList4')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('PatientsFamiliesResponsibilitiesList5')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('PatientsFamiliesResponsibilitiesList6')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('PatientsFamiliesResponsibilitiesList7')}</li>
                                <li className='lead'> <i className="fa fa-angle-right"></i> {t('PatientsFamiliesResponsibilitiesList8')}</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--privacy end--> */}

        </div>

        {/* <!--body content end-->  */}
    </>
}
