import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LocalizationContext } from '../../../Context/LocalizationContext'
import { useTranslation } from 'react-i18next';

export default function PageDetails({ contentType, title, updateDate }) {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    return <>
        <div class="post-desc">

            <div class="post-title">
                <h4><Link to="">{title}</Link></h4>
            </div>
            <div class="post-meta">

                <ul class="list-inline">
                    <li> </li>
                    <li> {t('Content Type')}  : <span className='small'>{contentType}</span>  </li>

                    {/* <li> Videos</li> */}
                </ul>
                <ul class="list-inline">
                    <li> </li>
                    <li>  {t('Last Update On')}  : <span className='small'>{updateDate}</span></li>
                </ul>
            </div>
            {/* <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> <Link class="post-btn" to="blog-details-right-sidebar.html">Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link> */}
        </div>
    </>
}
