import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function JoinUs(props) {
    // translation 
    const { t } = useTranslation("JoinUs");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    // get branches
    let [allBranches, setAllBranches] = useState([]);
    async function getAllBranch() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('action', 'get_all_hospital');

        const headers = {
            'fingerprint': localStorage.getItem('fingerPrint'),
            'lang': Cookies.get('i18next')
        }

        const response = await axios.post(`https://sgheg.com/Sadm25/api/jops/get_all_hospital`, bodyFormData, { headers })
            .then(response => {
                console.log(branchValue);
                // Handle the response
                // if (response.data.Emsg === 'Success') {
                // setCheckPhoneNum(response.data.Emsg);
                setAllBranches(response.data.Edata)

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    // get categories
    let [allCategories, setAllCategories] = useState([]);
    const [branchValue, setBranchValue] = useState();
    async function getAllCategory() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('action', 'get_all_category');
        bodyFormData.append('hospital_id', branchValue);
        const headers = {
            'fingerprint': localStorage.getItem('fingerPrint'),
            'lang': Cookies.get('i18next')
        }

        const response = await axios.post(`https://sgheg.com/Sadm25/api/jops/get_all_category`, bodyFormData, { headers })
            .then(response => {
                console.log(branchValue);
                // Handle the response
                // if (response.data.Emsg === 'Success') {
                // setCheckPhoneNum(response.data.Emsg);
                setAllCategories(response.data.Edata)

                // console.log('categories ' + allCategories.map(cat => cat.name));

                // }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    // get departments
    const [categoryValue, setCategoryValue] = useState();
    const [allDepartments, setAllDepartments] = useState([]);
    const [htmlPackage, setHtmlPackage] = useState([]);

    async function getAllDepartment() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('action', 'get_all_department');
        bodyFormData.append('hospital_id', branchValue);
        bodyFormData.append('category_id', categoryValue);

        const headers = {
            'fingerprint': localStorage.getItem('fingerPrint'),
            'lang': Cookies.get('i18next')
        }

        const response = await axios.post(`https://sgheg.com/Sadm25/api/jops/get_all_department`, bodyFormData, { headers })
            .then(response => {
                // console.log(branchValue);
                // Handle the response
                // if (response.data.Emsg === 'Success') {
                // setCheckPhoneNum(response.data.Emsg);

                setAllDepartments(response.data.Edata)
                setHtmlPackage(response.data.EHtml)

                // console.log('categories ' + allCategories.map(cat => cat.name));

                // }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    //  get positions
    const [departmentValue, setDepartmentValue] = useState();
    const [allPositions, setAllPositions] = useState([]);
    const [positionValue, setPositionValue] = useState();

    async function getAllPosition() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('action', 'get_all_position');
        bodyFormData.append('hospital_id', branchValue);
        bodyFormData.append('category_id', categoryValue);
        if (htmlPackage === null) {
            bodyFormData.append('department_id', 2);

        } else {
            bodyFormData.append('department_id', departmentValue);

        }
        const headers = {
            'fingerprint': localStorage.getItem('fingerPrint'),
            'lang': Cookies.get('i18next')
        }

        const response = await axios.post(`https://sgheg.com/Sadm25/api/jops/get_all_position`, bodyFormData, { headers })
            .then(response => {
                console.log(branchValue);
                // Handle the response
                // if (response.data.Emsg === 'Success') {
                // setCheckPhoneNum(response.data.Emsg);
                setAllPositions(response.data.Edata)



            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }


    //  send uploaded file
    const [fileValue, setFileValue] = useState();
    const [file, setFile] = useState();
    const [taregtPath, setTaregtPath] = useState();
    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
        setFileValue(e.target.value);

    }
    async function sendUploadedFile() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('action', 'upload_img');
        bodyFormData.append('file', file);


        const headers = {
            'fingerprint': localStorage.getItem('fingerPrint'),
            'lang': Cookies.get('i18next')
        }

        const response = await axios.post(`https://sgheg.com/Sadm25/api/jops/upload_cv_image`, bodyFormData, { headers })
            .then(response => {
                // console.log(branchValue);
                // Handle the response
                if (response.data.Emsg === 'Success') {
                    // setCheckPhoneNum(response.data.Emsg);
                    console.log(response.data.targetPath);
                    setTaregtPath(response.data.targetPath);
                    console.log(file);
                    console.log(response.data.targetPath);

                    // file_name:
                    // "wpforms-15001-Omra-Trip-2023-ID-15001-2023-11-08-08-54-33.xlsx"
                    // targetPath:
                    // "https://sgheg.com/Sadm25/api/upload_mena/20231111_104209_886779.xlsx"

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }


    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [message, setMessage] = useState('');

    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();
        // Append data to the FormData object

        // bodyFormData.append('wpforms[id]', '12801');
        // bodyFormData.append('wpforms[author]', '1');
        // bodyFormData.append('wpforms[post_id]', '12837');
        bodyFormData.append('branch', branchValue);
        bodyFormData.append('category', categoryValue);
        if (htmlPackage === null) {
            bodyFormData.append('department', 2);
            bodyFormData.append('position', 5);
        }
        else {
            bodyFormData.append('department', departmentValue);
            bodyFormData.append('position', positionValue);

        }
        bodyFormData.append('experienceDate', experienceValue);
        bodyFormData.append('qualification', qualificationValue);
        bodyFormData.append('QualificationsDate', dateValue);
        bodyFormData.append('name', nameValue);
        bodyFormData.append('address', addressValue);
        bodyFormData.append('birthDate', birthValue);
        bodyFormData.append('currentPosition', currentPosValue);
        bodyFormData.append('companyName', companyValue);
        bodyFormData.append('salary', salaryValue);
        bodyFormData.append('email', emailValue);
        bodyFormData.append('phone', phoneValue);
        bodyFormData.append('whatsapp', whatsappValue);
        bodyFormData.append('nationalId', idValue);
        bodyFormData.append('question', questionValue);
        bodyFormData.append('cv', file);


        const headers = {
            'fingerprint': localStorage.getItem('fingerPrint'),
            'lang': Cookies.get('i18next')
        }

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/join-us`, bodyFormData, { headers })
            .then(response => {
                // console.log(branchValue);
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    console.log(response.data);
                    // handleShow();

                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg(t('Form Submitted Successfully!'))
                        setMessage(t('Thank you for reaching out to us. Your message has been received, and we will get back to you as soon as possible.'))

                    } else {
                        setSubmitMsg('Error !! please Check Your Data')

                    }
                    // console.log(response.data.targetPath);
                    // file_name:
                    // "wpforms-15001-Omra-Trip-2023-ID-15001-2023-11-08-08-54-33.xlsx"
                    // targetPath:
                    // "https://sgheg.com/Sadm25/api/upload_mena/20231111_104209_886779.xlsx"
                    handleShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])

    //  all values

    const [nameValue, setNameValue] = useState();
    const [emailValue, setEmailValue] = useState();
    const [salaryValue, setSalaryValue] = useState();
    const [addressValue, setAddressValue] = useState();
    const [dateValue, setDateValue] = useState();
    const [birthValue, setBirthValue] = useState();
    const [currentPosValue, setCurrentPosValue] = useState();
    const [experienceValue, setExperienceValue] = useState();
    const [qualificationValue, setQualificationValue] = useState();

    const [companyValue, setCompanyValue] = useState();
    const [phoneValue, setPhoneValue] = useState();
    const [whatsappValue, setWhatsappValue] = useState();
    const [questionValue, setQuestionValue] = useState();
    const [idValue, setIdValue] = useState();

    useEffect(() => {

        getAllBranch();

    }, [])
    useEffect(() => {
        console.log('categoryValue ' + categoryValue);

        if (categoryValue) {
            getAllDepartment();
        }
    }, [categoryValue])

    useEffect(() => {
        if (branchValue) {
            getAllCategory();
        }

    }, [branchValue])
    useEffect(() => {
        if (departmentValue) {
            getAllPosition();
        }

    }, [departmentValue])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    // useEffect(() => {
    //     if (file) {
    //         sendUploadedFile();
    //     }

    // }, [file])
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Join')} anotherTitle={t('Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/join-us`} currentPage={t('Join Us')} /> : <PageTitle title={t('Join Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/join-us`} currentPage={t('Join Us')} />}
        {/* <!--page title end--> */}
        <div class="page-content">

            {/* <!--blog start--> */}

            <section>
                <div class="container">
                    <div class="row">
                        <div class="box-shadow p-5">
                            <div class="post-comments">

                                <form method="post" onSubmit={submitForm} >
                                    <div class="messages"></div>
                                    <div class="row justify-content-between">
                                        <div class="col-md-7">
                                            <div className='row'>
                                                <div class="form-group col-md-6">
                                                    <select class="form-select form-control" value={branchValue} onChange={(e) => setBranchValue(e.target.value)} required data-error="Branch is required.">
                                                        <option value="">{t('Select Branch')}</option>

                                                        {allBranches.map(branch => <>
                                                            <option value={branch.id}>{branch.name}</option>
                                                        </>)}

                                                    </select>
                                                    <div class="help-block with-errors"></div>

                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select class="form-select form-control" value={categoryValue} onChange={(e) => setCategoryValue(e.target.value)} required data-error="Category is required.">
                                                        {/* <option value=''>-- Select Category</option> */}
                                                        <option value="">{t('Select Category')}</option>

                                                        {allCategories.map(category => <>
                                                            <option value={category.id}>{category.name}</option>
                                                        </>)}

                                                    </select>
                                                    <div class="help-block with-errors"></div>

                                                </div>
                                                {htmlPackage === null ? '' : <div class="form-group col-md-6">
                                                    <select class="form-select form-control" value={departmentValue} onChange={(e) => setDepartmentValue(e.target.value)} required data-error="Department is required.">
                                                        <option value="">{t('Select Department')}</option>

                                                        {allDepartments.map(department => <>
                                                            <option value={department.id}>{department.name}</option>
                                                        </>)}

                                                    </select>
                                                    <div class="help-block with-errors"></div>

                                                </div>}
                                                {htmlPackage === null ? '' : <div class="form-group col-md-6">
                                                    <select class="form-select form-control" value={positionValue} onChange={(e) => setPositionValue(e.target.value)} required data-error="position is required.">
                                                        <option value="">{t('Select Position')}</option>

                                                        {allPositions.map(position => <>
                                                            <option value={position.id}>{position.name}</option>

                                                        </>)}

                                                    </select>
                                                    <div class="help-block with-errors"></div>

                                                </div>}
                                                <div class="form-group col-md-6">
                                                    <input type="number" name="experience" pattern="\d*" class="form-control" value={experienceValue} onChange={(e) => setExperienceValue(e.target.value)} placeholder={t("Years Of experience")} data-error="Years OF Experienceis required."
                                                        required="required" />
                                                    <div class="help-block with-errors"></div>

                                                </div>

                                                <div class="form-group col-md-6">
                                                    <select class="form-select form-control" value={qualificationValue} onChange={(e) => setQualificationValue(e.target.value)} required data-error="Qualifications is required.">
                                                        <option value="">{t('Select Qualification')}</option>
                                                        <option value='PhD / MD'>{t('PhD / MD')}</option>
                                                        <option value='Master'>{t('Master')}</option>
                                                        <option value='Bachelor'>{('Bachelors')}</option>
                                                        <option value='High Diploma'>{t('High Diploma')}</option>
                                                        <option value='Diplome'>{t('Diplome')}</option>
                                                        <option value='Other'>{t('Other')}</option>

                                                    </select>
                                                    <div class="help-block with-errors"></div>

                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label class="col-form-label small pt-3">{t('Date of Qualification')}</label>
                                                </div>
                                                <div class="form-group col-md-3">

                                                    <input
                                                        type="date"
                                                        name="date"
                                                        className="form-control"
                                                        defaultValue={dateValue}
                                                        onChange={(e) => setDateValue(e.target.value)}
                                                        placeholder="Date of Qualification"
                                                        required
                                                        data-error="Date OF Qualifications is required."
                                                    />

                                                    <div class="help-block with-errors"></div>

                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label class="col-form-label small pt-3">{t('Date of Birth')}</label>
                                                </div>
                                                <div class="form-group col-md-3">


                                                    <input type="date" name="dateofbirth" class="form-control" value={birthValue} onChange={(e) => setBirthValue(e.target.value)} placeholder="Date of Birth" data-error="Date of Birth is required."
                                                        required="required" />
                                                    <div class="help-block with-errors"></div>

                                                </div>
                                                <div class="form-group col-md-6">
                                                    <input id="form_name" type="text" name="name" class="form-control" placeholder={t("Full Name")} required="required" value={nameValue} onChange={(e) => setNameValue(e.target.value)} data-error="Full Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <input id="form_address" type="text" name="address" class="form-control" placeholder={t("Address")} required="required" value={addressValue} onChange={(e) => setAddressValue(e.target.value)} data-error="Address is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>


                                                <div class="form-group mb-lg-0 col-md-6">
                                                    <input id="form_position" type="text" name="currentPosition" class="form-control" value={currentPosValue} onChange={(e) => setCurrentPosValue(e.target.value)} placeholder={t("Current Position")} data-error="Current Position is required." />
                                                    <div class="help-block with-errors"></div>

                                                </div>
                                                <div class="form-group mb-lg-0 col-md-6">
                                                    <input id="form_Company" type="text" name="company" class="form-control" value={companyValue} onChange={(e) => setCompanyValue(e.target.value)} placeholder={t("Company Name")} data-error="Company Name is required." />
                                                    <div class="help-block with-errors"></div>

                                                </div>
                                                <div class="form-group col-md-6">
                                                    <input type="number" name="salary" class="form-control" placeholder={t("Current Salary")}
                                                        required="required" value={salaryValue} onChange={(e) => setSalaryValue(e.target.value)} data-error="Current Sallary is required." pattern="\d*" />
                                                    <div class="help-block with-errors"></div>

                                                </div>

                                                <div class="form-group col-md-6">
                                                    <input type="email" name="email" class="form-control" placeholder={t("Email")} value={emailValue} onChange={(e) => setEmailValue(e.target.value)} required="required" data-error="Valid email is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <input type="text" name="phone" value={phoneValue} onChange={(e) => setPhoneValue(e.target.value)} class="form-control" placeholder={t("Phone Number")}
                                                        required="required" data-error="Phone Number is required." data-rule-smart-phone-field="true" autoComplete='off' />
                                                    <div class="help-block with-errors"></div>

                                                </div>
                                                <div class="form-group col-md-6">
                                                    <input type="text" name="whatsapp" value={whatsappValue} onChange={(e) => setWhatsappValue(e.target.value)} class="form-control" placeholder={t("WhatsApp Number")}
                                                        required="required" data-error="WhatsApp Number is required." data-rule-smart-phone-field="true" autoComplete='off' />
                                                    <div class="help-block with-errors"></div>

                                                </div>


                                                <div class="form-group col-md-6">
                                                    <input type="number" name="id" value={idValue} onChange={(e) => setIdValue(e.target.value)} class="form-control" placeholder={t("National ID")}
                                                        required="required" data-error="National ID is required." pattern="\d*" minlength="14" maxLength="14" />
                                                    <div class="help-block with-errors"></div>

                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select class="form-select form-control" value={questionValue} onChange={(e) => setQuestionValue(e.target.value)} required data-error="This Field is required.">
                                                        <option value="">{t('How did you hear about us')}</option>
                                                        <option value='Company website'>{t('Company website')}</option>
                                                        <option value='LinkedIn'>{t('LinkedIn')}</option>
                                                        <option value='Facebook'>{t('Facebook')}</option>
                                                        <option value='Instagram'>{t('Instagram')}</option>
                                                        <option value='Wuzzuf'>{t('Wuzzuf')}</option>
                                                        <option value='Recommendation by Friend'>{t('Recommendation by Friend')}</option>
                                                        <option value='Other'>{t('Other')}</option>
                                                    </select>
                                                    <div class="help-block with-errors"></div>

                                                </div>



                                                <div class="form-group mb-0 col-md-6">
                                                    <input type='file' id="form_message" name="file" value={fileValue} onChange={handleFileUpload} class="form-control" placeholder={t("Upload your CV or Resume")} rows="4" required="required" data-error="Please,Upload Your CV." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                                <div class="col-md-6">
                                                    <button class="btn btn-border btn-circle w-100" type='submit'><span>{t('Submit')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {htmlPackage === null || htmlPackage.length <= 0 ?

                                            <>
                                                <div class="col-md-4 footer-list h-100 mt-3 mt-md-0 py-5 px-3 theme-bg">

                                                    <ul class="list-unstyled pb-5 px-3">
                                                        <strong className='text-light fs-6'>{t('Competitive Package')}</strong>
                                                        <li className='text-white'>{t('Attractive Salary')}
                                                        </li>
                                                        <li className='text-white'>{t('Monthly Bonus')}
                                                        </li>
                                                        <li className='text-white'>{t('Annual Increment')}
                                                        </li>
                                                        <li className='text-white'>{t('Attractive Benefits')}
                                                        </li>
                                                        <li className='text-white'>{t('Medical Insurance')}
                                                        </li>
                                                        <li className='text-white'>{t('Social Insurance')}
                                                        </li><li className='text-white'>{t('Career Path Program')}
                                                        </li><li className='text-white'>{t('Training Program')}
                                                        </li><li className='text-white'>{t('Paid Scientific Leave')}
                                                        </li><li className='text-white'>{t('Free Transportation')}
                                                        </li>

                                                    </ul>



                                                </div>
                                            </> : <div class="col-md-4 footer-list h-100 mt-3 mt-md-0 py-5 px-3 theme-bg">
                                                <div className='fs-4 html-Package' dangerouslySetInnerHTML={{ __html: htmlPackage }}></div>
                                            </div>}
                                    </div>

                                </form>

                                <div class="col-sm-12 my-5 text-center">



                                    <Modal   {...props}
                                        size="lg"
                                        style={customStyles}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered show={show} onHide={handleClose} className="top-0 ">

                                        <Modal.Header closeButton>
                                            <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                            <Modal.Title style={{ color: "#4ab242" }}>{t('Join Us')}</Modal.Title>
                                        </Modal.Header>

                                        <Modal.Body>
                                            <div className="team-member text-center">
                                                {/* <div className="team-images">
                                                    <img className="img-fluid" src="images/team/04.jpg" alt="" />
                                                </div> */}
                                                <div className="team-description pt-0">
                                                    <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                    <p className='lead'>{message}</p>
                                                </div>



                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                {t('Close')}
                                            </Button>

                                        </Modal.Footer>

                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </div >


    </>
}
