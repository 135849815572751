import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { GetDepartmentsOptionsContext } from '../../Context/GetDepartmentsOptions';

export default function RadiologyServicesSatisfactionSurvey(props) {

    const { pathname } = useLocation();
    const navigate = useNavigate();


    const [department, setDepartment] = useState('');
    const [question_1, setQuestion_1] = useState('');
    const [question_2, setQuestion_2] = useState('');
    const [question_3, setQuestion_3] = useState('');
    const [question_4, setQuestion_4] = useState('');
    const [question_5, setQuestion_5] = useState('');
    const [question_6, setQuestion_6] = useState('');
    const [question_7, setQuestion_7] = useState('');
    const [question_8, setQuestion_8] = useState('');
    const [question_9, setQuestion_9] = useState('');
    const [question_10, setQuestion_10] = useState('');
    const [question_11, setQuestion_11] = useState('');
    const [question_12, setQuestion_12] = useState('');
    const [question_13, setQuestion_13] = useState('');
    const [question_14, setQuestion_14] = useState('');
    const [question_15, setQuestion_15] = useState('');
    const [question_16, setQuestion_16] = useState('');
    const [question_17, setQuestion_17] = useState('');
    const [question_18, setQuestion_18] = useState('');
    const [question_19, setQuestion_19] = useState('');
    const [question_20, setQuestion_20] = useState('');
    const [question_21, setQuestion_21] = useState('');
    const [question_22, setQuestion_22] = useState('');
    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    // const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    const validateForm = () => {
        const errors = [];

        // if (department.trim() === '') {
        //     errors.push('Please Enter Your Department.');
        // }


        if (!question_1) {
            errors.push('This Field Has been Required.');
        }
        if (!question_2) {
            errors.push('This Field Has been Required.');
        }
        if (!question_3) {
            errors.push('This Field Has been Required.');
        }
        if (!question_4) {
            errors.push('This Field Has been Required.');
        }

        if (!question_5) {
            errors.push('This Field Has been Required.');
        }
        if (!question_6) {
            errors.push('This Field Has been Required.');
        }
        if (!question_7) {
            errors.push('This Field Has been Required.');
        }
        if (!question_8) {
            errors.push('This Field Has been Required.');
        }
        if (!question_9) {
            errors.push('This Field Has been Required.');
        }
        if (!question_10) {
            errors.push('This Field Has been Required.');
        }
        if (!question_11) {
            errors.push('This Field Has been Required.');
        }
        if (!question_12) {
            errors.push('This Field Has been Required.');
        }
        if (!question_13) {
            errors.push('This Field Has been Required.');
        }
        if (!question_14) {
            errors.push('This Field Has been Required.');
        }
        if (!question_15) {
            errors.push('This Field Has been Required.');
        }
        if (!question_16) {
            errors.push('This Field Has been Required.');
        }
        if (!question_17) {
            errors.push('This Field Has been Required.');
        }
        if (!question_18) {
            errors.push('This Field Has been Required.');
        }
        if (!question_19) {
            errors.push('This Field Has been Required.');
        }
        if (!question_20) {
            errors.push('This Field Has been Required.');
        }
        if (!question_21) {
            errors.push('This Field Has been Required.');
        }
        if (!question_22) {
            errors.push('This Field Has been Required.');
        }

        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append('department', department);
            bodyFormData.append('Do you always refer patients to our radiology department?', question_1);
            bodyFormData.append('If you don’t refer some patients or investigations to us, is there a specific reason why?', question_2);
            bodyFormData.append('Please estimate the percentage of patient referrals you refer to our radiology department', question_3);
            bodyFormData.append('Has your referral pattern to our radiology changed in the past year?', question_4);
            bodyFormData.append('If you have changed your referral patterns to our outpatient facilities, please briefly tell us why.', question_5);
            bodyFormData.append('Please rate our imaging services compared to other imaging facilities', question_6);
            bodyFormData.append('Do you find that patients are choosing their own radiology center?', question_7);
            bodyFormData.append('Who typically schedules imaging services for you?', question_8);
            bodyFormData.append('What hours of operation are most convenient for your patients?', question_9);
            bodyFormData.append('Would your office be interested in the option of requesting appointments online?', question_10);
            bodyFormData.append('If yes, please provide an email address:', question_11);
            bodyFormData.append('If you currently refer to our practice, please rate Radiology department SGH –Cairo services in the following areas: Our ability to offer your patient a timely appointment', question_12);
            bodyFormData.append('Our willingness to see urgent cases on short notice', question_13);
            bodyFormData.append('The courtesy and responsiveness of our staff', question_14);
            bodyFormData.append('The timeliness of receiving patient reports in critical results cases.', question_15);
            bodyFormData.append('The timeliness of receiving patient reports in STAT cases.', question_16);
            bodyFormData.append('The timeliness of receiving patient reports in routine OPD cases.', question_17);
            bodyFormData.append('Please rate our PACS services?', question_18);
            bodyFormData.append('Please rate the matter of accessibility and communication with radiology department staff (technicians, radiology specialists, radiology consultants… Others)?', question_19);
            bodyFormData.append("Your patient’s comments about our practice", question_20);
            bodyFormData.append('Hours of operation', question_21);
            bodyFormData.append('If you have indicated a fair or poor rating, please tell us why:', question_22);


            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/RadiologyServicesSatisfactionSurvey`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Thank you for Registration!');
                        // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');
                        setQuestion_1('');
                        setQuestion_2('');
                        setQuestion_3('');
                        setQuestion_4('');
                        setQuestion_5('');
                        setQuestion_6('');
                        setQuestion_7('');
                        setQuestion_8('');
                        setQuestion_9('');
                        setQuestion_10('');
                        setQuestion_11('');
                        setQuestion_12('');
                        setQuestion_13('');
                        setQuestion_14('');
                        setQuestion_15('');
                        setQuestion_16('');
                        setQuestion_17('');
                        setQuestion_18('');
                        setQuestion_19('');
                        setQuestion_20('');
                        setQuestion_21('');
                        setQuestion_22('');


                        setDepartment('');


                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])


    // get departments
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    useEffect(() => {
        GetDepartmentsSelectOptions('1');

    }, [])
    return <>
        <PageTitle title='Radiology Services' anotherTitle='Satisfaction Survey' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/radiology-services-satisfaction-survey`} currentPage='Radiology Services Satisfaction Survey' />
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='p-0'>
                <div className="container">
                    <div className="row">

                        <div className="post-comments">
                            <div className='row justify-content-center'>
                                <div className="section-title text-center mb-0">
                                    <h6 className="title my-3 text-center">WELCOME <span>Radiology Services Satisfaction Survey</span></h6>
                                    <p className='text-center'>
                                        By completing this survey, you can help Radiology & Nuclear Medicine Departments focus its efforts and resources effectively while recognizing and prioritizing your needs. This survey is confidential and is for services specifically provided at SGH-Cairo. Thank you, in advance, for your contribution to this survey.<br />
                                        We look forward to providing you with continued and improved imaging services.
                                        <br />  Your support is appreciated.

                                    </p>
                                </div>
                            </div>



                            <form onSubmit={submitForm}>
                                <div className="messages"></div>
                                <div className="row justify-content-center">
                                    <div className="col-md-7 p-5 order-3 box-shadow">

                                        <div className="row">

                                            <div className="form-group col-md-5">
                                                <input className='form-control' placeholder='Please indicate your department' value={department} onChange={(e) => setDepartment(e.target.value)} />

                                            </div>

                                            <div className="row">


                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Do you always refer patients to our radiology department?</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques1-1'
                                                        value="Yes"
                                                        checked={question_1 === 'Yes'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-1'> Yes</label>

                                                    <input className='ms-3 mx-2' id='ques1-2'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_1 === 'No'}
                                                        onChange={(e) => setQuestion_1(e.target.value)}
                                                    />
                                                    <label htmlFor='ques1-2'>No</label>

                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>


                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>If you don’t refer some patients or investigations to us, is there a specific reason why?</label>

                                                    <input
                                                        type="text" className='mx-2 form-control' id='ques2-1'
                                                        value={question_2}

                                                        onChange={(e) => setQuestion_2(e.target.value)}
                                                    />

                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Please estimate the percentage of patient referrals you refer to our radiology department</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques3-1'
                                                        value="<20%"
                                                        checked={question_3 === '<20%'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-1'> &lt;20%</label>

                                                    <input className='ms-3 mx-2' id='ques3-2'
                                                        type="radio"
                                                        value="21-40%"
                                                        checked={question_3 === '21-40%'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-2'>21-40%</label>

                                                    <input className='ms-3 mx-2' id='ques3-3'
                                                        type="radio"
                                                        value="41-60%"
                                                        checked={question_3 === '41-60%'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-3'>41-60%</label>

                                                    <input className='ms-3 mx-2' id='ques3-4'
                                                        type="radio"
                                                        value="61-80%"
                                                        checked={question_3 === '61-80%'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-4'>61-80%</label>

                                                    <input className='ms-3 mx-2' id='ques3-5'
                                                        type="radio"
                                                        value="81-100%"
                                                        checked={question_3 === '81-100%'}
                                                        onChange={(e) => setQuestion_3(e.target.value)}
                                                    />
                                                    <label htmlFor='ques3-5'>81-100%</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>



                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Has your referral pattern to our radiology changed in the past year? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques4-1'
                                                        value="No, it has stayed the same"
                                                        checked={question_4 === 'No, it has stayed the same'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-1'>No, it has stayed the same</label>

                                                    <input className='ms-3 mx-2' id='ques4-2'
                                                        type="radio"
                                                        value="I send more patients"
                                                        checked={question_4 === 'I send more patients'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-2'>I send more patients</label>

                                                    <input className='ms-3 mx-2' id='ques4-3'
                                                        type="radio"
                                                        value="I send fewer patients"
                                                        checked={question_4 === 'I send fewer patients'}
                                                        onChange={(e) => setQuestion_4(e.target.value)}
                                                    />
                                                    <label htmlFor='ques4-3'>I send fewer patients</label>



                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>


                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>If you have changed your referral patterns to our outpatient facilities, please briefly tell us why.</label>

                                                    <input
                                                        type="text" className='mx-2 form-control' id='ques5-1'
                                                        value={question_5}
                                                        onChange={(e) => setQuestion_5(e.target.value)}
                                                    />

                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Please rate our imaging services compared to other imaging facilities </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques6-1'
                                                        value="Excellent"
                                                        checked={question_6 === 'Excellent'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-1'> Excellent</label>

                                                    <input className='ms-3 mx-2' id='ques6-2'
                                                        type="radio"
                                                        value="Good"
                                                        checked={question_6 === 'Good'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-2'>Good</label>
                                                    <input className='ms-3 mx-2' id='ques6-3'
                                                        type="radio"
                                                        value="Fair"
                                                        checked={question_6 === 'Fair'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-3'> Fair</label>
                                                    <input className='ms-3 mx-2' id='ques6-4'
                                                        type="radio"
                                                        value="Poor"
                                                        checked={question_6 === 'Poor'}
                                                        onChange={(e) => setQuestion_6(e.target.value)}
                                                    />
                                                    <label htmlFor='ques6-4'> Poor</label>

                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Do you find that patients are choosing their own radiology center? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques7-1'
                                                        value="Yes"
                                                        checked={question_7 === 'Yes'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-1'> Yes</label>

                                                    <input className='ms-3 mx-2' id='ques7-2'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_7 === 'No'}
                                                        onChange={(e) => setQuestion_7(e.target.value)}
                                                    />
                                                    <label htmlFor='ques7-2'>No</label>

                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Who typically schedules imaging services for you?</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques8-1'
                                                        value="Patients"
                                                        checked={question_8 === 'Patients'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-1'> Patients</label>

                                                    <input className='ms-3 mx-2' id='ques8-2'
                                                        type="radio"
                                                        value="Physician"
                                                        checked={question_8 === 'Physician'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-2'>Physician</label>
                                                    <input className='ms-3 mx-2' id='ques8-3'
                                                        type="radio"
                                                        value="Office Staf"
                                                        checked={question_8 === 'Office Staf'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-3'> Office Staf</label>
                                                    <input className='ms-3 mx-2' id='ques8-4'
                                                        type="radio"
                                                        value="Other"
                                                        checked={question_8 === 'Other'}
                                                        onChange={(e) => setQuestion_8(e.target.value)}
                                                    />
                                                    <label htmlFor='ques8-4'> Other</label>

                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>


                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>What hours of operation are most convenient for your patients? </label>

                                                    <input
                                                        type="text" className='mx-2 form-control' id='ques9-1'
                                                        value={question_9}

                                                        onChange={(e) => setQuestion_9(e.target.value)}
                                                    />

                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Would your office be interested in the option of requesting appointments online? </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques10-1'
                                                        value="Yes"
                                                        checked={question_10 === 'Yes'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-1'> Yes</label>

                                                    <input className='ms-3 mx-2' id='ques10-2'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_10 === 'No'}
                                                        onChange={(e) => setQuestion_10(e.target.value)}
                                                    />
                                                    <label htmlFor='ques10-2'>No</label>

                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>
                                                {question_10 === 'Yes' && (
                                                    <div>
                                                        <label htmlFor="reason">If yes, please provide an email address:</label>
                                                        <input
                                                            type="email"
                                                            id="reason"
                                                            value={question_11}
                                                            onChange={(e) => setQuestion_11(e.target.value)}
                                                        />
                                                    </div>
                                                )}
                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>If you currently refer to our practice, please rate Radiology department SGH –Cairo services in the following areas: Our ability to offer your patient a timely appointment</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques12-1'
                                                        value="Excellent"
                                                        checked={question_12 === 'Excellent'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-1'> Excellent</label>

                                                    <input className='ms-3 mx-2' id='ques12-2'
                                                        type="radio"
                                                        value="Good"
                                                        checked={question_12 === 'Good'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-2'>Good</label>
                                                    <input className='ms-3 mx-2' id='ques12-3'
                                                        type="radio"
                                                        value="Fair"
                                                        checked={question_12 === 'Fair'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-3'> Fair</label>
                                                    <input className='ms-3 mx-2' id='ques12-4'
                                                        type="radio"
                                                        value="Poor"
                                                        checked={question_12 === 'Poor'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-4'> Poor</label>
                                                    <input className='ms-3 mx-2' id='ques12-5'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_12 === 'No'}
                                                        onChange={(e) => setQuestion_12(e.target.value)}
                                                    />
                                                    <label htmlFor='ques12-5'> No</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Our willingness to see urgent cases on short notice </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques13-1'
                                                        value="Excellent"
                                                        checked={question_13 === 'Excellent'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-1'> Excellent</label>

                                                    <input className='ms-3 mx-2' id='ques13-2'
                                                        type="radio"
                                                        value="Good"
                                                        checked={question_13 === 'Good'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-2'>Good</label>
                                                    <input className='ms-3 mx-2' id='ques13-3'
                                                        type="radio"
                                                        value="Fair"
                                                        checked={question_13 === 'Fair'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-3'> Fair</label>
                                                    <input className='ms-3 mx-2' id='ques13-4'
                                                        type="radio"
                                                        value="Poor"
                                                        checked={question_13 === 'Poor'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-4'> Poor</label>
                                                    <input className='ms-3 mx-2' id='ques13-5'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_13 === 'No'}
                                                        onChange={(e) => setQuestion_13(e.target.value)}
                                                    />
                                                    <label htmlFor='ques13-5'> No</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>The courtesy and responsiveness of our staff </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques14-1'
                                                        value="Excellent"
                                                        checked={question_14 === 'Excellent'}
                                                        onChange={(e) => setQuestion_14(e.target.value)}
                                                    />
                                                    <label htmlFor='ques14-1'> Excellent</label>

                                                    <input className='ms-3 mx-2' id='ques14-2'
                                                        type="radio"
                                                        value="Good"
                                                        checked={question_14 === 'Good'}
                                                        onChange={(e) => setQuestion_14(e.target.value)}
                                                    />
                                                    <label htmlFor='ques14-2'>Good</label>
                                                    <input className='ms-3 mx-2' id='ques14-3'
                                                        type="radio"
                                                        value="Fair"
                                                        checked={question_14 === 'Fair'}
                                                        onChange={(e) => setQuestion_14(e.target.value)}
                                                    />
                                                    <label htmlFor='ques14-3'> Fair</label>
                                                    <input className='ms-3 mx-2' id='ques14-4'
                                                        type="radio"
                                                        value="Poor"
                                                        checked={question_14 === 'Poor'}
                                                        onChange={(e) => setQuestion_14(e.target.value)}
                                                    />
                                                    <label htmlFor='ques14-4'> Poor</label>
                                                    <input className='ms-3 mx-2' id='ques14-5'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_14 === 'No'}
                                                        onChange={(e) => setQuestion_14(e.target.value)}
                                                    />
                                                    <label htmlFor='ques14-5'> No</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>The timeliness of receiving patient reports in critical results cases. </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques15-1'
                                                        value="Excellent"
                                                        checked={question_15 === 'Excellent'}
                                                        onChange={(e) => setQuestion_15(e.target.value)}
                                                    />
                                                    <label htmlFor='ques15-1'> Excellent</label>

                                                    <input className='ms-3 mx-2' id='ques15-2'
                                                        type="radio"
                                                        value="Good"
                                                        checked={question_15 === 'Good'}
                                                        onChange={(e) => setQuestion_15(e.target.value)}
                                                    />
                                                    <label htmlFor='ques15-2'>Good</label>
                                                    <input className='ms-3 mx-2' id='ques15-3'
                                                        type="radio"
                                                        value="Fair"
                                                        checked={question_15 === 'Fair'}
                                                        onChange={(e) => setQuestion_15(e.target.value)}
                                                    />
                                                    <label htmlFor='ques15-3'> Fair</label>
                                                    <input className='ms-3 mx-2' id='ques15-4'
                                                        type="radio"
                                                        value="Poor"
                                                        checked={question_15 === 'Poor'}
                                                        onChange={(e) => setQuestion_15(e.target.value)}
                                                    />
                                                    <label htmlFor='ques15-4'> Poor</label>
                                                    <input className='ms-3 mx-2' id='ques15-5'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_15 === 'No'}
                                                        onChange={(e) => setQuestion_15(e.target.value)}
                                                    />
                                                    <label htmlFor='ques15-5'> No</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>The timeliness of receiving patient reports in STAT cases. </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques16-1'
                                                        value="Excellent"
                                                        checked={question_16 === 'Excellent'}
                                                        onChange={(e) => setQuestion_16(e.target.value)}
                                                    />
                                                    <label htmlFor='ques16-1'> Excellent</label>

                                                    <input className='ms-3 mx-2' id='ques16-2'
                                                        type="radio"
                                                        value="Good"
                                                        checked={question_16 === 'Good'}
                                                        onChange={(e) => setQuestion_16(e.target.value)}
                                                    />
                                                    <label htmlFor='ques16-2'>Good</label>
                                                    <input className='ms-3 mx-2' id='ques16-3'
                                                        type="radio"
                                                        value="Fair"
                                                        checked={question_16 === 'Fair'}
                                                        onChange={(e) => setQuestion_16(e.target.value)}
                                                    />
                                                    <label htmlFor='ques16-3'> Fair</label>
                                                    <input className='ms-3 mx-2' id='ques16-4'
                                                        type="radio"
                                                        value="Poor"
                                                        checked={question_16 === 'Poor'}
                                                        onChange={(e) => setQuestion_16(e.target.value)}
                                                    />
                                                    <label htmlFor='ques16-4'> Poor</label>
                                                    <input className='ms-3 mx-2' id='ques16-5'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_16 === 'No'}
                                                        onChange={(e) => setQuestion_16(e.target.value)}
                                                    />
                                                    <label htmlFor='ques16-5'> No</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>The timeliness of receiving patient reports in routine OPD cases.</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input
                                                        type="radio" className='mx-2' id='ques17-1'
                                                        value="Excellent"
                                                        checked={question_17 === 'Excellent'}
                                                        onChange={(e) => setQuestion_17(e.target.value)}
                                                    />
                                                    <label htmlFor='ques17-1'> Excellent</label>

                                                    <input className='ms-3 mx-2' id='ques17-2'
                                                        type="radio"
                                                        value="Good"
                                                        checked={question_17 === 'Good'}
                                                        onChange={(e) => setQuestion_17(e.target.value)}
                                                    />
                                                    <label htmlFor='ques17-2'>Good</label>
                                                    <input className='ms-3 mx-2' id='ques17-3'
                                                        type="radio"
                                                        value="Fair"
                                                        checked={question_17 === 'Fair'}
                                                        onChange={(e) => setQuestion_17(e.target.value)}
                                                    />
                                                    <label htmlFor='ques17-3'> Fair</label>
                                                    <input className='ms-3 mx-2' id='ques17-4'
                                                        type="radio"
                                                        value="Poor"
                                                        checked={question_17 === 'Poor'}
                                                        onChange={(e) => setQuestion_17(e.target.value)}
                                                    />
                                                    <label htmlFor='ques17-4'> Poor</label>
                                                    <input className='ms-3 mx-2' id='ques17-5'
                                                        type="radio"
                                                        value="No"
                                                        checked={question_17 === 'No'}
                                                        onChange={(e) => setQuestion_17(e.target.value)}
                                                    />
                                                    <label htmlFor='ques17-5'> No</label>
                                                    {errors.includes('This Field Has been Required.') && (
                                                        <div className="ivaild-error">This Field Has been Required.</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Please rate our PACS services? </label>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <input
                                                    type="radio" className='mx-2' id='ques18-1'
                                                    value="Excellent"
                                                    checked={question_18 === 'Excellent'}
                                                    onChange={(e) => setQuestion_18(e.target.value)}
                                                />
                                                <label htmlFor='ques18-1'> Excellent</label>

                                                <input className='ms-3 mx-2' id='ques18-2'
                                                    type="radio"
                                                    value="Good"
                                                    checked={question_18 === 'Good'}
                                                    onChange={(e) => setQuestion_18(e.target.value)}
                                                />
                                                <label htmlFor='ques18-2'>Good</label>
                                                <input className='ms-3 mx-2' id='ques18-3'
                                                    type="radio"
                                                    value="Fair"
                                                    checked={question_18 === 'Fair'}
                                                    onChange={(e) => setQuestion_18(e.target.value)}
                                                />
                                                <label htmlFor='ques18-3'> Fair</label>
                                                <input className='ms-3 mx-2' id='ques18-4'
                                                    type="radio"
                                                    value="Poor"
                                                    checked={question_18 === 'Poor'}
                                                    onChange={(e) => setQuestion_18(e.target.value)}
                                                />
                                                <label htmlFor='ques18-4'> Poor</label>

                                                {errors.includes('This Field Has been Required.') && (
                                                    <div className="ivaild-error">This Field Has been Required.</div>
                                                )}
                                            </div>

                                            <div class="form-group col-md-12">
                                                <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Please rate the matter of accessibility and communication with radiology department staff (technicians, radiology specialists, radiology consultants… Others)?</label>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <input
                                                    type="radio" className='mx-2' id='ques19-1'
                                                    value="Excellent"
                                                    checked={question_19 === 'Excellent'}
                                                    onChange={(e) => setQuestion_19(e.target.value)}
                                                />
                                                <label htmlFor='ques19-1'> Excellent</label>

                                                <input className='ms-3 mx-2' id='ques19-2'
                                                    type="radio"
                                                    value="Good"
                                                    checked={question_19 === 'Good'}
                                                    onChange={(e) => setQuestion_19(e.target.value)}
                                                />
                                                <label htmlFor='ques19-2'>Good</label>
                                                <input className='ms-3 mx-2' id='ques19-3'
                                                    type="radio"
                                                    value="Fair"
                                                    checked={question_19 === 'Fair'}
                                                    onChange={(e) => setQuestion_19(e.target.value)}
                                                />
                                                <label htmlFor='ques19-3'> Fair</label>
                                                <input className='ms-3 mx-2' id='ques19-4'
                                                    type="radio"
                                                    value="Poor"
                                                    checked={question_19 === 'Poor'}
                                                    onChange={(e) => setQuestion_19(e.target.value)}
                                                />
                                                <label htmlFor='ques19-4'> Poor</label>

                                                {errors.includes('This Field Has been Required.') && (
                                                    <div className="ivaild-error">This Field Has been Required.</div>
                                                )}
                                            </div>

                                            <div class="form-group col-md-12">
                                                <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Your patient’s comments about our practice</label>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <input
                                                    type="radio" className='mx-2' id='ques20-1'
                                                    value="Excellent"
                                                    checked={question_20 === 'Excellent'}
                                                    onChange={(e) => setQuestion_20(e.target.value)}
                                                />
                                                <label htmlFor='ques20-1'> Excellent</label>

                                                <input className='ms-3 mx-2' id='ques20-2'
                                                    type="radio"
                                                    value="Good"
                                                    checked={question_20 === 'Good'}
                                                    onChange={(e) => setQuestion_20(e.target.value)}
                                                />
                                                <label htmlFor='ques20-2'>Good</label>
                                                <input className='ms-3 mx-2' id='ques20-3'
                                                    type="radio"
                                                    value="Fair"
                                                    checked={question_20 === 'Fair'}
                                                    onChange={(e) => setQuestion_20(e.target.value)}
                                                />
                                                <label htmlFor='ques20-3'> Fair</label>
                                                <input className='ms-3 mx-2' id='ques20-4'
                                                    type="radio"
                                                    value="Poor"
                                                    checked={question_20 === 'Poor'}
                                                    onChange={(e) => setQuestion_20(e.target.value)}
                                                />
                                                <label htmlFor='ques20-4'> Poor</label>
                                                <input className='ms-3 mx-2' id='ques20-5'
                                                    type="radio"
                                                    value="No"
                                                    checked={question_20 === 'No'}
                                                    onChange={(e) => setQuestion_20(e.target.value)}
                                                />
                                                <label htmlFor='ques20-5'> No</label>
                                                {errors.includes('This Field Has been Required.') && (
                                                    <div className="ivaild-error">This Field Has been Required.</div>
                                                )}
                                            </div>

                                            <div class="form-group col-md-12">
                                                <label className={`${errors.includes('This Field Has been Required.') ? 'is-invalid' : ''}`}>Hours of operation</label>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <input
                                                    type="radio" className='mx-2' id='ques21-1'
                                                    value="Excellent"
                                                    checked={question_21 === 'Excellent'}
                                                    onChange={(e) => setQuestion_21(e.target.value)}
                                                />
                                                <label htmlFor='ques21-1'> Excellent</label>

                                                <input className='ms-3 mx-2' id='ques21-2'
                                                    type="radio"
                                                    value="Good"
                                                    checked={question_21 === 'Good'}
                                                    onChange={(e) => setQuestion_21(e.target.value)}
                                                />
                                                <label htmlFor='ques21-2'>Good</label>
                                                <input className='ms-3 mx-2' id='ques21-3'
                                                    type="radio"
                                                    value="Fair"
                                                    checked={question_21 === 'Fair'}
                                                    onChange={(e) => setQuestion_21(e.target.value)}
                                                />
                                                <label htmlFor='ques21-3'> Fair</label>
                                                <input className='ms-3 mx-2' id='ques21-4'
                                                    type="radio"
                                                    value="Poor"
                                                    checked={question_21 === 'Poor'}
                                                    onChange={(e) => setQuestion_21(e.target.value)}
                                                />
                                                <label htmlFor='ques21-4'> Poor</label>
                                                <input className='ms-3 mx-2' id='ques21-5'
                                                    type="radio"
                                                    value="No"
                                                    checked={question_21 === 'No'}
                                                    onChange={(e) => setQuestion_21(e.target.value)}
                                                />
                                                <label htmlFor='ques21-5'> No</label>
                                                {errors.includes('This Field Has been Required.') && (
                                                    <div className="ivaild-error">This Field Has been Required.</div>
                                                )}
                                            </div>
                                            <div className="row">
                                                <div class="form-group col-md-12">
                                                    <label>If you have indicated a fair or poor rating, please tell us why:</label>
                                                    <textarea id="form_address" type="text" name="message" className='form-control' value={question_22} onChange={(e) => setQuestion_22(e.target.value)} >
                                                    </textarea>

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <button className="btn btn-border btn-circle w-100" type='submit'><span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/survey-form-online.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                                <img src="/images/forms/survey-form-online.jpg" alt="patient survey 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'contain', backgroundColor: '#61882b', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} />
                                            </div> */}
                                    <div class="col-sm-12 text-center">
                                        <Modal   {...props}
                                            size="lg"
                                            style={customStyles}
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered show={show} onHide={handleClose} className="top-0 ">

                                            <Modal.Header closeButton>
                                                <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <div className="team-member text-center">

                                                    <div className="team-description pt-0">
                                                        <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                        {/* <p className='lead'>{messageForm}</p> */}
                                                    </div>



                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>

                                            </Modal.Footer>

                                        </Modal>
                                    </div>
                                </div>

                            </form>

                        </div>

                    </div >
                </div >
            </section >
        </div >
    </>
}
