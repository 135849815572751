import axios from 'axios';
import { typeOf } from 'mathjs';
import React, { useState } from 'react'
// import '../../homeCareRegistration';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
export default function Registration(props) {
    const [currentStep, setCurrentStep] = useState(0);
    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    const progress = (value) => {
        document.getElementsByClassName('progress-bar')[0].style.width = `${value}%`;
    }
    const [progressWidth, setProgressWidth] = useState(0);
    const handleNextStep = () => {
        const formValidation = validateForm();

        if (currentStep < 2 && formValidation === true) {
            setCurrentStep(currentStep + 1);
            setProgressWidth((currentStep + 1) * 33.33);
        }
    };

    const handlePrevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
            setProgressWidth((currentStep - 1) * 33.33);
        }
    };



    let [errors, setErrors] = useState([]);
    let [message, setMessage] = useState('');
    let [name, setName] = useState('');
    let [pin, setPin] = useState('');
    let [email, setEmail] = useState('');
    let [phone, setPhone] = useState('');
    let [age, setAge] = useState(0);
    let [gender, setGender] = useState('');
    let [area, setArea] = useState('');
    let [nationalId, setNationalId] = useState('');
    let [locationDetaiils, setLocationDetaiils] = useState('');
    let [medicalStatus, setMedicalStatus] = useState('');
    let [image, setImage] = useState();
    let [medicalFiles, setMedicalFiles] = useState([]);
    let [services, setServices] = useState([]);
    const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    function validateForm() {
        const errors = [];
        const phoneNumberRegex = /^[\+]?[0-9]{0,3}\W?[()0-9]{0,3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

        if (name.trim === '') {
            errors.push('Name is required.');
        }

        // if (!email) {
        //     errors.push('Your Email is required.');
        // }
        // else if (!email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
        //     errors.push('Please enter a valid email Location Details. Expected format: example@example.com.');
        // }
        if (!phone) {
            errors.push('Your phone number is required.');
        }
        else if (!phoneNumberRegex.test(phone)) {
            errors.push('Please enter a valid phone number. Expected format: [+][Country Code][Area Code][Phone Number].');
        }

        if (!age) {
            errors.push('Age is required.');
        }
        if (!typeOf(age) === 'number') {
            errors.push('Age is Number.');
        }

        if (!gender) {
            errors.push('Gender is required.');
        }
        if (!area) {
            errors.push('Area is required.');
        }
        if (!locationDetaiils) {
            errors.push('Location Details is required.');
        }
        if (!nationalId) {
            errors.push('National ID is required.');
        }
        if (!services) {
            errors.push('services is required.');
        }
        if (!image) {
            errors.push('Your National ID is required.');
        }

        // if (!medicalStatus) {
        //     errors.push('Medical Status is required.');
        // }

        // if (!medicalFiles) {
        //     errors.push('Medical Files is required.');
        // }
        setErrors(errors);
        return errors;
    }
    async function handleSubmit(e) {
        e.preventDefault();
        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append('name', name);
            bodyFormData.append('pin', pin);
            bodyFormData.append('email', email);
            bodyFormData.append('phone', phone);
            bodyFormData.append('age', age);
            bodyFormData.append('gender', gender);
            bodyFormData.append('area_id', area);
            bodyFormData.append('location_details', locationDetaiils);
            bodyFormData.append('medical_status', medicalStatus);
            bodyFormData.append('identity_image', image);
            bodyFormData.append('medical_files', medicalFiles);
            bodyFormData.append('service_ids', services);

            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/homecare/register`, bodyFormData);
                if (response.data.Ecode === 200) {
                    // if (response.data.Edata.request_id > 0) {
                    setMessage('Thank you for your Registration!');
                    // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');

                } else {
                    setMessage('Error !! please Check Your Data');
                }
                handleShow();
            } catch (error) {
                console.error(error);
            }
        }
    }


    return <>


        <div class="container d-flex align-items-start min-vh-100">
            <div class="row g-0 justify-content-center">
                <div class="col-lg-4 offset-lg-1 mx-0 px-0">
                    <div id="title-container">
                        <img class="covid-image rounded-circle" src="./images/home-care/hand-holding-medical-icon-symbol-vector-healthcare-concept.jpg" />
                        <h2>Home Care</h2>
                        <h3>Registration</h3>
                        <p>Hospital-based home care services encompass a wide range of medical and non-medical interventions tailored to meet the unique needs of each patient.</p>
                    </div>
                </div>

                <div class="col-lg-7 mx-0 px-0" >
                    <div class="progress progress-homecare">
                        {/* <div className="progress"> */}
                        <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${progressWidth}%` }}></div>
                        {/* </div> */}
                        {/* <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="50" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: "0%" }}></div> */}
                    </div>
                    <div id="qbox-container">
                        <form class="needs-validation" id="form-wrapper" name="form-wrapper" onSubmit={handleSubmit}>
                            {/* <!-- STEPS HERE --> */}


                            <div id="steps-container">

                                <div className={currentStep === 0 ? 'row' : 'row step'}>

                                    <div className="col-md-12 mb-5">
                                        <h3 className='border-bottom pb-3' pb-3>Personal Information</h3>
                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Name</label>
                                        <input class={`form-registration ${errors.includes('Name is required.') ? 'is-invalid' : ''}`} value={name} onChange={(e) => setName(e.target.value)} data-error="Name is required." type="text" />
                                        {errors.includes('Name is required.') &&  <div className="ivaild-error">Name is required.</div>}
                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Pin Number</label>
                                        <input class={`form-registration ${errors.includes('Pin Number is required.') ? 'is-invalid' : ''}`} value={pin} onChange={(e) => setPin(e.target.value)} data-error="Pin Number is required." type="text" />
                                        {errors.includes('Pin Number is required.') &&  <div className="ivaild-error">Pin Number is required.</div>}

                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Phone</label>
                                        <input className={`form-registration ${errors.includes('Phone is required.') ? 'is-invalid' : ''}`} value={phone} onChange={(e) => setPhone(e.target.value)} data-error="Phone is required." type="text" />
                                        {errors.includes('Phone is required.') &&  <div className="ivaild-error">Phone is required.</div>}

                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Email</label>
                                        <input class={`form-registration ${errors.includes('Email is required.') ? 'is-invalid' : ''}`} value={email} onChange={(e) => setEmail(e.target.value)} data-error="Email is required." type="text" />
                                        {errors.includes('Email is required.') &&  <div className="ivaild-error">Email is required.</div>}

                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Age</label>
                                        <input class={`form-registration ${errors.includes('Age is required.') ? 'is-invalid' : ''}`} value={age} onChange={(e) => setAge(e.target.value)} data-error="Age is required." type="text" />
                                        {errors.includes('Age is required.') &&  <div className="ivaild-error">Age is required.</div>}

                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Gender</label>
                                        <input class={`form-registration ${errors.includes('Gender is required.') ? 'ivaild-error' : ''}`} value={gender} onChange={(e) => setGender(e.target.value)} data-error="Gender is required." type="text" />

                                        {errors.includes('Gender is required.') && (
                                            <div className="ivaild-error">Gender is required.</div>
                                        )}
                                    </div>
                                </div>

                                <div className={currentStep === 1 ? 'row' : 'row step'}>
                                    <div className="col-md-12 mb-5">
                                        <h3 className='border-bottom pb-3'><i class="flaticon-paper-plane me-2" aria-hidden="true"></i>
                                            Location</h3>
                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Area</label>
                                        <select class={`form-registration ${errors.includes('Area is required.') ? 'is-invalid' : ''}`} value={area} onChange={(e) => setArea(e.target.value)} data-error="Area is required." >
                                            <option value="">Select Area</option>
                                            <option value='1'>Cairo</option>
                                            <option value='2'>Alex</option>

                                        </select>
                                        {errors.includes('Area is required.') && <div className="ivaild-error">Area is required.</div>}

                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Location Details</label>
                                        <input class={`form-registration ${errors.includes('Location Details is required.') ? 'is-invalid' : ''}`} value={locationDetaiils} onChange={(e) => setLocationDetaiils(e.target.value)} data-error="Location Details is required." type="text" />
                                        {errors.includes('Location is required.') &&  <div className="ivaild-error">Location Details is required.</div>}

                                    </div>
                                    {/* <div class="form-check ps-0 q-box">
                                        <div class="q-box__question">
                                            <input class="form-check-input question__input" id="q_2_yes" name="q_2" type="radio" value="Yes" />
                                            <label class="form-check-label question__label" for="q_2_yes">Yes</label>
                                        </div>
                                        <div class="q-box__question">
                                            <input checked class="form-check-input question__input" id="q_2_no" name="q_2" type="radio" value="No" />
                                            <label class="form-check-label question__label" for="q_2_no">No</label>
                                        </div>
                                    </div> */}
                                </div>

                                <div className={currentStep === 2 ? 'row' : 'row step'}>
                                    <div className="col-md-12 mb-5">

                                        <h3 className='border-bottom pb-3' pb-3> <i className='flaticon-stethoscope me-2'></i>Medical Data</h3>
                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Upload Your National ID</label>
                                        <input class={`form-registration ${errors.includes('National ID is required.') ? 'is-invalid' : ''}`} value={nationalId} onChange={(e) => setNationalId(e.target.value)} data-error="National ID is required." type="file" />
                                        {errors.includes('National ID is required.') && <div className="ivaild-error">National ID is required.</div>}

                                    </div>
                                    <div className="col-md-10">
                                        <label class="form-label"> Medical Status</label>
                                        <input class={`form-registration ${errors.includes('Medical Status is required.') ? 'is-invalid' : ''}`} value={medicalStatus} onChange={(e) => setMedicalStatus(e.target.value)} data-error="Medical Status is required." type="text" />
                                        {errors.includes('Medical Status is required.') &&  <div className="ivaild-error">Medical Status is required.</div>}

                                    </div>
                                    <div className="col-md-10">
                                        <label for="imageUpload" class="form-label">Medical Files</label>
                                        <input class={`form-registration ${errors.includes('Medical Files is required.') ? 'is-invalid' : ''}`} value={medicalFiles} onChange={(e) => setMedicalFiles(e.target.value)} data-error="Medical Files is required." type="file" id="imageUpload" name="images" accept="image/*" multiple />
                                        <span className='small'> "Select multiple images"</span>
                                        {errors.includes('Medical Files is required.') &&  <div className="ivaild-error">Medical Files is required.</div>}

                                        {/* <button type="submit">Upload Images</button> */}
                                    </div>

                                    {/* <div className="col-md-10">
                                        <p>Location Details (IN Details)</p>
                                        <input class={`form-registration ${errors.includes('Gender is required.') ? 'is-invalid' : ''}`} value={gender} onChange={(e) => setGender(e.target.value)} data-error="Gender is required."  type="text" />
                                    </div> */}
                                </div>


                                <div id="success">
                                    <div class="mt-5">
                                        <h4>Success! We'll get back to you ASAP!</h4>
                                        <p>Meanwhile, clean your hands often, use soap and water, or an alcohol-based hand rub, maintain a safe distance from anyone who is coughing or sneezing and always wear a mask when physical distancing is not possible.</p>
                                        <a class="back-link" href="">Go back from the beginning ➜</a>
                                    </div>
                                </div>
                            </div>
                            <div id="q-box__buttons">
                                {currentStep !== 0 && (<button id="prev-btn" onClick={handlePrevStep}>Previous</button>)}
                                {currentStep !== 2 && (<button id="next-btn" onClick={handleNextStep}>Next</button>)}
                                {currentStep === 2 && (<button id="submit-btn" type='submit'>Submit</button>)}
                                {/* <button id="prev-btn" type="button">Previous</button>
                                            <button id="next-btn" type="button">Next</button>
                                            <button id="submit-btn" type="submit">Submit</button> */}
                            </div>



                        </form>
                    </div>
                    <div class="col-sm-12 text-center">
                        <Modal   {...props}
                            size="lg"
                            style={customStyles}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={show} onHide={handleClose} className="top-0 ">

                            <Modal.Header closeButton>
                                <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div className="team-member text-center">

                                    <div className="team-description pt-0">
                                        <h5 className="my-5"><Link>{message}</Link></h5>
                                        {/* <p className='lead'>{messageForm}</p> */}
                                    </div>



                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>

                            </Modal.Footer>

                        </Modal>
                    </div>
                </div>

            </div>
        </div>
    </>
}
