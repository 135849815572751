import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function SatisfactionSurvey(props) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [qualificationValue, setQualificationValue] = useState();
    const [age, setAge] = useState('');
    const [workDuration, setWorkDuration] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [gender, setGender] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [question6, setQuestion6] = useState('');
    const [question7, setQuestion7] = useState('');
    const [question8, setQuestion8] = useState('');
    const [question9, setQuestion9] = useState('');
    const [question10, setQuestion10] = useState('');
    const [question11, setQuestion11] = useState('');
    const [question12, setQuestion12] = useState('');
    const [question13, setQuestion13] = useState('');
    const [question14, setQuestion14] = useState('');
    const [question15, setQuestion15] = useState('');
    const [question16, setQuestion16] = useState('');
    const [question17, setQuestion17] = useState('');
    const [question18, setQuestion18] = useState('');
    const [question19, setQuestion19] = useState('');
    const [question20, setQuestion20] = useState('');
    const [question21, setQuestion21] = useState('');
    const [question22, setQuestion22] = useState('');
    const [question23, setQuestion23] = useState('');
    const [question25, setQuestion25] = useState('');
    const [question26, setQuestion26] = useState('');
    const [question27, setQuestion27] = useState('');
    const [question28, setQuestion28] = useState('');
    const [question29, setQuestion29] = useState('');
    const [question30, setQuestion30] = useState('');
    const [question31, setQuestion31] = useState('');
    const [question32, setQuestion32] = useState('');
    const [question33, setQuestion33] = useState('');
    const [errors, setErrors] = useState([]);
    const [registrationNumber, setRegistrationNumber] = useState('');

    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [message, setMessage] = useState('');
    const [agenda, setAgenda] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();


        bodyFormData.append('branch', branchValue);
        bodyFormData.append('category', categoryValue);
        bodyFormData.append('department', departmentValue);
        bodyFormData.append('age', age);
        bodyFormData.append('qualifications', qualificationValue);
        bodyFormData.append('work Duration', workDuration);
        bodyFormData.append('marital Status', maritalStatus);
        bodyFormData.append('gender', gender);
        bodyFormData.append('How satisfied are you working for SGHC ?', question1);
        bodyFormData.append('I would recommend the hospital as a good place to work', question2);
        bodyFormData.append('I would recommend the hospital as a good place for the patient', question3);
        bodyFormData.append("I'm aware of all the hospital's expansions and decisions", question4);
        bodyFormData.append("I agree with hospital's overall mission and aspiration", question5);
        bodyFormData.append('There is good communication between employees and managers in SGHC', question6);
        bodyFormData.append('My supervisor treats me fairly', question7);
        bodyFormData.append('My supervisor provides feedback on how well I am doing my job', question8);
        bodyFormData.append('My job gives me the opportunity to learn', question9);
        bodyFormData.append('How satisfied are you with your career progression at SGHC thus far', question10);
        bodyFormData.append('How satisfied are you with the process used to determine promotions', question11);
        bodyFormData.append('How satisfied are you with SGHC medical services for the staff?', question12);
        bodyFormData.append('I understand what is expected of me in my job and I am able to decide how to do my work.', question13);
        bodyFormData.append('I am given enough time to do what is expected of me in my job well and I have the materials, supplies, and equipment I need to do my work', question14);
        bodyFormData.append('How satisfied are you with SGHC staff clinic?', question15);
        bodyFormData.append('How satisfied are you with the cafeteria services?', question16);
        bodyFormData.append('How satisfied are you with the training courses?', question17);
        bodyFormData.append('How satisfied are you with the laundry services?', question18);
        bodyFormData.append('How satisfied are you with the transportation?', question19);
        bodyFormData.append('How satisfied are you with recreation activities ( trip-concerts-international days-offers-..)?', question20);
        bodyFormData.append('I am satisfied with house-keeping services in the administrative offices and in services places (WC, corridor ...etc.) in SGH Cairo?', question21);
        bodyFormData.append('I am satisfied with working environment in SGH Cairo', question22);
        bodyFormData.append('SGH Cairo takes effective action to prevent abuse in the workplace', question23);
        bodyFormData.append('I am able to balance my family and personal life with work', question25);
        bodyFormData.append('Are there any other comments you would like to add?', question26);
        bodyFormData.append('Please tell us what the hospital can do to increase your satisfaction?', question27);
        bodyFormData.append('What do you think would make Saudi German Saudi German hospital a better place to work?', question28);
        bodyFormData.append('How do you feel about the level of recognition and appreciation you receive for your work at the Saudi German hospital?', question29);
        bodyFormData.append('What additional wellness programs or activities would you like to see offered at the Saudi German hospital?', question30);
        bodyFormData.append('How can the Saudi German hospital improve its internal communication and overall awareness among employees?', question31);
        bodyFormData.append('What do you think the Saudi German hospital can do to attract and retain more talented employees?', question32);
        bodyFormData.append('How can the Saudi German hospital better support employee work-life balance and well-being?', question33);
        bodyFormData.append('registration Number', registrationNumber);


        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/satisfaction-survey`, bodyFormData)
            .then(response => {

                if (response.data.Ecode === 200) {
                    console.log(response.data);

                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Registration is Successful!')
                        setMessage("Thank you for registration")

                        // Set the generated registration number in the state
                        setRegistrationNumber(response.data.Edata.request_id);
                    } else {
                        setSubmitMsg('Error !! please Check Your Data ')

                    }

                    handleShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])

    // get branches
    let [allBranches, setAllBranches] = useState([]);
    async function getAllBranch() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('action', 'get_all_hospital');

        // const headers = {
        //     'fingerprint': localStorage.getItem('fingerPrint')
        // }

        const response = await axios.post(`https://my-health.sghcairo.net/hr/request45/jops/get_all_hospital`, bodyFormData)
            .then(response => {
                console.log(branchValue);
                // Handle the response
                // if (response.data.Emsg === 'Success') {
                // setCheckPhoneNum(response.data.Emsg);
                setAllBranches(response.data.Edata)

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    // get categories
    let [allCategories, setAllCategories] = useState([]);
    const [branchValue, setBranchValue] = useState();
    async function getAllCategory() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('action', 'get_all_category');
        bodyFormData.append('hospital_id', branchValue);
        // const headers = {
        //     'fingerprint': localStorage.getItem('fingerPrint')
        // }

        const response = await axios.post(`https://my-health.sghcairo.net/hr/request45/jops/get_all_category`, bodyFormData)
            .then(response => {
                console.log(branchValue);
                // Handle the response
                // if (response.data.Emsg === 'Success') {
                // setCheckPhoneNum(response.data.Emsg);
                setAllCategories(response.data.Edata)
                // console.log('categories ' + allCategories.map(cat => cat.name));

                // }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    // get departments
    const [categoryValue, setCategoryValue] = useState();
    const [allDepartments, setAllDepartments] = useState([]);
    const [htmlPackage, setHtmlPackage] = useState([]);
    const [departmentValue, setDepartmentValue] = useState();

    async function getAllDepartment() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('action', 'get_all_department');
        bodyFormData.append('hospital_id', branchValue);
        bodyFormData.append('category_id', categoryValue);

        // const headers = {
        //     'fingerprint': localStorage.getItem('fingerPrint')
        // }

        const response = await axios.post(`https://my-health.sghcairo.net/hr/request45/jops/get_all_department`, bodyFormData)
            .then(response => {
                // console.log(branchValue);
                // Handle the response
                // if (response.data.Emsg === 'Success') {
                // setCheckPhoneNum(response.data.Emsg);
                setAllDepartments(response.data.Edata)
                setHtmlPackage(response.data.EHtml)

                // console.log('categories ' + allCategories.map(cat => cat.name));

                // }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    useEffect(() => {

        getAllBranch();

    }, [])
    useEffect(() => {
        if (categoryValue) {
            getAllDepartment();
        }
    }, [categoryValue])

    useEffect(() => {
        if (branchValue) {
            getAllCategory();
        }

    }, [branchValue])
    return <>

        <PageTitle title='Satisfaction' anotherTitle='Survey' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/satisfaction-survey`} currentPage='Satisfaction Survey' />
        {/* <!--page title end--> */}
        <div className="page-content" style={{ backgroundImage: `url('/images/bg/events.jpeg')` }}>

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">

                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-12 p-5">
                                            <div className='row justify-content-center'>
                                                <div class="section-title mb-5">
                                                    <h2 class="title mb-3 text-center">Please, Fill out <span>the form</span></h2>


                                                </div>
                                                <div className="col-md-5">
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={branchValue} onChange={(e) => setBranchValue(e.target.value)} required data-error="Branch is required.">
                                                            <option value="">Select Branch</option>

                                                            {allBranches.map(branch => <>
                                                                <option value={branch.id}>{branch.name}</option>
                                                            </>)}

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={categoryValue} onChange={(e) => setCategoryValue(e.target.value)} required data-error="Category is required.">
                                                            {/* <option value=''>-- Select Category</option> */}
                                                            <option value="">Select Category</option>

                                                            {allCategories.map(category => <>
                                                                <option value={category.id}>{category.name}</option>
                                                            </>)}

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={departmentValue} onChange={(e) => setDepartmentValue(e.target.value)} required data-error="Department is required.">
                                                            <option value="">Select Department</option>

                                                            {allDepartments.map(department => <>
                                                                <option value={department.id}>{department.name}</option>
                                                            </>)}

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={qualificationValue} onChange={(e) => setQualificationValue(e.target.value)} required data-error="Qualifications is required.">
                                                            <option value="">Select Qualification</option>
                                                            <option value='PhD / MD'>PhD / MD</option>
                                                            <option value='Master'>Master</option>
                                                            <option value='Bachelor'>Bachelor</option>
                                                            <option value='High Diploma'>High Diploma</option>
                                                            <option value='Diplome'>Diplome</option>
                                                            <option value='Other'>Other</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={workDuration} onChange={(e) => setWorkDuration(e.target.value)} required data-error="Work Duration is required.">
                                                            <option value="">Select Work Duration</option>
                                                            <option value='Single '>Less than 3 M</option>
                                                            <option value='3 M : 1 Y'>3 M : 1 Y</option>
                                                            <option value='1 : 2 Y'>1 : 2 Y</option>
                                                            <option value='More than 2 Y'>More than 2 Y</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>



                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)} required data-error="Marital Status is required.">
                                                            <option value="">Select Marital Status</option>
                                                            <option value='Single'>Single</option>
                                                            <option value='Married and reliable'>Married and reliable</option>
                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={gender} onChange={(e) => setGender(e.target.value)} required data-error="Gender is required.">
                                                            <option value="">Select Gender </option>
                                                            <option value='Male'>Male</option>
                                                            <option value='Female'>Female</option>
                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={age} onChange={(e) => setAge(e.target.value)} required data-error="Age is required.">
                                                            <option value="">Select Age</option>
                                                            <option value='Less than 25'>Less than 25</option>
                                                            <option value='25 : 35'>25 : 35</option>
                                                            <option value='35 : 45'>35 : 45</option>
                                                            <option value='More than 45'>More than 45</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question11} onChange={(e) => setQuestion11(e.target.value)} required data-error="How satisfied are you with the process used to determine promotions is required.">
                                                            <option value="">How satisfied are you with the process used to determine promotions</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>

                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question6} onChange={(e) => setQuestion6(e.target.value)} required data-error="There is good communication between employees and managers in SGHC is required.">
                                                            <option value="">There is good communication between employees and managers in SGHC</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>




                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question2} onChange={(e) => setQuestion2(e.target.value)} required data-error="I would recommend the hospital as a good place to work is required.">
                                                            <option value="">I would recommend the hospital as a good place to work</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question1} onChange={(e) => setQuestion1(e.target.value)} required data-error="Work Duration is required.">
                                                            <option value="">How satisfied are you working for SGHC</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>
                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question3} onChange={(e) => setQuestion3(e.target.value)} required data-error="I would recommend the hospital as a good place for the patient is required.">
                                                            <option value="">I would recommend the hospital as a good place for the patient</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question4} onChange={(e) => setQuestion4(e.target.value)} required data-error="I'm aware of all the hospital's expansions and decisions is required.">
                                                            <option value="">I'm aware of all the hospital's expansions and decisions </option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>

                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question5} onChange={(e) => setQuestion5(e.target.value)} required data-error="I agree with hospital's overall mission and aspiration is required.">
                                                            <option value="">I agree with hospital's overall mission and aspiration</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question7} onChange={(e) => setQuestion7(e.target.value)} required data-error="My supervisor treats me fairly is required.">
                                                            <option value="">My supervisor treats me fairly</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question8} onChange={(e) => setQuestion8(e.target.value)} required data-error="My supervisor provides feedback on how well I am doing my job is required.">
                                                            <option value="">My supervisor provides feedback on how well I am doing my job </option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question9} onChange={(e) => setQuestion9(e.target.value)} required data-error="My job gives me the opportunity to learn is required.">
                                                            <option value="">My job gives me the opportunity to learn</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question10} onChange={(e) => setQuestion10(e.target.value)} required data-error="How satisfied are you with your career progression at SGHC thus far is required.">
                                                            <option value="">How satisfied are you with your career progression at SGHC thus far</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>

                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question12} onChange={(e) => setQuestion12(e.target.value)} required data-error="How satisfied are you with SGHC medical services for the staff? is required.">
                                                            <option value="">How satisfied are you with SGHC medical services for the staff?</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>

                                                </div>
                                                <div className="col-md-7">

                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question15} onChange={(e) => setQuestion15(e.target.value)} required data-error="How satisfied are you with SGHC staff clinic? is required.">
                                                            <option value="">How satisfied are you with SGHC staff clinic?</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>

                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question14} onChange={(e) => setQuestion14(e.target.value)} required data-error="I am given enough time to do what is expected of me in my job well and I have the materials, supplies, and equipment I need to do my work is required.">
                                                            <option value="">I am given enough time to do what is expected of me in my job well and I have the materials, supplies, and equipment I need to do my work</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question13} onChange={(e) => setQuestion13(e.target.value)} required data-error="I understand what is expected of me in my job and I am able to decide how to do my work is required.">
                                                            <option value="">I understand what is expected of me in my job and I am able to decide how to do my work.</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>

                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question16} onChange={(e) => setQuestion16(e.target.value)} required data-error="How satisfied are you with the cafeteria services? is required.">
                                                            <option value="">How satisfied are you with the cafeteria services?</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question17} onChange={(e) => setQuestion17(e.target.value)} required data-error="How satisfied are you with the training courses? is required.">
                                                            <option value="">How satisfied are you with the training courses?</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question18} onChange={(e) => setQuestion18(e.target.value)} required data-error="How satisfied are you with the laundry services? is required.">
                                                            <option value="">How satisfied are you with the laundry services? </option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question19} onChange={(e) => setQuestion19(e.target.value)} required data-error="How satisfied are you with the transportation? is required.">
                                                            <option value="">How satisfied are you with the transportation?</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question20} onChange={(e) => setQuestion20(e.target.value)} required data-error="How satisfied are you with recreation activities ( trip-concerts-international days-offers-..)?  is required.">
                                                            <option value="">How satisfied are you with recreation activities ( trip-concerts-international days-offers-..)? </option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question21} onChange={(e) => setQuestion21(e.target.value)} required data-error="I am satisfied with house-keeping services in the administrative offices and in services places (WC, corridor ...etc.) in SGH Cairo? is required.">
                                                            <option value="">I am satisfied with house-keeping services in the administrative offices and in services places (WC, corridor ...etc.) in SGH Cairo?</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question22} onChange={(e) => setQuestion22(e.target.value)} required data-error="I am satisfied with working environment in SGH Cairo is required.">
                                                            <option value="">I am satisfied with working environment in SGH Cairo</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>
                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question23} onChange={(e) => setQuestion23(e.target.value)} required data-error="SGH Cairo takes effective action to prevent abuse in the workplace is required.">
                                                            <option value="">SGH Cairo takes effective action to prevent abuse in the workplace</option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>

                                                    <div class="form-group">
                                                        <select class="form-select form-control" value={question25} onChange={(e) => setQuestion25(e.target.value)} required data-error="I am able to balance my family and personal life with work  is required.">
                                                            <option value="">I am able to balance my family and personal life with work </option>
                                                            <option value='Satisfied'>Satisfied</option>
                                                            <option value='Neutral'>Neutral</option>
                                                            <option value='Dissatisfied'>Dissatisfied</option>

                                                        </select>
                                                        <div class="help-block with-errors"></div>

                                                    </div>


                                                    <div className="form-group">
                                                        <input id="form_Company" type="text" name="question26" className="form-control" value={question26} onChange={(e) => setQuestion26(e.target.value)} placeholder="Are there any other comments you would like to add? " data-error="Are there any other comments you would like to add? is required." />
                                                        {errors.includes('Position is required') && <p>Position is required</p>}


                                                    </div>

                                                    <div className="form-group">
                                                        <input id="form_address" type="text" name="question28" className="form-control" placeholder="What do you think would make Saudi German Saudi German hospital a better place to work?" value={question28} onChange={(e) => setQuestion28(e.target.value)} data-error="What do you think would make Saudi German Saudi German hospital a better place to work? is required." />
                                                        {errors.includes('Course Name is required') && <p> Course Name is required</p>}

                                                    </div>
                                                    <div className="form-group">
                                                        <input id="form_address" type="text" name="question27" className="form-control" placeholder="Please tell us what the hospital can do to increase your satisfaction?" value={question27} onChange={(e) => setQuestion27(e.target.value)} data-error="Please tell us what the hospital can do to increase your satisfaction? is required." />
                                                        {errors.includes('Course Name is required') && <p> Course Name is required</p>}

                                                    </div>

                                                    <div className="form-group">
                                                        <input id="form_address" type="text" name="question30" className="form-control" placeholder="What additional wellness programs or activities would you like to see offered at the Saudi German hospital?" value={question30} onChange={(e) => setQuestion30(e.target.value)} data-error="What additional wellness programs or activities would you like to see offered at the Saudi German hospital? is required." />
                                                        {errors.includes('Course Name is required') && <p> Course Name is required</p>}

                                                    </div>
                                                    <div className="form-group">
                                                        <input id="form_address" type="text" name="question29" className="form-control" placeholder="How do you feel about the level of recognition and appreciation you receive for your work at the Saudi German hospital?" value={question29} onChange={(e) => setQuestion29(e.target.value)} data-error="How do you feel about the level of recognition and appreciation you receive for your work at the Saudi German hospital? is required." />
                                                        {errors.includes('Course Name is required') && <p> Course Name is required</p>}

                                                    </div>
                                                    <div className="form-group">
                                                        <input id="form_address" type="text" name="question31" className="form-control" placeholder="How can the Saudi German hospital improve its internal communication and overall awareness among employees?" value={question31} onChange={(e) => setQuestion31(e.target.value)} data-error="How can the Saudi German hospital improve its internal communication and overall awareness among employees? is required." />
                                                        {errors.includes('Course Name is required') && <p> Course Name is required</p>}

                                                    </div>
                                                    <div className="form-group">
                                                        <input id="form_address" type="text" name="question32" className="form-control" placeholder="What do you think the Saudi German hospital can do to attract and retain more talented employees?" value={question32} onChange={(e) => setQuestion32(e.target.value)} data-error="What do you think the Saudi German hospital can do to attract and retain more talented employees? is required." />
                                                        {errors.includes('Course Name is required') && <p> Course Name is required</p>}

                                                    </div>

                                                    <div className="form-group">
                                                        <input id="form_address" type="text" name="question33" className="form-control" placeholder="How can the Saudi German hospital better support employee work-life balance and well-being?" value={question33} onChange={(e) => setQuestion33(e.target.value)} data-error="How can the Saudi German hospital better support employee work-life balance and well-being? is required." />
                                                        {errors.includes('Course Name is required') && <p> Course Name is required</p>}

                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <button className="btn btn-border btn-circle w-100" type='submit' disabled={errors.length > 0}><span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            <p className='lead'>{message}</p>
                                                            <h5 className="my-5"><Link>Your Registration Number is : {registrationNumber}</Link></h5>
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
