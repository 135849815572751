import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import Pagination from 'react-js-pagination';
import data from '../../insurance-companies.json'
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';
export default function InsuranceCompanies() {
    const [selectedSector, setSelectedSector] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const itemsPerPage = 8; // Number of items to display per page

    // Get unique sector categories
    const sectorCategories = Array.from(new Set(data.map(company => company.Sector)));

    // Filter the data based on the selected sector and search query
    // const filteredData = data.filter(company => {
    //     if (selectedSector && selectedSector !== 'all' && company.Sector !== selectedSector && company.sectorAr !== selectedSector) {
    //         return false;
    //     }
    //     if (searchQuery && !company.CompanyName.toLowerCase().includes(searchQuery.toLowerCase()) && company.اسمالشركة.toLowerCase().includes(searchQuery.toLowerCase())) {
    //         return false;
    //     }
    //     return true;
    // });

    const filteredData = data.filter(company => {
        const companyNameMatches =
            company.CompanyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (company.اسمالشركة && company.اسمالشركة.toLowerCase().includes(searchQuery.toLowerCase()));

        if (selectedSector && selectedSector !== 'all' && company.Sector !== selectedSector && company.sectorAr !== selectedSector) {
            return false;
        }

        return companyNameMatches;
    });
    // Pagination logic
    const handlePageChange = pageNumber => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Handle search input change
    const handleSearchInputChange = event => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset page to 1 when search query changes
    };

    // Handle sector selection
    const handleSectorSelect = sector => {
        setSelectedSector(sector);
        setSearchQuery(''); // Clear search input when a new sector is selected
        setCurrentPage(1); // Reset page to 1 when sector is selected
    };

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleSectorChange = (event) => {
        setSelectedSector(event.target.value);
    };

    // Filter companies based on the selected sector
    const companiesInSector = data.filter(company => company.Sector === selectedSector);


    // change language

    const navigate = useNavigate();
    // window.alert(`${lang}`);
    const { t } = useTranslation("InsuranceCompanies");

    let { i18n } = useContext(LocalizationContext)
    // const changeLanguage = (lang, dir) => {
    //     document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    //     document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    // }
    const { pathname } = useLocation();

    const changeLanguage = (lang, dir) => {
        document.documentElement.lang = lang;
        document.documentElement.dir = dir;
    }


    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        changeLanguage(lang, lang === 'ar' ? 'rtl' : 'ltr');
        // Update the language in the cookie
        Cookies.set('i18next', lang);
        // Update the URL with the new language code
        navigate(`/${lang}/${pathname.slice('4')}`);
    };
    // useEffect(() => {
    //     if (pathname.includes('/en')) {
    //         Cookies.set('i18next', 'en')

    //         // i18n.language = Cookies.get('i18next');

    //         // changeLanguage('en', 'ltr')
    //         document.getElementsByTagName("html")[0].setAttribute("lang", 'en');
    //         document.getElementsByTagName("html")[0].setAttribute("dir", 'ltr');


    //     } else {
    //         // i18n.language = Cookies.get('i18next');

    //         Cookies.set('i18next', 'ar')
    //         // changeLanguage('ar', 'rtl')
    //         document.getElementsByTagName("html")[0].setAttribute("lang", 'ar');
    //         document.getElementsByTagName("html")[0].setAttribute("dir", 'rtl');


    //     }


    // fixed hedader when scroll down




    // }, [])

    // useEffect(() => {


    //     if (i18n.language === 'ar') {

    //         changeLanguage('ar', 'rtl')
    //         Cookies.set('i18next', 'ar')

    //     } else {
    //         changeLanguage('en', 'ltr')
    //         Cookies.set('i18next', 'en')

    //     }




    // }, [Navigate, navigate]);

    // remove repeat from array
    // const uniqueSectors = new Set();

    // data.forEach(company => {
    //     // Add the sector name based on the selected language to the Set
    //     uniqueSectors.add(i18n.language === 'ar' ? company.sectorAr : company.Sector);
    // });

    // // Convert the Set back to an array for rendering
    // const uniqueSectorsArray = Array.from(uniqueSectors);
    return <>
        <section className="insurance-section overflow-hidden grey-bg" >
            <div className="container insurance-container" style={{
                backgroundImage: 'url(/images/health-insurance/page-header-bg.jpg)', position: 'absolute',
                top: ' 0',
                right: '0',
                bottom: '0',
                width: '1221px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: '-1',
            }}>

            </div>
            <div class="page-header-shape-1"><img src="/images/health-insurance/page-header-shape-1.png" alt="shape" /></div>
            <div className="container">
                <div className={`row align-items-center justify-content-center text-center ${i18n.language === 'en' ? 'text-md-start' : 'text-md-end'}  justify-content-md-between`}>

                    <div className="col-lg-6 col-md-6 ps-2 ms-2 ps-md-0 ms-md-0">
                        <h3 className="title mb-0 d-none d-md-block"> <span> {t('Health Insurance')} </span></h3>

                    </div>
                    <div className={`col-lg-6 col-md-5 ps-2 ms-2 ps-md-0 ms-md-0 ${i18n.language === 'en' ? ' text-lg-end' : 'text-lg-start'} mt-2 mt-md-0`}>
                        <nav aria-label="breadcrumb" className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/${Cookies.get('i18next')}`}><i className="fas fa-home"></i></Link>
                                </li>

                                <li className="breadcrumb-item">
                                    <Link to={`/${Cookies.get('i18next')}/insurance-companies`}>{t('Health Insurance')}   </Link>
                                </li>




                            </ol>
                        </nav>
                    </div>
                </div></div>
        </section >

        <section class="tracking pt-3">
            <div class="container">

                <div className="row justify-content-center">
                    <div className="col-md-5 text-center mb-3">

                        {i18n.language === 'en' && (
                            <button className='col-md-4 btn-lang' onClick={() => handleLanguageChange('ar')}>
                                <span>اللغة العربية</span>
                            </button>
                        )}
                        {i18n.language === 'ar' && (
                            <button className='col-md-4 btn-lang' onClick={() => handleLanguageChange('en')}>
                                <span>English Language</span>
                            </button>
                        )}
                    </div>
                    <div class="tracking__inner">

                        <div class="tracking-shape-1 float-bob-y">
                            <img decoding="async" src="/images/health-insurance/tracking-shape-1.png" alt="alt" />
                        </div>
                        <div class="tracking-shape-2 float-bob-x">
                            <img decoding="async" src="/images/health-insurance/tracking-shape-2.png" alt="" />
                        </div>
                        <div class="tracking-shape-3 float-bob-x">
                            <img decoding="async" src="/images/health-insurance/tracking-shape-3.png" alt="alt" />
                        </div>
                        <div class="tracking-shape-4 float-bob-y">
                            <img decoding="async" src="/images/health-insurance/tracking-shape-4.png" alt="alt" />
                        </div>
                        <div class="tracking__btn-box mx-5 d-md-block d-none">
                            <div class="elementor-element elementor-element-99fddf2 elementor-widget elementor-widget-insur-sidebar" data-id="99fddf2" data-element_type="widget" data-widget_type="insur-sidebar.default">
                                <div class="elementor-widget-container">
                                    <div class="make-a-claim__claim-box">
                                        <div class="menu-insur-details-sidebar-container">
                                            <ul id="menu-insur-details-sidebar" class="make-a-claim__claim-list list-unstyled ml-0">
                                                <li
                                                    id="menu-item-all"
                                                    className={`menu-item menu-item-type-post_type menu-item-object-page ${selectedSector === 'all' ? 'current' : ''
                                                        }`}
                                                >
                                                    <Link onClick={() => handleSectorSelect('all')} aria-current="page">
                                                        {t('All')}   <span className="icon-right-arrow1 mx-3">
                                                            {i18n.language === 'en' ? <i className="fa-solid fa-arrow-right-long"></i> : <i className="fa-solid fa-arrow-left-long"></i>}
                                                        </span>
                                                    </Link>
                                                </li>

                                                {i18n.language === 'en' ? [...new Set(data.map(item => item.Sector))].map((sector, index) => (
                                                    <li key={index} id={`menu-item-${sector}`} className={`menu-item menu-item-type-post_type menu-item-object-page ${selectedSector === sector ? 'current' : ''}`}>
                                                        <Link onClick={() => handleSectorSelect(sector)} aria-current="page">
                                                            {sector}
                                                            <span className="icon-right-arrow1 mx-3">
                                                                {i18n.language === 'en' ? <i className="fa-solid fa-arrow-right-long"></i> : <i className="fa-solid fa-arrow-left-long"></i>}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                )) : [...new Set(data.map(item => item.sectorAr))].map((sectorAr, index) => (
                                                    <li key={index} id={`menu-item-${sectorAr}`} className={`menu-item menu-item-type-post_type menu-item-object-page ${selectedSector === sectorAr ? 'current' : ''}`}>
                                                        <Link onClick={() => handleSectorSelect(sectorAr)} aria-current="page">
                                                            {sectorAr}
                                                            <span className="icon-right-arrow1 mx-3">
                                                                {i18n.language === 'en' ? <i className="fa-solid fa-arrow-right-long"></i> : <i className="fa-solid fa-arrow-left-long"></i>}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                ))}






                                            </ul></div>    </div>
                                </div>
                            </div>
                        </div>


                        <div class="tracking__left me-md-5 me-0">

                            <div data-vc-full-width="true" data-vc-full-width-init="true" class="vc_row wpb_row vc_row-fluid skin-bg fullwidth-on-ipad vc_custom_1483601796566">
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper"><h2 class="vc_custom_heading text-inherit text-white company-title"> {t('the contracted companies')} <br />
                                        </h2>
                                        </div>
                                        <div  >
                                            <form class="search-company row">
                                                <select className="insurance-input d-block d-md-none" id="sectorSelect" onChange={handleSectorChange}>
                                                    <option value="">{t('Select a Sector')}</option>
                                                    {i18n.language === 'en' ? [...new Set(data.map(item => item.Sector))].map((sector, index) => (
                                                        <option key={index} value={sector}>
                                                            {sector}
                                                        </option>
                                                    )) : [...new Set(data.map(item => item.sectorAr))].map((sectorAr, index) => (
                                                        <option key={index} value={sectorAr}>
                                                            {sectorAr}
                                                        </option>
                                                    ))}


                                                </select>
                                                <input className='insurance-input mt-2'
                                                    type="text"
                                                    value={searchQuery}
                                                    onChange={handleSearchInputChange}
                                                    placeholder={`${t('Search by')} ${selectedSector && selectedSector !== 'all' ? `${selectedSector}` : t('Company Name')
                                                        }`}
                                                />
                                                {/* <i class="thm-btn tracking__btn fa-solid fa-magnifying-glass-arrow-right"></i> */}

                                            </form>

                                        </div>

                                    </div>
                                    <div class="vc_column-inner "><div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                {filteredData.length > 0 ? (
                                                    <table className='insurance-table'>
                                                        <thead className='companies-head'>
                                                            <tr>
                                                                <th>{t('Index')}</th>

                                                                <th>{t('Company Name')}</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className='companies-body'>
                                                            {i18n.language === 'en' ? currentItems.map((company, index) => (
                                                                <tr>
                                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>

                                                                    <td>{company.CompanyName}</td>
                                                                </tr>
                                                            )) : currentItems.map((company, index) => (
                                                                <tr>
                                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>

                                                                    <td>{company.اسمالشركة}</td>
                                                                </tr>))}
                                                        </tbody>
                                                    </table>
                                                ) : <h3 className='text-white fw-bolder text-center'>{t('There Is No Data')}</h3>}
                                            </div>
                                        </div>
                                        <div class="wpb_text_column wpb_content_element mt-5 mb-5 text-center ">
                                            {/* Pagination */}
                                            {filteredData.length > itemsPerPage && (
                                                <Pagination
                                                    activePage={currentPage}
                                                    itemsCountPerPage={itemsPerPage}
                                                    totalItemsCount={filteredData.length}
                                                    pageRangeDisplayed={3}
                                                    onChange={handlePageChange}
                                                />
                                            )}
                                        </div>
                                    </div>


                                    </div>
                                </div>
                            </div >
                        </div>

                    </div>

                </div>
            </div>
        </section >

    </>
}
