import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';
import PageTitle from '../Utilites/PageTitle';

export default function SeasonalFluVaccineQuestionnaire(props) {
    // translation 
    const { t } = useTranslation("FluVCaccineQuestionnair");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const navigate = useNavigate();
    const changeLanguage = (lang, dir) => {
        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
        document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    }
    const { pathname } = useLocation();

    const [name, setName] = useState('');
    const [idNo, setIDNo] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [category, setCategory] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    const validateForm = () => {
        const errors = [];
        const phoneNumberRegex = /^[\+]?[0-9]{0,3}\W?[()0-9]{0,3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;


        if (name.trim() === '') {
            errors.push(t('Name is required.'));
        }
        if (idNo.trim() === '') {
            errors.push(t('ID No is required.'));
        }

        if (contactNumber.trim() === '') {
            errors.push(t('Contact Number is required.'));
        }
        else if (!phoneNumberRegex.test(contactNumber)) {
            errors.push(t('Please enter a valid Contact number. Expected format: [+][Country Code][Area Code][Contact Number].'))
        }
        if (category.trim() === '') {
            errors.push(t('Category is required.'));
        }
        if (age.trim() === '') {
            errors.push(t('Age is required.'));
        }

        if (!gender) {
            errors.push(t('Your gender is required.'));
        }

        if (!question1) {
            errors.push(t('This Field Is Required.'));
        }
        if (!question2) {
            errors.push(t('Please select an answer.'));
        }
        if (!question3) {
            errors.push(t('Please answer this question.'));
        }
        if (!question4) {
            errors.push(t('Please select an Answer from these options'));
        }

        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();
            bodyFormData.append('name', name);
            bodyFormData.append('age', age);
            bodyFormData.append('ID No', idNo);
            bodyFormData.append('Gender', gender);
            bodyFormData.append('Contact Number', contactNumber);
            bodyFormData.append('Category', category);
            bodyFormData.append('Do you want to take the seasonal flu vaccine for the year 2024/2025?', question1);
            bodyFormData.append('Do you have any allergies?', question2);
            bodyFormData.append('Have you received vaccine against seasonal influenza last year?', question3);
            bodyFormData.append('Have you suffered from any side effects after vaccination?', question4);

            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/SeasonalFluVaccineQuestionnaire`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg(t('Thank you for Your Registartion!'));
                        setIDNo('');
                        setName('');
                        setAge('');
                        setCategory('');
                        setContactNumber('');
                        setGender('');
                        setQuestion1('');
                        setQuestion2('');
                        setQuestion3('');
                        setQuestion4('');
                        // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');
                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>
        {i18n.language === 'en' ? <PageTitle title={t('Seasonal Flu vaccine')} anotherTitle={t('Questionnaire')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/excon2024-session-registration`} currentPage={t('Seasonal Flu vaccine Questionnaire')} /> : <PageTitle title={t('Questionnaire')} anotherTitle={t('Seasonal Flu vaccine')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/excon2024-session-registration`} currentPage={t('Seasonal Flu vaccine Questionnaire')} />}
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div class="section-title mb-0 text-center">
                            <h4 class="title mb-3 text-center">{t('Seasonal Flu vaccine')} <span>{t('2024/2025')}</span></h4>

                            <p>{t('Your answer on this questionnaire will be consider as a consent that you Agree/refuse to take the flu vaccine dose of 2024/2025')}</p>
                            <p>{t('In case of refusal to be vaccinated against the seasonal influenza you`re requested to be compliant with the IPC-hospital policy related to wearing the surgical mask during your duty.')}</p>

                        </div>
                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-6 p-5 order-2">
                                            <div className='row justify-content-center'>


                                                <div className="form-group col-md-6">
                                                    <input id="form_name" type="text" name="name" className={`form-control ${errors.includes(t('Name is required.')) ? 'is-invalid' : ''}`} placeholder={t("Name")} value={name} onChange={(e) => setName(e.target.value)} data-error={t("Name is required.")} />

                                                    {errors.includes(t('Name is required.')) && (
                                                        <div className="invalid-feedback">{t('Name is required.')}</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input id="form_name" type="number" name="title" className={`form-control ${errors.includes(t('ID No is required.')) ? 'is-invalid' : ''}`} placeholder={t("ID No")} value={idNo} onChange={(e) => setIDNo(e.target.value)} data-error={t("ID No is required.")} />

                                                    {errors.includes(t('ID No is required.')) && (
                                                        <div className="invalid-feedback">{t('ID No is required.')}</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input id="form_name" type="text" name="organization" className={`form-control ${errors.includes(t('Contact Number is required.')) ? 'is-invalid' : ''}  ${errors.includes(t('Please enter a valid Contact number. Expected format: [+][Country Code][Area Code][Contact Number].')) ? 'is-invalid' : ''}`} placeholder={t("Contact Number")} value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} data-error={t("Contact Number is required.")} />

                                                    {errors.includes(t('Contact Number is required.')) && (
                                                        <div className="invalid-feedback">{t('Contact Number is required.')}</div>
                                                    )}
                                                    {errors.includes(t('Please enter a valid Contact number. Expected format: [+][Country Code][Area Code][Contact Number].')) && (
                                                        <div className="invalid-feedback">{t('Please enter a valid Contact number. Expected format: [+][Country Code][Area Code][Contact Number].')}</div>
                                                    )}
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <input type="number" name="Whatsapp" className={`form-control ${errors.includes(t('Age is required.')) ? 'is-invalid' : ''}`} placeholder={t("Age")} value={age}
                                                        onChange={(e) => setAge(e.target.value)}
                                                    />
                                                    {errors.includes(t('Age is required.')) && (
                                                        <div className="invalid-feedback">{t('Age is required.')}</div>
                                                    )}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <select name="Whatsapp" className={`form-control ${errors.includes(t('Your gender is required.')) ? 'is-invalid' : ''}`} placeholder={t("Gender")} value={gender}
                                                        onChange={(e) => setGender(e.target.value)}
                                                    >
                                                        <option value="">{t('Gender')} </option>
                                                        <option value={t("Male")}>{t('Male')}</option>
                                                        <option value={t("Female")}>{t('Female')}</option>
                                                    </select>
                                                    {errors.includes(t('Your gender is required.')) && (
                                                        <div className="invalid-feedback">{t('Your gender is required.')}</div>
                                                    )}

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <select id="form_address" className={`form-control ${errors.includes(t('Category is required.')) ? 'is-invalid' : ''}`} value={category} onChange={(e) => setCategory(e.target.value)} >
                                                        <option value="">{t('Category')} </option>
                                                        <option value={t("Medical")}>{t('Medical')}</option>
                                                        <option value={t("Nursing")}>{t('Nursing')}</option>
                                                        <option value={t("Allied Health")}>{t('Allied Health')}</option>
                                                        <option value={t("Admin")}>{t('Admin')}</option>
                                                        <option value={t("Support Services")}>{t('Support Services')}</option>

                                                    </select>
                                                    {errors.includes(t('Category is required.')) && (
                                                        <div className="invalid-feedback">{t('Category is required.')}</div>
                                                    )}


                                                </div>

                                                <div className="form-group col-md-12">
                                                    <select id="form_address" type="text" name="rounnd-table" className={`form-control ${errors.includes(t('This Field Is Required.')) ? 'is-invalid' : ''}`} value={question1} onChange={(e) => setQuestion1(e.target.value)} >
                                                        <option value="">{t('Do you want to take the seasonal flu vaccine for the year 2024/2025?')} </option>
                                                        <option value={t("Yes")}>{t('Yes')}</option>
                                                        <option value={t("No")}>{t('No')}</option>

                                                    </select>
                                                    {errors.includes(t('This Field Is Required.')) && (
                                                        <div className="invalid-feedback">{t('This Field Is Required.')}</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <select id="form_address" type="text" name="rounnd-table" className={`form-control ${errors.includes(t('Please select an answer.')) ? 'is-invalid' : ''}`} value={question2} onChange={(e) => setQuestion2(e.target.value)} >
                                                        <option value="">{t('Do you have any allergies?')} </option>
                                                        <option value={t("Ove albumin Gentamycin Yes")}>{t('Ove albumin Gentamycin Yes')}</option>
                                                        <option value={t("No Allergy")}>{t('No Allergy')}</option>
                                                        <option value={t("I don’t know")}>{t('I don’t know')}</option>

                                                    </select>
                                                    {errors.includes(t('Please select an answer.')) && (
                                                        <div className="invalid-feedback">{t('Please select an answer.')}</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <select id="form_address" type="text" name="rounnd-table" className={`form-control ${errors.includes(t('Please answer this question.')) ? 'is-invalid' : ''}`} value={question3} onChange={(e) => setQuestion3(e.target.value)} >
                                                        <option value="">{t('Have you received vaccine against seasonal influenza last year?')} </option>
                                                        <option value={t("Yes")}>{t('Yes')}</option>
                                                        <option value={t("No")}>{t('No')}</option>

                                                    </select>
                                                    {errors.includes(t('Please answer this question.')) && (
                                                        <div className="invalid-feedback">{t('Please answer this question.')}</div>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <select id="form_address" type="text" name="rounnd-table" className={`form-control ${errors.includes(t('Please select an Answer from these options')) ? 'is-invalid' : ''}`} value={question4} onChange={(e) => setQuestion4(e.target.value)} >
                                                        <option value="">{t('Have you suffered from any side effects after vaccination?')} </option>
                                                        <option value={t("Yes")}>{t('Yes')}</option>
                                                        <option value={t("No")}>{t('No')}</option>

                                                    </select>
                                                    {errors.includes(t('Please select an Answer from these options')) && (
                                                        <div className="invalid-feedback">{t('Please select an Answer from these options')}</div>
                                                    )}
                                                </div>

                                                <div className="col-md-6">
                                                    <button className="btn btn-border btn-circle w-100" type='submit'><span>{t('Submit')}</span>
                                                    </button>
                                                </div>
                                                <div className="col-md-6">
                                                    {i18n.language === 'en' && <button className='btn btn-border btn-circle w-100' onClick={() => {
                                                        i18n.changeLanguage('ar');
                                                        changeLanguage('ar', 'rtl');

                                                        navigate(`/${Cookies.get('i18next')}/${pathname.slice('4')}`)

                                                    }}>  <span>
                                                            العربية  </span>

                                                    </button>}
                                                    {i18n.language === 'ar' && <button className='btn btn-border btn-circle w-100' onClick={() => {
                                                        i18n.changeLanguage('en');
                                                        changeLanguage('en', 'ltr');

                                                        navigate(`/${Cookies.get('i18next')}/${pathname.slice('4')}`)


                                                    }}><span>
                                                            English</span>

                                                    </button>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/shutterstock_1470251888_h2.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            {/* <img src="/images/forms/HowtheCDCUsesFeartoIncreaseDemandforFluVaccines.jpg" alt="excon 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} /> */}
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>{t('Your Message')}</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            {/* <p className='lead'>{messageForm}</p> */}
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        {t('Close')}
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
