import Cookies from 'js-cookie';
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
export default function MedicalEducationServices() {
    const { pathname } = useLocation();
    const navigate = useNavigate()
    function goToLink() {
        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/medical-education-unit/services`)
    }
    return <>
    
        <div className="price-table style-2 active vh-100" data-bg-img="/images/bg/01.jpg" style={{ backgroundImage: `url('/images/bg/01.jpg')`, zIndex: '1000000000' }}>
            <div className="price-inner h-100" style={{ padding: '100px 5rem 100px' }}>
                <div className="price-header">
                    <h2 className="price-title"> Committed to you</h2>
                </div>
                <div className="price-value">
                    <p className='text-muted lead'>
                        The Medical Education Unit at the Saudi German Hospital dedicated to advancing medical excellence through well-integrated educationa programs and top-tier scientific activities. Our comprehensive training programs for healthcare providers ensure standardized competence at all levels. Through collaborations with prestigious institutions like Mayo Clinic and the American Heart Association, we actively contribute to shaping a health care system committed to the highest standards of education, innovation and excellence.
                    </p>
                </div>

                <a className="btn btn-block btn-theme mt-4 w-25 rounded-3" target='_blank' href={`${pathname.split('/')[0]}/#/sgh/${Cookies.get('i18next')}/health-hub/medical-education-unit/services`}> <span>View Services</span>
                </a>
            </div>
        </div>

    </>
}
