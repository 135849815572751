import React, { createContext, useState } from "react";

export let PatientPortalBaseUrlContext = createContext(0);
export default function PatientPortalBaseUrlContextProvider(props) {
    let [baseUrl, setBaseUrl] = useState(`https://my-health.sghcairo.net/sgh_api/test/portal/public`);
    return <>
        <PatientPortalBaseUrlContext.Provider value={{ baseUrl, setBaseUrl }}>
            {props.children}
        </PatientPortalBaseUrlContext.Provider>

    </>
}
