import React, { createContext, useContext, useState } from "react";
import { PatientPortalBaseUrlContext } from "../PatientPortalBaseUrlContext";
import axios from "axios";
import Cookies from "js-cookie";
import PatientPortalChangePasswordModale from "./PatientPortalChangePasswordModale";

export let PatientPortalPinInpContext = createContext(0);
export default function PatientPortalPinInpContextProvider(props) {
  let { baseUrl, setBaseUrl } = useContext(PatientPortalBaseUrlContext)

  const [pinInp, setPinInp] = useState('');
  let [visitsSummary, setVisitsSummary] = useState([]);
  let [labSummary, setLabSummary] = useState([]);
  let [radSummary, setRadSummary] = useState([]);
  let [cardiologySummary, setCardiologySummary] = useState([]);
  let [vitalSummary, setvitalSummary] = useState({});
  const [portalChangePassword, setPortalChangePassword] = useState(false);

  const handlePortalChangePasswordClose = () => {
    setPortalChangePassword(false);
  };

  async function getsummary() {
    // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
    const bodyFormData = new FormData();
    // Append data to the FormData object
    bodyFormData.append('pin', localStorage.getItem('pinNumber'));

    const headers = {

      'Content-Type': 'multipart/form-data',
      'lang': Cookies.get('i18next'),
      'fingerprint': localStorage.getItem('fingerPrint'),
      'token': localStorage.getItem('patientPortalToken'),
    };
    await axios.post(`${baseUrl}/patientportal/getsummery`, bodyFormData, { headers })
      .then(response => {
        // Handle the response
        if (response.data.Ecode === 200) {
          console.log(response.data.Edata.Visits);
          setVisitsSummary(response.data.Edata.Visits);
          setLabSummary(response.data.Edata.Lab);
          setRadSummary(response.data.Edata.Rad);
          setCardiologySummary(response.data.Edata.Cardiology);
          setvitalSummary(response.data.Edata.vital);
        } else if (response.data.Ecode === 1407) {
          // setPortalChangePassword(true)
          setPortalChangePassword(true)
        }
      })
      .catch(error => {

        // Handle any errors
        console.error(error);
      });

    // calcBmi();
  }
  return (
    <>
      <PatientPortalChangePasswordModale
        show={portalChangePassword}
        onHide={handlePortalChangePasswordClose}
      // Pass other props as needed
      />
      <PatientPortalPinInpContext.Provider value={{ pinInp, setPinInp, vitalSummary, setvitalSummary, getsummary, visitsSummary, labSummary, radSummary, cardiologySummary }}>
        {props.children}
      </PatientPortalPinInpContext.Provider>
    </>
  );

}
