import React from 'react'
import { useLocation } from 'react-router-dom';
import PageTitle from '../../../Utilites/PageTitle';
import Cookies from 'js-cookie';

export default function NewsDetails() {
    const { pathname } = useLocation();

    return <>
        <div className="page-content">

            {/* <!--page title start--> */}
            <PageTitle title='WORLD' anotherTitle=' CANCER DAY' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/news/news-details`} currentPage='WORLD CANCER DAY' />
            {/* <PageTitle title='Facilities' anotherTitle='Event ' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/facilities-events`} currentPage='Facilities Event' /> */}
            {/* <!--page title end--> */}
            <section>
                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-md-12">
                            <div class="post-image">
                                <img class="img-fluid w-100" src="/images/service/01.jpg" alt="" />
                            </div>

                        </div>
                        <div className="col-lg-6">

                            <div class="box-shadow p-5 d-flex flex-column align-items-center">
                                <div class="post-desc">
                                    <div class="post-title">
                                        <h4>World Cancer
                                            Day</h4>
                                    </div>
                                    <div class="post-meta">
                                        <ul class="list-inline">
                                            <li>23 April, 2019</li>
                                            <li>Date</li>
                                        </ul>
                                    </div>
                                    <p class="mb-0 line-h-3">Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat erci tation ullamco laboris nisi ut aliq uip.eiu smod tempor the incidi dunt ut labore et dolore magna aliqua. Ut atenim ad minim veniam, quis nostrud exerci tation abore et dolore magn exerci tation abore et dolore magna aliqua. Uhbt atenim</p>
                                    <p class="mb-0 line-h-3">Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat erci tation ullamco laboris nisi ut aliq uip.eiu smod tempor the incidi dunt ut labore et dolore magna aliqua. Ut atenim ad minim veniam, quis nostrud exerci tation abore et dolore magn exerci tation abore et dolore magna aliqua. Uhbt atenim</p>
                                </div>



                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </div>

    </>
}
