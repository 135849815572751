import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function NewsDetails() {
    // translation 
    const { t } = useTranslation("News");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    const { pathname } = useLocation();
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const { news_id } = useParams();
    const [newsDetails, setNewsDetails] = useState({});

    async function getNewsDetails(hospital_id, newsId) {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }

        const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/news/details/${hospital_id}/${newsId}/${Cookies.get('i18next')}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    setNewsDetails(response.data.Edata)


                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    useEffect(() => {
        getNewsDetails('1', news_id);
    }, []);

    return <>
        <div className="page-content">

            {/* <!--page title start--> */}
            <PageTitle title={t('News')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/news/${newsDetails.title}/${newsDetails.id}`} currentPage={t('News')} />
            {/* <PageTitle title='Facilities' anotherTitle='Event ' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/facilities-events`} currentPage='Facilities Event' /> */}
            {/* <!--page title end--> */}
            <section>
                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-md-12">
                            <div class="post-image">
                                {newsDetails.image ? <img class="img-fluid w-100" src={newsDetails.image} alt={newsDetails.title} /> : <img class="img-fluid w-100" src="/images/service/01.jpg" alt="" />}
                            </div>

                        </div>
                        <div className="col-lg-6">

                            <div class="box-shadow p-5 d-flex flex-column align-items-center">
                                <div class="post-desc">
                                    <div class="post-title">
                                        <h4>{newsDetails.title}</h4>
                                    </div>

                                    <p class="mb-0 line-h-3" dangerouslySetInnerHTML={{ __html: newsDetails.overveiw }}></p>
                                </div>



                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </div>

    </>
}
