import ReactDOM from 'react-dom/client';
import "./i18n";
import App from './App';
import reportWebVitals from './reportWebVitals';
import LocalizationContextProvider from './Context/LocalizationContext';
import GetCategoriesContextProvider from './Context/GetCategories';
import GetCategoryDetailsContextProvider from './Context/GetCategoryDetails';
import CategorySearchDataContextProvider from './Context/CategorySearchData';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './index.css';
import MetaTagContextProvider from './Context/MetaTag/MetaTag';
import GetDepartmentsOptionsContextProvider from './Context/GetDepartmentsOptions';
import GetDoctorsOptionsContextProvider from './Context/GetDoctorsOptions';
import ScrollToTopContextProvider from './Context/ScrollToTop/ScrollToTop';
import PatientPortalPinInpContextProvider from './Context/Authentication/PatientPortalPinInpContext';
import AuthContextProvider from './Context/Authentication/AuthContext';
import SaveUserDataContextProvider from './Context/Authentication/SaveUserDataContext';
import PatientPortalBaseUrlContextProvider from './Context/PatientPortalBaseUrlContext';

// import { useContext } from 'react';
// import "././css/color-customize/color-customizer.css";
// import "././css/date-picker/date-picker.css";
// import "././css/magnific-popup/magnific-popup.css";
// import "././css/owl-carousel/owl.carousel.css";
// import "././css/animate.css";
// import "././css/base.css";
// import "././css/bootstrap.min.css";
// import "././css/fontawesome-all.css";
// import "././css/responsive.css";
// import "././css/shortcodes.css";
// import "././css/spacing.css";
// import "././css/style.css";
// import "././css/themify-icons.css";

// import "././js/color-customize/color-customizer.js";
// import "././js/contact-form/contact-form.js";
// import "././js/countdown/jquery.countdown.min.js";
// import "././js/counter/counter.js";
// import "././js/date-picker/date-picker.js";
// import "././js/isotope/isotope.pkgd.min.js";
// import "././js/jarallax/jarallax.min.js";
// import "././js/magnific-popup/jquery.magnific-popup.min.js";
// import "././js/owl-carousel/owl.carousel.min.js";
// import "././js/skill/circle-progressbar.js";
// import "././js/theme.js";
// import "././js/theme-script.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import "bootstrap/dist/js/bootstrap.bundle.js";

// import 'owl.carousel/dist/owl.carousel.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <LocalizationContextProvider>
    <GetCategoriesContextProvider>
      <CategorySearchDataContextProvider>
        <GetCategoryDetailsContextProvider>
          <GetDepartmentsOptionsContextProvider>
            <GetDoctorsOptionsContextProvider>
              {/* <ScrollToTopContextProvider> */}
              <PatientPortalBaseUrlContextProvider>
                <PatientPortalPinInpContextProvider>



                  <AuthContextProvider>
                    <SaveUserDataContextProvider>
                      <App />
                    </SaveUserDataContextProvider>

                  </AuthContextProvider>
                </PatientPortalPinInpContextProvider>
              </PatientPortalBaseUrlContextProvider>
              {/* </ScrollToTopContextProvider> */}

            </GetDoctorsOptionsContextProvider>
          </GetDepartmentsOptionsContextProvider>
        </GetCategoryDetailsContextProvider>
      </CategorySearchDataContextProvider>
    </GetCategoriesContextProvider>
  </LocalizationContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
