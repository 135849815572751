import Cookies from 'js-cookie'
import React from 'react'
import { Link } from 'react-router-dom'


export default function PageTitle({ title, anotherTitle, prevLink, prevPage, currentLink, currentPage, nextLink, nextPage }) {
    // const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}

        <section className="page-title overflow-hidden grey-bg">
            <div className="container">
                <div className="row align-items-center justify-content-center text-center text-md-start justify-content-md-between">
                    <div className="col-lg-6 col-md-6 ps-2 ms-2 ps-md-0 ms-md-0">
                        <h1 className="title mb-0 d-none d-md-block">{title}<span> {anotherTitle}</span></h1>

                    </div>
                    <div className="col-lg-6 col-md-5 ps-2 ms-2 ps-md-0 ms-md-0 text-lg-end mt-2 mt-md-0">
                        <nav aria-label="breadcrumb" className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/${Cookies.get('i18next')}`}><i className="fas fa-home"></i></Link>
                                </li>
                                {prevPage ?
                                    <li className="breadcrumb-item">
                                        <Link to={prevLink}>{prevPage} </Link>
                                    </li>
                                    : ''}

                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link to={currentLink}>{currentPage}</Link>
                                </li>
                                {nextPage ?
                                    <li className="breadcrumb-item" >
                                        <Link to={nextLink} target='blank'>{nextPage} </Link></li>
                                    : ''}

                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        {/* <!--page title end--> */}
    </>
}
