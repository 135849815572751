import React from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../../Utilites/PageTitle';
import OurPartnersDetails from '../../Utilites/OurPartnersDetails';
export default function MayoClinic() {
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Mayo Clinic' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/`} prevPage='Our Partner' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/mayo-clinic`} currentPage='Mayo Clinic' />


        {/* <!--page title end--> */}


        {/* <!--about us start--> */}
        <OurPartnersDetails partnerImgSrc="/images/mayo.png" partnerTitle='Mayo Clinic Care Network MCCN' partnerDescription='Pioneering healthcare across continents, Saudi German Health Egypt stands tall as the first hospital in Africa and the 3rd in the Middle East to join the prestigious Mayo Clinic Care Network. This exclusive partnership brings the renowned expertise and cutting-edge practices of Mayo Clinic directly to patients in Egypt, offering unparalleled access to world-class medical care for all. ' />


        {/* <!--about us end--> */}
    </>
}
