import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../../../Utilites/PageTitle';

export default function Chest() {
    const { pathname } = useLocation();
    const [expandedItems, setExpandedItems] = useState([]);

    const data = [
        {
            id: 1,
            title: 'Sleep laboratory to diagnose and treat breathing problems during sleep',

        },
        {
            id: 2,
            title: 'Pulmonary Exercise Program done as outpatient exercise to identify the physiologic cause for the impairment and tailor specific exercise regimens and therapeutic strategies',

        },
        {
            id: 3,
            title: 'Pulmonary Hypertension Program that offers a comprehensive and multidisciplinary approach patients with this devastating disease which is seen more common during the last years',

        },
        {
            id: 4,
            title: 'Early detection of lung cancer program for butte prognosis for these',

        },
        {
            id: 5,
            title: 'Patients Smoking Cessation Clinic',

        },

    ];
    const toggleList = (itemId) => {
        if (expandedItems.includes(itemId)) {
            setExpandedItems(expandedItems.filter((id) => id !== itemId));
        } else {
            setExpandedItems([...expandedItems, itemId]);
        }
    };
    const renderArrowIcon = (itemId) => {
        if (expandedItems.includes(itemId)) {
            return (


                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                >
                    <path d="M7 14l5-5 5 5z" />
                </svg>
            );
        } else {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                >
                    <path d="M7 10l5 5 5-5z" />
                </svg>
            );
        }
    };

    const renderList = (items) => {
        return items.map((item) => (
            <li key={item.id}>
                <div className="item" onClick={() => toggleList(item.id)}>
                    {renderArrowIcon(item.id)}
                    <span
                        style={{
                            fontWeight: expandedItems.includes(item.id) ? 'bold' : 'normal',
                            color: expandedItems.includes(item.id) ? '4ab242' : 'black',
                        }}
                    >
                        {item.title}
                    </span>
                </div>
                {expandedItems.includes(item.id) && item.children && (
                    <ul className="sub-list">{renderList(item.children)}</ul>
                )}
            </li>
        ));
    };

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Chest And Pulmonology' anotherTitle='Depertment' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties`} prevPage='specialties' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/chest-and-pulmonology`} currentPage='Chest And Pulmonology' />
        {/* <!--page title end--> */}
        <div class="page-content">

            {/* <!--service details start--> */}

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-12">
                            <div class="left-side">
                                <div>

                                    <div class="service-details mt-4">
                                        <h2 class="title">Chest And Pulmonology <span>Depertment</span></h2>
                                        <p class="lead">At Saudi German Hospital Cairo, we recognize the paramount importance of breathing in sustaining human life. With every breath, we take in inspiration and nourish a heightened sense of creativity and purpose. Despite its critical role, breathing is often taken for granted, with many individuals unaware of their unconscious patterns.As such, we offer world-class pulmonology medicine services to help patients breathe easier and enjoy a better quality of life. Our highly skilled pulmonology consultants and specialists provide state-of-the-art consultative services to both outpatients and inpatients, ensuring highly specialized evaluations and treatment options.  </p>
                                        <p class="mb-0">We prioritize patient satisfaction as our primary clinical objective, and our team is equipped to evaluate and treat a diverse range of respiratory conditions, including but not limited to Chronic Obstructive Pulmonary Disease (COPD), bronchial asthma, interstitial lung disease, infectious lung diseases, autoimmune and collagen vascular disease, as well as acute and chronic respiratory failure</p>
                                        <div class="tab mt-5">
                                            {/* <!-- Nav tabs --> */}
                                            <div class="service-details mt-4">
                                                <h2 class="h3 title">Scope Of Services:</h2>
                                            </div>

                                            {/* <!-- Tab panes --> */}
                                            {/* <div class="tab-content pb-0" id="nav-tabContent">

                                                <div role="tabpanel" class="tab-pane fade show active" id="tab1-1">

                                                    <ul className="main-list list-unstyled pricing-list clearfix p-0">{renderList(data)}</ul>

                                                </div>

                                            </div> */}

                                            <div role="tabpanel" id="tab1-2">
                                                <ul class="list-unstyled pricing-list clearfix p-0">
                                                    <li><span>Orthotics</span> </li>
                                                    <li><span>Colonoscopy </span> </li>
                                                    <li><span>Paratyroid scan</span> </li>
                                                    <li><span>Full Blood Test</span> </li>
                                                    <li><span>Bronchoscopy</span> </li>
                                                    <li><span>Head</span> </li>
                                                    <li><span>Colonoscopy</span> </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12 sidebar mt-5 mt-lg-0">
                            <div class="widget mb-5">

                                <div class="service-images">
                                    <img class="img-fluid w-100" src="/images/service/Chest.webp" alt="" />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section >

            {/* <!--service details end--> */}





            {/* <!--team start--> */}

            <section>
                <div class="container">
                    <div class="row text-center">
                        <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                            <div class="section-title">
                                <h6>Team</h6>
                                <h2 class="title">Meet Our <span>Doctors</span></h2>
                                <p class="mb-0">Saudi German Hospital Provide Greate Services Exerci tation ullamcorper suscipitorens lobortis nisl ut aliquip ex ea commodo, Exerci tation ullamcorper suscipitorens Ut elit tellus.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">

                        <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
                            <div class="team-member text-center">
                                <div class="team-images">
                                    <img class="img-fluid" src="/images/team/02.jpg" alt="" />
                                    <div class="team-about">
                                        <p>earned MBBCH from Ain Shams University in 2007 and M.Sc in chest diseases from Ain Shams University in 2012.  </p>
                                        <div class="team-social-icon">
                                            <ul>
                                                <li><Link to=""><i class="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="team-description"> <span>Doctor</span>
                                    <h5><Link to="doctor-single.html">Dr.Ramy Adel Younan </Link></h5>
                                </div>
                                <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/dr-name`} class="btn btn-sm btn-border btn-theme w-100 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>Personal Page</Link>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
                            <div class="team-member text-center">
                                <div class="team-images">
                                    <img class="img-fluid" src="/images/team/03.jpg" alt="" />
                                    <div class="team-about">
                                        <p>Graduated from Faculty of Medicine, Ain Shams University in 1994. Her subspecialty is Interventional Bronchoscopy, Pulmonary critical care and Lung Transplant.  </p>
                                        <div class="team-social-icon">
                                            <ul>
                                                <li><Link to=""><i class="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to=""><i class="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="team-description"> <span>Doctor</span>
                                    <h5><Link to="doctor-single.html">Dr. Nevine Mohamed Mohamed  </Link></h5>
                                </div>
                                <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/dr-name`} class="btn btn-sm btn-border btn-theme w-100 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>Personal Page</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--team end--> */}

        </div >
    </>
}
