import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LocalizationContext } from '../../Context/LocalizationContext'
import MayoSearch from './MayoSearch'
import axios from 'axios'
import PageTitle from '../Utilites/PageTitle'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

export default function MayoMain() {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const [searchData, setSearchData] = useState([]);
    const [search, setSearch] = useState([]);
    async function getSearchData(e) {
        e.preventDefault()

        if (search) {

            console.log(search);
            let { data } = await axios.post(`https://my-health.sghcairo.net/sgh_api/mayo/public/search_docs?search=${search}`);
            setSearchData(data.Edata);
            console.log('VIDEO DETAILS');
            console.log(data.Edata);
            document.getElementById('categories').classList.add('d-none');


        } else {
            document.getElementById('categories').classList.remove('d-none');

            document.getElementById('categories').classList.add('d-flex');
        }

    }

    useEffect(() => {
        getSearchData()
    }, [])
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Mayo Clinic')} anotherTitle={t('Health Library')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} currentPage={t('Mayo Clinic')} />
            : <PageTitle title={t('Health Library')} anotherTitle={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} currentPage={t('Mayo Clinic')} />}

        {/* <!--page title end--> */}


        {/* <!--body content start--> */}

        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='pt-2'>
                <div className="container">
                    <div className='row justify-content-center mb-5'>
                        <div className="col-lg-6 col-md-12 order-lg-12">
                            <div className="widget">
                                <div className="widget-search">
                                    {/* <form className="form-inline form">
                                        <div className="widget-searchbox">
                                            <button type="submit" className="search-btn"> <i className="fas fa-search"></i>
                                            </button>
                                            <input type="text" placeholder="Search..." className="form-control" />
                                        </div>
                                    </form> */}

                                    <div class="section-title mb-4">
                                        <h6 className='h3'>{t('Search for Articles')}
                                        </h6>

                                        {/* <p class="text-white">Hectolab have facility to produce adipisicing elit, Excepturi vero aliquam id consectetur adipisicing elit, technologicaly changes and industrial systems.</p> */}
                                    </div>
                                    <div class="theme-bg px-5 pb-5 pt-2">

                                        <form className="form-inline form" onSubmit={getSearchData}>
                                            <div class="messages"></div>
                                            <div className="widget-searchbox">
                                                <button type="submit" className="search-btn text-white"> <i className="fas fa-search"></i>
                                                </button>
                                                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={`${t(`Search`)}`} className="form-control" />
                                            </div>
                                        </form>

                                        {/* <div class="col-sm-12  mt-5 mt-sm-0">
                                                <button class="btn btn-white btn-radius"><span>Search Now</span>
                                                </button>
                                            </div> */}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <MayoSearch searchData={searchData} />
                    <div className="row justify-content-center" id='categories'>
                        {/* <h2 className='pb-2'><Link to="">Explore</Link></h2> */}

                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Disease`}>
                                <div className="team-member text-center" role="button">
                                    <div className="team-images">
                                        <img className="img-fluid" src="/images/mayo-clinic/disease-min.webp" alt="" />
                                        <div className="team-about">
                                            {/* <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> */}
                                            {/* <div className="team-social-icon">
                                            <ul>
                                                <li><Link to="/"><i className="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="team-description">
                                        {/* <span>Doctor</span> */}
                                        <h5><Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Disease`}>{t('Disease')}</Link></h5>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/FirstAid`}>
                                <div className="team-member text-center" role="button">
                                    <div className="team-images">
                                        <img className="img-fluid" src="/images/mayo-clinic/first-aid-min.webp" alt="" />
                                        <div className="team-about">

                                        </div>
                                    </div>
                                    <div className="team-description">
                                        {/* <span>Doctor</span> */}
                                        <h5><Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/FirstAid`}>{t('First Aid')}</Link></h5>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Recipe`}>
                                <div className="team-member text-center" role="button">
                                    <div className="team-images">
                                        <img className="img-fluid" src="/images/mayo-clinic/recipes-min.webp" alt="" />
                                        <div className="team-about">
                                        </div>
                                    </div>
                                    <div className="team-description">
                                        {/* <span>Doctor</span> */}
                                        <h5><Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Recipe`}>{t('Recipes')}</Link></h5>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                            <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Symptom`}>
                                <div className="team-member text-center" role="button">
                                    <div className="team-images">
                                        <img className="img-fluid" src="/images/mayo-clinic/symptoms-min.webp" alt="" />
                                        <div className="team-about">
                                            {/* <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> */}
                                            {/* <div className="team-social-icon">
                                            <ul>
                                                <li><Link to="/"><i className="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="team-description">
                                        {/* <span>Doctor</span> */}
                                        <h5><Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Symptom`}>{t('Symptom')}</Link></h5>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-3 col-md-6 mt-5 ">
                            <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/TestProcedure`}>
                                <div className="team-member text-center " role="button">
                                    <div className="team-images">
                                        <img className="img-fluid" src="/images/mayo-clinic/test-procedures-min.webp" alt="" />
                                        <div className="team-about">
                                            {/* <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> */}
                                            {/* <div className="team-social-icon">
                                            <ul>
                                                <li><Link to="/"><i className="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="team-description">
                                        {/* <span>Doctor</span> */}
                                        <h5><Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/TestProcedure`}>{t('Test Procedure')}</Link></h5>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 ">
                            <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Article`}>
                                <div className="team-member text-center" role="button">
                                    <div className="team-images">
                                        <img className="img-fluid" src="/images/mayo-clinic/article-min.webp" alt="" />
                                        <div className="team-about">
                                            {/* <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> */}
                                            {/* <div className="team-social-icon">
                                            <ul>
                                                <li><Link to="/"><i className="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="team-description">
                                        {/* <span>Doctor</span> */}
                                        <h5><Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Article`}>{t('Article')}</Link></h5>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 ">
                            <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Video`}>

                                <div className="team-member text-center" role="button">
                                    <div className="team-images">
                                        <img className="img-fluid" src="/images/mayo-clinic/Video-min.webp" alt="" />
                                        <div className="team-about">
                                            {/* <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> */}
                                            {/* <div className="team-social-icon">
                                            <ul>
                                                <li><Link to="/"><i className="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="team-description">
                                        {/* <span>Doctor</span> */}
                                        <h5><Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Video`}>{t('Video')}</Link></h5>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 ">
                            <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Slideshow`}>

                                <div className="team-member text-center" role="button">
                                    <div className="team-images">
                                        <img className="img-fluid" src="/images/mayo-clinic/health-style-min.webp" alt="" />
                                        <div className="team-about">
                                            {/* <p>Cras ultricies ligula sed magna dictum porta, Sed ut perspiciatis unde omnis iste natus error sit voluptat</p> */}
                                            {/* <div className="team-social-icon">
                                            <ul>
                                                <li><Link to="/"><i className="fab fa-facebook-f"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-twitter"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-google-plus-g"></i></Link>
                                                </li>
                                                <li><Link to="/"><i className="fab fa-linkedin-in"></i></Link>
                                                </li>
                                            </ul>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="team-description">
                                        {/* <span>Doctor</span> */}
                                        <h5><Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Slideshow`}>{t('HealthStyle')}</Link></h5>
                                    </div>
                                </div>
                            </Link>
                        </div>


                    </div>
                </div>
            </section>

            {/* <!--blog end--> */}


        </div>

        {/* <!--body content end--> */}

    </>
}
