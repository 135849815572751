import React from 'react'
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import { useLocation } from 'react-router-dom';
import VisibilitySensor from "react-visibility-sensor";
import ProgressBar from "@ramonak/react-progress-bar";
import { Table } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export default function Reports() {
    const { pathname } = useLocation();

    const option = {
        baseBgColor: '#eceff8',
        position: 'relative',
        overflow: 'visible',
        borderRadius: '5px',
        fontWeight: 'light',
        background: '#4ab242',
        background: '-moz-linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)',
        background: ' -webkit-linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)',
        background: 'linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)',
        filter: "progid: DXImageTransform.Microsoft.gradient(startColorstr = '#4ab242', endColorstr = '#0358d5', GradientType = 1)",
    }
    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Reports' anotherTitle='' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage='About' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/reports`} currentPage='Reports' />

        {/* <!--page title end--> */}
        {/* <!--about us start--> */}

        <section>

            {/* <div className="container">
                <div className="row align-items-center">

                    <div className="section-title mb-4">
                        <h2 className="title">In Patient </h2>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-5 mt-lg-0">

                        <div class="page-content">

                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Room cleanliness </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={91} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Courtesy of person cleaning room </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={90} animateOnRender={true} loop={false} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Nurses Overall </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={90} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Friendliness/courtesy of nurses </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={92} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>

                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Promptness response to call  </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={90} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Nurses' attitude toward requests  </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={91} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Skill of nurses  </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={90} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Friendliness/courtesy of doctors  </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={91} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Staff concern for privacy </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={90} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Staff wash their hands before exam  </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={91} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>

                        </div >
                    </div >
                    <div className="col-lg-6 col-md-12 mt-5 mt-lg-0">


                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 > Likelihood of recommending </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={90} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>
                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 > Overall rating of care  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={90} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>
                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 > Friendliness/courtesy ICU nurses  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={92} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>
                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 >Operating/Recovery Room Overall  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={91} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>

                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 > Rating of surgery experience  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={92} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>
                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 >Rating of O/RR room staff  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={92} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>
                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 > Special Services Overall  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={94} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>
                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 >Phys ther involve you plan/coord  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={95} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}

                                </>
                                }
                            </VisibilitySensor>
                        </div>

                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 >Friendliness/courtesy physical ther   </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={94} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}

                                </>
                                }
                            </VisibilitySensor>
                        </div>

                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 >Phys Ther concern pain/discomfort  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={94} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}

                                </>
                                }
                            </VisibilitySensor>
                        </div>

                    </div >

                </div >

                <div className="row align-items-center">

                    <div className="section-title mb-4">
                        <h2 className="title">OPD</h2>
                    </div>
                    <div className="col-lg-6 col-md-12 mt-5 mt-lg-0">

                        <div class="page-content">


                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 >Ease of scheduling appointments  </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={91} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 > Cleanliness of our practice </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={92} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>
                            <div class="ht-progress-bar">
                                <VisibilitySensor partialVisibility>

                                    {({ isVisible }) => <>
                                        < h4 >Facility Overall  </h4>
                                        {isVisible ? <ProgressBar class="progress" completed={92} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                        </ProgressBar>
                                            : ''}


                                    </>
                                    }
                                </VisibilitySensor>
                            </div>



                        </div >
                    </div >
                    <div className="col-lg-6 col-md-12 mt-5 mt-lg-0">
                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 > Courtesy and respect of PT staff  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={92} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>

                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 > Overall Assessment Overall   </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={90} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>
                        <div class="ht-progress-bar">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>
                                    < h4 > Staff worked together care for you  </h4>
                                    {isVisible ? <ProgressBar class="progress" completed={93} animateOnRender={true} height='5px' {...option} labelAlignment='outside' labelColor='#000' bgColor='linear-gradient(to right, #4ab242 0%, #18c9bf 50%, #3dcaff 50%, #0358d5 100%)'>

                                    </ProgressBar>
                                        : ''}


                                </>
                                }
                            </VisibilitySensor>
                        </div>
                    </div>


                    <div className="col-sm-4">
                        <div className="ht-rounded-skill">

                            <div>
                                <div style={{
                                    position: "relative",
                                    userSelect: "none",
                                    boxSizing: "border-box",
                                    width: "150px",
                                    height: "150px",
                                    margin: "auto",
                                }}>

                                    <VisibilitySensor>

                                        {({ isVisible }) => {
                                            const percentage = isVisible ? 91 : 0;
                                            return (


                                                <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={buildStyles({
                                                        pathTransitionDuration: 2,

                                                    })}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        boxSizing: "border-box",
                                                        border: " 5px solid #eeeeee",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        stroke: "#409639",
                                                        backgroundColor: "#409639",
                                                        fill: "#409639",
                                                    }}
                                                />


                                            );
                                        }}


                                    </VisibilitySensor>
                                </div>
                            </div>
                            <span className="skill-title text-center">Ease of scheduling appointments </span>

                        </div>
                    </div>
                    <div className="col-sm-4 mt-5 mt-sm-0">
                        <div className="ht-rounded-skill">
                       
                            <div>
                                <div style={{
                                    position: "relative",
                                    userSelect: "none",
                                    boxSizing: "border-box",
                                    width: "150px",
                                    height: "150px",
                                    margin: "auto",
                                }}>

                                    <VisibilitySensor>

                                        {({ isVisible }) => {
                                            const percentage = isVisible ? 92 : 0;
                                            return (


                                                <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={buildStyles({
                                                        pathTransitionDuration: 2,

                                                    })}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        boxSizing: "border-box",
                                                        border: " 5px solid #eeeeee",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        stroke: "#409639",
                                                        backgroundColor: "#409639",

                                                    }}
                                                />


                                            );
                                        }}


                                    </VisibilitySensor>
                                </div>
                            </div>

                            <span className="skill-title text-center">Cleanliness of our practice</span>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-5 mt-sm-0">
                        <div className="ht-rounded-skill">
                       
                            <div>
                                <div style={{
                                    position: "relative",
                                    userSelect: "none",
                                    boxSizing: "border-box",
                                    width: "150px",
                                    height: "150px",
                                    margin: "auto",
                                }}>

                                    <VisibilitySensor>

                                        {({ isVisible }) => {
                                            const percentage = isVisible ? 92 : 0;
                                            return (


                                                <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={buildStyles({
                                                        pathTransitionDuration: 2,

                                                    })}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        boxSizing: "border-box",
                                                        border: " 5px solid #eeeeee",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        stroke: "#409639",
                                                        backgroundColor: "#409639",
                                                        fill: "#409639",
                                                    }}
                                                />


                                            );
                                        }}


                                    </VisibilitySensor>
                                </div>
                            </div>

                            <span className="skill-title text-center">Facility Overall</span>
                        </div>
                    </div>



                    <div className="col-sm-4 mt-5 mt-sm-0">
                        <div className="ht-rounded-skill">

                            <div>
                                <div style={{
                                    position: "relative",
                                    userSelect: "none",
                                    boxSizing: "border-box",
                                    width: "150px",
                                    height: "150px",
                                    margin: "auto",
                                }}>

                                    <VisibilitySensor>

                                        {({ isVisible }) => {
                                            const percentage = isVisible ? 92 : 0;
                                            return (


                                                <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={buildStyles({
                                                        pathTransitionDuration: 2,

                                                    })}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        boxSizing: "border-box",
                                                        border: " 5px solid #eeeeee",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        stroke: "#409639",
                                                        backgroundColor: "#409639",
                                                        fill: "#409639",
                                                    }}
                                                />


                                            );
                                        }}


                                    </VisibilitySensor>
                                </div>
                            </div>
                            <span className="skill-title text-center"> Courtesy and respect of PT staff </span>

                        </div>
                    </div>
                    <div className="col-sm-4 mt-5 mt-sm-0">
                        <div className="ht-rounded-skill">
                           
                            <div>
                                <div style={{
                                    position: "relative",
                                    userSelect: "none",
                                    boxSizing: "border-box",
                                    width: "150px",
                                    height: "150px",
                                    margin: "auto",
                                }}>

                                    <VisibilitySensor>

                                        {({ isVisible }) => {
                                            const percentage = isVisible ? 90 : 0;
                                            return (


                                                <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={buildStyles({
                                                        pathTransitionDuration: 2,

                                                    })}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        boxSizing: "border-box",
                                                        border: " 5px solid #eeeeee",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        stroke: "#409639",
                                                        backgroundColor: "#409639",
                                                        fill: "#409639",
                                                    }}
                                                />


                                            );
                                        }}


                                    </VisibilitySensor>
                                </div>
                            </div>

                            <span className="skill-title text-center">Overall Assessment Overall </span>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-5 mt-sm-0">
                        <div className="ht-rounded-skill">
                        
                            <div>
                                <div style={{
                                    position: "relative",
                                    userSelect: "none",
                                    boxSizing: "border-box",
                                    width: "150px",
                                    height: "150px",
                                    margin: "auto",
                                }}>

                                    <VisibilitySensor>

                                        {({ isVisible }) => {
                                            const percentage = isVisible ? 93 : 0;
                                            return (


                                                <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={buildStyles({
                                                        pathTransitionDuration: 2,

                                                    })}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        boxSizing: "border-box",
                                                        border: " 5px solid #eeeeee",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        stroke: "#409639",
                                                        backgroundColor: "#409639",
                                                        fill: "#409639",
                                                    }}
                                                />


                                            );
                                        }}


                                    </VisibilitySensor>
                                </div>
                            </div>

                            <span className="skill-title text-center">Staff worked together care for you </span>
                        </div>
                    </div>

                </div >

            </div > */}

            <div className='container'>
                <div className="row align-items-center">

                    <div className="section-title mb-4">
                        <h2 className="title">In Patient </h2>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">ROOM CLEANLINESS</h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='80'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 91 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "50%",
                                                    boxSizing: "border-box",
                                                    border: " 5px solid #eeeeee",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    stroke: "#3e98c7 !important",
                                                    backgroundColor: "#3e98c7 !important",
                                                    fill: "#3e98c7 !important",
                                                }}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">LIKELIHOOD OF RECOMMENDING</h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='90'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 90 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">COURTESY OF PERSON CLEANING ROOM
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='90'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 90 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">OVERALL RATING OF CARE
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='90'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 90 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">NURSES OVERALL
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='90'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 90 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">FRIENDLINESS/COURTESY ICU NURSES</h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='92'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 92 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">FRIENDLINESS/COURTESY OF NURSES</h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='92'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 92 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">OPERATING/RECOVERY ROOM OVERALL
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='91'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 91 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">PROMPTNESS RESPONSE TO CALL
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='90'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 90 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">RATING OF SURGERY EXPERIENCE

                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='92'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 92 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">NURSES' ATTITUDE TOWARD REQUESTS

                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='91'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 91 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">RATING OF O/RR ROOM STAFF
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='92'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 92 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">SKILL OF NURSES
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='90'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 90 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">SPECIAL SERVICES OVERALL
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='94'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 94 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">FRIENDLINESS/COURTESY OF DOCTORS

                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='91'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 91 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">PHYS THER INVOLVE YOU PLAN/COORD
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='95'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 95 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">STAFF CONCERN FOR PRIVACY
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='90'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 90 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">FRIENDLINESS/COURTESY PHYSICAL THER

                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='94'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 94 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">STAFF WASH THEIR HANDS BEFORE EXAM
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='91'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 91 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">PHYS THER CONCERN PAIN/DISCOMFORT
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='94'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 94 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">

                    <div className="section-title mb-4">
                        <h2 className="title">OPD</h2>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">EASE OF SCHEDULING APPOINTMENTS</h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='91'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 91 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "50%",
                                                    boxSizing: "border-box",
                                                    border: " 5px solid #eeeeee",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    stroke: "#3e98c7 !important",
                                                    backgroundColor: "#3e98c7 !important",
                                                    fill: "#3e98c7 !important",
                                                }}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">COURTESY AND RESPECT OF PT STAFF</h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='92'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 92 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">CLEANLINESS OF OUR PRACTICE
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='92'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 92 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">OVERALL ASSESSMENT OVERALL

                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='90'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 90 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">FACILITY OVERALL
                            </h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='92'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 92 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 mb-4">
                        <div class="bg-white rounded-lg p-5 shadow">
                            <h2 class="h6 font-weight-bold text-center mb-4">STAFF WORKED TOGETHER CARE FOR YOU</h2>

                            {/* <!-- Progress bar 1 --> */}

                            <div class="progress mx-auto" data-value='93'>
                                <span class="progress-left">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                <span class="progress-right">
                                    <span class="progress-bar border-primary"></span>
                                </span>
                                {/* <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center"> */}
                                {/* <div class="h2 font-weight-bold">80<sup class="small">%</sup></div> */}

                                <VisibilitySensor>

                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 93 : 0;
                                        return (


                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    pathTransitionDuration: 2,

                                                })}

                                            />


                                        );
                                    }}


                                </VisibilitySensor>
                                {/* </div> */}
                            </div>
                            {/* <!-- END --> */}

                            {/* <!-- Demo info --> */}

                            {/* <!-- END --> */}
                        </div>
                    </div>
                </div>

            </div>

            <div className="container mt-5 price-table style-2 active" data-bg-img="/images/bg/01.jpg" style={{ backgroundImage: 'url("/images/bg/01.jpg")' }}>
                <div className="price-inner pt-0">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabel ">

                                <Table striped bordered hover className='text-center '>
                                    <thead>
                                        <tr>

                                            <th colSpan={8} className='fs-3 fw-semibold  bg-new text-white' >OPD Facts</th>

                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th colSpan={1}></th>
                                            <th>Jan-23</th>
                                            <th>Feb-23</th>
                                            <th>Mar-23</th>
                                            <th>Apr-23</th>
                                            <th>May-23</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th colSpan={1}>Average Door to balloon in time</th>
                                            <td>0:25</td>
                                            <td>0:26</td>
                                            <td>0:42</td>
                                            <td>0:46</td>
                                            <td>0:47</td>

                                        </tr>
                                        <tr>
                                            <th colSpan={1}>Target: less than</th>
                                            <td>1:30</td>
                                            <td>1:30</td>
                                            <td>1:30</td>
                                            <td>1:30</td>
                                            <td>1:30</td>

                                        </tr>


                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row mt-5 text-end">
                    <div class="team-description section-title  mb-0">
                        <h6> <span className='fw-bolder'>Last Updated : </span> 10 / 10 / 2023</h6>
                    </div>
                </div>
            </div>
        </section >

        {/* <!--about us end--> */}
    </>
}
