import React from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../../Utilites/PageTitle';
import OurPartnersDetails from '../../Utilites/OurPartnersDetails';
export default function ISO() {
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='ISO' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/`} prevPage='Our Partner' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/iso`} currentPage='ISO' />


        {/* <!--page title end--> */}


        {/* <!--about us start--> */}
        <OurPartnersDetails partnerImgSrc="/images/partners/iso-certification.jpg" partnerTitle='ISO' partnerDescription='Food safety management systems
SO 22000:2005 specifies requirements for a food safety management system where an organization in the food chain needs to demonstrate its ability to control food safety hazards in order to ensure that food is safe at the time of human consumption.
It is applicable to all organizations, regardless of size, which are involved in any aspect of the food chain and want to implement systems that consistently provide safe products. The means of meeting any requirements of ISO 22000:2005 can be accomplished through the use of internal and/or external resources.' />


        {/* <!--about us end--> */}
    </>
}
