import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
export default function MedicalEducation() {
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}

        {/* <PageTitle title='Medical Education' anotherTitle='Unit' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/medical-education-unit`} currentPage='Medical Education Unit' /> */}


        {/* <!--page title end--> */}


        {/* <!--body content start--> */}

        <section className="fullscreen-banner dark-bg banner-2 text-center grediant-overlay p-0 overflow-hidden" data-bg-img="/images/bg/04.webp" data-overlay="7" style={{ backgroundImage: `url('/images/bg/04.webp')` }}>
            <div className="pt-0" style={{ top: '20%' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 mx-auto">
                            <h1 className="text-white mt-8 animated7 font-w-7">Medical Education Unit </h1>
                            <p className='text-white my-4 text-justify'>The Medical Education Unit at Saudi German Hospital is dedicated to advancing medical excellence through well-integrated education programs and top-tier scientific activities. Our comprehensive training programs for healthcare providers ensure standardized competence at all levels. Through collaborations with prestigious institutions like Mayo Clinic and the American Heart Association, we actively contribute to shaping a healthcare ecosystem committed to the highest standards of education, innovation, and excellence.</p>
                            <div className="video-btn animated6 mt-5"> <Link className="popup-youtube" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/medical-education-unit/features`}><span className="btn-play">Learn More</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}
