import React from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../../Utilites/PageTitle';
import OurPartnersDetails from '../../Utilites/OurPartnersDetails';
export default function CanadianAccreditation() {
    const { pathname } = useLocation();

    return <>

        {/* <!--page title start--> */}
        <PageTitle title='Canadian Accreditation' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/`} prevPage='Our Partner' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/aast`} currentPage='Canadian Accreditation' />


        {/* <!--page title end--> */}


        {/* <!--about us start--> */}
        <OurPartnersDetails partnerImgSrc="/images/partners/logo-en.jpg" partnerTitle='Arab Academy for Science & Technology AAST' partnerDescription='Saudi German Health Egypt joins hands with the prestigious Arab Academy for Science and Technology. This dynamic partnership fosters a Master in Business Administration in Hospital Management.' />


        {/* <!--about us end--> */}
    </>
}
