import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import { ReactRadioButtonsGroup, ReactRadioButton } from 'react-radio-buttons-group';
import { useState } from 'react';
export default function ReferAPatient() {
    const { pathname } = useLocation();
    const [gender, setGender] = useState('')

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Refer A' anotherTitle='Patient' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/refer-patient`} currentPage='Refer A Patient' />
        {/* <!--page title end--> */}
        {/* <!--appointment start--> */}

        <section class="dark-bg position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 h-100 image-column image-column-h bg-pos-l" data-bg-img="../../images/bg/04.jpg" style={{ backgroundImage: 'url("../../images/bg/04.jpg")' }}></div>
                    <div class="col-lg-6 col-md-12 ms-auto ps-lg-5 mt-5 mt-lg-0">
                        <div class="section-title mb-4">
                            <h2 className='text-light'>Refer A <span>Patient !</span>
                            </h2>
                            <p class="line-h-3 text-white">We have a secure online referral service to help you refer patients to Saudi German Hospital
                            </p>
                            <h6 className='title'>Referring Physician’s Details :  </h6>
                        </div>

                        <form id="queto-form" class="row" method="post"
                            action="https://themeht.com/template/hectolab/html/ltr/php/contact.php">
                            <div class="messages"></div>
                            <div class="form-group col-sm-6">
                                <input id="form_name1" type="text" name="name" class="form-control" placeholder="Your Name"
                                    required="required" data-error="Name is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input id="form_email1" type="email" name="email" class="form-control" placeholder="Your Email"
                                    required="required" data-error="Valid email is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input id="form_number" type="text" name="name" class="form-control" placeholder="Phone Number"
                                    required="required" />
                            </div>

                            <div class="form-group col-sm-6">
                                <select class="form-select form-control">
                                    <option selected>- Select Specialty</option>
                                    <option value="1">Cardiology</option>
                                    <option value="2">Blood Test</option>
                                    <option value="3">Dental</option>
                                    <option value="4">Orthopaedics</option>
                                    <option value="5">Neurology</option>
                                    <option value="6">Outdoor Checkup</option>
                                    <option value="7">Pharmacy</option>
                                </select>
                            </div>
                            <div className='section-title my-4'>
                                <h6 className='title'>Referred Patient Details :  </h6>
                            </div>
                            <div class="form-group col-sm-6">
                                <input id="form_name1" type="text" name="name" class="form-control" placeholder="Patient’s Name"
                                    required="required" data-error="Name is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input id="form_email1" type="email" name="email" class="form-control" placeholder="Patient’s Email"
                                    required="required" data-error="Valid email is required." />
                                <div class="help-block with-errors"></div>
                            </div>
                            <div class="form-group col-sm-6">
                                <input id="form_number" type="text" name="name" class="form-control" placeholder="Patient’s Number"
                                    required="required" />
                            </div>
                            <div class="form-group col-sm-6">
                                <input id="form_age" type="number" name="age" class="form-control" placeholder="Patient’s Age"
                                    required="required" />
                            </div>



                            <div class="form-group col-sm-6">

                                <input id="form_data" type="text" name="data" class="form-control" placeholder="Patient’s medical data"
                                    required="required" />
                            </div>

                            <div class="form-group col-sm-6">

                                <input id="form_file" type="file" name="file" class="form-control" placeholder="Upload any medical documents"
                                    required="required" />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="" className='text-white'>Patient's Gender</label>
                            </div>

                            <div class="form-group col-sm-6">

                                <ReactRadioButtonsGroup group='sex' onChange={(value) => setGender(value)}>
                                    <ReactRadioButton value='0' className="lead">Male</ReactRadioButton>
                                    <ReactRadioButton value='1'>Female</ReactRadioButton>
                                </ReactRadioButtonsGroup>
                                {/* <input value={height} onChange={(event) => setHeight(event.target.value)} class="form-control" placeholder="Your Height" required="required" data-error="Valid Your Height is required." /> */}
                                <div class="help-block with-errors"></div>
                            </div>
                            <div className='section-title mb-4'>
                                <h6 className='title'>Refer to :  </h6>
                            </div>
                            <div class="form-group col-sm-6">
                                <input id="form_name1" type="text" name="name" class="form-control" placeholder="Doctor’s Name"
                                    required="required" data-error="Name is required." />
                                <div class="help-block with-errors"></div>
                            </div>

                            <div class="form-group col-sm-6">
                                <select class="form-select form-control">
                                    <option selected>- Select Doctor’s Specialty</option>
                                    <option value="1">Cardiology</option>
                                    <option value="2">Blood Test</option>
                                    <option value="3">Dental</option>
                                    <option value="4">Orthopaedics</option>
                                    <option value="5">Neurology</option>
                                    <option value="6">Outdoor Checkup</option>
                                    <option value="7">Pharmacy</option>
                                </select>
                            </div>
                            <div class="col-sm-12 mt-5">
                                <button class="btn btn-theme btn-radius"><span>Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </section >

        {/* <!--appointment end--> */}
    </>
}
