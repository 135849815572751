import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';

export default function OurServices() {
    const { pathname } = useLocation();
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const options = {
        margin: 30,
        responsiveClass: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },

            1000: {
                items: 3,

            }
        },
    }

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Our' anotherTitle='Services' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services`} currentPage='Services' />
        {/* <!--page title end--> */}


        {/* <!--body content start--> */}


        <div className="page-content">

            {/* <!--service start--> */}

            <section class="text-center">
                <div class="container">
                    <div class="row">

                        <div class="service-item col-lg-4">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services/laboratory-and-blood-bank`} onClick={scrollToTop}>
                                <div class="service-images">
                                    <img class="img-fluid" src="../../images/service/01.jpg" alt="" />
                                    <div class="service-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                </div>
                                <h4>Laboratory & Blood Bank</h4>
                                <div class="service-description text-black">
                                    <p>
                                        Saudi Gorman Hospital operates a full-service laboratory, 24 hours a day, seven days a week.
                                        The laboratory scope including the following specialties: Clinical Hematology / Clinical Chemistry/ Clinical immunology / Clinical Microbiology / Bone Marrow diagnosis unit / Flow cytometry / Blood bank unit /</p> <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services/laboratory-and-blood-bank`} onClick={scrollToTop}>Read More <i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                </div>
                            </Link>
                        </div>


                        <div class="service-item col-lg-4">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services/physiotherapy-department`} onClick={scrollToTop}>
                                <div class="service-images">
                                    <img class="img-fluid" src="../../images/service/02.jpg" alt="" />
                                    <div class="service-icon"> <i class="flaticon-health"></i>
                                    </div>
                                </div>
                                <h4>Physiotherapy Department</h4>
                                <div class="service-description text-black">
                                    <p>Our Physiotherapy and ilitation department is a global leader in providing the most advanced evidence-based practice protocols and cutting-edge services that enable our patients to regain their functional abilities and get back to daily activities without pain or functional constraints.</p> <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services/physiotherapy-department`} onClick={scrollToTop}>Read More <i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                </div>
                            </Link>
                        </div>


                        <div class="service-item col-lg-4">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services/radiology`} onClick={scrollToTop}>
                                <div class="service-images">
                                    <img class="img-fluid" src="../../images/service/03.jpg" alt="" />
                                    <div class="service-icon"> <i class="flaticon-brain"></i>
                                    </div>
                                </div>
                                <h4>Radiology</h4>
                                <div class="service-description text-black">
                                    <p>Saudi German Hospital is providing an Ultramodern Digital Radiography & Diagnostic Medical Imaging, Interventional Radiology & Nuclear Medicine departments (PET CT & Gamma Camera ) fully equipped with state of art high technology imaging modalities, being managed by</p> <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services/radiology`} onClick={scrollToTop}>Read More <i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                </div>
                            </Link>
                        </div>



                    </div>
                </div>
            </section>



        </div>
    </>
}
