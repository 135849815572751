import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../../../Utilites/PageTitle';
import Carousel from "react-bootstrap/Carousel";

export default function PhysiotherapyDepartment() {
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Physiotherapy' anotherTitle='Department' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/services`} prevPage='Our Services' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/services/physiotherapy-department`} currentPage='Physiotherapy Department' />
        {/* <!--page title end--> */}

        <div className="page-content">

            {/* <!--service start--> */}

            <section>
                <div class="container">
                    {/* <!--featured end--> */}
                    <section className='pt-0'>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5 col-md-12">
                                    <Carousel indicators={false} slide={true}>
                                        <Carousel.Item>

                                            <img
                                                src="/images/testimonial/03.jpg"
                                                className="d-block w-100"
                                                alt="screenshot"
                                            />

                                        </Carousel.Item>
                                        <Carousel.Item>

                                            <img
                                                src="/images/testimonial/02.jpg"
                                                className="d-block w-100"
                                                alt="screenshot"
                                            />

                                        </Carousel.Item>
                                        <Carousel.Item>

                                            <img
                                                src="/images/testimonial/01.jpg"
                                                className="d-block w-100"
                                                alt="screenshot"
                                            />

                                        </Carousel.Item>
                                        <Carousel.Item>

                                            <img
                                                src="/images/testimonial/03.jpg"
                                                className="d-block w-100"
                                                alt="screenshot"
                                            />

                                        </Carousel.Item>
                                        <Carousel.Item>

                                            <img
                                                src="/images/testimonial/02.jpg"
                                                className="d-block w-100"
                                                alt="screenshot"
                                            />

                                        </Carousel.Item>

                                    </Carousel>
                                </div>
                                <div className="col-lg-7 col-md-12 mt-5 mt-lg-0">

                                    <div className="section-title mb-4">
                                        <h2 class="title"> Our Services (Allied Health)</h2>
                                        <h6 className='h5'><span>Physiotherapy Department </span></h6>

                                        <p> Our Physiotherapy and ilitation department is a global leader in providing the most advanced evidence-based practice protocols and cutting-edge services that enable our patients to regain their functional abilities and get back to daily activities without pain or functional constraints.</p>
                                        <p>Our Highly qualified team of Physical therapists' experts are treating our patients through evidence-based practice and state of art equipment to ensure safe and effective treatment.</p>
                                        <p className="mb-3">Numerous Subspecialties are available to meet our patient’s demands and all age groups:</p>

                                    </div>
                                </div>
                                <div className="footer-list ps-lg-5 mt-5" >
                                    <h5>Scope of  <span className="text-theme">service</span></h5>
                                    <div class="featured-item left-icon mt-5">
                                        <div class="featured-icon"> <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="featured-title text-uppercase">
                                            <h5>Orthopedic And Sports Injuries</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p className="mb-3">Sports Injured Patients and others suffering Joints pain, muscle injuries, chronic arthritis, fractures or post orthopedic surgeries can benefit from diverse modalities of magnetic therapy, Laser therapy, therapeutic exercises, manual therapy, hydrotherapy, recovery techniques, taping, cryotherapy, and profit from advanced protocols in our unique gym area by our specialized team.</p>
                                        </div>
                                    </div>
                                    <div class="featured-item left-icon">
                                        <div class="featured-icon"> <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="featured-title text-uppercase">
                                            <h5>Hydrotherapy Services:</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p className="mb-3">Hydrotherapy is an excellence center that provides high quality unique services for our patients whether they are recovering from surgeries, having chronic pains, willing to improve their wellness or suffering from neurological diseases that inhibits their ability to function normally. Also, pediatric ilitation in hydro is available for children recovering from sports injuries or cerebral palsy and other neurological and muscle diseases. </p>
                                        </div>
                                    </div>
                                    <div class="featured-item left-icon">
                                        <div class="featured-icon"> <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="featured-title text-uppercase">
                                            <h5>Spine diseases</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p className="mb-3">Our team is pleased to help patients with back pain, neck pain, spine diseases, disc prolapse and other spine problems through Manual therapy, Spine mobilization, electrotherapy, and specially tailored exercises programs to help pain relief and improving quality of life.</p>
                                        </div>
                                    </div>
                                    <div class="featured-item left-icon">
                                        <div class="featured-icon"> <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="featured-title text-uppercase">
                                            <h5>Neurological ilitation</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p className="mb-3">Patients with recent or old stroke, brain Hemorrhage, peripheral neuropathies, brain tumors, paraplegia, Multiple sclerosis, Parkinson diseases and others can benefit from standardized assessment and interventions provided using highest technological solutions in the region.</p>
                                        </div>
                                    </div>
                                    <div class="featured-item left-icon">
                                        <div class="featured-icon"> <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="featured-title text-uppercase">
                                            <h5>Cardio-pulmonary ilitation</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p className="mb-3">Our aim is improving quality of life through supporting heart and lung functions to get back to daily activities with ease after heart surgeries, post CABAGE, Myocardial infarction, COVID-19, patients with chronic pulmonary diseases and heart failure patients.</p>
                                        </div>
                                    </div>
                                    <div class="featured-item left-icon">
                                        <div class="featured-icon"> <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="featured-title text-uppercase">
                                            <h5>Lymphedema management</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p className="mb-3">Lymphatic system physical therapy is done using lymphatic pressure devices, manual therapy, specific exercises program and bandaging to help our patients through their supportive management by a specialized team.</p>
                                        </div>
                                    </div>
                                    <div class="featured-item left-icon">
                                        <div class="featured-icon"> <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="featured-title text-uppercase">
                                            <h5>Pre and Postpartum </h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p className="mb-3">Pre- and post-partum programs help moms stay active, fit, flexible, and prepared for normal labor, also help restoring core and pelvic muscles strength, fitness level and avoid possible complications after delivery.</p>
                                        </div>
                                    </div>
                                    <div class="featured-item left-icon">
                                        <div class="featured-icon"> <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="featured-title text-uppercase">
                                            <h5>Pediatrics ilitation </h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p className="mb-3">Developmental assessments and interventions for children with Cerebral palsy, Spina bifida, congenital anomalies and genetic diseases are provided to define children's needs and support their ilitation journey.</p>
                                        </div>
                                    </div>
                                    <div class="featured-item left-icon">
                                        <div class="featured-icon"> <i class="flaticon-stethoscope"></i>
                                        </div>
                                        <div class="featured-title text-uppercase">
                                            <h5>Inpatient Services</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p className="mb-3">During admission we provide ultimate care through our unique Chest physical therapy and mobility interventions. Patients admitted for general surgeries, neurological diseases, ICU, Cardiac patients and others are managed by specialized teams and can benefit from all  solutions available.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </section>


        </div>
    </>
}
