import React, { useContext, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
// import OwlCarousel from 'react-owl-carousel';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Cookies from "js-cookie";
import Carousel from "react-bootstrap/Carousel";
import Select from 'react-select';
import axios from 'axios';
import { GetDepartmentsOptionsContext } from '../../Context/GetDepartmentsOptions';
import { GetDoctorsOptionsContext } from '../../Context/GetDoctorsOptions';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

// import { easeQuadInOut } from 'd3-ease';
// import AnimatedProgressProvider from "./../../AnimatedProgressProvider";
// import ChangingProgressProvider from "./../../ChangingProgressProvider";
// Radial separators
// import RadialSeparators from "./../../RadialSeparators";

// import { render } from "react-dom";
export default function Home() {
    // translation 
    const { t } = useTranslation("Home");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    const startValue = 0;
    const endValue = 2500000;
    const formatNumber = (value) => {
        const stringValue = parseFloat(value).toPrecision();
        const formattedValue = stringValue.replace(/0/g, '').replace(/(\d)(?=(\d{1})+(?!\d))/g, '$1.');
        return formattedValue;
    };
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    let { GetDoctorsSelectOptions, DoctorsOptions, selectedOption, setSelectedOption, setDoctorsOptions, selectedBranch, setSelectedBranch } = useContext(GetDoctorsOptionsContext);

    const { pathname } = useLocation();

    const client = {
        autoplay: true,
        loop: true,
        margin: 30,
        responsiveClass: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 3,
            },
            600: {
                items: 4,
            },

            1000: {
                items: 5,

            }
        },
    }

    const brancheOptions = [
        { value: '1', label: 'Cairo' },
        { value: '2', label: 'Alexandria' },
    ]
    const defaultValue = { value: 'Cairo', label: 'Cairo' };

    // const [DoctorsOptions, setDoctorsOptions] = useState('');
    const handleSelectBranchChange = (selectedBranch) => {

        setSelectedBranch(selectedBranch);
        console.log('selectedBranch ' + selectedBranch.value);

    };
    const handleSelectChange = (selectedOption, DoctorsOptions) => {

        setSelectedOption(selectedOption);
        setDoctorsOptions(DoctorsOptions);

    };
    const handleDoctorSelectChange = (DoctorsOptions) => {
        // setSelectedOption(selectedOption);

        setDoctorsOptions(DoctorsOptions);
    };

    function preventDefault(e) {
        e.preventDefault();

    }
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState([]);
    let search = []
    function bookingResult() {

        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
    }
    let [currentPageForSearch, setCurrentPageForSearch] = useState(1)
    // let [lastPageOfSearch, setLastPageOfSearch] = useState()
    // let [perPageOfSearch, setPerPageOfSearch] = useState()
    // let [totalOfSearch, setTotalOfSearch] = useState()
    async function doctorSearch(e) {
        if (e) {
            e.preventDefault();

        }
        // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
        if (selectedBranch || selectedOption || DoctorsOptions) {
            let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/en?search_hospital=${selectedBranch.value}&search_name=&search_title[]=&search_title[]=&search_title[]=&gender[]=&gender[]=&search_department=${selectedOption.value}&doc_id=${DoctorsOptions.value}&page=${currentPageForSearch}&orderby=&order=`);
            setSearchData(data.Edata.data);
            // setSearchData(data.Edata.data);
            // setPerPageOfSearch(data.Edata.per_page)
            // setLastPageOfSearch(data.Edata.last_page)
            // setTotalOfSearch(data.Edata.total);
            // console.log('tototofsearch' + totalOfSearch);
            // <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`} />
            // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
        }

    }
    useEffect(() => {
        GetDepartmentsSelectOptions();
    }, [])

    useEffect(() => {
        GetDoctorsSelectOptions();
    }, [selectedOption])


    useEffect(() => {
        if (selectedBranch) {
            search = searchData?.filter((doctorSearch) => selectedBranch === doctorSearch.branch)

        } else if (selectedOption) {
            search = searchData?.filter((doctorSearch) => selectedOption === doctorSearch.department_id)

        } else if (DoctorsOptions) {
            search = searchData?.filter((doctorSearch) => DoctorsOptions === doctorSearch.doc_id)

        } else {
            search = searchData?.map((doctorSearch))
        }
        doctorSearch();
    }
        , [selectedOption, DoctorsOptions, selectedBranch])

    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }


    // events

    const [allEvents, SetAllEvents] = useState([]);
    async function getEvents(hospital_id) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/event/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    SetAllEvents(response.data.Edata);

                    // console.log('all services ' + response.data.Edata.data);


                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getEvents('1');
    }, [])


    // news section
    let [currentPage, setCurrentPage] = useState(1)
    const [allNews, setAllNews] = useState([]);
    const [allNewsMsg, setAllNewsMsg] = useState('');

    async function getNews(hospital_id) {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }

        const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/news/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}&page=${currentPage}&in_home=1`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    setAllNews(response.data.Edata.data);
                    setAllNewsMsg('');

                } else {
                    setAllNewsMsg('There is No News');
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    // useEffect(() => {
    //     getNews('0');
    // }, [currentPage]);

    // video modal
    const [isOpen, setIsOpen] = useState(false);
    const [selectedVideoId, setSelectedVideoId] = useState('');

    const openModal = (videoId) => {
        // Perform any necessary operations before opening the modal
        console.log('Opening modal for video:', videoId);
        setSelectedVideoId(videoId);

        setIsOpen(true);
    };

    const closeModal = () => {
        setSelectedVideoId('');
        setIsOpen(false);
    };

    useEffect(() => {
        getNews('1');
    }, []);

    const animationStyle = {
        playState: 'running'
    };
    return <>

        {/* <!--body content start--> */}

        <div className="page-content">
            <section className="p-0 position-relative d-md-block d-none">
                <Carousel controls={true} loop={true} indicators={true} slide={true} class="owl-carousel no-pb slide-arrow-2" data-items="1" data-dots="false" data-nav="true"
                    data-autoplay="true">
                    <Carousel.Item class="item">

                        <img
                            src="/images/Saudi German Health Sliders/cairo-branch--min.webp"
                            className=" d-block w-100"
                            alt="screenshot"

                        />
                        <div className="align-center pt-0 text-center" style={{ top: '20%' }}>
                            <div className="container">
                                <div className="row text-center justify-content-center">
                                    <div className="col-lg-10 col-md-12">
                                        {/* <h5 className="text-white letter-space-1 mb-3 animated6" data-wow-delay="4s"><span className="p-2 theme-bg">Medical Center</span></h> */}
                                        {/* <h2 className="text-black mb-3 animated5">What Makes Us Different
                                        </h2>
                                        <h2 className="text-black mb-3 animated5" style={{ fontSize: '55px' }}>Saudi German Hospital</h2> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item class="item">

                        <img
                            src="/images/Saudi German Health Sliders/alex-branch--min.webp"
                            className=" d-block w-100"
                            alt="screenshot"

                        />
                        <div className="align-center pt-0 text-center" style={{ top: '20%' }}>
                            <div className="container">
                                <div className="row text-center justify-content-center">
                                    <div className="col-lg-10 col-md-12">
                                        {/* <h5 className="text-white letter-space-1 mb-3 animated6" data-wow-delay="4s"><span className="p-2 theme-bg">Medical Center</span></h5> */}
                                        {/* <h2 className="text-black mb-3 animated5">What Makes Us Different
                                        </h2>
                                        <h2 className="text-black mb-3 animated5" style={{ fontSize: '55px' }}>Saudi German Hospital</h2> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item class="item">

                        {i18n.language === 'en' ?
                            <img
                                src="/images/Saudi German Health Sliders/option1-min.webp"
                                className=" d-block w-100"
                                alt="screenshot"

                            /> : <img
                                src="/images/Saudi German Health Sliders/academy_result.webp"
                                className=" d-block w-100"
                                alt="screenshot"

                            />}
                        <div className="align-center pt-0 text-center" style={{ top: '20%' }}>
                            <div className="container">
                                <div className="row text-center justify-content-center">
                                    <div className="col-lg-10 col-md-12">
                                        {/* <h5 className="text-white letter-space-1 mb-3 animated6" data-wow-delay="4s"><span className="p-2 theme-bg">Medical Center</span></h5> */}
                                        {/* <h2 className="text-black mb-3 animated5">What Makes Us Different
                </h2>
                <h2 className="text-black mb-3 animated5" style={{ fontSize: '55px' }}>Saudi German Hospital</h2> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item class="item">

                        {i18n.language === 'en' ? <img
                            src="/images/Saudi German Health Sliders/mayo clinic -min.webp"
                            className=" d-block w-100"
                            alt="screenshot"

                        /> : <img
                            src="/images/Saudi German Health Sliders/mayo clinic _result.webp"
                            className=" d-block w-100"
                            alt="screenshot"

                        />}
                        <div className="align-center pt-0 text-center" style={{ top: '20%' }}>
                            <div className="container">
                                <div className="row text-center justify-content-center">
                                    <div className="col-lg-10 col-md-12">
                                        {/* <h5 className="text-white letter-space-1 mb-3 animated6" data-wow-delay="4s"><span className="p-2 theme-bg">Medical Center</span></h5> */}
                                        {/* <h2 className="text-black mb-3 animated5">What Makes Us Different
</h2>
<h2 className="text-black mb-3 animated5" style={{ fontSize: '55px' }}>Saudi German Hospital</h2> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                </Carousel>
                {/* egypt health appointment banner */}
                {/* <div className="align-center booking-banner pt-0">
                    <div className="container">
                        <div className="row">
                            <div class="col-xl-8 col-lg-8 col-md-8 mx-auto">
                                <div class="white-bg box-shadow pt-5 rounded-top-lg ">
                                    <h2 className="h3 title fw-bolder mb-4 pb-2 text-center">BOOK AN  <span>APPOINTMENT !</span></h2>
                                    <form onSubmit={doctorSearch} className="row justify-content-between w-100 m-0">
                                        <div className="messages"></div>
                                        <div className="form-group justify-content-center mb-4 clearfix col-md-4 d-flex align-items-center justify-content-between" style={{ zIndex: '1001' }}>
                                            <i className="flaticon-paper-plane text-main border-end pe-2" style={{ fontSize: '18px' }}></i>

                                            <Select placeholder="Select Branch" options={brancheOptions} onChange={handleSelectBranchChange} value={selectedBranch} className="w-100 px-2" styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                    outline: state.isFocused ? ' none' : 'none',

                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: state.isFocused ? '#409639' : ' ',
                                                    color: state.isFocused ? '#fff' : '',

                                                }),

                                            }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                            </Select>
                                        </div>
                                        <div className="form-group justify-content-center clearfix px-0 col-md-4 d-flex align-items-center justify-content-between" style={{ zIndex: '1000' }}>
                                            <i className="flaticon-stethoscope text-main border-end pe-2" style={{ fontSize: '18px' }}></i>
                                            <Select placeholder="Select Department" options={DepartmentsOptions} value={selectedOption}
                                                onChange={handleSelectChange} className="w-100 px-2" styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                        outline: state.isFocused ? ' none' : 'none',
                                                    }),
                                                    option: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                                        backgroundColor: state.isFocused ? '#409639' : ' ',
                                                        color: state.isFocused ? '#fff' : '',

                                                    }),
                                                }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                            </Select>
                                        </div>
                                        <div className="form-group justify-content-center clearfix col-md-4 d-flex align-items-center justify-content-between" style={{ zIndex: '999' }}>
                                            <i className="flaticon-doctor text-main  border-end pe-2" style={{ fontSize: '18px' }}></i>
                                            <Select placeholder="Select physician" options={DoctorsOptions} onChange={handleDoctorSelectChange} className="w-100 px-2" styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                    outline: state.isFocused ? ' none' : 'none',

                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: state.isFocused ? '#409639' : ' ',
                                                    color: state.isFocused ? '#fff' : '',

                                                }),
                                            }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                            </Select>
                                        </div>

                                        {selectedBranch.value === '1' ? <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`} className='p-0'> <button className="btn btn-theme btn-block mt-2 rounded-bottom-lg" type='submit'><span>Book Appointment</span>
                                        </button>
                                        </Link> : <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/doctors`} className='p-0'> <button className="btn btn-theme btn-block mt-2 rounded-bottom-lg" type='submit'><span>Book Appointment</span>
                                        </button>
                                        </Link>}
                                    </form>
                                 
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div > */}
                {/* <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 mx-auto">
                                <div className="white-bg box-shadow pt-5 rounded-top-lg">
                                    <h2 className="h3 title fw-bolder mb-4 pb-2 text-center">BOOK AN  <span>APPOINTMENT !</span></h2>
                                    <form onSubmit={doctorSearch} className="row justify-content-between w-100 m-0">
                                        <div className="messages"></div>
                                        <div className="form-group justify-content-center mb-4 clearfix col-md-4 d-flex align-items-center justify-content-between" style={{ zIndex: '1001' }}>
                                            <i className="flaticon-paper-plane text-main" style={{ fontSize: '18px' }}></i>

                                            <Select placeholder="Select Branch" options={brancheOptions} onChange={handleSelectBranchChange} value={selectedBranch} className="w-100 px-2" styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                    outline: state.isFocused ? ' none' : 'none',

                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: state.isFocused ? '#409639' : ' ',
                                                    color: state.isFocused ? '#fff' : '',

                                                }),

                                            }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                            </Select>
                                        </div>
                                        <div className="form-group justify-content-center clearfix col-md-4 d-flex align-items-center justify-content-between" style={{ zIndex: '1000' }}>
                                            <i className="flaticon-stethoscope text-main" style={{ fontSize: '18px' }}></i>
                                            <Select placeholder="Select Department" options={DepartmentsOptions} value={selectedOption}
                                                onChange={handleSelectChange} className="w-100 px-2" styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                        outline: state.isFocused ? ' none' : 'none',
                                                    }),
                                                    option: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                                        backgroundColor: state.isFocused ? '#409639' : ' ',
                                                        color: state.isFocused ? '#fff' : '',

                                                    }),
                                                }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                            </Select>
                                        </div>
                                        <div className="form-group justify-content-center clearfix col-md-4 d-flex align-items-center  justify-content-between" style={{ zIndex: '999' }}>
                                            <i className="flaticon-doctor text-main" style={{ fontSize: '18px' }}></i>
                                            <Select placeholder="Select physician" options={DoctorsOptions} onChange={handleDoctorSelectChange} className="w-100 px-2" styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                    outline: state.isFocused ? ' none' : 'none',

                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: state.isFocused ? '#409639' : ' ',
                                                    color: state.isFocused ? '#fff' : '',

                                                }),
                                            }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                            </Select>
                                        </div>

                                        {selectedBranch.value === '1' ? <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`} className='p-0'> <button className="btn btn-theme btn-block mt-2 rounded-bottom-lg" type='submit'><span>Book Appointment</span>
                                        </button>
                                        </Link> : <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/doctors`} className='p-0'> <button className="btn btn-theme btn-block mt-2 rounded-bottom-lg" type='submit'><span>Book Appointment</span>
                                        </button>
                                        </Link>}
                                    </form>

                                </div>
                            </div> */}
            </section >

            <section class="grey-bg fullscreen-banner p-0 overflow-hidden d-md-none d-block">
                <Carousel controls={true} loop={true} indicators={true} slide={true} class="owl-carousel no-pb slide-arrow-2" data-items="1" data-dots="false" data-nav="true"
                    data-autoplay="true">

                    <Carousel.Item class="item" data-bg-img="/images/mob-version/HEALTH/cairo-branch--min.webp" style={{ backgroundImage: `url('/images/mob-version/HEALTH/cairo-branch--min.webp')` }}>
                        <div className="align-center w-60 end-0 d-flex justify-content-end flex-column opacity-0">
                            <h5 className="text-white mb-5 animated6" data-wow-delay="4s"><span className="theme-bg span-ivp ">International Visiting Professors</span></h5>

                            <h4 className='w-100 text-center bg-white bg-opacity-50 time-ivp text-theme mb-0'> <i className='fas fa-calendar-alt text-theme me-1'></i> 24-29 Feb 2024
                            </h4>
                            <h3 className="mb-0 w-100 text-center animated5 theme-bg text-white fw-bold ivp-name" >PROF. Khalid elbatarji</h3>

                            <h6 className="w-100 text-center bg-white bg-opacity-50 fw-bold dep-ivp text-theme mb-0 animated5" > Consultant Of Orthopedic
                                <br />
                                <p className='fw-light mb-2'>Berater für Orthopädie</p>
                                <img src="/images/icons/germany.png" alt="" />
                            </h6>

                            {/* <p className='lead fs-4'>Faculty of Natural Sciences Mb. Bch.</p> */}

                        </div>
                    </Carousel.Item>
                    <Carousel.Item class="item" data-bg-img="/images/mob-version/HEALTH/alex-branch--min.webp" style={{ backgroundImage: `url('/images/mob-version/HEALTH/alex-branch--min.webp')` }}>
                        <div className="align-center ms-auto w-60 end-0 ms-auto opacity-0">
                            <h5 className="text-white mb-5 animated6" data-wow-delay="4s"><span className="theme-bg span-ivp ">International Visiting Professors</span></h5>

                            <h4 className='w-100 text-center bg-white bg-opacity-50 time-ivp text-theme mb-0'> <i className='fas fa-calendar-alt text-theme me-1'></i> 24-29 Feb 2024
                            </h4>
                            <h3 className="mb-0 w-100 text-center animated5 theme-bg text-white fw-bold ivp-name" >PROF. Khalid elbatarji</h3>

                            <h6 className="w-100 text-center bg-white bg-opacity-50 fw-bold dep-ivp text-theme mb-0 animated5" > Consultant Of Orthopedic
                                <br />
                                <p className='fw-light mb-2'>Berater für Orthopädie</p>
                                <img src="/images/icons/germany.png" alt="" />
                            </h6>


                        </div>
                    </Carousel.Item>
                    <Carousel.Item class="item" data-bg-img="/images/mob-version/HEALTH/new -min.webp" style={{ backgroundImage: `url('/images/mob-version/HEALTH/new -min.webp')` }}>
                        <div className="align-center ms-auto w-60 end-0 ms-auto opacity-0">
                            <h5 className="text-white mb-5 animated6" data-wow-delay="4s"><span className="theme-bg span-ivp ">International Visiting Professors</span></h5>

                            <h4 className='w-100 text-center bg-white bg-opacity-50 time-ivp text-theme mb-0'> <i className='fas fa-calendar-alt text-theme me-1'></i> 24-29 Feb 2024
                            </h4>
                            <h3 className="mb-0 w-100 text-center animated5 theme-bg text-white fw-bold ivp-name" >PROF. Khalid elbatarji</h3>

                            <h6 className="w-100 text-center bg-white bg-opacity-50 fw-bold dep-ivp text-theme mb-0 animated5" > Consultant Of Orthopedic
                                <br />
                                <p className='fw-light mb-2'>Berater für Orthopädie</p>
                                <img src="/images/icons/germany.png" alt="" />
                            </h6>


                        </div>
                    </Carousel.Item>
                    <Carousel.Item class="item" data-bg-img="/images/mob-version/HEALTH/mobile version -min.webp" style={{ backgroundImage: `url('/images/mob-version/HEALTH/mobile version -min.webp')` }}>
                        <div className="align-center ms-auto w-60 end-0 ms-auto opacity-0">
                            <h5 className="text-white mb-5 animated6" data-wow-delay="4s"><span className="theme-bg span-ivp ">International Visiting Professors</span></h5>

                            <h4 className='w-100 text-center bg-white bg-opacity-50 time-ivp text-theme mb-0'> <i className='fas fa-calendar-alt text-theme me-1'></i> 24-29 Feb 2024
                            </h4>
                            <h3 className="mb-0 w-100 text-center animated5 theme-bg text-white fw-bold ivp-name" >PROF. Khalid elbatarji</h3>

                            <h6 className="w-100 text-center bg-white bg-opacity-50 fw-bold dep-ivp text-theme mb-0 animated5" > Consultant Of Orthopedic
                                <br />
                                <p className='fw-light mb-2'>Berater für Orthopädie</p>
                                <img src="/images/icons/germany.png" alt="" />
                            </h6>


                        </div>
                    </Carousel.Item>

                </Carousel>
            </section >
            {/* <!--3 step start--> */}

            <div className="page-content">



                {/* <!--team start--> */}

                {/* <section className="grey-bg">
<div className="container">
<div className="row text-center">
<div className="col-xl-8 col-lg-10 col-md-12 mx-auto">
    <div className="section-title">
        <h6>News</h6>
        <h2 className="title">What's <span>News</span></h2>
        <p className="mb-0">Hectolab Provide Greate Services Exerci tation ullamcorper suscipitorens lobortis nisl ut aliquip ex ea commodo, Exerci tation ullamcorper suscipitorens Ut elit tellus.</p>
    </div>
</div>
</div>
<div className="container">
<div class="post-queto text-white mt-5">
    <Carousel class="owl-carousel no-pb owl-loaded owl-drag" controls={false} indicators={true} slide={true}>
        <Carousel.Item class="item">
            <div className='row'>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                    <div className="d-sm-flex mb-3 teams">
                        <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                            <img className="img-fluid" src={`../../images/blog/01.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                            <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                        </div>

                        <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                            <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                            </div>


                            <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                            </p>

                        </div>






                    </div>

                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                    <div className="d-sm-flex mb-3 teams">
                        <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                            <img className="img-fluid" src={`../../images/blog/01.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                            <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                        </div>

                        <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                            <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                            </div>


                            <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                            </p>

                        </div>






                    </div>

                </div>

            </div>


        </Carousel.Item>

        <Carousel.Item class="item">
            <div className='row'>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                    <div className="d-sm-flex mb-3 teams">
                        <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                            <img className="img-fluid" src={`../../images/blog/02.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                            <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                        </div>

                        <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                            <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                            </div>


                            <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                            </p>

                        </div>






                    </div>

                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                    <div className="d-sm-flex mb-3 teams">
                        <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                            <img className="img-fluid" src={`../../images/blog/02.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                            <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                        </div>

                        <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                            <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                            </div>


                            <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                            </p>

                        </div>






                    </div>

                </div>

            </div>
        </Carousel.Item>

        <Carousel.Item class="item">
            <div className='row'>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                    <div className="d-sm-flex mb-3 teams">
                        <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                            <img className="img-fluid" src={`../../images/blog/03.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                            <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                        </div>

                        <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                            <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                            </div>


                            <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                            </p>

                        </div>





                    </div>

                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 teams-container">
                    <div className="d-sm-flex mb-3 teams">
                        <div className="flex-shrink-0 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                            <img className="img-fluid" src={`../../images/blog/03.jpg`} alt='' style={{ width: ' 200px', marginBottom: '-100px' }} />
                            <Link onClick='' className="ms-1 btn btn-sm btn-theme  border-0 btn-border btn-radius">Read More</Link>
                        </div>

                        <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                            <div className="comment-date mb-2 "> <span className="date me-2 fs-4 fw-bold">Africa Health ExCon </span>
                                <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 -</p>


                            </div>


                            <p className='text-muted m-0 small pe-2 lead'>On the sidelines of the first African medical exhibition. «Saudi German» organizes a conference on health care management
                            </p>

                        </div>






                    </div>

                </div>


            </div>
        </Carousel.Item>
    </Carousel>

</div>
</div>
</div>
</section > */}

                {/* facts and figure */}
                {/* <section>
                    <div class="container">
                        <div class="row justify-content-between">
                            <div class="col-lg-4 col-md-12">
                                <div class="featured-step style-2"> <span>01</span>
                                    <h5 class="mb-3">Best Checkup</h5>
                                    <div class="featured-desc">
                                        <p>Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor erat, sed diam voluptua.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-3 mt-lg-0">
                                <div class="featured-step style-2"> <span>02</span>
                                    <h5 class="mb-3">Online Appointment</h5>
                                    <div class="featured-desc">
                                        <p>Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor erat, sed diam voluptua.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-3 mt-lg-0">
                                <div class="featured-step style-2"> <span>03</span>
                                    <h5 class="mb-3">24 x 7 Available</h5>
                                    <div class="featured-desc">
                                        <p>Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor erat, sed diam voluptua.</p>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </section> */}


                {/* <!--team end--> */}
                <section className="grey-bg py-4">
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                                <div class="section-title">
                                    {/* <h6>News</h6> */}
                                    <h2 class="title">{t('Latest')} <span>{t('News')}</span></h2>
                                    <button className='btn btn-theme btn-sm appoint-btn mt-0 rounded-top rounded-bottom'><Link onClick={scrollToTop} className='text-white' to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/news`}>{t('More News')}</Link>  </button>
                                </div>
                            </div>
                        </div>


                        <div class="row gy-3">
                            {allNews.map((news, index) => <>
                                <div class="col-md-4" key={index}>
                                    <div class="item">
                                        <div class="post">
                                            <div class="post-image">
                                                {news.image ? <img class="img-fluid w-100 h-100" src={news.image} alt={news.title} /> : <img class="img-fluid w-100 h-100" src="/images/blog/01.jpg" alt={news.title} />}
                                            </div>
                                            <div class="post-desc">
                                                <div class="post-meta">
                                                    <ul class="list-inline">
                                                        {/* <li>23 April, 2019</li> */}
                                                        {/*  */}
                                                    </ul>
                                                </div>
                                                <div class="post-title">
                                                    <h5><Link to="">{news.title}</Link></h5>
                                                </div>
                                                <p dangerouslySetInnerHTML={{ __html: news.shortnote.replace(/^(.{200}\S*).*$/, '$1') }}></p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/news/${news.title}/${news.id}`}>{t('Read More')}
                                                    {i18n.language === 'en' ? <i class="ms-2 fas fa-long-arrow-alt-right"></i> : <i class="ms-2 fas fa-long-arrow-alt-left"></i>}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                            }


                        </div>

                    </div>




                </section >


                {/* <section className="p-0" style={{ backgroundImage: `url('/images/bg/home-banner-bg.jpg')`, backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
                    <div className="w-50 pb-3 me-auto branches-corner">
                        <ul class="pricing-options personal flex-column mb-2 mt-0 pt-2 ms-5">

                            <li class="option bg-transparent pb-0 text-center ">
                                <div class="sell-helper w-auto fs-2 text-theme">Visit Our Branches</div>
                            </li>
                            <p className='lead text-white pt-3'>We have two branches of the Saudi German Hospital offering a range of excellent medical services.</p>
                        </ul>
                        <ul class="m-0 pricing-options personal inherited-styles-for-exported-element" id="buy-now" data-animation="" style={{ animationStyle }}>
                            <li class="option yearly highlight py-4 px-2 me-5 ms-5 mb-3 align-items-center">
                                <div>
                                    <img src='/images/icons/hospital (1).png' />
                                    <p class="price"><span>Cairo</span></p>
                                </div>

                                <Link onClick={scrollToTop} class="buy-button w-60" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo`} >Visit now</Link>
                            </li>
                            <li class="option yearly highlight py-4 px-2 me-5 ms-5 ms-md-0 mb-3 align-items-center">

                                <div>
                                    <img src='/images/icons/hospital (1).png' />

                                    <p class="price"><span >Alexandria</span></p>
                                </div>

                                <Link onClick={scrollToTop} class="buy-button w-60" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex`} >Visit now</Link>
                            </li>
                        </ul>
                    </div>
                </section> */}

                <section className='text-center' style={{ background: 'linear-gradient(270deg, #40ad56 0%, #007438 100%)' }}>
                    <div className="container">
                        <div class="row">
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>

                                    <div class="col-md-2">
                                        <div class="featured-title text-uppercase">
                                            {isVisible ?

                                                <h3 className='text-light fw-bolder mt-3'> <CountUp end={18} /></h3>
                                                : ''}
                                            <h6 className="lead text-white pt-3">{t('Medical facilities across the Middle East & North Africa')}
                                            </h6>
                                        </div>
                                    </div>



                                </>}
                            </VisibilitySensor>

                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>

                                    <div class="col-md-2">
                                        <div class="featured-title text-uppercase">
                                            {isVisible ?
                                                <h3 className='text-light fw-bolder mt-3'> <CountUp end={3000} /><sup className='fw-bolder'>+</sup></h3>
                                                : ''}
                                            <h6 className="lead text-white pt-3">{t('Beds')}</h6>
                                        </div>
                                    </div>
                                </>}
                            </VisibilitySensor>
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>

                                    <div class="col-md-2">
                                        <div class="featured-title text-uppercase">
                                            {isVisible ?
                                                <h3 className='text-light fw-bolder mt-3'> <CountUp end={44} /></h3>
                                                : ''}
                                            <h6 className="lead text-white pt-3">{t('Medical specialties & subspecialties')} </h6>
                                        </div>
                                    </div>
                                </>}
                            </VisibilitySensor>
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>

                                    <div class="col-md-2">
                                        <div class="featured-title text-uppercase">
                                            {isVisible ?
                                                <h3 className='text-light fw-bolder mt-3'> <CountUp end={9000} /><sup className='fw-bolder'>+</sup></h3>
                                                : ''}
                                            <h6 className="lead text-white pt-3">{t('Employees')} </h6>
                                        </div>
                                    </div>
                                </>}
                            </VisibilitySensor>
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>

                                    <div class="col-md-2">
                                        <div class="featured-title text-uppercase">
                                            {isVisible ?
                                                <h3 className='text-light fw-bolder mt-3'> <CountUp
                                                    start={endValue}
                                                    separator=""
                                                    decimal=""
                                                    decimalPlaces={0}
                                                    duration={2.5}
                                                    formattingFn={formatNumber} />M<sup className='fw-bolder'>+</sup></h3> : ''}
                                            <h6 className="lead text-white pt-3">{t('Patients a Year')} </h6>
                                        </div>
                                    </div>
                                </>}
                            </VisibilitySensor>
                            <VisibilitySensor partialVisibility>

                                {({ isVisible }) => <>

                                    <div class="col-md-2">
                                        <div class="featured-title text-uppercase">
                                            {isVisible ?
                                                <h3 className='text-light fw-bolder mt-3'> <CountUp end={35} /><sup className='fw-bolder'>+</sup></h3> : ''}
                                            <h6 className="lead text-white pt-3">{t('Years of excellence')}</h6>
                                        </div>
                                    </div>
                                </>}
                            </VisibilitySensor>
                        </div>
                    </div>
                </section>
                <section >
                    <div className="container">
                        <div className="row text-center justify-content-between">


                            <div className="col-lg-12 col-md-12 teams-container">
                                <div class="section-title mb-0">

                                    {i18n.language === 'en' ? <h2 class="title">{t('Success')} <span>{t('Stories')}</span></h2> : <h2 class="title">{t('Stories')} <span>{t('Success')}</span></h2>}

                                </div>
                                <div className='text-center pt-5 d-flex justify-content-between align-items-center'>
                                    <h6 className='text-center'> {t('Saudi German Hospital')}
                                        <h6 className='text-muted w-25 pt-2 text-center d-inline'>  {t('Success Stories')}

                                        </h6>
                                    </h6>
                                    <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/success-stories`} class="btn btn-sm btn-simple"><span>{t('See More')}
                                        {i18n.language === 'en' ? <i class="ms-2 fas fa-long-arrow-alt-right"></i> : <i class="ms-2 fas fa-long-arrow-alt-left"></i>}
                                    </span></Link>

                                </div>


                                <Link to=''>
                                    <div className="d-sm-flex mb-3 teams p-0 justify-content-start">
                                        {/* <div className="flex-shrink-0 overflow-hidden d-flex flex-column justify-content-center align-items-center p-4 text-white fs-5 fw-bold ">
                                           

                                        </div> */}
                                        <iframe width="560" height="315" className='stories-video' src="https://www.youtube.com/embed/ENvIIBtvcpQ?si=spyPjPU4Ar2uUfRP" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>


                                        <div className="flex-grow-1 p-3 position-relative text-start">
                                            <div className="comment-date pb-2"> <span className="date me-2 fs-6 fw-bold text-muted">{t('Egypt Award Final Ceremony')}</span>
                                                {/* <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 </p> */}


                                            </div>


                                            <p className='text-muted m-0 small pe-2'>{t('The final ceremony of the Saudi German Health Award under the patronage of Ministry of Health and Ministry of Social Solidarity')}
                                            </p>

                                        </div>






                                    </div>
                                </Link>

                                <Link to=''>
                                    <div className="d-sm-flex mb-3 teams p-0 justify-content-start">
                                        {/* <div className="flex-shrink-0 overflow-hidden d-flex flex-column justify-content-center align-items-center p-4 text-white fs-5 fw-bold ">
                                           

                                        </div> */}
                                        <iframe width="560" className='stories-video' height="315" src="https://www.youtube.com/embed/nhYlFzimxGU?si=ITc3gws1EBaTSypf" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                                        <div className="flex-grow-1 p-3 position-relative text-start">
                                            <div className="comment-date pb-2"> <span className="date me-2 fs-6 fw-bold text-muted">  {t('Africa Health Excon 2nd Edition')} </span>
                                                {/* <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 </p> */}


                                            </div>


                                            <p className='text-muted m-0 small pe-2'>{t('Saudi German Health Egypt is the Conference Partner with 2nd Africa Health Excon in providing the Healthcare Leadership and Management.')}
                                            </p>

                                        </div>






                                    </div>
                                </Link>

                                <Link to=''>
                                    <div className="d-sm-flex mb-3 teams p-0 justify-content-start">
                                        {/* <div className="flex-shrink-0 overflow-hidden d-flex flex-column justify-content-center align-items-center p-4 text-white fs-5 fw-bold ">
                                           

                                        </div> */}
                                        <iframe width="560" className='stories-video' height="315" src="https://www.youtube.com/embed/CqleIE9aUjE?si=JXJ6-UaargDNwl75" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                                        <div className="flex-grow-1 p-3 position-relative text-start">
                                            <div className="comment-date pb-2"> <span className="date me-2 fs-6 fw-bold text-muted">{t('Gold Winner of International Hospital Federation')} </span>
                                                {/* <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 </p> */}


                                            </div>


                                            <p className='text-muted m-0 small pe-2'>{t('Saudi German Hospital Cairo is the gold winner of the IHF Grand Hospital Award being the Egyptian National Accreditation Modelling Reference.')}
                                            </p>

                                        </div>






                                    </div>
                                </Link>
                                <Link to=''>
                                    <div className="d-sm-flex mb-3 teams p-0 justify-content-start">
                                        {/* <div className="flex-shrink-0 overflow-hidden d-flex flex-column justify-content-center align-items-center p-4 text-white fs-5 fw-bold ">
                                           

                                        </div> */}
                                        <iframe className='stories-video' width="560" height="315" src="https://www.youtube.com/embed/nL7HM1SUb9s?si=PIMDQVeTKPKlLsJa" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                                        <div className="flex-grow-1 p-3 position-relative text-start ">
                                            <div className="comment-date pb-2"> <span className="date me-2 fs-6 fw-bold text-muted">{t('Mayo Clinic Care Network Membership')}</span>
                                                {/* <p className='text-capitalize m-0'>  <i className="fa fa-calendar text-muted small"></i> Monday, 6 June, 2022 </p> */}


                                            </div>


                                            <p className='text-muted m-0 small pe-2'>{t('Saudi German Hospital Cairo is a member in Mayo Clinic Care Network MCCN as 1st hospital in Africa and 3rd in Middle East.')}
                                            </p>

                                        </div>






                                    </div>
                                </Link>



                                {/* <Modal
                                    isOpen={isOpen}
                                    onRequestClose={closeModal}
                                    contentLabel="YouTube Video"
                                >
                                    {selectedVideoId && (
                                        <div>
                                            <iframe
                                                width="560"
                                                height="315"
                                                src={`https://www.youtube.com/embed/${selectedVideoId}`}
                                                title="YouTube Video"
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>
                                            <button onClick={closeModal}>Close</button>
                                        </div>
                                    )}
                                </Modal> */}

                            </div>

                            {/* <div className="col-lg-5 col-md-12  teams-container">
                                <div class="section-title mb-0">

                                    <h2 class="title">Our <span>Events</span></h2>

                                </div>
                                <div className='text-center pt-5 d-flex justify-content-between align-items-center'>
                                    <h6 className='text-center'>UpComming
                                        <h6 className='text-muted w-25 pt-2 text-center d-inline'> Events

                                        </h6>
                                    </h6>
                                    <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/facilities-events`} class="btn btn-sm btn-simple"><span>All Events <i class="ms-2 fas fa-long-arrow-alt-right"></i></span></Link>

                                </div>

                                {allEvents.slice(0, 4).map((event, index) => <>
                                    <Link key={index} onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/event-details/${event.title}/${event.id}`}>
                                        <div className="d-sm-flex mb-3 teams p-0 justify-content-start">
                                            <div className="flex-shrink-0 overflow-hidden d-flex flex-md-column justify-content-center align-items-center p-4 text-white fs-5 fw-bold " style={{ background: 'linear-gradient(135deg,#00aae7,#3dae2b)' }}>
                                                <h6 className='text-white fw-bolder fs-4 mb-0 mb-md-2 pe-2 pe-md-0'>{new Date(event.start_date).getDate()}</h6><span> {format(new Date(event.start_date), 'MMM')}</span>

                                            </div>

                                            <div className="flex-grow-1 p-3 position-relative text-start">
                                                <div className="comment-date pb-2"> <span className="date me-2 fs-6 fw-bold text-muted">{event.title} </span>


                                                </div>


                                                <p className='text-muted m-0 small pe-2'>{event.shortnote}
                                                </p>

                                            </div>


                                        </div>
                                    </Link>
                                </>)}

                        
                            </div> */}
                        </div>
                    </div>
                </section>

                <section class="text-center py-1 d-none d-md-block" style={{ background: 'linear-gradient(270deg, #40ad56 0%, #007438 100%)' }}>
                    <div class="container">

                        <Carousel controls={false} indicators={false} slide={true}>
                            <Carousel.Item class="item">
                                <div class="row justify-content-between justify-content-center">
                                    <div class=" col-md-3">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/Mayo-clinic_white-min.webp" alt="" />
                                    </div>
                                    <div class="col-md-3">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/ACHS-international-white--min.webp" alt="" />
                                    </div>
                                    <div class=" col-md-3">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/IHF-white-min.webp" alt="" />
                                    </div>
                                    <div class=" col-md-3">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/geneva-sustainbility-centre_white-min.webp" alt="" />
                                    </div>


                                </div>
                            </Carousel.Item>
                            <Carousel.Item class="item">
                                <div class="row justify-content-between">

                                    <div class=" col-md-3">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/american-university-white-min.webp" alt="" />
                                    </div>
                                    <div class=" col-md-3">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/GUC-white-min.webp" alt="" />
                                    </div>
                                    <div class=" col-md-3">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/Arab-Academy-white-colour-min.webp" alt="" />
                                    </div>



                                </div>

                            </Carousel.Item>
                        </Carousel>

                    </div>
                </section>


                <section class="text-center py-1 d-block d-md-none" style={{ background: 'linear-gradient(270deg, #40ad56 0%, #007438 100%)' }}>
                    <div class="container">

                        <Carousel controls={false} indicators={true} slide={true} className='py-5'>
                            <Carousel.Item class="item">
                                <div class="row justify-content-center">
                                    <div class=" co-12">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/Mayo-clinic_white-min.webp" alt="" />
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item class="item">
                                <div className="row justify-content-center">
                                    <div class="col-12">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/ACHS-international-white--min.webp" alt="" />
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item class="item">
                                <div className="row justify-content-center">
                                    <div class=" col-12">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/IHF-white-min.webp" alt="" />
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item class="item">
                                <div className="row justify-content-center">
                                    <div class=" col-12">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/geneva-sustainbility-centre_white-min.webp" alt="" />
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item class="item">
                                <div class="row justify-content-center">

                                    <div class=" col-12">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/american-university-white-min.webp" alt="" />
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item class="item">
                                <div class="row justify-content-center">
                                    <div class=" col-12">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/GUC-white-min.webp" alt="" />
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item class="item">
                                <div class="row justify-content-center">
                                    <div class=" col-12">
                                        <img class="img-fluid d-inline" src="/images/about/partners-section/Arab-Academy-white-colour-min.webp" alt="" />
                                    </div>
                                </div>
                            </Carousel.Item>

                        </Carousel>

                    </div>
                </section >

                {/* <section className='grey-bg text-center'>
                    <div className="container">
                        <div class="row text-center">

                            <Carousel controls={false} indicators={false} slide={true} class="owl-carousel no-pb slide-arrow-2 col-md-12" data-items="1" data-dots="false" data-nav="true"
                                data-autoplay="true">
                                <Carousel.Item class="item">
                                    <div class="row">

                                        <div className=' col-md-4'>
                                            <img class="img-fluid d-inline" src="images/about/partners and Afillities/Mayo-clinic-min.webp" alt="" />

                                        </div>
                                        <div className=' col-md-4'>

                                            <img class="img-fluid d-inline" src="images/about/partners and Afillities/ACHS-international--min.webp" alt="" />
                                        </div>
                                        <div className=' col-md-4'>

                                            <img class="img-fluid d-inline" src="images/about/partners and Afillities/geneva-sustainbility-centre-min.webp" alt="" />
                                        </div>
                                    </div>
                                </Carousel.Item>



                                <Carousel.Item class="item">
                                    <div class="row">

                                        <div className=' col-md-4'>
                                            <img class="img-fluid d-inline" src="images/about/partners and Afillities/IHF--min.webp" alt="" />
                                        </div>
                                        <div className=' col-md-4'>

                                            <img class="img-fluid d-inline" src="images/about/partners and Afillities/american-university--min.webp" alt="" />
                                        </div>
                                        <div className=' col-md-4'>

                                            <img class="img-fluid d-inline" src="images/about/partners and Afillities/GUC--min.webp" alt="" />
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                          
                        </div>
                    </div>
                </section> */}
            </div >



        </div >

        {/* <!--body content end-->  */}


    </>
}
