import Cookies from 'js-cookie';
import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet';
import MetaTag from '../MetaTag/MetaTag';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function Awards() {
    // translation 
    const { t } = useTranslation("About");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    return <>
        <Helmet>
            <title>awards page</title>
            <meta name="description" content="awards Page" />

            <MetaTag />
            {/* <!--page title start--> */}
        </Helmet>
        {i18n.language === 'en' ? <PageTitle title={t('Our')} anotherTitle={t('Awards')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/awards`} currentPage={t('Our Awards')} /> : <PageTitle title={t('Our Awards')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/awards`} currentPage={t('Our Awards')} />}
        {/* <!--page title end--> */}


        <section>
            <div className="container">

                <div class="row ms-0 tab">
                    {/* <!-- Nav tabs --> */}
                    <div className="col-lg-12 col-md-12 ">
                        {/* <div class="section-title mb-4">
                            <h6 class=" h5"><span>Our Awards and Accreditations</span></h6>
                        </div> */}
                        <nav>

                            <div class="nav nav-tabs w-100" id="nav-tab-2" role="tablist">

                                <Link class="awards nav-item nav-link  active" id="nav-tab5" data-bs-toggle="tab" data-bs-target="#tab2-1" type="button" role="tab" aria-selected="true" onClick={scrollToTop}>
                                    {t('Awards')}</Link>

                                <Link class="awards nav-item nav-link " id="nav-tab6" data-bs-toggle="tab" data-bs-target="#tab2-2" type="button" role="tab" aria-selected="false" onClick={scrollToTop}> {t('Accreditation')}  </Link>


                            </div>

                        </nav>
                    </div>
                    {/* <!-- Tab panes --> */}
                    <div className="col-lg-12 col-md-12">

                        <div class="tab-content w-100 p-0">
                            <div role="tabpanel" class="tab-pane fade show active row ms-0" id="tab2-1">
                                {/* <div class="service-images">
                                    <img class="img-fluid w-100" src="../../images/partners/JCI.-copy.jpg" alt="" />
                                </div> */}
                                <div className="col-md-12 row ms-0 mt-3 p-0 ">
                                    <p class="mb-0 mt-3 col-md-8 ps-0 order-2">
                                        <h6 className='title'> <span className='fw-normal'> <b>{t('Gold Level – Grand Hospital Award at International Hospital Federation')}
                                        </b></span></h6>
                                        {t("Saudi German Hospital Cairo was honored with the prestigious Dr. Kwang Tae Kim Grand Hospital Award, the highest recognition bestowed upon an individual hospital by the IHF (International Hospital Federation). This esteemed award signifies the hospital's exceptional performance across five key dimensions of healthcare delivery: health service quality, patient experience, clinical outcomes and health equity, cost efficiency, and workforce wellbeing. Saudi German Hospital Cairo's outstanding achievements were acknowledged during the award ceremony hosted by the IHF in Barcelona, Spain, in November 2022, where it was recognized as a gold winner.")}
                                        <br /> {t('Winner Project is')} <b> {t('Saudi German Hospital Cairo: National Accreditation Modelling Reference in Egypt')}</b>
                                    </p>
                                    <img className='col-md-4 order-1 order-md-3 ps-0' src="/images/about/Awards/IHF-spain-min.webp" alt="" />


                                </div>

                                <div className="col-md-12 row ms-0 mt-3 p-0">
                                    <p class="mb-0 mt-3 col-md-8 ps-0 order-2">
                                        <h6 className='title'> <span className='fw-normal'>
                                            <b>{t('Honorable Award – Excellency in Quality and Patient Safety at International Hospital Federation')}  </b>
                                        </span>
                                        </h6>
                                        {t("In a feat of pioneering healthcare excellence, Saudi German Hospital Cairo proudly received the prestigious award for Quality and Patient Safety at the International Hospital Federation Congress in Dubai, UAE, 2022. This coveted recognition stands as a testament to the hospital's dedication to its revolutionary project, 'Establishing an integrated quality management model (holistic approach)' in Egypt.This groundbreaking initiative transcends traditional quality control measures. It meticulously weaves together a tapestry of patient safety systems, clinical governance frameworks, and continuous improvement strategies, encompassing every facet of the hospital's operations. From meticulously curated infection prevention protocols to staff training programs fostering a culture of safety, the integrated model prioritizes patient well-being at every turn.")}
                                        <br />  {t('Winner Project is')} <b> {t("Establishing an integrated quality management model (holistic approach) in Egypt")}</b>
                                    </p>
                                    <img className='col-md-4 order-1 order-md-3 ps-0' src="/images/about/Awards/2.Honorable Award - Excellency In Quality And Patient Safety At International Hospital Federation-min.webp" alt="" />

                                </div>

                                <div className="col-md-12 row ms-0 mt-3 p-0">
                                    <p class="mb-0 mt-3 col-md-8 ps-0 order-2">
                                        <h6 className='title'> <span className='fw-normal'>
                                            <b>{t('Bronze Level - Excellence Award for Healthcare Workers’ Wellbeing at American Hospital Association')}
                                            </b>

                                        </span>
                                        </h6>
                                        {t('Saudi German Hospital Cairo has been honored with the bronze level recognition for its exemplary commitment to the wellbeing of healthcare workers. Through its collaboration with the Ministry of Health and Social Solidarity, the hospital implemented a National Strategy aimed at boosting the morale of healthcare workers. This initiative has had a significant impact on recognizing the extraordinary efforts made by all hospitals in the Arab Republic of Egypt. The award ceremony took place in Portugal in October 2023, where Saudi German Hospital Cairo was acknowledged for its outstanding achievements in ensuring the wellbeing of healthcare workers.')}
                                        <br /> {t('Winner Project is')} <b>{t("Honoring Hospital Heroes: A National Strategy to Boost their Morale and Engagement")}</b>
                                    </p>
                                    <img className='col-md-4 order-1 order-md-3 ps-0' src="/images/about/Awards/IHF-libson-min.webp" alt="" />


                                </div>


                            </div>
                            <div role="tabpanel" class="tab-pane fade" id="tab2-2">
                                {/* <div class="service-images">
                                    <img class="img-fluid w-100" src="../../images/partners/Untitled-design-2.webp" alt="" />
                                </div> */}

                                <div className="col-md-12 row ms-0 mt-3 p-0 text-justify">
                                    <p class="mb-0 mt-3 col-md-8 ps-0 order-2">
                                        <h6 className='title'> <span className='fw-normal'>
                                            <b>{t('Joint Commission International Accreditation JCIA')}
                                            </b>
                                        </span>
                                        </h6>
                                        {t("Saudi German Hospital Cairo has achieved the prestigious accreditation from the Joint Commission International (JCI), a renowned global accreditation body for healthcare organizations. In January 2018, the hospital successfully obtained the JCI accreditation, which serves as a mark of excellence in healthcare delivery and patient safety. Demonstrating its unwavering commitment to maintaining high standards, Saudi German Hospital Cairo was reaccredited by the JCI in January 2020. This reaccreditation reaffirms the hospital's dedication to providing exceptional healthcare services, adhering to rigorous international quality and safety standards.The JCI accreditation serves as a testament to Saudi German Hospital Cairo's continuous pursuit of excellence and its commitment to delivering the highest level of care to patients.")}

                                    </p>
                                    <img className='col-md-4 order-1 order-md-3 ps-0' src="/images/about/Accreditation/JCI-min.webp" alt="" />
                                </div>
                                <div className="col-md-12 row ms-0 mt-3 p-0 text-justify ">
                                    <p class="mb-0 mt-3 col-md-8 ps-0 order-2">
                                        <h6 className='title'> <span className='fw-normal'>
                                            <b>{t('Canadian Accreditation – Diamond Level')}
                                            </b>
                                        </span>
                                        </h6>
                                        {t('Saudi German Hospital Cairo stands as a shining example of its unwavering dedication to quality, proudly attaining Canadian Accreditation, a prestigious standard for healthcare excellence. Since its initial achievement of diamond level accreditation in December 2018, the hospital has consistently upheld its exceptional standards, attaining the esteemed Diamond Level reaccreditation in December 2020. This rigorous recognition serves as a testament to its unwavering commitment to patient safety, continuous improvement, and adherence to the highest international benchmarks. With this distinguished accreditation, Saudi German Hospital Cairo serves as a beacon of healthcare expertise in the region, attracting patients from around the world who seek top-notch care combined with the warmth of Middle Eastern hospitality.')}

                                    </p>
                                    <img className='col-md-4 order-1 order-md-3 ps-0' src="/images/about/Accreditation/CANADIAN-min.webp" alt="" />
                                </div>

                                <div className="col-md-12 row ms-0 mt-3 p-0 ">
                                    <p class="mb-0 mt-3 col-md-8 ps-0 order-2">
                                        <h6 className='title'> <span className='fw-normal'>
                                            <b>{t('The General Authority for Healthcare Accreditation and Regulation GAHAR')}

                                            </b>
                                        </span>
                                        </h6>
                                        {t('In a landmark achievement for healthcare in Egypt, Saudi German Hospital Cairo has established itself as a trailblazer. Marking a significant milestone, it became the first hospital in the Egypt to be registered in June 2019 and accredited in Feb 2020 by the prestigious General Authority for Healthcare Accreditation and Regulation (GAHAR).')}
                                        <br />
                                        {t('GAHAR, established in 2018, represents the pinnacle of healthcare quality accrediting body in Egypt. Its rigorous accreditation process scrutinizes every aspect of a healthcare facility, from patient safety and treatment efficacy to organizational structure and ethical practices. Achieving GAHAR accreditation signifies adherence to the highest international standards, demonstrating a steadfast commitment to providing exceptional care.')}

                                    </p>
                                    <img className='col-md-4 order-1 order-md-3 ps-0' src="/images/about/Accreditation/GAHAR-min.webp" alt="" />
                                </div>
                            </div>

                        </div>
                    </div>


                </div >





            </div >
        </section >

    </>
}
