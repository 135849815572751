import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { ReactRadioButtonsGroup, ReactRadioButton } from 'react-radio-buttons-group';
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import PageTitle from '../../Utilites/PageTitle';
import Cookies from 'js-cookie';
import { LocalizationContext } from '../../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';
export default function StrokeRiskCalculator(props) {
    // translation 
    const { t } = useTranslation("HealthHub");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();

    // state
    const [gender, setGender] = useState('')
    const [years, setYears] = useState('')
    const [old, setOld] = useState('')
    const [diabetes, setDiabetes] = useState('')
    const [failure, setFailure] = useState('')
    const [pressure, setPressure] = useState('')
    const [heart, setHeart] = useState('')
    const [stroke, setStroke] = useState('')
    const [getStroke, setGetStroke] = useState('')
    const [message, setMessage] = useState('')

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // const [modalShow, setModalShow] = React.useState(false);


    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    let calstroke = (event) => {
        //prevent submitting to the server
        event.preventDefault()
        let result = (Number(gender) + Number(years) + Number(old) + Number(diabetes) + Number(failure) + Number(pressure) + Number(heart) + Number(stroke));
        console.log(result);
        if (result === 0) {
            setGetStroke(t('Low Risk'))
        } else if (result === 1) {
            setGetStroke(t('Intermediate Risk'))

        } else {
            setGetStroke(t('High Risk'))

        }

    }
    return <>
        {/* <!--page title start--> */}

        {i18n.language === 'en' ? <PageTitle title={t('Stroke Risk')} anotherTitle={t('Calculator')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/health-tools/stroke-risk-calculator`} currentPage={t('Stroke Risk Calculator')} /> : <PageTitle title={t('Calculator')} anotherTitle={t('Stroke Risk')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/health-tools/stroke-risk-calculator`} currentPage={t('Stroke Risk Calculator')} />}



        {/* <!--page title end--> */}

        {/* <!--body content start--> */}



        <div className="page-content mt-5 mb-5 pt-5 overflow-hidden">

            {/* <!--appointment start--> */}

            <section class="theme-bg text-white position-relative pb-0 pb-lg-11 mt-5 pt-5">
                <div class="container">
                    <div class="row">
                        <div class="section-title text-center">
                            {/* <h6>Reservation</h6> */}
                            <h2 class="title text-white">{t('Stroke Risk Calculator!')}
                            </h2>
                            <p class="mb-0">{t('Our stroke risk calculator can be used to assess your risk of having a stroke . Please note that this calculation is an estimate only - please see your doctor for an accurate diagnosis as there may be other contributing risk factors.')}
                            </p>
                        </div>
                        <div class="col-xl-9 col-lg-12 col-md-12 ms-auto ps-lg-0 order-lg-1">

                            <form class="row" onSubmit={calstroke}>
                                <div class="messages"></div>

                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <label for="" className='text-white' style={{ fontSize: '16px' }}>{t('Gender')}</label>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <ReactRadioButtonsGroup group='sex' onChange={(value) => setGender(value)}>
                                        <ReactRadioButton value='0' className="lead">{t('Male')} </ReactRadioButton>
                                        <ReactRadioButton value='1'>{t('Female')}</ReactRadioButton>
                                    </ReactRadioButtonsGroup>
                                    {/* <input value={height} onChange={(event) => setHeight(event.target.value)} class="form-control" placeholder="Your Height" required="required" data-error="Valid Your Height is required." /> */}
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <label for="" className='text-white' style={{ fontSize: '16px' }}>{t('65 to 75 Years Old ?')}</label>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <ReactRadioButtonsGroup group='years' onChange={(value) => setYears(value)}>
                                        <ReactRadioButton value='1'>{t('Yes')} </ReactRadioButton>
                                        <ReactRadioButton value='0'>{t('No')}</ReactRadioButton>
                                    </ReactRadioButtonsGroup>
                                    {/* <input value={weight} onChange={(e) => setWeight(e.target.value)} class="form-control" placeholder="Your Weight" required="required" data-error="Valid Your Weight is required." /> */}

                                    <div class="help-block with-errors"></div>
                                </div>

                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <label for="" className='text-white' style={{ fontSize: '16px' }}>{t('Over 75 Years Old ?')}</label>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">

                                    <ReactRadioButtonsGroup group='old' onChange={(value) => setOld(value)}>
                                        <ReactRadioButton value='1'>{t('Yes')} </ReactRadioButton>
                                        <ReactRadioButton value='0'>{t('No')} </ReactRadioButton>
                                    </ReactRadioButtonsGroup>
                                    {/* <input value={weight} onChange={(e) => setWeight(e.target.value)} class="form-control" placeholder="Your Weight" required="required" data-error="Valid Your Weight is required." /> */}

                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <label for="" className='text-white' style={{ fontSize: '16px' }}>{t('Do you have heart failure ?')}</label>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">

                                    <ReactRadioButtonsGroup group='failure' onChange={(value) => setFailure(value)}>
                                        <ReactRadioButton value='1'>{t('Yes')} </ReactRadioButton>
                                        <ReactRadioButton value='0'>{t('No')} </ReactRadioButton>
                                    </ReactRadioButtonsGroup>
                                    {/* <input value={weight} onChange={(e) => setWeight(e.target.value)} class="form-control" placeholder="Your Weight" required="required" data-error="Valid Your Weight is required." /> */}

                                    <div class="help-block with-errors"></div>
                                </div>



                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <label for="" className='text-white' style={{ fontSize: '16px' }}>{t('Do you have high blood pressure?')} </label>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">

                                    <ReactRadioButtonsGroup group='pressure' onChange={(value) => setPressure(value)}>
                                        <ReactRadioButton value='1'>{t('Yes')} </ReactRadioButton>
                                        <ReactRadioButton value='0'>{t('No')} </ReactRadioButton>
                                    </ReactRadioButtonsGroup>
                                    {/* <input value={height} onChange={(event) => setHeight(event.target.value)} class="form-control" placeholder="Your Height" required="required" data-error="Valid Your Height is required." /> */}
                                    <div class="help-block with-errors"></div>
                                </div>

                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <label for="" className='text-white' style={{ fontSize: '16px' }}>{t('Do you have diabetes?')}</label>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">

                                    <ReactRadioButtonsGroup group='diabetes' onChange={(value) => setDiabetes(value)}>
                                        <ReactRadioButton value='1'>{t('Yes')} </ReactRadioButton>
                                        <ReactRadioButton value='0'>{t('No')} </ReactRadioButton>
                                    </ReactRadioButtonsGroup>
                                    {/* <input value={height} onChange={(event) => setHeight(event.target.value)} class="form-control" placeholder="Your Height" required="required" data-error="Valid Your Height is required." /> */}
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <label for="" className='text-white' style={{ fontSize: '16px' }}>{t('Have you ever had a stroke ?')}</label>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">

                                    <ReactRadioButtonsGroup group='stroke' onChange={(value) => setStroke(value)}>
                                        <ReactRadioButton value='1'>{t('Yes')} </ReactRadioButton>
                                        <ReactRadioButton value='0'>{t('No')} </ReactRadioButton>
                                    </ReactRadioButtonsGroup>
                                    {/* <input value={height} onChange={(event) => setHeight(event.target.value)} class="form-control" placeholder="Your Height" required="required" data-error="Valid Your Height is required." /> */}
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">
                                    <label for="" className='text-white' style={{ fontSize: '16px' }}>{t('Have you had a heart attack ?')}</label>
                                </div>
                                <div class="form-group col-xl-3 col-lg-6 col-md-6">

                                    <ReactRadioButtonsGroup group='heart' onChange={(value) => setHeart(value)}>
                                        <ReactRadioButton value='1'>{t('Yes')} </ReactRadioButton>
                                        <ReactRadioButton value='0'>{t('No')}  </ReactRadioButton>
                                    </ReactRadioButtonsGroup>
                                    {/* <input value={height} onChange={(event) => setHeight(event.target.value)} class="form-control" placeholder="Your Height" required="required" data-error="Valid Your Height is required." /> */}
                                    <div class="help-block with-errors"></div>
                                </div>

                                <div class="col-sm-12 my-5 text-center">
                                    <button class="btn btn-white btn-radius" type='submit' onClick={handleShow}><span>{t('Compute Stroke Risk')}</span>
                                    </button>

                                    <Modal   {...props}
                                        size="lg"
                                        style={customStyles}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered show={show} onHide={handleClose} className="top-0 ">

                                        <Modal.Header closeButton>
                                            <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                            <Modal.Title style={{ color: "#4ab242" }}> {t('Stroke Risks Calculator')}</Modal.Title>
                                        </Modal.Header>

                                        <Modal.Body>
                                            <div className="team-member text-center">
                                                {/* <div className="team-images">
                                                    <img className="img-fluid" src="images/team/04.jpg" alt="" />
                                                </div> */}
                                                <div className="team-description pt-0"> <span>{t('Hello')}</span>
                                                    <h5 className="mb-4"><Link> {t('Stroke Risk Result')}</Link></h5>
                                                    {/* <span>Your BMI IS :</span>
                                                    <p>Normal Weight = 18.5 : 24.9 </p> */}
                                                    <div className="social-icons social-colored social-fullwidth">
                                                        <ul style={{ marginBottom: " 2px" }}>
                                                            <li className="bg-black fw-bolder"><Link>{t('You Have')} :</Link>
                                                            </li>
                                                            <li><Link className='bg-light text-muted'>{getStroke} {t('of Stroke Risk')}</Link>
                                                            </li>

                                                        </ul>
                                                        {/* <div className="social-icons social-colored social-fullwidth">
                                                            <ul>
                                                                <li className="bg-black fw-bolder"><Link>Your Message Is:</Link>
                                                                </li>
                                                                <li><Link className='bg-light text-muted'>{message}</Link>
                                                                </li>

                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                </div>

                                                {/* <div className="social-icons social-colored social-fullwidth">
                                                    <ul style={{ margin: " 2px" }}>
                                                        <li className="social-facebook"><Link>Name :</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>Mr. John Maxwell</Link>
                                                        </li>

                                                    </ul>
                                                    <ul style={{ margin: " 2px" }}>
                                                        <li className="social-twitter"><Link>Phone Number :</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>0120255662</Link>
                                                        </li>

                                                    </ul>
                                                    <ul style={{ margin: " 2px" }}>
                                                        <li className="social-gplus"><Link>Pin Number :</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>45115</Link>
                                                        </li>

                                                    </ul>
                                                    <ul style={{ margin: "2px" }}>
                                                        <li className="social-linkedin"><Link>Email :</Link>
                                                        </li>
                                                        <li><Link className='bg-light text-muted'>john-naxwell@gmail.com</Link>
                                                        </li>
                                                    </ul>

                                                </div> */}

                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                {t('Close')}
                                            </Button>

                                        </Modal.Footer>

                                    </Modal>
                                </div>
                            </form>
                        </div>
                        <div class="col-xl-3 col-md-12 ps-lg-0 d-xl-block d-none">
                            <div class="appoinment-img mt-8 mt-lg-0">
                                <img class="img-fluid w-50" src="/images/about/01.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div >
            </section >

        </div >

        {/* <!--appointment end--> */}


    </>
}


