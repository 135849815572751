import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function Policy() {
    // translation 
    const { t } = useTranslation("Policy");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Privacy')} anotherTitle={t('Policy')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/policy`} currentPage={t('Privacy Policy')} />
            : <PageTitle title={t('Policy')} anotherTitle={t('Privacy')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/policy`} currentPage={t('Privacy Policy')} />}
        {/* <!--page title end--> */}
        {/* <!--body content start--> */}

        <div class="page-content">

            {/* <!--privacy start--> */}

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            {i18n.language === 'en' ? <h2 class="title mb-0">{t('Privacy')} <span>{t('Policy')}</span></h2> : <h2 class="title mb-0">{t('Policy')} <span>{t('Privacy')}</span></h2>}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12">
                            <p>{t('Welcome to Saudi German Health Egypt website, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, store, and disclose your personal data when you visit and interact with our website. Please read this policy carefully to understand our practices regarding your personal information.')}
                            </p>
                            <p>{t("In this privacy statement, 'our', 'we' and 'us' refer to Saudi German Health Egypt.")}
                            </p>
                            <p>{t('The Site is operated by Saudi German Health Egypt.')}
                            </p>
                            <h5 class="mt-5 text-theme">{t('Information We Collect')}:</h5>
                            <p className='fw-bolder'>{t('We may collect the following types of personal information when you visit our website:')}</p>
                            <ul class="list-unstyled list-icon">

                                <li> <i class="fa fa-angle-right"></i>{t('Contact Information: Name, email address, phone number, and other contact details you provide when submitting forms or communicating with us.')}

                                </li>
                                <li> <i class="fa fa-angle-right"></i> {t('Demographic Information: Age, gender, and other relevant demographic data.')}

                                </li>
                                <li> <i class="fa fa-angle-right"></i> {t('Technical Information: IP address, browser type, device information, and other technical data collected through cookies and similar technologies.')}

                                </li>
                                <li> <i class="fa fa-angle-right"></i> {t('Usage Information: Information about how you interact with our website, including pages visited, links clicked, and other actions taken.')}

                                </li>
                            </ul>

                            <h5 class="mt-5 text-theme">
                                {t('Use of Collected Information:')}
                            </h5>

                            <p className='fw-bolder'>{t('We use the collected information for the following purposes:')}
                            </p>
                            <ul class="list-unstyled list-icon">
                                <li> <i class="fa fa-angle-right"></i> {t("To provide and personalize our website's content and services.")}
                                </li>
                                <li> <i class="fa fa-angle-right"></i> {t('To communicate with you, respond to inquiries, and provide support.')}

                                </li>
                                <li> <i class="fa fa-angle-right"></i>{t('To improve our website, products, and services based on your feedback and preferences.')}
                                </li>
                                <li> <i class="fa fa-angle-right"></i>{t('To send you promotional materials, newsletters, and updates if you have opted to receive them.')}
                                </li>
                                <li> <i class="fa fa-angle-right"></i> {t('To analyze and understand user behavior, trends, and preferences to enhance user experience.')}
                                </li>
                            </ul>

                            <h5 class="mt-5 text-theme">
                                {t('Data Sharing and Disclosure:')}

                            </h5>

                            <p className='fw-bolder'>{t('We may share your personal information in the following circumstances:')}

                            </p>
                            <ul class="list-unstyled list-icon">
                                <li> <i class="fa fa-angle-right"></i> {t('With our affiliated entities, subsidiaries, and third-party service providers who assist us in operating our website and delivering services.')}
                                </li>
                                <li> <i class="fa fa-angle-right"></i>{t('With regulatory authorities, government agencies, or law enforcement officials if required by law or to protect our legal rights.')}

                                </li>
                                <li> <i class="fa fa-angle-right"></i>{t('In the event of a merger, acquisition, or sale of our business or assets, personal information may be transferred to the relevant third party.')}

                                </li>

                            </ul>
                            <h5 class="mt-5 text-theme">{t('Data Security:')}</h5>
                            <p>{t('We employ appropriate technical and organizational measures to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.')}</p>
                            <h5 class="mt-5 text-theme">  {t('Your Rights:')}</h5>

                            <p>
                                {t('You have certain rights regarding your personal information, including the right to access, correct, update, or delete your data. You may also have the right to object to or restrict the processing of your personal information. To exercise these rights or for any privacy-related queries, please contact us using the information provided at the end of this policy.')}
                            </p>
                            <h5 class="mt-5 text-theme"> {t('Third-Party Links:')}</h5>

                            <p>{t('Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such websites. We encourage you to review the privacy policies of those third parties before providing any personal information.')}
                            </p>

                            <h5 class="mt-5 text-theme">  {t('Updates to the Privacy Policy:')}</h5>

                            <p>{t('We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website. We encourage you to review this Privacy Policy periodically.')}
                            </p>
                            <h5 class="mt-5 text-theme">{t('Contact Us:')}
                            </h5>
                            <p className='fw-bolder'> {t('If you have any questions, concerns, or requests regarding this Privacy Policy or how we handle your personal information, please contact us at:')}
                            </p>
                            <ul className="contact-info list-unstyled mt-4">
                                <li className="mb-4"><i className="flaticon-paper-plane"></i><span>{t('Address :')}</span>
                                    <p>{t('Josef Teto Street, Nozha, Heliopolis, Cairo, Egypt')}</p>
                                    <p> {t('Kilo 23 Alex Matrouh Coastal Road, Compound Alex West.')}</p>
                                </li>
                                <li className="mb-4"><i className="fa-regular flaticon-phone-call shake" ></i><span>{t('Hotline :')}</span><a href="tel:+912345678900">{t('16259')}</a>
                                </li>
                                <li><i className="flaticon-email"></i><span>{t('Email :')}</span><a href="mailto: info@sghgroup.net">{t('info.egypt@sghgroup.net')}</a>
                                </li>
                            </ul>
                            <p class="mt-5 mb-0">{t('By using our website, you signify your acceptance of this Privacy Policy. If you do not agree with this policy, please refrain from using our website.')}
                            </p>
                            <p>{t('Thank you for entrusting your personal information to Saudi German Health Egypt.')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--privacy end--> */}

        </div>

        {/* <!--body content end--> */}
    </>
}
