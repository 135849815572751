import React from 'react'

export default function Loader() {
    return <>
        <div className='fade modal-backdrop show' style={{ zIndex: 100000 }}></div>
        <div className="page-wrapper">
            <div className="container">
                <div class="row justify-content-center align-items-center">

                    <div className="col-12 text-center">
                        <img src="/images/loading-screen.gif" alt="loading" className='position-absolute top-50 text-center loading-screen w-50' />
                    </div>
                </div>
            </div>
        </div>

    </>
}
