import React, { useContext, useEffect, useState } from 'react'
import PageTitle from '../Utilites/PageTitle'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';
export default function SuccessStories() {

    // translation 
    const { t } = useTranslation("About");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 

    const { pathname } = useLocation();

    let [shownStroke, setShownStroke] = useState("The video features a success story from Saudi German Hospital,where a stroke patient's life was saved by the hospital's emergency department.")
    let [shownCases, setShownCases] = useState("At Saudi German Health, patients can expect exceptional care and support from highly qualified and experienced staff. ")
    let [shownOperation, setShownOperation] = useState("In this video one of our patients shares her experience with Saudi German Health, ")
    let [shownFingerOperation, setShownFingerOperation] = useState("At Saudi German Hospital, we pride ourselves on our commitment to providing the highest quality healthcare services to our patients. ")
    let [shownMario, setShownMario] = useState("Once a passenger on a flight to Dubai, Mario Petchenely's life took a sudden turn when he began to feel extremely tired mid-flight. ")
    let [shownPatient, setShownPatient] = useState("When Farida was diagnosed with a brain tumor, her family turned to the medical professionals at Saudi German Hospital for help. ")


    // const videoUrl = '../../images/success-stories/Cerebral-Stroke-Patient.mp4/video?autoplay=0';
    // useEffect(() => {
    //     const iframe = document.querySelector('.videos');
    //     const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    //     const videoElement = iframeDocument.querySelector('video');

    //     iframe.innerHTML = `<video controls="" name="media"><source src="http://localhost:3000/images/success-stories/Cerebral-Stroke-Patient.mp4" type="video/mp4"></video>`;

    // }, [])
    function seeMoreStroke() {
        setShownStroke("The video features a success story from Saudi German Hospital,where a stroke patient's life was saved by the hospital's emergency department. The patient is shown in the video sharing his experience and gratitude for the care and treatment he received at the hospital. This video in turn, showcases the hospital's commitment to providing high-quality healthcare services to its patients, this is a success story that serves as a powerful testimonial to the hospital's capabilities, as we’re always looking forward to building trust and credibility with our patients .")
        document.querySelector('.post-btn').classList.add('d-none');
        document.querySelector('.less-btn').classList.remove('d-none');
    }
    function seeLessStroke() {
        setShownStroke("The video features a success story from Saudi German Hospital,where a stroke patient's life was saved by the hospital's emergency department. ")
        document.querySelector('.post-btn').classList.remove('d-none');
        document.querySelector('.less-btn').classList.add('d-none');
    }
    function seeMoreCases() {
        setShownCases("At Saudi German Health, patients can expect exceptional care and support from highly qualified and experienced staff.  This video showcases the success stories of Dr Mohamed El Sayad in Saudi German Hospital, which are a testament to his expertise in interventional radiology and his commitment to providing high-quality care to his patients. Dr. Mohamed El Sayad, a dual Egyptian/British Interventional Radiologist with a PhD degree, has been successful in providing patients with a wide range of minimally invasive treatment options at Saudi German Hospital.")
        document.querySelector('.post-cases-btn').classList.add('d-none');
        document.querySelector('.less-cases-btn').classList.remove('d-none');
    }
    function seeLessCases() {
        setShownCases("At Saudi German Health, patients can expect exceptional care and support from highly qualified and experienced staff.  ")
        document.querySelector('.post-operation-btn').classList.remove('d-none');
        document.querySelector('.less-operation-btn').classList.add('d-none');
    }
    function seeMoreOperation() {
        setShownOperation("In this video one of our patients shares her experience with Saudi German Health, describing how the hospital accurately diagnosed and treated her father's condition, ultimately saving his life. The patient was impressed not only by the hospital's medical expertise, but also by the staff's emotional support and friendliness during a difficult time.")
        document.querySelector('.post-operation-btn').classList.add('d-none');
        document.querySelector('.less-operation-btn').classList.remove('d-none');
    }
    function seeLessOperation() {
        setShownOperation("In this video one of our patients shares her experience with Saudi German Health, ")
        document.querySelector('.post-operation-btn').classList.remove('d-none');
        document.querySelector('.less-operation-btn').classList.add('d-none');
    }
    function seeMoreFingerOperation() {
        setShownFingerOperation("At Saudi German Hospital, we pride ourselves on our commitment to providing the highest quality healthcare services to our patients. One recent success story involved a patient who underwent a finger operation that had a very low 5% success rate. Thanks to the expertise and dedication of our medical team, the operation was a success, and the patient was able to regain full use of their finger. Following the operation, the patient received effective physiotherapy to help them fully recover and regain strength in their finger. The patient expressed their gratitude for the exceptional care they received at Saudi German Hospital, and credited our medical team and physiotherapists with helping them to achieve a successful outcome. ")
        document.querySelector('.post-finger-btn').classList.add('d-none');
        document.querySelector('.less-finger-btn').classList.remove('d-none');
    }
    function seeLessFingerOperation() {
        setShownFingerOperation("At Saudi German Hospital, we pride ourselves on our commitment to providing the highest quality healthcare services to our patients. ")
        document.querySelector('.post-finger-btn').classList.remove('d-none');
        document.querySelector('.less-finger-btn').classList.add('d-none');
    }

    function seeMoreMario() {
        setShownMario("Once a passenger on a flight to Dubai, Mario Petchenely's life took a sudden turn when he began to feel extremely tired mid-flight. The pilot made an emergency landing in Cairo and Mario was rushed to Saudi German Hospital, where he received life-saving treatment. Under the care of the expert medical team at Saudi German Hospital, Mario's condition began to improve. The doctors worked tirelessly to diagnose and treat his illness, and their dedication paid off when Mario finally regained his health. ")
        document.querySelector('.post-mario-btn').classList.add('d-none');
        document.querySelector('.less-mario-btn').classList.remove('d-none');
    }
    function seeLessMario() {
        setShownMario("Once a passenger on a flight to Dubai, Mario Petchenely's life took a sudden turn when he began to feel extremely tired mid-flight. ")
        document.querySelector('.post-mario-btn').classList.remove('d-none');
        document.querySelector('.less-mario-btn').classList.add('d-none');
    }

    function seeMorePatient() {
        setShownPatient("When Farida was diagnosed with a brain tumor, her family turned to the medical professionals at Saudi German Hospital for help. The hospital's team of doctors and nurses quickly sprang into action, developing a plan for Farida's treatment and providing her with the emotional support that she needed to face this challenging time. Thanks to their expertise and dedication, Farida underwent a successful surgery and made a full recovery. Farida's story is a testament to the quality of care that patients can expect to receive at Saudi German Hospital. ")
        document.querySelector('.post-patient-btn').classList.add('d-none');
        document.querySelector('.less-patient-btn').classList.remove('d-none');
    }
    function seeLessPatient() {
        setShownPatient("When Farida was diagnosed with a brain tumor, her family turned to the medical professionals at Saudi German Hospital for help. ")
        document.querySelector('.post-patient-btn').classList.remove('d-none');
        document.querySelector('.less-patient-btn').classList.add('d-none');
    }
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Success')} anotherTitle={t('Stories')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/success-stories`} currentPage={t('Success Stories')} /> : <PageTitle title={t('Stories')} anotherTitle={t('Success')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/success-stories`} currentPage={t('Success Stories')} />}
        {/* <!--page title end--> */}

        {/* <!--body content start--> */}
        <div class="page-content">
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div className="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/PW7THF4ayTM?si=MP7m40gUaeD6xcI2" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('11 OCt, 2023')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Alexandria Inauguration Ceremony')}</Link></h5>
                                            </div>
                                            <p>{t('The opening ceremony of the 2nd branch of Saudi German Health in Egypt')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/ENvIIBtvcpQ?si=spyPjPU4Ar2uUfRP" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('11 Mar, 2023')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Egypt Award Final Ceremony')}</Link></h5>
                                            </div>
                                            <p>{t('The final ceremony of the Saudi German Health Award under the patronage of Ministry of Health and Ministry of Social Solidarity')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/vQzS9GUMmc0?si=R2IMkiDYFC8kCyzm" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('11 Mar, 2023')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Gold Award Winner Testimonial')} </Link></h5>
                                            </div>
                                            <p>{t('The Gold Award of the Improvement Project “MS Unit” Category of Saudi German Health Award.')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/oFADPj6LzMA?si=mRgAQeNT9peCHj1j" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('11 Mar, 2023')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Silver Award Winner Testimonial')} </Link></h5>
                                            </div>
                                            <p>{t('The Silver Award of the Improvement Project “Sokkary Sadiki Initiative” Category of Saudi German Health Award')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/J8efd7VL2rI?si=4z4MMiqw_Bu-cTH3" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('11 Mar, 2023')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Bronze Award Winner Testimonial')}
                                                </Link></h5>
                                            </div>
                                            <p>{t('The Bronze Award of the Improvement Project “Department of Audiology” Category of Saudi German Health Award.')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/3rngQ3z-CKM?si=7GOXhI8t1Dx7vCzT" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('11 Mar, 2023')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Batterjee Medical Award Winner')}
                                                </Link></h5>
                                            </div>
                                            <p>{t('Documentary Video about the Imminent Professor Dr. Sherif Mokhtar – Professor of Cardiology and the Founder of the Critical Care Unit ElKasr El-Ainy Faculty of Medicine. during the Final Award Ceremony.')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/yRdiQQVYjJg?si=HZDzZTYClzzh4Zzd" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('11 Mar, 2023')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Journey of Saudi German Health Award')}
                                                </Link></h5>
                                            </div>
                                            <p>{t("An inspiring journey celebrating excellence in healthcare, from inception to grand finale.")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Cugbib_2p1o?si=Jhs-Ywu_RS7PXHiE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t("Date")}</li>
                                                    <li>{t('11 OCt, 2023')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Our Community Role')}
                                                </Link></h5>
                                            </div>
                                            <p>{t("Highlighting Saudi German Health Egypt's impactful role in serving the community.")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/3-SZpRTWZkw?si=hahFcC7UvtsjYSMk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('26 JAN, 2022')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('The Walk of Art')}
                                                </Link></h5>
                                            </div>
                                            <p>{t("Unveiling the artistic journey: Saudi German Health Egypt's walk of art initiative")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/nhYlFzimxGU?si=ITc3gws1EBaTSypf" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('9 JUN, 2023')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Africa Health Excon 2nd Edition')}
                                                </Link></h5>
                                            </div>
                                            <p>{t('Saudi German Health Egypt is the Conference Partner with 2nd Africa Health Excon in providing the Healthcare Leadership and Management.')} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/gyxqg83U7sI?si=5JluCbUcrUyWAhwW" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('9 JUN, 2022')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Africa Health Excon 1st Edition')}

                                                </Link></h5>
                                            </div>
                                            <p>{t('Saudi German Health Egypt is the Conference Partner with 1st Africa Health Excon in providing the Healthcare Leadership and Management.')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/nL7HM1SUb9s?si=PIMDQVeTKPKlLsJa" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('19 Sep, 2019')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Mayo Clinic Care Network Membership')}
                                                </Link></h5>
                                            </div>
                                            <p>{t('Saudi German Hospital Cairo is a member in Mayo Clinic Care Network MCCN as 1st hospital in Africa and 3rd in Middle East.')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/CqleIE9aUjE?si=JXJ6-UaargDNwl75" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('30 Nov, 2021')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Gold Winner of International Hospital Federation')}
                                                </Link></h5>
                                            </div>
                                            <p>{t('Saudi German Hospital Cairo is the gold winner of the IHF Grand Hospital Award being the Egyptian National Accreditation Modelling Reference.')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/CnBZMDKDnF0?si=nGE7KvujinIq_4Rp" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('9 Sep, 2021')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Our Journey during COVID19')}
                                                </Link></h5>
                                            </div>
                                            <p>{t("Uniting against COVID-19: Saudi German Health Egypt's impactful support initiative.")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/gOl6lPfJBS0?si=7aA4-zOY004ok9N-" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>

                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('20 Feb, 2020')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('GAHAR Accreditation Ceremony')}

                                                </Link></h5>
                                            </div>
                                            <p>{t('Saudi German Hospital Cairo is the first hospital in Egypt to be accredited by GAHAR.')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="masonry row">
                                <div class="masonry-brick">
                                    <div class="post">
                                        <div class="video-post">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/E8oqtDaYKuY?si=4ot5zeQjQ3SvYXk1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    <li>{t('Date')}</li>
                                                    <li>{t('20 Dec, 2018')}</li>

                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link to="">{t('Canadian Accreditation Ceremony')}

                                                </Link></h5>
                                            </div>
                                            <p>{t('Saudi German Hospital Cairo is accredited by the Canadian Accreditation – Diamond Level')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </div >

        {/* <!--body content end--> */}
    </>
}
