import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function TermsAndConditions() {
    // translation 
    const { t } = useTranslation("TermsAndConditions");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title={t("Terms And Conditions")} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/terms-conditions`} currentPage={t('Terms And Conditions')} />
        {/* <!--page title end--> */}
        {/* <!--body content start--> */}

        <div className="page-content">

            {/* <!--terms start--> */}

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h2 className="title mb-0">{t('Terms and')} <span>{t('Conditions')}</span></h2>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-12 col-md-12">
                            <h5 className="text-theme">{t('Welcome to our website!')}
                            </h5>
                            <p className='fw-bolder'>{t('When you visit the website, you agree to the following terms and conditions which are set of rules and guidelines that govern how you interact with our website, Also, they are the subject to your agreement with Saudi German Health Egypt. If you do not wish to comply with these restrictions, do not use this site, because there are several legal restrictions that should not be violated:')}
                            </p>
                            <ul className='list-unstyled'>
                                <li>{t('I implicitly agree to abide by the all the following terms and conditions which stipulate my agreement with Saudi German Health Egypt, and in case I infringe any of these terms I lead myself for legal consequences, reputation damage and loss of user trust.')}</li>
                                <h5 className='pt-3 text-theme'>{t('Purpose of this website')} </h5>
                                <li>{t('This site does not provide medical advices or prescriptions and nothing in the content of this site should be considered or used as a substitute for the medical advice, diagnosis, or treatment and it is not a substitute for your doctor and all the contents of this site of the articles published through the administration is for the purpose of education and to provide information only, and any question about any confusion please see your doctor.')}
                                </li>
                                <h5 className='pt-3 text-theme'>{t('ACCEPTABLE USERS')}</h5>
                                <li>{t('The Site is ACCEPTED for use by individuals 21 years of age or older. It`s not directed for use by minors under the age of 21.')}</li>
                                <li>{t('Minors users should get the assistance of a parent or guardian to use this Site.')}</li>
                                <h5 className='pt-3 text-theme'>{t('(Trademarks and intellectual property)')} </h5>
                                <li>{t('Saudi German Health Egypt owns the trademarks, logos and service marks displayed on this website. These shall not be used without the written permission. Materials on this site are protected by copyright. No part of these materials may be modified, reproduced, stored in a retrieval system, transmitted, copied, distributed or used in any other way for commercial or public purposes without Saudi German Health Egypt prior written consent.')}
                                </li>
                                <li>{t('Do not infringe on intellectual property rights, trademarks, public morals, and do not breach the site for any reason or imagine that you reproduce our information and services illegally or without prior permission, otherwise you will led yourself to legal liability.')}
                                </li>
                                <li>{t('The contents published on this site are private property of the Saudi German Health Egypt under the intellectual property law. Therefore, we have a genuine right to maintain our copyright, patents, and do not give all of the above to customers, for their convenience and full care. We do not recommend or encourage any content, services, tests, products, procedures, opinions, or other information that may be received.')}
                                </li>
                                <h5 className='pt-3 text-theme'>{t('ACCEPTABLE USERS')} </h5>
                                <li>{t("Saudi German Health Egypt’s site is provided “AS IS' and 'AS AVAILABLE,' without any warranties of any kind, whether express or implied. Furthermore, Saudi German Health Egypt disclaim any express, implied, and statutory  warranties regarding the content, including warranties of satisfactory quality, merchanbility, fitness for a particular purpose, or non- infringement.")}
                                </li>
                                <li>{t('Saudi German Health Egypt does not warrant that the services or the content is free of malware or other harmful components.')} </li>
                                <li>{t('Saudi German Health Egypt does not guarantee the accuracy, adequacy, or completeness of any information and is not responsible for any error or omissions for the results obtained from the use of such information')}</li>
                                <li>{t('You agree that Saudi German Health Egypt doesn’t operate or control the internet, therefore Saudi German Health Egypt doesn’t guarantee that the use of this website will be free of error or technology downtimes or unavailability, You also agree that Saudi German Health Egypt doesn’t guarantee against Viruses, or other unauthorized users or hacks attempting, Despite Saudi German Health Egypt does its best to avoid any of the Abovementioned.')}
                                </li>
                                <li>{t('Saudi German Health Egypt does not assume any criminal responsibility or claim compensation. We consider ourselves a purely informational window for the convenience of the customer.')}
                                </li>
                                <h5 className='pt-3 text-theme'>{t('Prohibited activities and Restrictions')}</h5>
                                <p className='fw-bolder'>{t('You Agree that it`s prohibited to do any of the following, for example but not limited:')}</p>
                                <li>{t('Use this website to post or transmit material that is unlawful, harassing, threatening, abusive, hateful, or embarrassing to any other person or entity.')}
                                </li>
                                <li>{t('Use this website for any purpose in violation of any laws especially Egyptian Laws.')}
                                </li>
                                <li>{t('Use this Site as a means to distribute advertising or other unsolicited material to any third party.')}
                                </li>
                                <li>{t('Trail to disable or hack this site or otherwise interfere with the proper functioning of this website.')}
                                </li>
                                <li>{t('Use any device, software, or any other means to interfere or attempt to interfere with the proper working of this site or any activity being conducted on this site.')}
                                </li>
                                <h5 className='pt-3 text-theme'>{t('Limitations of Liability')}</h5>
                                <li>{t('The use of this site is entirely at your own reasonability, and in no event shall Saudi German health Egypt be liable for any direct, indirect, incidental, or any other monetary or other damages, fines, penalties or liabilities arising out or relating in any way to this service or any information provided herein.')}</li>
                                <h5 className='pt-3 text-theme'>{t('Indemnification')}</h5>
                                <li>{t('You agree that you will indemnify, and hold harmless of Saudi German Health Egypt from and against any damages, losses, liabilities, judgments, costs, or expenses against or incurred by Saudi German Health Egypt arising out of any claim by a third party relating to your breach of any of these Terms and conditions and any other materials you post or allow to be posted to this website, or any other actions taken by you on the Site.')}
                                </li>
                                <h5 className='pt-3 text-theme'>{t('TERMS & CONDITIONS UPDATES')}</h5>
                                <li>{t("Saudi German Health Egypt may modify these terms and conditions at any time, and such modifications shall be effective immediately upon posting the modified terms and conditions on Saudi German Health Egypt’s Site, so, You agree to review the agreement periodically to be aware of such modifications, and your accessing or using the service constitutes your acceptance of the agreement as it appears at the time of your access or use. From time to time, so if you register on this Site, this constitutes acceptance of the new Modified Terms and Conditions of Use and you are legally bound to do so.")}
                                </li>
                                <h5 className='pt-3 text-theme'>{t('TERMINATION & SUSPENSION')}</h5>
                                <li>{t('Saudi German Health Egypt may, in its sole discretion, terminate your account or your use of the Site at any time.')}
                                </li>
                                <li>{t('Saudi German Health Egypt reserves the right to change, suspend, or discontinue all or any aspects of the Site at any time without prior notice.')}
                                </li>
                            </ul>


                        </div>
                    </div>
                </div>
            </section>

            {/* <!--terms end--> */}

        </div>

        {/* <!--body content end--> */}
    </>
}
