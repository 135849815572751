import React from 'react'
import { useLocation } from 'react-router-dom';
import PageTitle from '../../Utilites/PageTitle';
import Cookies from 'js-cookie';

export default function MedicalEducationFeatures() {
  const { pathname } = useLocation();

  return <>
    <PageTitle title='Medical Education' anotherTitle='Unit' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/health-hub/medical-education-unit`} currentPage='Medical Education Unit' />

    <div class="page-content">

      <section>
        <div class="container">
          <div class="row text-center">
            <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
              <div class="section-title">
                <h2 class="title"> Our <span>Services</span></h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="team-member style-2 text-center">
                <div class="team-images">
                  <img class="img-fluid" src="/images/team/06.jpg" alt="" />
                </div>
                <div class="team-description">
                  <h5 class="mb-2"><a href="doctor-single.html">American Heart Association ITC</a></h5>
                  <p>Cras ultricies ligula sed magna dictum porta, iste natus error sit voluptat</p>
                </div>
                <div class="social-icons social-colored social-fullwidth">
                  <ul>

                    <li class="btn btn-theme"><a href="#">Learn More</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-5 mt-md-0">
              <div class="team-member style-2 text-center">
                <div class="team-images">
                  <img class="img-fluid" src="images/team/05.jpg" alt="" />
                </div>
                <div class="team-description">
                  <h5 class="mb-2"><a href="doctor-single.html">Medical Internship</a></h5>
                  <p>Cras ultricies ligula sed magna dictum porta, iste natus error sit voluptat</p>
                </div>
                <div class="social-icons social-colored social-fullwidth">
                  <ul>

                    <li class="btn btn-theme"><a href="#">Learn More</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
              <div class="team-member style-2 text-center">
                <div class="team-images">
                  <img class="img-fluid" src="images/team/06.jpg" alt="" />
                </div>
                <div class="team-description">
                  <h5 class="mb-2"><a href="doctor-single.html">Scientific Events</a></h5>
                  <p>Cras ultricies ligula sed magna dictum porta, iste natus error sit voluptat</p>
                </div>
                <div class="social-icons social-colored social-fullwidth">
                  <ul>

                    <li class="btn btn-theme"><a href="#">Learn More</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-5">
              <div class="team-member style-2 text-center">
                <div class="team-images">
                  <img class="img-fluid" src="/images/team/06.jpg" alt="" />
                </div>
                <div class="team-description">
                  <h5 class="mb-2"><a href="doctor-single.html">Egyptian Fellowship</a></h5>
                  <p>Cras ultricies ligula sed magna dictum porta, iste natus error sit voluptat</p>
                </div>
                <div class="social-icons social-colored social-fullwidth">
                  <ul>

                    <li class="btn btn-theme"><a href="#">Learn More</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-5">
              <div class="team-member style-2 text-center">
                <div class="team-images">
                  <img class="img-fluid" src="images/team/05.jpg" alt="" />
                </div>
                <div class="team-description">
                  <h5 class="mb-2"><a href="doctor-single.html">Arab Board</a></h5>
                  <p>Cras ultricies ligula sed magna dictum porta, iste natus error sit voluptat</p>
                </div>
                <div class="social-icons social-colored social-fullwidth">
                  <ul>

                    <li class="btn btn-theme"><a href="#">Learn More</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-5">
              <div class="team-member style-2 text-center">
                <div class="team-images">
                  <img class="img-fluid" src="images/team/06.jpg" alt="" />
                </div>
                <div class="team-description">
                  <h5 class="mb-2"><a href="doctor-single.html">Foreign Fellowships</a></h5>
                  <p>Cras ultricies ligula sed magna dictum porta, iste natus error sit voluptat</p>
                </div>
                <div class="social-icons social-colored social-fullwidth">
                  <ul>

                    <li class="btn btn-theme"><a href="#">Learn More</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
}
