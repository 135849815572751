
import axios from "axios";
import React, { createContext, useState } from "react";
export let GetDepartmentsOptionsContext = createContext('');

export default function GetDepartmentsOptionsContextProvider(props) {
    let [DepartmentsOptions, setDepartmentsOptions] = useState([])
    async function GetDepartmentsSelectOptions(hospital_id) {
        // e.preventDefault()
        // if (e.target.value) {
        let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/booking/department/${hospital_id}/en`);
        setDepartmentsOptions(data);

        // console.log('DETAILS DepartmentsOptions>>> ' + DepartmentsOptions);
        // console.log(data);
        // }
    }
    // useEffect(() => { GetDepartmentsSelectOptions() }, []) 

    return <>
        <GetDepartmentsOptionsContext.Provider value={{
            GetDepartmentsSelectOptions, DepartmentsOptions

        }}>
            {props.children}
        </GetDepartmentsOptionsContext.Provider>
    </>
}
