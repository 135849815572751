import React, { createContext, useEffect } from "react";
import { AuthContext } from './AuthContext';
import jwtDecode from "jwt-decode";
import { useContext } from "react";

export let SaveUserDataContext = createContext(0);

export default function SaveUserDataContextProvider(props) {

    const { userData, setUserData } = useContext(AuthContext);
    function saveUserData() {
        let encodedToken = localStorage.getItem("patientPortalToken");
        let decodedToken = jwtDecode(encodedToken);
        console.log(decodedToken);
        setUserData(decodedToken);
        console.log('userdata ' + userData);

    }
    // useEffect(() => {
    //     if (localStorage.getItem("patientPortalToken") !== null) {
    //         saveUserData();
    //     }
    // }, []);
    return <>
        <SaveUserDataContext.Provider value={{ saveUserData }}>
            {props.children}
        </SaveUserDataContext.Provider>

    </>
}



