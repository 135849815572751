import React from 'react'
import PageTitle from '../Utilites/PageTitle'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export default function FAQ() {

    const { t } = useTranslation("faq");
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title={t('faq')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/faq`} currentPage={t('faq')} />
        {/* <!--page title end--> */}
        {/* <!--body content start--> */}

        <div class="page-content">

            {/* <!--accordion start--> */}

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="accordion" id="accordion">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                                            {t('ques-1')}
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                        <div class="accordion-body text-muted">
                                            <li> {t('ans-1')}</li>
                                            <li>  {t('ans-1-1')}</li>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            {t('ques-2')}

                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                        <div class="accordion-body text-muted">
                                            {t('ans-2')}
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            {t('ques-3')}
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                                        <div class="accordion-body text-muted">
                                            {t('ans-3')}
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            {t('ques-4')}
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                                        <div class="accordion-body text-muted">
                                            {t('ans-4')}
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFive">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            {t('ques-5')}
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordion">
                                        <div class="accordion-body text-muted">
                                            {t('ans-5')}
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingSix">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            {t('ques-6')}
                                        </button>
                                    </h2>
                                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordion">
                                        <div class="accordion-body text-muted">
                                            {t('ans-6')}
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingSeven">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            {t('ques-7')}
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordion">
                                        <div class="accordion-body text-muted">
                                            {t('ans-7')}
                                        </div>
                                    </div>
                                </div>

                            </div>




                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingEight">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                        {t('ques-8')}
                                    </button>
                                </h2>
                                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordion">
                                    <div class="accordion-body text-muted">
                                        {t('ans-8')}
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingNine">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                        {t('ques-9')}
                                    </button>
                                </h2>
                                <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordion">
                                    <div class="accordion-body text-muted">
                                        {t('ans-9')}
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTen">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                        {t('ques-10')}
                                    </button>
                                </h2>
                                <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordion">
                                    <div class="accordion-body text-muted">
                                        {t('ans-10')}
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingEleven">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                        {t('ques-11')}
                                    </button>
                                </h2>
                                <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordion">
                                    <div class="accordion-body text-muted">
                                        {t('ans-11')}
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwelv">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelv" aria-expanded="false" aria-controls="collapseTwelv">
                                        {t('ques-12')}
                                    </button>
                                </h2>
                                <div id="collapseTwelv" class="accordion-collapse collapse" aria-labelledby="headingTwelv" data-bs-parent="#accordion">
                                    <div class="accordion-body">
                                        {t('ans-12')}
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTherteen">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTherteen" aria-expanded="false" aria-controls="collapseTherteen">
                                        {t('ques-13')}
                                    </button>
                                </h2>
                                <div id="collapseTherteen" class="accordion-collapse collapse" aria-labelledby="headingTherteen" data-bs-parent="#accordion">
                                    <div class="accordion-body text-muted">
                                        {t('ans-13')}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!--accordion end--> */}

        </div>

        {/* <!--body content end--> */}
    </>
}
