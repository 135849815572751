import React from 'react'
import PageTitle from '../Utilites/PageTitle'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Select from 'react-select';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useContext } from 'react';
import { GetDepartmentsOptionsContext } from '../../Context/GetDepartmentsOptions';
import { GetDoctorsOptionsContext } from '../../Context/GetDoctorsOptions';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function RequestAppointment() {
    // translation 
    const { t } = useTranslation("BookNow");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    let { GetDoctorsSelectOptions, DoctorsOptions, selectedOption, setSelectedOption, setDoctorsOptions, selectedBranch, setSelectedBranch } = useContext(GetDoctorsOptionsContext);

    const { pathname } = useLocation();

    const client = {
        autoplay: true,
        loop: true,
        margin: 30,
        responsiveClass: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 3,
            },
            600: {
                items: 4,
            },

            1000: {
                items: 5,

            }
        },
    }


    const brancheOptions = [
        { value: '1', label: t('Cairo') },
        { value: '2', label: t('Alexandria') },
    ]
    const defaultValue = { value: 'Cairo', label: 'Cairo' };
    const handleSelectBranchChange = (selectedBranch) => {

        setSelectedBranch(selectedBranch);
        console.log('selectedBranch ' + selectedBranch.value);

    };
    // const [DoctorsOptions, setDoctorsOptions] = useState('');

    const handleSelectChange = (selectedOption, DoctorsOptions) => {

        setSelectedOption(selectedOption);
        setDoctorsOptions(DoctorsOptions);

    };
    const handleDoctorSelectChange = (DoctorsOptions, { action }) => {
        // setSelectedOption(selectedOption);

        if (action === 'select-option') {
            setDoctorsOptions(DoctorsOptions);
        } else if (action === 'clear') {
            setDoctorsOptions(null);
        }
    };

    function preventDefault(e) {
        e.preventDefault();

    }
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState([]);
    let search = []
    function bookingResult() {

        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
    }
    let [currentPageForSearch, setCurrentPageForSearch] = useState(1)

    async function doctorSearch(e) {
        if (e) {
            e.preventDefault();
            // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
            if (selectedOption || selectedBranch || DoctorsOptions) {
                let { data } = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/doctor/list/en?search_hospital=${selectedBranch.value}&search_name=&search_title[]=&search_title[]=&search_title[]=&gender[]=&gender[]=&search_department=${selectedOption.value}&doc_id=${DoctorsOptions.value}&page=${currentPageForSearch}&orderby=&order=`);
                setSearchData(data.Edata.data);

                // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
            }
        }
    }
    useEffect(() => {
        GetDepartmentsSelectOptions();
    }, [])

    useEffect(() => {
        GetDoctorsSelectOptions();
    }, [selectedOption])
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    useEffect(() => {

        if (selectedOption) {
            search = searchData?.filter((doctorSearch) => selectedOption === doctorSearch.department_id)

        } else if (selectedBranch) {
            search = searchData?.filter((doctorSearch) => selectedBranch === doctorSearch.branch)

        } else if (DoctorsOptions) {
            search = searchData?.filter((doctorSearch) => DoctorsOptions === doctorSearch.doc_id)

        } else {
            search = searchData?.map((doctorSearch))
        }
        doctorSearch();
    }
        , [selectedOption, selectedBranch, DoctorsOptions])
    return <>
        {/* <!--page title start--> */}
        <PageTitle title={t('Request Appointment')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-and-visitors/request-an-appointment`} currentPage={t('Request Appointment')} />
        {/* <!--page title end--> */}

        {/* <!--appointment start--> */}

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12 mx-auto">
                        <div className="theme-bg p-5">
                            <div className="section-title mb-4">
                                {/* <h6>Reservation</h6> */}
                                <h2 className="title text-white mb-5">{t('Book')} <span>{t('Appointment !')}</span></h2>
                                {/* <p className="text-white">Saudi German Hospital have facility to produce adipisicing elit, Excepturi vero aliquam id consectetur adipisicing elit, technologicaly changes and industrial systems.</p> */}
                            </div>
                            <form onSubmit={doctorSearch} className="row justify-content-center" method="post" >
                                <div className="messages"></div>
                                <div className="form-group justify-content-center mb-4 clearfix col-md-8 d-flex align-items-center justify-content-between" >
                                    <i className="flaticon-paper-plane text-white border-end pe-2" style={{ fontSize: '18px' }}></i>
                                    <Select options={brancheOptions} value={selectedBranch} onChange={handleSelectBranchChange} placeholder={t("Select Branch")} className="w-100 px-2" styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                            outline: state.isFocused ? ' none' : 'none',

                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isFocused ? '#409639' : ' ',
                                            color: state.isFocused ? '#fff' : '',

                                        }),

                                    }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                    </Select>
                                </div>
                                <div className="form-group justify-content-center clearfix col-md-8 d-flex align-items-center justify-content-between" >
                                    <i className="flaticon-stethoscope text-white border-end pe-2" style={{ fontSize: '18px' }}></i>
                                    <Select placeholder={t("Select Department")} options={DepartmentsOptions} value={selectedOption}
                                        onChange={handleSelectChange} className="w-100 px-2" styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                outline: state.isFocused ? ' none' : 'none',
                                            }),
                                            option: (baseStyles, state) => ({
                                                ...baseStyles,
                                                // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                                backgroundColor: state.isFocused ? '#409639' : ' ',
                                                color: state.isFocused ? '#fff' : '',

                                            }),
                                        }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                    </Select>
                                </div>
                                <div className="form-group justify-content-center clearfix col-md-8 d-flex align-items-center  justify-content-between">
                                    <i className="flaticon-doctor text-white border-end pe-2" style={{ fontSize: '18px' }}></i>
                                    <Select placeholder={t("Select physician")} options={DoctorsOptions} onChange={handleDoctorSelectChange} className="w-100 px-2" styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                            outline: state.isFocused ? ' none' : 'none',

                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isFocused ? '#409639' : ' ',
                                            color: state.isFocused ? '#fff' : '',

                                        }),
                                    }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                    </Select>
                                </div>
                                <div className="col-md-5 col-sm-12 mt-3 mt-sm-0 text-center mx-auto">
                                    {selectedBranch.value === '1' ? <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`} className='p-0'> <button className="btn btn-white btn-radius w-75" style={{ zIndex: '0' }} type='submit'><span className='small'>{t('Make Appointment')}</span>
                                    </button>
                                    </Link> : <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/doctors`} className='p-0'> <button className="btn btn-white btn-radius w-75" style={{ zIndex: '0' }} type='submit'><span className='small'>{t('Make Appointment')}</span>
                                    </button>
                                    </Link>}
                                    {/* <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`} className='p-0'><button className="btn btn-white btn-radius w-100" style={{ zIndex: '0' }} type='submit'><span>Make Appointment</span>
                                    </button></Link> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!--appointment end--> */}



    </>
}


