import React from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie';
import PageTitle from '../../Utilites/PageTitle';
import OurPartnersDetails from '../../Utilites/OurPartnersDetails';
export default function ACHS() {
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='ACHS' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/`} prevPage='Our Partner' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/australian-council-on-health-care-standards`} currentPage='ACHS' />


        {/* <!--page title end--> */}


        {/* <!--about us start--> */}
        <OurPartnersDetails partnerImgSrc="/images/partners/achs.jpg" partnerTitle='Australian Council for Healthcare Standards ACHS' partnerDescription='Elevating care with global partnership: Saudi German Health Egypt proudly holds its place as a key partner with the prestigious Australian Council for Healthcare Standards International (ACHSI). This collaboration signifies a commitment to exceeding international benchmarks in healthcare excellence, ensuring patients receive world-class treatment delivered with the utmost professionalism and care. Through this partnership, Saudi German Health Egypt continues to raise the bar for healthcare in Egypt and across the region .' />


        {/* <!--about us end--> */}
    </>
}
