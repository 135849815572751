import axios from 'axios'
import { PatientPortalBaseUrlContext } from '../../Context/PatientPortalBaseUrlContext';
import Cookies from 'js-cookie';

// Create an instance of axios with custom configuration
// let { baseUrl, setBaseUrl } = useContext(PatientPortalBaseUrlContext);

const instance = axios.create({

    headers: {
        'Content-Type': 'application/json', // Set your common headers here
        'lang': Cookies.get('i18next'),
        // Add more headers if needed
    },
});

export default instance;