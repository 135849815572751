import React, { useEffect, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { GetCategoryDetailsContext } from '../../Context/GetCategoryDetails';
import { LocalizationContext } from '../../Context/LocalizationContext'
import PageTitle from '../Utilites/PageTitle';
import PageDetails from '../Utilites/MayoClinic/PageDetails';
import OverViewPageDetails from '../Utilites/MayoClinic/OverViewPageDetails';
import CopyWrite from '../Utilites/MayoClinic/CopyWrite';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function AnsowerDetails() {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    const { pathname } = useLocation();

    let { id, contentType } = useParams();

    let { answerDetails, setAnswerDetails, getCategoryDetails } = useContext(GetCategoryDetailsContext);



    //   console.log(`${diseaseDetails}`);
    useEffect(() => {
        getCategoryDetails(id, setAnswerDetails);

        // console.log(getCategoryDetails(setDiseaseDetails));
    }, [i18n.language]);
    return <>
        {/* <!--page title start--> */}
        {answerDetails.map((answerDetail, index) => (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{answerDetail.title}</title>
                <link rel="canonical" href={answerDetail.originalUrl} />
            </Helmet>
            {i18n.language === 'en' ? <PageTitle key={index} title={t('Mayo Clinic')} anotherTitle={t('Health Library')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Answer/${answerDetail.docId}`} currentPage={answerDetail.title} />
                : <PageTitle key={index} title={t('Health Library')} anotherTitle={t('Mayo Clinic')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/Answer/${answerDetail.docId}`} currentPage={answerDetail.title} />}

        </>))}


        {/* <!--page title end--> */}

        {/* <!--body content start--> */}

        <div class="page-content">

            {/* <!--blog start--> */}

            <section>
                <div class="container">
                    <div class="row">
                        {answerDetails.map((answerDetail, index) => (<>

                            <div key={index} class="col-lg-12 col-md-12 order-lg-12">
                                <PageDetails contentType={answerDetail.contentType} title={answerDetail.title} updateDate={answerDetail.updateDate} />

                                <div className="service-details mt-4">

                                    {/* <p className="lead" dangerouslySetInnerHTML={{ __html: answerDetail.abstract }}></p> */}

                                    <div key={index} class="row">

                                        {answerDetail.sections.map((section, index) => (<>
                                            <div key={index} className="col-md-9 order-2">
                                                {section.sectionHead ? <h6 className='text-theme'>{section.sectionHead}</h6> : ''}
                                                <p className='lead' dangerouslySetInnerHTML={{ __html: section.html }}></p>
                                            </div>
                                            <div class="col-md-3 mt-3 mt-md-0 order-1 order-md-3">
                                                {section.media ?
                                                    section.media.map((med, index) => (<>
                                                        {med.file_name ?
                                                            <OverViewPageDetails key={index} fileName={med.file_name} />
                                                            : ''}

                                                    </>))
                                                    : ''}
                                            </div>
                                        </>))}
                                    </div>

                                </div>

                                <CopyWrite />
                            </div>
                        </>)

                        )}
                    </div>
                </div>
            </section>

            {/* <!--blog end--> */}

        </div>

        {/* <!--body content end-->  */}
    </>
}
