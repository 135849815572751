import React, { useContext, useEffect } from "react";
import { GetCategoriesContext } from "../../Context/GetCategories";
import { Link, useLocation } from "react-router-dom";
import { LocalizationContext } from "../../Context/LocalizationContext";
import { CategorySearchDataContext } from "../../Context/CategorySearchData";
import PageTitle from "../Utilites/PageTitle";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

export default function MayoSlideshow() {
    // translation 
    const { t } = useTranslation("MayoClinic");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    let { getCategoryByChars, slideShow, setSlideShow } = useContext(GetCategoriesContext);
    let { CategorySearchData, searchData } = useContext(CategorySearchDataContext);
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    useEffect(() => {
        getCategoryByChars("SlideShow", setSlideShow);
    }, [i18n.language]);

    useEffect(() => {

        CategorySearchData("SlideShow");

    }, [searchData]);
    return <>


        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Mayo Clinic')} anotherTitle={t('Health Library')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/SlideShow`} currentPage={t('Health Style')} />
            : <PageTitle title={t('Health Library')} anotherTitle={t('Mayo Clinic')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary`} prevPage={t('Mayo Clinic')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/SlideShow`} currentPage={t('Health Style')} />}

        {/* <!--page title end--> */}



        {/* <!--body content start--> */}

        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className='row justify-content-center mb-5'>
                        <div className="col-lg-6 col-md-12 order-lg-12">
                            <div className="widget">
                                <div className="widget-search">
                                    {/* <form className="form-inline form">
                                        <div className="widget-searchbox">
                                            <button type="submit" className="search-btn"> <i className="fas fa-search"></i>
                                            </button>
                                            <input type="text" placeholder="Search..." className="form-control" />
                                        </div>
                                    </form> */}

                                    <div class="section-title mb-4">
                                        <h6 className='h3'>{t('SearchHealthStyle')}
                                        </h6>

                                        {/* <p class="text-white">Hectolab have facility to produce adipisicing elit, Excepturi vero aliquam id consectetur adipisicing elit, technologicaly changes and industrial systems.</p> */}
                                    </div>
                                    <div class="theme-bg p-5">

                                        {/* <form id="queto-form" class="row" method="post" action="https://themeht.com/template/hectolab/html/ltr/php/contact.php"> */}
                                        <div class="messages"></div>
                                        <div className="widget-searchbox">
                                            <button type="submit" className="search-btn text-white"> <i className="fas fa-search"></i>
                                            </button>
                                            <input type="text" onChange={(e) => CategorySearchData(e, "SlideShow")} placeholder={`${t(`Search`)}`} className="form-control" />
                                        </div>

                                        {/* <div class="col-sm-12  mt-5 mt-sm-0">
                                                <button class="btn btn-white btn-radius"><span>Search Now</span>
                                                </button>
                                            </div> */}
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {searchData.length > 0 ? (
                        <>
                            {searchData.map((slideShowSearch, index) => (<>
                                <button key={index} type="button" class="btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 consultation">{slideShowSearch.char}</button>
                                < div key={slideShowSearch.id} className="row mt-5 mb-5" >
                                    {slideShowSearch.data.map((slideShowName, index) => (<>
                                        <div key={index} className="col-lg-4 col-md-6 mt-5 mt-md-0">
                                            <div>

                                                <div className="team-description section-title  mb-0">
                                                    {/* <span>Doctor</span> */}
                                                    <h6 style={{ lineHeight: '28px', fontSize: "14px" }} onClick={scrollToTop}>  <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/SlideShow/${slideShowName.id}`}> {slideShowName.title}</Link>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>

                                    </>))}
                                </div>
                            </>))}
                        </>
                    ) :
                        slideShow.map((slideShowItem, index) => (<>
                            <button key={index} type="button" class="btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 consultation">{slideShowItem.char}</button>
                            < div key={slideShowItem.id} className="row mt-5 mb-5" >
                                {slideShowItem.data.map((slideShowTitle, index) => (<>
                                    <div key={index} className="col-lg-4 col-md-6 mt-5 mt-md-0">
                                        <div>

                                            <div className="team-description  section-title  mb-0">
                                                {/* <span>Doctor</span> */}
                                                <h6 style={{ lineHeight: '28px', fontSize: "14px" }} onClick={scrollToTop}>  <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/MCLibrary/SlideShow/${slideShowTitle.id}`}> {slideShowTitle.title}</Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>

                                </>))}
                            </div>
                        </>
                        )
                        )
                    }




                </div>
            </section >

            {/* <!--blog end--> */}

        </div >

        {/* <!--body content end--> */}
    </>
}
