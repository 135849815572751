import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';
export default function Subscribe(props) {
    // translation 
    const { t } = useTranslation("Footer");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    // subscribe form

    const [showForm, setShowForm] = useState(false);

    const handleFormClose = () => setShowForm(false);
    const handleFormShow = () => setShowForm(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };




    const [submitMsg, setSubmitMsg] = useState('');
    const [email, setEmail] = useState('');

    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();

        bodyFormData.append('email', email);

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/subscribe`, bodyFormData)
            .then(response => {

                if (response.data.Ecode === 200) {
                    console.log(response.data);
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg(t('Thank you for your submition!'))
                    } else {
                        setSubmitMsg(t('Error!! please Check Your Email'))

                    }

                    handleFormShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    return <>
        <div className="subscribe-form">
            <form id="mc-form" onSubmit={submitForm} className="group row align-items-center g-0">
                <div className="col-sm-8">
                    <input type="email" name="EMAIL" value={email} onChange={(e) => setEmail(e.target.value)} className="email box-shadow" id="mc-email" placeholder={t("Email Address")} required="" />
                </div>
                <div className="col-sm-4 mt-2 mt-sm-0">
                    <button className="btn btn-theme btn-circle mx-3" type="submit" name="subscribe" >
                        {t('submit')}
                    </button>

                </div>
                <label htmlFor="mc-email" className="subscribe-message"></label>
            </form >
        </div >
        <Modal
            size="lg"  {...props}
            style={customStyles}
            aria-labelledby="contained-modal-title-vcenter"
            centered show={showForm} onHide={handleFormClose} className="top-0 ">

            <Modal.Header closeButton>
                <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Subscribe')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="team-member text-center">
                    <div className="team-description pt-0">
                        <h5 className="my-5"><Link>{submitMsg}</Link></h5>

                    </div>



                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleFormClose}>
                    {t('Close')}
                </Button>

            </Modal.Footer>

        </Modal>
    </>
}
