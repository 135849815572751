import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function PulmoNephroDay(props) {

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [organization, setOrganization] = useState('');
  const [title, setTitle] = useState('');
  const [errors, setErrors] = useState([]);

  //  submit 
  const [submitMsg, setSubmitMsg] = useState('');
  const [message, setMessage] = useState('');
  const [agenda, setAgenda] = useState('');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const customStyles = {
    content: {
      top: '0',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      // transform: 'translate(-50%, -50%)',
    },
  };

  const validateForm = () => {
    const errors = [];
    const phoneNumberRegex = /^[\+]?[0-9]{0,3}\W?[()0-9]{0,3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const whatsappNumberRegex = /^[\+]?[0-9]{0,3}\W?[()0-9]{0,3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    if (name.trim() === '') {
      errors.push('Name is required.');
    }
    if (email.trim() === '') {
      errors.push('Email is required.');
    }

    if (whatsapp.trim() === '') {
      errors.push('Whatsapp Number is required.');
    }
    else if (!whatsappNumberRegex.test(whatsapp)) {
      errors.push('Please enter a valid Whatsapp number. Expected format: [+][Country Code][Area Code][Whatsapp Number].')
    }
    if (phone.trim() === '') {
      errors.push('Phone Number is required.');
    }
    else if (!phoneNumberRegex.test(phone)) {
      errors.push('Please enter a valid Phone number. Expected format: [+][Country Code][Area Code][Phone Number].')
    }
    if (organization.trim() === '') {
      errors.push('Organization is required.');
    }
    if (title.trim() === '') {
      errors.push('Title is required.');
    }


    setErrors(errors);
    return errors;
  };
  async function submitForm(e) {
    e.preventDefault();
    const formErrors = validateForm();

    if (formErrors.length === 0) {
      const bodyFormData = new FormData();

      bodyFormData.append('full name', name);
      bodyFormData.append('email', email);
      bodyFormData.append('phone', phone);
      bodyFormData.append('whatsapp', whatsapp);
      bodyFormData.append('organization', organization);
      bodyFormData.append('title', title);

      const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/PulmoNephroDay`, bodyFormData)
        .then(response => {

          if (response.data.Ecode === 200) {
            console.log(response.data);




            if (response.data.Edata.request_id > 0) {
              setSubmitMsg('Registration is Successful!');
              setMessage('Thank you for registering and you will be receiving a confirmation message on WhatsApp');

            } else {
              setSubmitMsg('Error !! please Check Your Data')

            }

            handleShow();

          }

        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });

    }
  }
  useEffect(() => {
    if (submitMsg.length > 0) {
      handleShow();

    }

  }, [submitMsg])
  return <>

    <PageTitle title='PulmoNephro' anotherTitle='Day' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/pulmoNephro-day`} currentPage='PulmoNephro Day' />
    {/* <!--page title end--> */}
    <div className="page-content">

      {/* <!--blog start--> */}

      <section>
        <div className="container">
          <div className="row">
            <div className="box-shadow">
              <div className="post-comments">

                <form onSubmit={submitForm}>
                  <div className="messages"></div>
                  <div className="row justify-content-between">
                    <div className="col-md-7 p-5">
                      <div className='row'>
                        <div className="form-group col-md-6">
                          <input id="form_name" type="text" name="name" className={`form-control ${errors.includes('Name is required.') ? 'is-invalid' : ''}`} placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} data-error="Name is required." />
                          {errors.includes('Name is required.') && <p className="ivaild-error">Name is required.</p>}

                        </div>
                        <div className="form-group col-md-6">
                          <input type="email" name="email" className={`form-control ${errors.includes('Email is required.') ? 'is-invalid' : ''}`} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} data-error="Email is required." />
                          {errors.includes('Email is required.') && <p className="ivaild-error">Email is required.</p>}

                        </div>
                        <div className="form-group col-md-6">
                          <input type="number" name="experience" className={`form-control ${errors.includes('Phone Number is required.') ? 'is-invalid' : ''}  ${errors.includes('Please enter a valid Phone number. Expected format: [+][Country Code][Area Code][Phone Number].') ? 'is-invalid' : ''}`} placeholder="Phone Number" value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          {errors.includes('Phone Number is required.') && <p className="ivaild-error">Phone Number is required.</p>}


                        </div>

                        <div className="form-group col-md-6">
                          <input type="number" name="experience" className={`form-control ${errors.includes('Whatsapp Number is required.') ? 'is-invalid' : ''} ${errors.includes('Please enter a valid Whatsapp number. Expected format: [+][Country Code][Area Code][Whatsapp Number].') ? 'is-invalid' : ''}`} placeholder="Whatsapp Number" value={whatsapp}
                            onChange={(e) => setWhatsapp(e.target.value)}
                          />
                          {errors.includes('Whatsapp Number is required.') && <p className="ivaild-error">Whatsapp Number is required.</p>}

                        </div>

                        <div className="form-group col-md-6">
                          <input id="form_address" type="text" name="organization" placeholder="Organization (Work place or University)" value={organization} onChange={(e) => setOrganization(e.target.value)} className={`form-control ${errors.includes('Organization is required.') ? 'is-invalid' : ''}`} />
                          {errors.includes('Organization is required.') && <p className="ivaild-error"> Organization is required.</p>}

                        </div>

                        <div className="form-group col-md-6">
                          <select className={`form-control ${errors.includes('Title is required.') ? 'is-invalid' : ''}`} placeholder="Title" value={title}
                            onChange={(e) => setTitle(e.target.value)}>
                            <option value='Select Title'>Select Title</option>
                            <option value='medical student'>medical student</option>
                            <option value='physician'>physician</option>
                            <option value='pharmacist'>pharmacist</option>
                          </select>
                          {errors.includes('Title is required.') && <p className="ivaild-error">Title is required</p>}

                        </div>

                        <div className="col-md-4">
                          <button className="btn btn-border btn-circle w-100" type='submit' ><span>Submit</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5" style={{ backgroundImage: `url('/images/forms/10_1471_result.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                      {/* <img src="images/bg/04.webp" alt="open day" /> */}
                    </div>
                    <div class="col-sm-12 text-center">
                      <Modal   {...props}
                        size="lg"
                        style={customStyles}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered show={show} onHide={handleClose} className="top-0 ">

                        <Modal.Header closeButton>
                          <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                          <Modal.Title style={{ color: "#4ab242" }}>PulmoNephro Day</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <div className="team-member text-center">

                            <div className="team-description pt-0">
                              <h5 className="my-5"><Link>{submitMsg}</Link></h5>

                            </div>



                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                            Close
                          </Button>

                        </Modal.Footer>

                      </Modal>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
}
