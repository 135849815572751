import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../Utilites/PageTitle';
import Carousel from "react-bootstrap/Carousel";

export default function Radiology() {
    const { pathname } = useLocation();

    return <>
        {/* <!--page title start--> */}
        <PageTitle title='Rdiology' prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services/`} prevPage='Our Services' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/services/radiology`} currentPage='Radiology' />
        {/* <!--page title end--> */}


        <div className="page-content">

            {/* <!--service start--> */}

            <section>
                <div class="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12">

                            <Carousel indicators={false} slide={true}>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/03.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/02.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/01.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/03.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>
                                <Carousel.Item>

                                    <img
                                        src="../../../images/testimonial/02.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>

                            </Carousel>

                        </div>
                        <div className="col-lg-7 col-md-12 mt-5 mt-lg-0">

                            <div className="section-title my-4">
                                <h2 class="title"> Our Services (Allied Health)</h2>
                                <h6 className='h5'><span>Radiology</span></h6>

                                <p>Saudi German Hospital is providing an Ultramodern Digital Radiography & Diagnostic Medical Imaging, Interventional Radiology & Nuclear Medicine departments (PET CT & Gamma Camera ) fully equipped with state of art high technology imaging modalities, being managed by a dedicated specialized team including Specialized and subspecialized   Radiology Consultants and Specialists , Specialized Qualified Radiology Technologists, Radiology Nurses and Radiology Qualified Administrative Staff headed by a highly qualified and experienced Senior Consultant Radiologist.</p>

                            </div>
                        </div>
                    </div>
                    {/* <div className="row justify-content-between mt-5">
                        <h6><span className="text-theme">services :</span></h6>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0 footer-list ps-lg-5">
                            <ul className='list-unstyled'>
                                <li>RIS/PACS
                                </li>

                                <li>Digital Radiography
                                </li>
                                <li>Fluoroscopy
                                </li>
                                <li>Digital Mammography
                                </li>
                                <li>Interventional Neuro- Radiology Unit
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0 footer-list ps-lg-5">
                            <ul className='list-unstyled'>
                                <li>Dental Digital Imaging
                                </li>
                                <li>Digital Subtraction Angiography & Interventional Radiology Suite
                                </li>
                                <li>Ultrasound (2,3&4D) & Color Duplex
                                </li>
                                <li>Multi-Slice CT Scan
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0 footer-list ps-lg-5">
                            <ul className='list-unstyled'>
                                <li>Magnetic Resonance Imaging (MRI)
                                </li>
                                <li>Nuclear Medicine Isotope Scanning And PET-CT
                                </li>
                                <li>Bone Densitometry
                                </li>
                                <li>Female Imaging Unit
                                </li>
                                <li>Sports Injuries Imaging Unit
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="row justify-content-center ">

                        <div className="section-title my-4">

                            <h6 className='h3'><span>Services : </span></h6>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>RIS/PACS</h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Digital Radiography</h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-drug"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Fluoroscopy</h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-blood-donation"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Digital Mammography
                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5  mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Interventional Neuro- Radiology Unit
                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5  mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Dental Digital Imaging
                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5  mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Digital Subtraction Angiography & Interventional Radiology Suite
                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5  mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Ultrasound (2,3&4D) & Color Duplex
                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Multi-Slice CT Scan
                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5  mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-drug"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Magnetic Resonance Imaging (MRI)
                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Bone Densitometry

                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>

                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-drug"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Nuclear Medicine Isotope Scanning And PET-CT

                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Female Imaging Unit

                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">

                            <div class="featured-item text-center">
                                <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                </div>
                                <div class="featured-title text-uppercase">
                                    <h6>Sports Injuries Imaging Unit
                                    </h6>
                                </div>
                                {/* <div class="featured-desc">
<p>We have experience in different areas of Health Center, We offer our Better solutions your
treatment is appropriate.</p>
</div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </div>
    </>
}
